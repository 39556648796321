import React from 'react';
import QuillEditor from '../QuillEditor';
import { doesDeltaContainInsOrDel, getDiffDelta, } from '../QuillEditor/utils/diff';
const DiffFinder = ({ newHTML, oldHTML, onDiff }) => {
    const handleEditorChanged = (quill) => {
        if (quill) {
            const diffDelta = getDiffDelta(quill, oldHTML, newHTML);
            const hasChanges = doesDeltaContainInsOrDel(diffDelta);
            onDiff({
                hasChanges,
                delta: diffDelta,
            });
        }
    };
    return (React.createElement(QuillEditor, { readOnly: true, initialValue: "", onEditorChanged: handleEditorChanged }));
};
export default DiffFinder;
