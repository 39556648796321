export var HelperViewsZStack;
(function (HelperViewsZStack) {
    HelperViewsZStack[HelperViewsZStack["PLAYLIST_CONTROLLER"] = 1] = "PLAYLIST_CONTROLLER";
    HelperViewsZStack[HelperViewsZStack["HIDDEN_VIEW"] = 2] = "HIDDEN_VIEW";
    HelperViewsZStack[HelperViewsZStack["OVERLAY"] = 3] = "OVERLAY";
    HelperViewsZStack[HelperViewsZStack["BOTTOM_DRAWER"] = 4] = "BOTTOM_DRAWER";
    HelperViewsZStack[HelperViewsZStack["IMAGE_VIEWER"] = 5] = "IMAGE_VIEWER";
    HelperViewsZStack[HelperViewsZStack["SNACKBAR"] = 6] = "SNACKBAR";
    HelperViewsZStack[HelperViewsZStack["DROPDOWN"] = 7] = "DROPDOWN";
})(HelperViewsZStack || (HelperViewsZStack = {}));
