import { useCallback, useEffect } from 'react';
import { LayoutAnimation, Platform, UIManager } from 'react-native';
export default function useLayoutAnimation() {
    useEffect(() => {
        if (Platform.OS === 'android') {
            if (UIManager.setLayoutAnimationEnabledExperimental) {
                UIManager.setLayoutAnimationEnabledExperimental(true);
            }
        }
    }, []);
    const setupLayoutAnimation = useCallback(() => {
        LayoutAnimation.configureNext({
            duration: 400,
            update: {
                type: LayoutAnimation.Types.easeIn,
                springDamping: 0.7,
            },
            delete: {
                type: LayoutAnimation.Types.easeIn,
                property: LayoutAnimation.Properties.opacity,
                springDamping: 0.7,
            },
            create: {
                type: LayoutAnimation.Types.easeIn,
                property: LayoutAnimation.Properties.opacity,
                springDamping: 0.7,
            },
        });
    }, []);
    const animations = {
        setupLayoutAnimation,
        setupDeleteLayoutAnimation: setupLayoutAnimation,
        setupUpdateLayoutAnimation: setupLayoutAnimation,
    };
    return animations;
}
