import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import AppleIcon from 'src/components/10-atoms/icons/AppleIcon';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import PressableArea, { PRESSABLE_SIZE, } from 'src/components/templates/PressableArea';
import palette from 'src/customizations/themes/10-parts/color-schemes/palette';
import withThemeProvider from 'src/customizations/themes/hoc/withThemeProvider';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
const AppleSigninBtn = ({ ...btnProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(Button, { btnStyle: styles.appleLoginBtn, textContainerStyle: styleUtils.flex, LeftIcon: ({ disabled }) => (React.createElement(PressableArea
        // eslint-disable-next-line react-native/no-inline-styles
        , { 
            // eslint-disable-next-line react-native/no-inline-styles
            style: {
                backgroundColor: disabled
                    ? theme.palette.pressable.disabled
                    : 'black',
            } },
            React.createElement(AppleIcon, { color: disabled
                    ? theme.palette.pressable.disabledText
                    : theme.palette.primary.contrast, size: PRESSABLE_SIZE }))), RightIcon: ({ disabled }) => (React.createElement(FeatherIcon, { name: "arrow-right", color: disabled
                ? theme.palette.pressable.disabledText
                : theme.palette.primary.contrast })), ...btnProps }, translate('Sign in with Apple')));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    appleLoginBtn: {
        backgroundColor: palette.grey[900],
    },
}));
export default withThemeProvider(AppleSigninBtn, 'light');
