import get from 'lodash/get';
export function searchList(list, keys, searchTerm = '', synonymsLookup) {
    if (!searchTerm) {
        list;
    }
    const searchRegex = new RegExp(searchTerm, 'i');
    function search(value) {
        const synonyms = synonymsLookup && synonymsLookup[value];
        if (synonyms) {
            const synonymMatch = synonyms.some(synonym => synonym.match(searchRegex));
            if (synonymMatch) {
                return true;
            }
        }
        return value.match(searchRegex);
    }
    return list.filter(item => {
        if (typeof item === 'string')
            return search(item);
        return keys.some(key => {
            const value = get(item, key, '');
            return search(value);
        });
    });
}
