import { useCallback, useEffect } from 'react';
import useFTUEStorage from './useFTUEStorage';
export default function useFTUE(key, events = {}, forcedValue) {
    const [state, setState] = useFTUEStorage(key);
    const markComplete = useCallback(() => {
        setState(false);
    }, []);
    useEffect(() => {
        if (state === true) {
            events.onFTUE && events.onFTUE(markComplete);
        }
    }, [state, markComplete]);
    return [forcedValue ?? state, { markComplete }];
}
