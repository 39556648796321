import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
import { NotificationStatus } from '../../types';
export const NotificationUnreadCountPageSize = 10;
const NOTIFICATION_IN_APP_UNREAD_COUNT_QUERY = gql `
  query Notification_getInAppUnreadCount(
    $pagination: PaginationOptions!
    $searchFilter: NotificationInAppListSearchFilter!
  ) {
    notification_getInAppList(
      pagination: $pagination
      searchFilter: $searchFilter
    ) {
      list {
        id
        status
      }
    }
  }
`;
export function useInAppNotificationUnreadCountQuery(options = {}) {
    return useGQLQuery(NOTIFICATION_IN_APP_UNREAD_COUNT_QUERY, {
        ...options,
        variables: {
            searchFilter: { status: NotificationStatus.UNREAD },
            pagination: { offset: 0, limit: 10 },
        },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
    });
}
