import { useEffect } from 'react';
import useIsLoggedIn from 'src/modules/authentication/hooks/useIsLoggedIn';
import useNotificationPermissionStorage from './storage';
import requestPermission from './requestPermission';
export default function useNotificationPermission() {
    const [permissionGranted, setPermissionGranted] = useNotificationPermissionStorage();
    const isLoggedIn = useIsLoggedIn();
    useEffect(() => {
        if (isLoggedIn) {
            requestPermission().then(isEnabled => {
                if (isEnabled) {
                    setPermissionGranted(true);
                }
            });
        }
    }, [isLoggedIn]);
    return [permissionGranted];
}
