import { useEffect } from 'react';
import { BackHandler } from 'react-native';
import { isWeb } from 'src/utils/platform';
export default function useOnBackPress(callback) {
    useEffect(() => {
        if (isWeb) {
            return;
        }
        const subscription = BackHandler.addEventListener('hardwareBackPress', () => {
            try {
                const result = callback();
                return result ?? true;
            }
            catch (error) {
                return false;
            }
        });
        return () => {
            subscription.remove();
        };
    }, [callback]);
}
