import { useEffect, useMemo, useState } from 'react';
const data = {};
function addListener(key, setState, defaultValue) {
    if (!data[key]) {
        data[key] = {
            state: defaultValue,
            handlers: [setState],
        };
    }
    else {
        data[key].handlers.push(setState);
    }
}
function removeListener(key, setState) {
    data[key].handlers = data[key].handlers.filter(stateSetter => stateSetter !== setState);
}
function changeState(key) {
    return (newState) => {
        data[key].state =
            typeof newState === 'function'
                ? newState(data[key].state)
                : newState;
        data[key].handlers.forEach(updateState => {
            updateState(state => !state);
        });
    };
}
export default function useSharedState(key, defaultValue) {
    const [, setState] = useState(false);
    // this should be run just once and in sync
    useMemo(() => {
        addListener(key, setState, defaultValue);
    }, []);
    // Remove the state on unmount of the component
    useEffect(() => () => removeListener(key, setState), [key, setState]);
    return [data[key].state, changeState(key)];
}
