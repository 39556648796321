import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
const QUESTION_TOPICS_LIST_QUERY = gql `
  query EP_QuestionTopicList($ancestorID: ID!) {
    ep_questionTopicList(ancestorID: $ancestorID) {
      totalCount
      list {
        id
        questionCount
        title
      }
    }
  }
`;
export function useQuestionTopicListQuery(ancestorID) {
    return useGQLQuery(QUESTION_TOPICS_LIST_QUERY, {
        variables: {
            ancestorID,
        },
    });
}
