export var ICaseType;
(function (ICaseType) {
    ICaseType[ICaseType["COMMUNITY_ABSUIVE_CONTENT"] = 0] = "COMMUNITY_ABSUIVE_CONTENT";
    ICaseType[ICaseType["EP_ABSUIVE_CONTENT"] = 1] = "EP_ABSUIVE_CONTENT";
})(ICaseType || (ICaseType = {}));
export var ICaseEntityType;
(function (ICaseEntityType) {
    ICaseEntityType[ICaseEntityType["COMMUNITY_ANSWER"] = 0] = "COMMUNITY_ANSWER";
    ICaseEntityType[ICaseEntityType["COMMUNITY_QUESTION"] = 1] = "COMMUNITY_QUESTION";
    ICaseEntityType[ICaseEntityType["COMMUNITY_REPLY"] = 2] = "COMMUNITY_REPLY";
    ICaseEntityType[ICaseEntityType["EP_WRITTEN_ANSWER"] = 3] = "EP_WRITTEN_ANSWER";
    ICaseEntityType[ICaseEntityType["EP_ORALS_ANSWER"] = 4] = "EP_ORALS_ANSWER";
})(ICaseEntityType || (ICaseEntityType = {}));
