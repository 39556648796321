import { useMemo } from 'react';
export default function useListState(queryState) {
    const listQueryState = useMemo(() => ({
        loading: (queryState.loading &&
            (!queryState.data || queryState.data.list.length === 0)) ||
            (!queryState.loading && !queryState.error && !queryState.data),
        error: queryState.error,
        empty: queryState.data
            ? queryState.data.totalCount === 0 ||
                (queryState.data.totalCount < 0 && queryState.data.list.length === 0)
            : false,
    }), [queryState]);
    const listDataState = useMemo(() => {
        const listData = [];
        if (listQueryState.loading) {
            listData.push({
                type: 'list_loading',
            });
        }
        else if (listQueryState.error) {
            listData.push({
                type: 'list_error',
            });
        }
        else if (queryState.data) {
            if (listQueryState.empty) {
                listData.push({ type: 'list_empty' });
            }
            else {
                listData.push(...queryState.data.list.map((item) => ({
                    type: 'list_data',
                    data: item,
                })));
                if (!queryState.data.hasMore) {
                    listData.push({
                        type: 'end_of_list',
                    });
                }
            }
        }
        return listData;
    }, [queryState, listQueryState]);
    const state = { query: listQueryState, data: listDataState };
    return [state];
}
