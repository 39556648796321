import Url from 'url-parse';
// interface URLParse {
//   protocol: string;
//   slashes: boolean;
//   auth: string;
//   username: string;
//   password: string;
//   host: string;
//   hostname: string;
//   port: string;
//   pathname: string;
//   query: Record<string, string>;
//   hash: string;
//   href: string;
//   origin: string;
// }
export default function parseURL(url) {
    return new Url(url);
}
