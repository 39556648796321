import useRequest from 'src/hooks/request/useRequest';
const getLangTranslationsAPIConfig = (code, updatePref = true) => {
    return {
        url: `/translations/lang/${code}`,
        params: {
            updatePref,
        },
        bypassETag: updatePref,
        useMock: false,
        mockResponse: {
            status: '200',
            headers: {
                vary: 'Origin',
                'request-id': 'f5778a69-b0d7-4a9c-9f2a-18bdffca5505',
                etag: '4110771835',
                'content-type': 'application/json; charset=utf-8',
                'content-length': '204',
                connection: 'keep-alive',
            },
            data: {
                id: '6416f37fecc7db8e7ad785c1',
                code: 'en',
                contentHash: 4110771835,
                label: 'English',
                dateLocale: 'enUS',
                translations: {
                    // Auth
                    'Sign in with Apple': 'Sign in with Apple',
                    'Sign in with Google': 'Sign in with Google',
                    'Continue as Guest': 'Continue as Guest',
                    'By signing in, you agree to ': 'By signing in, you agree to ',
                    'Terms of Service': 'Terms of Service',
                    ' and ': ' and ',
                    'Privacy Policy': 'Privacy Policy',
                    'User was not authenticated by Apple. Please try other Sigin options': 'User was not authenticated by Apple. Please try other Sigin options',
                    'Login to perform the Action': 'Login to perform the Action',
                    // Community
                    'Show only Questions that needs help': 'Only Questions that needs help',
                    'Delete Answer': 'Delete Answer',
                    'Are you sure you want to delete this answer?': 'Are you sure you want to delete this answer?',
                    'Edit Answer': 'Edit Answer',
                    'Please enter an Answer': 'Please enter an Answer',
                    'You have blocked this Answer': 'You have blocked this Answer',
                    'You have blocked the Author of this Answer': 'You have blocked the Author of this Answer',
                    'Add your Answer': 'Add your Answer',
                    'You have already Answered this Question, please update the same if necessary': 'You have already Answered this Question, please update the same if necessary',
                    ANSWERS: 'ANSWERS',
                    'Marine Community is waiting for an Answer that you might know': 'Marine Community is waiting for an Answer that you might know',
                    'ADD YOUR ANSWER': 'ADD YOUR ANSWER',
                    'Share this Question': 'Share this Question',
                    'Successfully submitted your Answer!': 'Successfully submitted your Answer!',
                    'We Salute your contribution on behalf of Marine Community': 'We Salute your contribution on behalf of Marine Community',
                    'Mark as Accepted': 'Mark as Accepted',
                    'Remove Accepted': 'Remove Accepted',
                    Accepted: 'Accepted',
                    Answer: 'Answer',
                    'Needs Help': 'Needs Help',
                    'Delete Reply': 'Delete Reply',
                    'Are you sure you want to delete this reply ?': 'Are you sure you want to delete this reply ?',
                    'You have blocked this Reply': 'You have blocked this Reply',
                    'You have blocked the Author of this Reply': 'You have blocked the Author of this Reply',
                    Reply: 'Reply',
                    'SHOW %repliesCount REPLY': 'SHOW %repliesCount REPLY',
                    'SHOW %repliesCount REPLIES': 'SHOW %repliesCount REPLIES',
                    'Please enter a reply': 'Please enter a reply',
                    'Enter your Reply': 'Enter your Reply',
                    "Post a New Question only if it's not present already": "Post a New Question only if it's not present already",
                    Search: 'Search',
                    'Successfully submitted your Question!': 'Successfully submitted your Question!',
                    'Sit back and relax! We’ll notify you once your Question is answered by the Community': 'Sit back and relax! We’ll notify you once your Question is answered by the Community',
                    // Exam Preparation
                    'Select your Exam Community': 'Select your Exam Community',
                    'Select Exam Level': 'Select Exam Level',
                    '-- Select your Exam Level --': '-- Select your Exam Level --',
                    Subjects: 'Subjects',
                    'There are no Exam Communities at the moment. Please check again later on.': 'There are no Exam Communities at the moment. Please check again later on.',
                    'There are no Exam Levels for this Community at the moment. Please check again later on.': 'There are no Exam Levels for this Community at the moment. Please check again later on.',
                    'There are no Subjects for this Exam at the moment. Please check again later on.': 'There are no Subjects for this Exam at the moment. Please check again later on.',
                    'Have Suggestions?': 'Have Suggestions?',
                    'Have the Answer?': 'Have the Answer?',
                    "Nobody answered it yet.\nOthers don't have to see this\nif YOU": "Nobody answered it yet.\nOthers don't have to see this\nif YOU",
                    "Have Examiner's Feedback?": "Have Examiner's Feedback?",
                    "We don't have it yet.\nOthers don't have to see this\nif YOU": "We don't have it yet.\nOthers don't have to see this\nif YOU",
                    "Currently Dark mode doesn't support Math expressions": "Currently Dark mode doesn't support Math expressions",
                    "Examiner's Feedback": "Examiner's Feedback",
                    'Question Paper': 'Question Paper',
                    'Search for a question / topic...': 'Search for a question / topic...',
                    'There are no Question Papers for this Subject at the moment. Please check again later on.': 'There are no Question Papers for this Subject at the moment. Please check again later on.',
                    'Question Papers': 'Question Papers',
                    'There are no Topics for this Subject at the moment. Please check again later on.': 'There are no Topics for this Subject at the moment. Please check again later on.',
                    Subject: 'Subject',
                    Topic: 'Topic',
                    Year: 'Year',
                    'Topic-wise Questions': 'Topic-wise Questions',
                    '%marks marks': '%marks marks',
                    'There are no Questions at the moment.\nPlease check again later on.': 'There are no Questions at the moment.\nPlease check again later on.',
                    // Common
                    ADD: 'ADD',
                    UPDATE: 'UPDATE',
                    OK: 'OK',
                    Cancel: 'Cancel',
                    'Something went wrong, please try again': 'Something went wrong, please try again',
                },
                fallbackLangs: [],
            },
        },
    };
};
export function useGetLangTranslationsAPI() {
    return useRequest(getLangTranslationsAPIConfig);
}
