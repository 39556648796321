import React from 'react';
import { View } from 'react-native';
import Label from 'src/components/10-atoms/form/Label';
import Column from 'src/components/10-atoms/layout/Column';
import MDRenderer from 'src/components/10-atoms/markdown/MarkDownRenderer';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const ExaminersFeedbackTimelineItem = ({ questionPaper, examinersFeedback, isLast, isCurrent, style, ...colProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(Column, { style: [styles.container, style], ...colProps },
        React.createElement(Column, { style: [styles.innerContainer, isLast && styles.lastContainer] },
            React.createElement(View, { style: styles.marker }),
            React.createElement(Label, { fontWeight: isCurrent
                    ? TypographyFontWeight.MEDIUM
                    : TypographyFontWeight.REGULAR }, `${questionPaper.data.label}${isCurrent ? translate(' (Current)') : ''}`),
            examinersFeedback ? (React.createElement(MDRenderer, null, examinersFeedback.data.feedback)) : (React.createElement(Typography, { variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary }, translate("Examiner's Feedback is missing"))))));
};
const MARKER_SIZE = 24;
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingLeft: 12,
    },
    innerContainer: {
        borderLeftWidth: 1,
        borderLeftColor: theme.palette.border.main,
        paddingLeft: theme.spacing.standard.md,
        paddingBottom: theme.spacing.itemSeparation,
    },
    lastContainer: {
        borderLeftWidth: 0,
    },
    marker: {
        position: 'absolute',
        top: 0,
        left: -(MARKER_SIZE / 2),
        width: MARKER_SIZE,
        height: MARKER_SIZE,
        borderRadius: MARKER_SIZE,
        borderWidth: 2,
        borderColor: theme.palette.background.main,
        backgroundColor: theme.palette.background.darker,
    },
}));
export default ExaminersFeedbackTimelineItem;
