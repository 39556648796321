import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import userStorage from 'src/modules/authentication/storage/user/storage';
import { isRegisteredUser } from 'src/modules/authentication/storage/user/utils';
import getGraphQLEntityType from '../utils/getGraphQLEntityType';
import { ICaseEntityType } from './types';
const BLOCK_CONTENT_MUTATION = gql `
  mutation UGCPolicy_blockContent($entity: CaseEntity!) {
    ugcPolicy_blockContent(entity: $entity)
  }
`;
export function useBlockContentMutation(options) {
    return useGQLMutation(BLOCK_CONTENT_MUTATION, {
        // animateOnSuccess: true,
        ...options,
        update(...args) {
            const [cache, , { variables }] = args;
            if (variables?.entity) {
                const gqlEntityType = getGraphQLEntityType(variables.entity.type);
                const userDetails = userStorage.getUserDetails();
                if (
                // 'EPAnswerHistoryItem' doesn't have an graphql identity
                // hence modifying the cache doesn't make sense
                ![
                    ICaseEntityType.EP_WRITTEN_ANSWER,
                    ICaseEntityType.EP_ORALS_ANSWER,
                ].includes(variables.entity.type) &&
                    gqlEntityType &&
                    userDetails &&
                    isRegisteredUser(userDetails)) {
                    cache.modify({
                        id: cache.identify({
                            id: variables.entity.id,
                            __typename: gqlEntityType,
                        }),
                        fields: {
                            blockedBy(existing) {
                                return (existing ?? []).concat(userDetails.id);
                            },
                        },
                    });
                }
            }
        },
    });
}
