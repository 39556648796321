import React, { useMemo } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Field from 'src/components/10-atoms/form/Field';
import Form from 'src/components/10-atoms/form/Form';
import { getFieldState } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import useFormState from 'src/components/10-atoms/form/hooks/useFormState';
import SelectInputGroup from 'src/components/10-atoms/form/inputs/select-input-group/SelectInputGroup';
import { ISelectionType } from 'src/components/10-atoms/form/inputs/SelectInput/types';
import Message from 'src/components/10-atoms/info/Message';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import useI18n from 'src/modules/translations/hooks/useI18n';
const ReportAbuseReasonDrawer = ({ onConfirm, }) => {
    const { translate } = useI18n();
    const options = useMemo(() => [
        {
            id: 'option-1',
            title: translate('Sexual Content'),
        },
        {
            id: 'option-2',
            title: translate('Violent or repulsive content'),
        },
        {
            id: 'option-3',
            title: translate('Hateful or abusive content'),
        },
        {
            id: 'option-4',
            title: translate('Harmful or dangerous acts'),
        },
        {
            id: 'option-5',
            title: translate('Spam or misleading'),
        },
        {
            id: 'option-6',
            title: translate('Child abuse'),
        },
    ], []);
    const [formState, formActions, formHelpers] = useFormState({
        fields: {
            reason: getFieldState([]),
        },
    }, {
        fieldValidations: {
            reason({ value }) {
                if (!value) {
                    return translate('Please tell us why you want to report this content?');
                }
                return true;
            },
        },
    });
    const handleSubmit = () => {
        if (formHelpers.hasErrors() || !formActions.validateForm()) {
            snackbarHelperActions.show({
                variant: 'NEGATIVE',
                message: translate('Please check the errors in the form'),
            });
        }
        else {
            onConfirm(formState.fields.reason.value.map(item => item.title).join(','));
        }
    };
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Report Abuse')),
        React.createElement(BottomDrawerLayout.Body, { ph: 0, pv: 0 },
            React.createElement(Message, null, translate('Your identity will never be revealed to the Author')),
            React.createElement(Form, null,
                React.createElement(Field, { label: "Reason", error: formState.fields.reason.errors[0]?.message },
                    React.createElement(SelectInputGroup, { type: ISelectionType.MULTI, value: formState.fields.reason.value, options: options, onChange: formActions.fields.reason.setValue })))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: handleSubmit }, translate('Submit')))));
};
export default ReportAbuseReasonDrawer;
