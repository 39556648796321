import React, { useRef } from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import OverlayLayout from 'src/components/10-atoms/overlays/OverlayLayout';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import HTMLEditor from './Editor';
import { findDiff } from './DiffFinder/utils';
const HTMLEditorOverlay = ({ title, html, placeholder, onClose, onSubmit, ...columnProps }) => {
    const [{}] = useTheme(stylesGenerator);
    const htmlEditorRef = useRef(null);
    const handleSubmit = useCallbackWithCtx(async (ctx) => {
        try {
            const updatedHTML = htmlEditorRef.current?.getHTML() ?? '';
            const { hasChanges } = await findDiff(ctx.html, updatedHTML);
            onSubmit({
                html: updatedHTML,
                hasContent: htmlEditorRef.current?.hasContent() ?? true,
                hasChanges,
            });
        }
        catch (error) {
            console.error('Failed to get HTML Editor Content:', error);
        }
    }, { html });
    return (React.createElement(Column, { flex: true, ...columnProps },
        React.createElement(OverlayLayout.FormHeader, { title: title, onSubmit: handleSubmit, onClose: onClose }),
        React.createElement(HTMLEditor, { ref: htmlEditorRef, flex: true, initialValue: html, placeholder: placeholder })));
};
const stylesGenerator = createThemedStyle(() => ({}));
export default HTMLEditorOverlay;
