import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import { refetchCommunityQuestionList } from 'src/modules/community/graphql/question/list/queries';
import { GET_BLOCKED_USER_LIST_QUERY } from './getBlockedUserList';
import { GET_BLOCKED_USER_LIST_RAW_QUERY } from './getBlockedUserListRaw';
const UNBLOCK_USER_MUTATION = gql `
  mutation UGCPolicy_unblockUser($userID: String!) {
    ugcPolicy_unblockUser(blockedUserID: $userID)
  }
`;
export function useUnblockUserMutation(options) {
    return useGQLMutation(UNBLOCK_USER_MUTATION, {
        animateOnSuccess: true,
        ...options,
        update(cache, {}, { variables }) {
            if (variables?.userID) {
                cache.updateQuery({ query: GET_BLOCKED_USER_LIST_RAW_QUERY }, data => ({
                    ugcPolicy_getBlockedUserListRaw: (data?.ugcPolicy_getBlockedUserListRaw ?? []).filter((userID) => userID !== variables.userID),
                }));
                cache.updateQuery({
                    query: GET_BLOCKED_USER_LIST_QUERY,
                    variables: {
                        pagination: {
                            offset: 0,
                        },
                    },
                }, data => ({
                    ugcPolicy_getBlockedUserList: {
                        totalCount: (data?.ugcPolicy_getBlockedUserList?.totalCount || 1) - 1,
                        list: (data?.ugcPolicy_getBlockedUserList?.list ?? []).filter((item) => item.id !== variables.userID),
                    },
                }));
            }
        },
        refetchQueries: [refetchCommunityQuestionList],
    });
}
