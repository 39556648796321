import { gql } from '@apollo/client';
import { addTypePolicies } from 'src/lib/graphql/cache';
import useGQLPaginatedQuery from 'src/lib/graphql/hooks/useGQLPaginatedQuery';
import paginatedListFieldPolicy from 'src/lib/graphql/utils/paginatedListFieldPolicy';
export const QP_LIST_QUERY = gql `
  query EP_QPList($subjectID: ID!, $pagination: PaginationOptions!) {
    ep_qpList(subjectID: $subjectID, pagination: $pagination) {
      totalCount
      list {
        id
        type
        data {
          date
          label
        }
      }
    }
  }
`;
export function useQPListQuery(subjectID) {
    return useGQLPaginatedQuery(QP_LIST_QUERY, 'ep_qpList', {
        variables: {
            subjectID,
            pagination: {
                offset: 0,
            },
        },
    });
}
addTypePolicies({
    Query: {
        fields: {
            ep_qpList: paginatedListFieldPolicy(['subjectID']),
        },
    },
});
