import usePersistedState from 'src/hooks/globalState/usePersistedState';
import StorageKeys from 'src/lib/storage/storageKeys';
import { NotificationUnreadCountPageSize } from '../graphql/in-app/unread-count';
export default function useUnreadNotificationCount() {
    const [state, setState] = usePersistedState(StorageKeys.unreadNotificationCount);
    const count = state ?? 0;
    const countState = {
        count,
        display: count >= NotificationUnreadCountPageSize
            ? `${NotificationUnreadCountPageSize}+`
            : String(count),
    };
    return [countState, setState];
}
