import { useMemo } from 'react';
import { Dimensions } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { MAX_VIEWPORT_WIDTH } from 'src/constants/dimensions';
import { isAndroid, isWeb } from 'src/utils/platform';
const deviceWidth = Dimensions.get('window').width;
const deviceHeight = isAndroid
    ? require('react-native-extra-dimensions-android').get('REAL_WINDOW_HEIGHT')
    : Dimensions.get('window').height;
export default function useDimensions() {
    const safeAreaInsets = useSafeAreaInsets();
    const dimensions = useMemo(() => {
        // Computing the device Width inside the useMemo, as
        // it was returing 0 due to unknown reasons, when
        // computed outside
        const dw = isWeb ? window.innerWidth : deviceWidth;
        const dh = isWeb ? window.innerHeight : deviceHeight;
        return {
            device: {
                height: dh,
                width: dw,
            },
            viewPort: {
                height: dh,
                width: Math.min(dw, MAX_VIEWPORT_WIDTH),
            },
            safeAreaInsets,
        };
    }, [safeAreaInsets]);
    return [dimensions];
}
