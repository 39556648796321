import { isWebView, isWeb } from 'src/utils/platform';
import { IDebugEnv } from '../../types';
const defaultEnv = __DEV__
    ? isWeb && !isWebView
        ? IDebugEnv.DEV
        : IDebugEnv.PRE_PROD
    : IDebugEnv.PROD;
export const getDefaultDebugSettingsState = () => ({
    env: defaultEnv,
    webEnv: defaultEnv,
    disableHotReload: false,
});
