export default function getFieldActions(setState, options = {}) {
    const { clearErrorsOnChange = true, customFieldActions } = options;
    const setError = (errors) => {
        setState(prevState => ({
            ...prevState,
            errors: [].concat(errors),
        }));
    };
    const updateMeta = (meta) => {
        setState(prevState => ({
            ...prevState,
            meta: {
                ...prevState.meta,
                ...meta,
            },
        }));
    };
    const updateValue = (newValue) => {
        setState(prevState => ({
            ...prevState,
            meta: {
                ...prevState.meta,
                dirty: true,
            },
            value: newValue,
            errors: clearErrorsOnChange ? [] : prevState.errors,
        }));
    };
    const actions = {
        hide: () => updateMeta({ hidden: true }),
        show: () => updateMeta({ hidden: false }),
        disable: () => updateMeta({ disabled: true }),
        enable: () => updateMeta({ disabled: false }),
        focus: () => updateMeta({ focused: true, touched: true }),
        blur: () => updateMeta({ focused: false }),
        setValue: updateValue,
        setError,
        clearError: () => setError([]),
    };
    return Object.assign(actions, customFieldActions);
}
