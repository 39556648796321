import StorageKeys from 'src/lib/storage/storageKeys';
import usePersistedState from 'src/hooks/globalState/usePersistedState';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import useIsLoggedIn from 'src/modules/authentication/hooks/useIsLoggedIn';
import { useUpdateTokenMutation } from '../../graphql/updateToken';
import registerTokenListener from './registerTokenListener';
function printToken(token) {
    console.log('*'.repeat(20));
    console.log('FCM Token:', token);
    console.log('*'.repeat(20));
}
function useFCMToken() {
    return usePersistedState(StorageKeys.fcmToken);
}
export default function useFCMTokenUpdater(permissionGranted) {
    const [fcmToken, setFCMToken] = useFCMToken();
    const [, updateTokenMutationActions] = useUpdateTokenMutation();
    const isLoggedIn = useIsLoggedIn();
    useEffectWithCtx(ctx => {
        if (permissionGranted && isLoggedIn) {
            function handleUpdateToken(token) {
                __DEV__ && printToken(token);
                if (fcmToken !== token) {
                    setFCMToken(token);
                    ctx.updateToken.update({
                        variables: {
                            token,
                        },
                    });
                }
            }
            return registerTokenListener(handleUpdateToken);
        }
    }, { updateToken: updateTokenMutationActions, fcmToken }, [permissionGranted, isLoggedIn]);
}
