import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
export const EP_QUESTION_DATA_FIELDS = gql `
  fragment EPQuestionDataFields on EPQuestionData {
    questionHTML
    marks
    topics
    sequence
    label
    isSubQuestion
  }
`;
export const EP_QUESTION_FIELDS = gql `
  fragment EPQuestionFields on EPQuestion {
    id
    type
    data {
      ...EPQuestionDataFields
    }
    translationInfo {
      ...TranslationInfoFields
    }
    config {
      WRITTEN_QUESTION {
        hasExaminersFeedback
      }
    }
    ancestorsDetails @include(if: $withAncestorsDetails) {
      writtenSubject {
        id
        data {
          title
        }
      }
      writtenQP {
        id
        data {
          date
          label
        }
      }
      oralsSubject {
        id
        data {
          title
        }
      }
      oralsQP {
        id
        data {
          date
          label
        }
      }
    }
    examinersFeedback @include(if: $withEF) {
      id
      data {
        feedback
      }
      translationInfo {
        ...TranslationInfoFields
      }
    }
    answer {
      id
      data {
        answerHistory {
          ctime
          answer
          author {
            id
            details {
              avatarURL
              firstName
              lastName
              userName
            }
          }
        }
      }
      config {
        WRITTEN_ANSWER {
          contribution {
            enableHistory
            enableEdit
          }
        }
      }
      translationInfo {
        ...TranslationInfoFields
      }
    }
    questionPaper {
      id
      data {
        label
        date
      }
    }
    duplicates {
      questionPaper {
        id
        data {
          label
          date
        }
      }
      examinersFeedback @include(if: $withEF) {
        id
        data {
          feedback
        }
        translationInfo {
          ...TranslationInfoFields
        }
      }
    }
  }

  ${EP_QUESTION_DATA_FIELDS}
`;
export const EP_QUESTION_DETAILS_QUERY = gql `
  query EP_QuestionDetails(
    $questionID: ID!
    $withAncestorsDetails: Boolean = true
    $withEF: Boolean = true
  ) {
    ep_questionDetails(questionID: $questionID) {
      ...EPQuestionFields
    }
  }

  ${EP_QUESTION_FIELDS}
`;
export function useQuestionDetailsQuery(options) {
    return useGQLQuery(EP_QUESTION_DETAILS_QUERY, options);
}
