import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Button from './Button';
import useTimer from 'src/hooks/useTimer';
import TimerIcon from '../icons/TimerIcon';
const TimerButton = ({ enableAfter, children, LeftIcon: leftIcon, disabled, ...btnProps }) => {
    const [{}] = useTheme(stylesGenerator);
    const [timerState] = useTimer(enableAfter);
    return (React.createElement(Button, { textStyle: !timerState.completed && { textTransform: 'none' }, LeftIcon: timerState.completed ? leftIcon : () => React.createElement(TimerIcon, null), disabled: !timerState.completed ? true : disabled, ...btnProps }, !timerState.completed
        ? `${enableAfter - timerState.progressInSeconds}s`
        : children));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({}));
export default TimerButton;
