import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import NavigationBar from '../../../components/NavigationBar';
import useData from './useData';
const QPNavigationBar = ({ ...actionsBarProps }) => {
    const [{}] = useTheme(stylesGenerator);
    const [state, actions] = useData();
    return (React.createElement(NavigationBar, { sab: true, error: state.siblingQuery.error, loading: state.siblingQuery.loading, prevDisabled: !state.prevQuestion, onPrevPress: actions.handlePrevPress, nextDisabled: !state.nextQuestion, onNextPress: actions.handleNextPress, ...actionsBarProps }));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPNavigationBar;
