import React, { useCallback, useMemo, useState } from 'react';
import { Pressable as RNPressable, } from 'react-native';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import { getDefaultHitSlop } from './utils';
const Pressable = React.forwardRef(({ touchFeedback = true, style, disabled, onPress, onLayout, children, ...pressableProps }, ref) => {
    const [{}] = useTheme(stylesGenerator);
    const [pressActive, setPressActive] = useState(false);
    const [containerDimensions, setContainerDimensions] = useState({
        height: PRESSABLE_SIZE,
        width: PRESSABLE_SIZE,
    });
    const handleLayout = useCallback((ev) => {
        setContainerDimensions({
            height: ev.nativeEvent.layout.height,
            width: ev.nativeEvent.layout.width,
        });
    }, []);
    const defaultHitSlop = useMemo(() => getDefaultHitSlop(containerDimensions), [containerDimensions]);
    return onPress ? (React.createElement(RNPressable, { ref: ref, onPress: disabled ? undefined : onPress, hitSlop: defaultHitSlop, onLayout: ev => {
            handleLayout(ev);
            onLayout && onLayout(ev);
        }, style: [
            touchFeedback &&
                !disabled && {
                opacity: pressActive ? 0.6 : 1,
            },
            style,
        ], onPressIn: () => setPressActive(true), onPressOut: () => setPressActive(false), disabled: disabled, ...pressableProps }, children)) : (React.createElement(React.Fragment, null, children));
});
const stylesGenerator = createThemedStyle(({}) => ({}));
export default Pressable;
