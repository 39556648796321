import { createNativeStackNavigator, } from '@react-navigation/native-stack';
import React from 'react';
import { Platform } from 'react-native';
import useIsLoggedIn from 'src/modules/authentication/hooks/useIsLoggedIn';
import { renderDebugStackRoutes } from 'src/modules/debug/debugStackRoutes';
import { renderNotificationStackRoutes } from 'src/modules/notification/notificationStackRoutes';
import { renderReviewPipelineStackRoutes } from 'src/modules/review-pipeline/reviewPipelineStackRoutes';
import { renderSTCStackRoutes } from 'src/modules/support-the-creators/stcStackRoutes';
import { renderUserStackRoutes } from 'src/modules/user/userStackRoutes';
import renderAuthRoutes from '../modules/authentication/authRoutes';
import { renderExamPreparationStackRoutes } from '../modules/exam-preparation/examPreparationStackRoutes';
import NavigationWrapper from './NavigationWrapper';
import { renderTabStackRoutes } from './TabNavigation';
export const RootStack = createNativeStackNavigator();
const stackNavigationOptions = {
    headerShown: false,
    gestureEnabled: Platform.OS === 'ios',
};
const RootNavigation = ({ ...navigationWrapperProps }) => {
    const isLoggedIn = useIsLoggedIn();
    return (React.createElement(NavigationWrapper, { ...navigationWrapperProps },
        React.createElement(RootStack.Navigator, { initialRouteName: "Login", screenOptions: stackNavigationOptions }, !isLoggedIn ? (renderAuthRoutes()) : (React.createElement(React.Fragment, null,
            renderTabStackRoutes(),
            renderExamPreparationStackRoutes(),
            renderNotificationStackRoutes(),
            renderUserStackRoutes(),
            renderReviewPipelineStackRoutes(),
            __DEV__ && renderDebugStackRoutes(),
            renderSTCStackRoutes())))));
};
export default RootNavigation;
