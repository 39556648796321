import React from 'react';
import { Path } from 'react-native-svg';
import { IUserReputationBadgeRank } from 'src/modules/common/user/types';
import SVGIcon from './SVGIcon';
import withDefaultIconProps from './withDefaultIconProps';
const getColor = (() => {
    const colorMap = {
        [IUserReputationBadgeRank.BRONZE]: '#CC711D',
        [IUserReputationBadgeRank.SILVER]: '#BEBEBE',
        [IUserReputationBadgeRank.GOLD]: '#EFC83C',
    };
    return (type) => colorMap[type];
})();
const ReputationBadgeIcon = ({ type, color, ...rest }) => {
    color = getColor(type);
    return (React.createElement(SVGIcon, { ...rest },
        React.createElement(Path, { d: "m4 5 8-3 8 3v5.955c0 4.318-2.44 8.265-6.3 10.195a3.8 3.8 0 0 1-3.4 0A11.398 11.398 0 0 1 4 10.955V5Z", fill: color })));
};
export default withDefaultIconProps(ReputationBadgeIcon);
