import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
export default function useFormStyles({ focused = false, disabled = false, }) {
    const [{ styles }] = useTheme(stylesGenerator, { focused, disabled }, [
        focused,
        disabled,
    ]);
    return styles;
}
const stylesGenerator = createThemedStyle(({ theme }, { focused = false, disabled = false }) => ({
    inputContainer: {
        borderWidth: 1,
        borderColor: focused
            ? theme.palette.form.input.border.focused
            : theme.palette.form.input.border.blurred,
        backgroundColor: focused
            ? theme.palette.form.input.background.focused
            : disabled
                ? theme.palette.form.input.background.disabled
                : theme.palette.form.input.background.blurred,
        borderRadius: theme.borderRadius.standard.md,
        paddingHorizontal: theme.spacing.standard.xs,
    },
}));
