import React from 'react';
import { View } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import { getStyleForSize } from './utils';
const AvatarLoader = ({ size, style, ...viewProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(View, { style: [
            {
                backgroundColor: theme.palette.loader.placeholder,
            },
            getStyleForSize(size),
            style,
        ], ...viewProps }));
};
export default AvatarLoader;
