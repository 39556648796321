import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import ModalBase from 'src/components/10-atoms/modals/ModalBase';
import useQueuedVisibility from 'src/hooks/useQueuedVisibility';
import Column from 'src/components/10-atoms/layout/Column';
import styleUtils from 'src/utils/styles';
const DatePicker = ({ visible, value, minValue, maxValue, onClose, onChange, }) => {
    const [{ theme, styles }] = useTheme(stylesGenerator);
    const [queuedVisible, queuedVisibilityActions] = useQueuedVisibility(visible);
    const handleClose = () => {
        queuedVisibilityActions.handleHideCompleted();
        onClose();
    };
    return (React.createElement(ModalBase, { animationIn: "slideInUp", animationOut: "slideOutDown", style: styles.modal, propagateSwipe: true, isVisible: queuedVisible, onDismiss: handleClose },
        React.createElement(Column, { bg: theme.palette.background.main, brd: theme.borderRadius.standard.xl, style: styleUtils.ovfHidden },
            React.createElement(StaticDatePicker, { defaultValue: value, minDate: minValue, maxDate: maxValue, onAccept: date => onChange?.(date), onClose: handleClose }))));
};
const stylesGenerator = createThemedStyle(({}) => ({
    modal: {
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
export default DatePicker;
