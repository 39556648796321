import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        borderRadius: theme.borderRadius.standard.md,
        paddingHorizontal: theme.spacing.standard.xs,
        paddingVertical: theme.spacing.standard.xs,
        backgroundColor: theme.palette.background.dark,
    },
}));
export default stylesGenerator;
