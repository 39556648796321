import { useCallback, useMemo, useRef, useState } from 'react';
import APIClient from 'src/lib/api-client';
import Cache from 'src/lib/cache';
import { getCacheKey, getCacheTTL, isEligibleForCache } from './cache';
import ETagUtils from './etag';
import requestInProgress from './inProgress';
import { AxiosError } from 'axios';
const getDefaultState = (...overrideState) => Object.assign({
    loading: false,
    error: null,
    data: null,
}, ...overrideState);
function useRequest(getAPIConfig, events = {}, options = {}) {
    const { initialState } = options;
    const { onLoading, onData, onError } = events;
    const [state, setState] = useState(getDefaultState(initialState));
    const validReqTime = useRef(Date.now());
    const forceFetch = useRef(false);
    const updateState = useCallback((newState) => {
        setState(prevState => Object.assign(getDefaultState({ data: prevState.data }), newState));
    }, []);
    const cancelPrevRequests = useCallback(() => {
        validReqTime.current = Date.now();
    }, []);
    const isNotStaleRequest = useCallback((reqTime) => {
        return validReqTime.current <= reqTime;
    }, []);
    const updateData = useCallback((data, ...args) => {
        updateState({ data });
        onData && onData(getDefaultState({ data }), ...args);
    }, []);
    const fetch = useCallback(async (...args) => {
        cancelPrevRequests();
        const reqStartTime = Date.now();
        updateState({ loading: true });
        onLoading && onLoading();
        try {
            const apiConfig = getAPIConfig(...args);
            // For responding with a mock. This can be useful
            // during testing for quicker prototyping
            if (apiConfig.useMock && apiConfig.mockResponse) {
                updateData(apiConfig.mockResponse.data, ...args);
                return;
            }
            // Do not use cached data if forceFetch is true
            if (!forceFetch.current &&
                isEligibleForCache(apiConfig, options.paginated)) {
                if (Cache.has(getCacheKey(apiConfig))) {
                    const data = Cache.get(getCacheKey(apiConfig));
                    updateData(data, ...args);
                    return;
                }
                else if (requestInProgress.exists(apiConfig)) {
                    const response = await requestInProgress.get(apiConfig);
                    updateData(response.data, ...args);
                    return;
                }
            }
            // Marking forceFetch as complete
            forceFetch.current = false;
            const etag = ETagUtils.getETagForRequest(apiConfig);
            if (etag) {
                if (!apiConfig.headers) {
                    apiConfig.headers = {};
                }
                apiConfig.headers.etag = etag;
            }
            const apiRequest = APIClient(apiConfig);
            if (isEligibleForCache(apiConfig)) {
                requestInProgress.add(apiConfig, apiRequest);
            }
            const response = await apiRequest;
            if (response.constructor === Error ||
                response.constructor === AxiosError) {
                throw response;
            }
            // If 304(Not modified) is received, then check
            // if saved response exists, if so then use the same
            // as the response
            if (String(response.status) === '304') {
                const savedResponse = ETagUtils.getSavedResponse(apiConfig);
                if (savedResponse) {
                    updateData(savedResponse.response, ...args);
                    return;
                }
            }
            else {
                if (response.headers.etag) {
                    ETagUtils.saveResponse(apiConfig, response);
                }
            }
            if (isEligibleForCache(apiConfig)) {
                requestInProgress.remove(apiConfig);
            }
            if (isNotStaleRequest(reqStartTime)) {
                updateData(response.data, ...args);
                if (isEligibleForCache(apiConfig)) {
                    Cache.set(getCacheKey(apiConfig), response.data, getCacheTTL(apiConfig));
                }
            }
        }
        catch (error) {
            if (isNotStaleRequest(reqStartTime)) {
                updateState({ error });
                onError && onError(error);
            }
        }
    }, []);
    // useEffect(() => {
    //   if (!lazy) {
    //     fetch(...(initialFetchOptions as any));
    //   }
    //   return () => cancelPrevRequests();
    // }, []);
    const actions = useMemo(() => ({
        fetch: fetch,
        forceFetch: ((...args) => {
            forceFetch.current = true;
            fetch(...args);
        }),
        update: fetch,
        submit: fetch,
        cancel: cancelPrevRequests,
    }), [cancelPrevRequests, fetch]);
    return [state, actions];
}
export default useRequest;
