import React, { useContext } from 'react';
export const translationsContext = React.createContext({
    label: 'UNKNOWN',
    code: 'UNKNOWN',
    langTranslations: null,
    changeLanguage() { },
});
export const EnglishContext = {
    code: 'en',
    label: 'English',
    langTranslations: null,
    changeLanguage() { },
};
export function useTranslationsContext() {
    return useContext(translationsContext);
}
