import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { NodeType } from 'src/modules/exam-preparation/graphql/common/types/node';
import styleUtils from 'src/utils/styles';
import QPList from '../QPList';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import { overlayHelperViewActions } from 'src/components/20-helper-views/overlay/useOverlay';
import QPFormOverlay from './QPForm';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useIsSuperAdmin from 'src/modules/authentication/hooks/useIsSuperAdmin';
const QPTab = ({ subjectID, subject, onQPPress, style, ...columnProps }) => {
    const [{}] = useTheme(stylesGenerator);
    const handleAddQP = useCallbackWithCtx(ctx => {
        overlayHelperViewActions.show({
            component: (React.createElement(QPFormOverlay, { subjectID: ctx.subjectID, variant: "new", onClose: overlayHelperViewActions.hide, onComplete: overlayHelperViewActions.hide })),
        });
    }, { subjectID });
    const isSuperAdmin = useIsSuperAdmin();
    return (React.createElement(Column, { style: [styleUtils.flex, style], ...columnProps },
        React.createElement(QPList, { containerStyle: styleUtils.flex, contentContainerStyle: styleUtils.spaceBtwHeadNBody, subjectID: subjectID, onQPPress: onQPPress, groupBy: subject?.type === NodeType.WRITTEN_SUBJECT ? 'year' : 'month', subjectType: subject?.type ?? NodeType.WRITTEN_SUBJECT }),
        isSuperAdmin && (React.createElement(Button, { variant: "float", LeftIcon: props => React.createElement(FeatherIcon, { name: "plus", ...props }), onPress: handleAddQP }, "Question Paper"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPTab;
