import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { renderNamedSlot } from 'src/utils/component';
import FeatherIcon from '../icons/FeatherIcon';
import Row from '../layout/Row';
import RowCard from './RowCard';
const FieldCard = ({ title, children, ...rowCardProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(RowCard, { xAxis: "space-between", yAxis: "center", spacing: theme.spacing.itemSeparation, ...rowCardProps },
        renderNamedSlot(title),
        React.createElement(Row, { xAxis: "flex-end", spacing: theme.spacing.standard.sm },
            children,
            React.createElement(FeatherIcon, { name: "chevron-right", color: theme.palette.primary.main }))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({}));
export default FieldCard;
