import React, { Suspense } from 'react';
import { ActivityIndicator } from 'react-native';
import ColumnCenter from 'src/components/10-atoms/layout/ColumnCenter';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import styleUtils from 'src/utils/styles';
export default function withSuspense(Component) {
    return function SuspenseComponent(props) {
        const [{ theme }] = useTheme();
        return (React.createElement(Suspense, { fallback: React.createElement(ColumnCenter, { bg: theme.palette.background.main, style: styleUtils.containerHeight },
                React.createElement(ActivityIndicator, { size: "large" })) },
            React.createElement(Component, { ...props })));
    };
}
