import { AndroidImportance, } from '@notifee/react-native';
export const NOTIFICATION_PRESS_ID = {
    DEFAULT: 'default',
    MARK_AS_READ: 'mark-as-read',
};
export const NOTIFICATION_ANDROID_CHANNELS = [
    {
        id: 'default',
        name: 'Default',
    },
    {
        id: 'conversation',
        name: 'Conversations',
        badge: true,
        importance: AndroidImportance.HIGH,
        lights: true,
        description: 'This Channel shows notifications for the conversations in the App',
    },
    {
        id: 'community_help',
        name: 'Request Community Help',
        importance: AndroidImportance.LOW,
        description: 'This Channel show notifications for requesting help from the Community',
    },
];
export const NOTIFICATION_IOS_CATEGORIES = [
    {
        id: 'default',
    },
    {
        id: 'conversation',
        allowAnnouncement: true,
        allowInCarPlay: true,
    },
    {
        id: 'community_help',
    },
];
