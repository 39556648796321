import React from 'react';
import { FlatList } from 'react-native';
import Divider from 'src/components/10-atoms/Divider';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import EmptyList from 'src/components/templates/EmptyList.template';
import AppAssets from 'src/constants/appAssets';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import TopicItem from './TopicItem';
import TopicsLoader from './TopicsLoader';
const TopicsList = ({ listQueryState, listQueryActions, topicList, onTopicPress, showOnlySelected, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    if (listQueryState.data) {
        return (React.createElement(FlatList, { style: { flex: 1 }, keyboardShouldPersistTaps: "handled", keyExtractor: item => item.id, data: topicList, ItemSeparatorComponent: Divider, renderItem: ({ item }) => (React.createElement(TopicItem, { onPress: () => onTopicPress(item.id), ...item })), ListEmptyComponent: () => (React.createElement(EmptyList, { image: showOnlySelected
                    ? {
                        type: 'image',
                        source: {
                            uri: AppAssets.emptySelection,
                        },
                    }
                    : undefined, message: showOnlySelected
                    ? "You haven't selected any Topics yet"
                    : 'No Topics Found. \nPlease try alternative words' })) }));
    }
    if (listQueryState.loading) {
        return (React.createElement(TopicsLoader, { count: 5, ph: theme.spacing.page, pv: theme.spacing.standard.md }));
    }
    if (listQueryState.error) {
        return (React.createElement(GenericServerError, { error: listQueryState.error, onRetry: listQueryActions.fetch }));
    }
    return null;
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default TopicsList;
