import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Column from '../layout/Column';
import LoaderBase from './LoaderBase';
const TextLoader = ({ lines = 1, height = 16, ...columnProps }) => {
    const [{ styles }] = useTheme(stylesGenerator, { height }, [height]);
    return (React.createElement(Column, { spacing: 8, ...columnProps },
        new Array(lines - 1).fill(0).map((_, i) => (React.createElement(LoaderBase, { key: i, style: styles.loader }))),
        React.createElement(LoaderBase, { style: [styles.loader, lines > 1 && styles.lastLine] })));
};
const stylesGenerator = createThemedStyle((_, { height }) => ({
    loader: {
        height,
        width: '100%',
    },
    lastLine: {
        width: '20%',
    },
}));
export default TextLoader;
