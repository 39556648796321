import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import TimerButton from 'src/components/10-atoms/button/TimerButton';
import Message from 'src/components/10-atoms/info/Message';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
const AccountDeleteConfirmation = ({ onCancel, onConfirm, }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('This is Irreversible!!!')),
        React.createElement(BottomDrawerLayout.Body, { ph: 0, spacing: theme.spacing.standard.sm },
            React.createElement(Column, { ph: theme.spacing.page },
                React.createElement(Typography, null, translate('You may not want to do this because deleting your Account will also delete:')),
                React.createElement(ULItem, null, translate('Community Contributions')),
                React.createElement(ULItem, null, translate('Exam Preparation Contributions')),
                React.createElement(ULItem, null, translate('Reputation built so far')),
                React.createElement(ULItem, null, translate('Any other Contributions'))),
            React.createElement(Message, { type: "info" }, translate('Even if you try to login with the same credentials, a new Account will be created.'))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { variant: "text", onPress: onCancel }, translate('Cancel')),
            React.createElement(TimerButton, { enableAfter: 10, onPress: onConfirm, colorScheme: "negative" }, translate('Delete')))));
};
export default AccountDeleteConfirmation;
