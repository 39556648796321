import { useMemo } from 'react';
import { useEPQuestionSearchQuery, } from '../../../../graphql/question/questionSearch';
import { useEPMarkQuestionAsDuplicateMutation } from 'src/modules/exam-preparation/graphql/question/markQuestionAsDuplicate';
import { stripHTMLTags } from 'src/utils/html';
export default function useData(duplicateQuestion) {
    const [listQueryState, listQueryActions] = useEPQuestionSearchQuery();
    const [markAsDuplicateMutationState, markAsDuplicateMutationActions] = useEPMarkQuestionAsDuplicateMutation();
    const listQueryVariables = useMemo(() => {
        const subjectID = (duplicateQuestion.ancestorsDetails.writtenSubject ??
            duplicateQuestion.ancestorsDetails.oralsSubject)?.id;
        return {
            ancestorID: subjectID,
            query: stripHTMLTags(duplicateQuestion.data.questionHTML),
        };
    }, [duplicateQuestion]);
    const state = {
        listQuery: listQueryState,
        listQueryVariables,
        markAsDuplicateMutation: markAsDuplicateMutationState,
    };
    const actions = {
        listQuery: listQueryActions,
        markAsDuplicateMutation: markAsDuplicateMutationActions,
    };
    return [state, actions];
}
