import murmur from 'murmurhash-js';
import StorageKeys from '../storage/storageKeys';
const seed = 581349;
const getHashKey = (key) => murmur.murmur3(JSON.stringify(key), seed);
const addCacheKeyPrefix = (key) => `${StorageKeys.cachePrefix.key}/${key}`;
export const getCacheKey = (key) => addCacheKeyPrefix(getHashKey(key));
export const isCacheKey = (key) => key.startsWith(StorageKeys.cachePrefix.key);
export const getMeta = (value) => value.__meta__;
export const setMeta = (value, meta) => {
    value.__meta__ = meta;
    return value;
};
export const getData = (value) => value.data;
export const setData = (value, data) => {
    value.data = data;
    return value;
};
export const isStale = (value) => getMeta(value).expiresAt <= Date.now();
