import { useEffect, useState } from 'react';
import useSearch from 'src/hooks/useSearch';
import { useCategoryListQuery } from '../graphql';
import { parseTopic } from '../utils';
import useFilteredList from './useFilteredList';
export default function useCategoryList(org, entityID, initialValue) {
    const [categoryListQueryState, categoryListQueryActions] = useCategoryListQuery({
        variables: {
            org,
            entityID,
        },
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-only',
    });
    const [topicsStore, setTopicsStore] = useState({});
    useEffect(() => {
        if (categoryListQueryState.data?.community_getAllCategories) {
            const categories = categoryListQueryState.data?.community_getAllCategories;
            setTopicsStore(categories.reduce((store, category) => {
                category.topics
                    .slice()
                    .sort((topicA, topicB) => topicA.localeCompare(topicB))
                    .forEach(topic => {
                    const { tag } = parseTopic(topic);
                    store[topic] = {
                        id: topic,
                        topic: tag,
                        category,
                        selected: initialValue.includes(topic),
                    };
                });
                return store;
            }, {}));
        }
    }, [categoryListQueryState.data]);
    const [searchState, searchActions] = useSearch('throttle', 200);
    const [{ lists }] = useFilteredList(topicsStore, searchState.deferredVal);
    const state = {
        topicsStore,
        categoryListQuery: categoryListQueryState,
        search: searchState,
        lists,
    };
    const actions = {
        search: searchActions,
        toggleTopicSelection(topicID) {
            setTopicsStore(prevState => {
                prevState[topicID].selected = !prevState[topicID].selected;
                return { ...prevState };
            });
        },
        categoryListQuery: categoryListQueryActions,
    };
    return [state, actions];
}
