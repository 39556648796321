import { useState } from 'react';
import useCurrPagePath from 'src/hooks/navigation/useCurrPagePath';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import navigationHelperViewActions from 'src/navigation/helperViewActions';
import { PlayerType } from '../../types';
import { PlayerState } from './types';
import useTTSPlayer from './useTTSPlayer';
import { isSamePath, shouldAutoNavigate } from './utils';
const DEFAULT_STATE = {
    prevItem: null,
    item: null,
    hasMoreOptions: false,
    state: PlayerState.IDLE,
    hooks: {
        onEnd() { },
        onSkip() { },
    },
};
export default function usePlayer() {
    const [state, setState] = useState(DEFAULT_STATE);
    const [currPagePath] = useCurrPagePath();
    const ttsPlayer = useTTSPlayer();
    useEffectWithCtx(ctx => {
        if (ctx.state.item?.playerInput) {
            // console.log('[Player] Item Changed, playing the new item');
            const players = {
                [PlayerType.TTS]() {
                    ctx.ttsPlayer.play(ctx.state.item, {
                        onEnd: ctx.state.hooks.onEnd,
                        onSkip: ctx.state.hooks.onSkip,
                    });
                    return () => ctx.ttsPlayer.stop();
                },
            };
            return players[ctx.state.item.playerInput.type]();
        }
    }, { ttsPlayer, state }, [state.item]);
    useEffectWithCtx(ctx => {
        if (ctx.state.item) {
            // Check if the User can be navigated to the currently playing item
            // if yes, then trigger navigate to the currently playing item
            if (shouldAutoNavigate(ctx.state.prevItem, ctx.state.item, ctx.currPagePath)) {
                navigateToItem(ctx.state.item);
            }
        }
    }, { state, currPagePath }, [state.item]);
    const play = useCallbackWithCtx((ctx, item, hooks) => {
        if (item) {
            setState(prevState => {
                return {
                    prevItem: prevState.item,
                    item,
                    state: PlayerState.PLAYING,
                    hooks,
                };
            });
        }
    }, { currPagePath });
    const pause = useCallbackWithCtx(async (ctx) => {
        const pausePlayer = {
            async [PlayerType.TTS]() {
                await ctx.ttsPlayer.pause();
            },
        };
        if (ctx.state.item?.playerInput) {
            await pausePlayer[ctx.state.item.playerInput.type]?.();
            setState(prevState => ({
                ...prevState,
                state: PlayerState.PAUSED,
            }));
        }
    }, { state, ttsPlayer });
    const resume = useCallbackWithCtx(async (ctx) => {
        const resumePlayer = {
            async [PlayerType.TTS]() {
                await ctx.ttsPlayer.resume();
            },
        };
        if (ctx.state.item?.playerInput) {
            await resumePlayer[ctx.state.item.playerInput.type]();
            setState(prevState => ({
                ...prevState,
                state: PlayerState.PLAYING,
            }));
        }
    }, { state, ttsPlayer });
    const stop = useCallbackWithCtx(async (ctx) => {
        const stopPlayer = {
            async [PlayerType.TTS]() {
                await ctx.ttsPlayer.stop();
            },
        };
        if (ctx.state.item?.playerInput) {
            await stopPlayer[ctx.state.item.playerInput.type]();
            setState(prevState => ({
                ...prevState,
                state: PlayerState.STOPPED,
            }));
        }
    }, { state, ttsPlayer });
    const navigateToItem = useCallbackWithCtx((ctx, item) => {
        if (item.playerInput) {
            navigationHelperViewActions.navigate(item.playerInput.pageDetails.routeName, item.playerInput.pageDetails.routeParams);
        }
    }, {});
    const openItem = useCallbackWithCtx(ctx => {
        if (ctx.state.item) {
            navigateToItem(ctx.state.item);
        }
    }, { state });
    const actions = {
        play,
        pause,
        resume,
        stop,
        openItem,
    };
    const helpers = {
        isOpenDisabled() {
            return isSamePath(state.item, currPagePath);
        },
    };
    return [state, actions, helpers];
}
