import Quill from 'quill';
import { ToolbarActionState } from '../../types';
import { useEffect, useState } from 'react';
export default function useActionsState(quill) {
    const [actionsState, setActionsState] = useState({
        bold: ToolbarActionState.INACTIVE,
        italic: ToolbarActionState.INACTIVE,
        unorderedList: ToolbarActionState.INACTIVE,
        orderedList: ToolbarActionState.INACTIVE,
        hyperLink: ToolbarActionState.INACTIVE,
        table: ToolbarActionState.INACTIVE,
    });
    // Update ToolbarActionState based on the format in the current Selection
    useEffect(() => {
        if (quill) {
            /// Implementation ref:
            // https://github.com/slab/quill/blob/5d752e3fa8155a9b1ea1ddd99a9ae2d9a04f0ade/packages/quill/src/modules/toolbar.ts#L64C5-L67C8
            const handleEvent = () => {
                const [range] = quill.selection.getRange();
                if (range?.index) {
                    const formats = quill.getFormat(range.index);
                    setActionsState({
                        bold: (formats?.bold ?? false)
                            ? ToolbarActionState.ACTIVE
                            : ToolbarActionState.INACTIVE,
                        italic: (formats?.italic ?? false)
                            ? ToolbarActionState.ACTIVE
                            : ToolbarActionState.INACTIVE,
                        orderedList: formats?.list === 'ordered'
                            ? ToolbarActionState.ACTIVE
                            : ToolbarActionState.INACTIVE,
                        unorderedList: formats?.list === 'bullet'
                            ? ToolbarActionState.ACTIVE
                            : ToolbarActionState.INACTIVE,
                        hyperLink: formats?.hyperLink ?? false
                            ? ToolbarActionState.ACTIVE
                            : ToolbarActionState.INACTIVE,
                        table: formats?.['table-cell-line'] ?? false
                            ? ToolbarActionState.ACTIVE
                            : ToolbarActionState.INACTIVE,
                    });
                }
            };
            quill.on(Quill.events.EDITOR_CHANGE, handleEvent);
            return () => {
                quill.off(Quill.events.EDITOR_CHANGE, handleEvent);
            };
        }
    }, [quill]);
    return [actionsState, setActionsState];
}
