import { uniqueId } from 'lodash';
import useSharedState from 'src/hooks/globalState/useSharedState';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { PlaylistItemState, } from '../types';
function addInitialState(firstItemIndex) {
    return (state, index) => {
        return {
            id: uniqueId(),
            state: PlaylistItemState.IDLE,
            firstItemInPlaylist: firstItemIndex === index,
            ...state,
        };
    };
}
export default function usePlaylist() {
    const [state, setState] = useSharedState('playlist/state', {
        activeIndex: -1,
        list: [],
    });
    const updateItem = useCallbackWithCtx((ctx, itemId, newState) => {
        ctx.setState(prevState => {
            const itemIndex = prevState.list.findIndex(item => item.id === itemId);
            if (itemIndex === -1) {
                return prevState;
            }
            prevState.list[itemIndex] = {
                ...prevState.list[itemIndex],
                ...newState,
            };
            return {
                ...prevState,
                list: [...prevState.list],
            };
        });
    }, { setState });
    const updateItemState = useCallbackWithCtx((ctx, itemId, newState) => {
        updateItem(itemId, { state: newState });
    }, {});
    const updateItemData = useCallbackWithCtx((ctx, itemId, newData) => {
        updateItem(itemId, { playerInput: newData });
    }, {});
    const addToQueue = useCallbackWithCtx((ctx, list) => {
        setState(prevState => {
            const newList = list.map(addInitialState(-1));
            if (prevState.activeIndex > -1) {
                return {
                    activeIndex: prevState.activeIndex,
                    list: prevState.list.concat(newList),
                };
            }
            return {
                activeIndex: 0,
                list: newList,
            };
        });
    }, {});
    const overrideQueue = useCallbackWithCtx((ctx, data) => {
        const activeIndex = data.activeIndex ?? 0;
        const newList = data.list.map(addInitialState(activeIndex));
        setState({
            activeIndex,
            list: newList,
        });
    }, {});
    const clearQueue = useCallbackWithCtx(() => {
        setState({
            activeIndex: -1,
            list: [],
        });
    }, {});
    const navigate = useCallbackWithCtx((ctx, direction) => {
        setState(prevState => {
            const newIndex = prevState.activeIndex + direction;
            const item = prevState.list[newIndex];
            if (item) {
                item.state = [
                    PlaylistItemState.ENDED,
                    PlaylistItemState.SKIPPED,
                ].includes(item.state)
                    ? PlaylistItemState.READY
                    : item.state;
                return {
                    ...prevState,
                    list: [...prevState.list],
                    activeIndex: newIndex,
                };
            }
            return prevState;
        });
    }, {});
    const gotoNext = useCallbackWithCtx(() => {
        navigate(1);
    }, {});
    const gotoPrev = useCallbackWithCtx(() => {
        navigate(-1);
    }, {});
    const actions = {
        // list actions
        addToQueue,
        overrideQueue,
        clearQueue,
        gotoPrev,
        gotoNext,
        // item actions
        updateItem,
        updateItemState,
        updateItemData,
    };
    const helpers = {
        // getStatus() {
        //   if (state.currIndex === -1) {
        //     return PlaylistStatus.IDLE;
        //   }
        //   if (state.currIndex === state.list.length - 1) {
        //     return PlaylistStatus.ENDED;
        //   }
        // },
        getActiveItem() {
            return state.list[state.activeIndex];
        },
        hasNext() {
            return state.list.length - 1 > state.activeIndex;
        },
        hasPrev() {
            return state.activeIndex > 0;
        },
        getNext() {
            return state.list[state.activeIndex + 1];
        },
    };
    return [state, actions, helpers];
}
