import { gql } from '@apollo/client';
import useGQLPaginatedQuery from 'src/lib/graphql/hooks/useGQLPaginatedQuery';
export const GET_BLOCKED_USER_LIST_QUERY = gql `
  query UGCPolicy_getBlockedUserList($pagination: PaginationOptions!) {
    ugcPolicy_getBlockedUserList(pagination: $pagination) {
      totalCount
      list {
        id
        details {
          firstName
          lastName
          userName
          avatarURL
        }
      }
    }
  }
`;
export function useGetBlockedUserListQuery(options) {
    return useGQLPaginatedQuery(GET_BLOCKED_USER_LIST_QUERY, 'ugcPolicy_getBlockedUserList', options);
}
