import { useEffect, useState } from 'react';
export default function useDelay(duration) {
    const [delayCompleted, setDelayCompleted] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setDelayCompleted(true);
        }, duration);
        return () => {
            clearTimeout(timer);
        };
    }, []);
    return delayCompleted;
}
