import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import { QP_LIST_QUERY } from './getList';
const EP_CREATE_QP_MUTATION = gql `
  mutation EP_createQP($subjectID: ID!, $data: EPNewQPInput!) {
    ep_createQP(subjectID: $subjectID, data: $data) {
      sentForReview
    }
  }
`;
export function useEPCreateQPMutation(options) {
    return useGQLMutation(EP_CREATE_QP_MUTATION, {
        ...options,
        refetchQueries: [QP_LIST_QUERY],
    });
}
