import { useTranslationsContext } from '../context';
const defaultDisplayLang = {
    id: 'UNKNOWN',
    code: 'en',
    contentHash: 123,
    dateLocale: 'enUS',
    fallbackLangs: [],
    label: 'English',
    translations: {},
};
export default function useDisplayLang() {
    const context = useTranslationsContext();
    return context.langTranslations ?? defaultDisplayLang;
}
