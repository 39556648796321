import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
const USER_REPUTATION_DETAILS_QUERY = gql `
  query User_getReputation {
    user_getDetails {
      id
      reputation {
        score
        badgesInfo {
          summary {
            gold
            silver
            bronze
          }
          badges {
            id
            count
            rank
            type
            title
            condition {
              for
            }
          }
        }
      }
    }
  }
`;
export function useUserReputationQuery(options = {}) {
    return useGQLQuery(USER_REPUTATION_DETAILS_QUERY, options);
}
