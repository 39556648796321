import { useSubjectListQuery } from 'src/modules/exam-preparation/graphql/subject/getList';
export default function useData(examLevel, type) {
    const [queryState, queryActions] = useSubjectListQuery(examLevel.id, type);
    const state = {
        query: queryState,
    };
    const actions = {
        query: queryActions,
    };
    return [state, actions];
}
