import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from './Row';
const Grid = ({ cols = 2, spacing = 12, children, ...rowProps }) => {
    const [{ theme }] = useTheme();
    const [containerWidth, setContainerWidth] = useState(-1);
    if (spacing > theme.spacing.page && __DEV__) {
        throw new Error(`Spacing in a Grid cannot be > ${theme.spacing.page}`);
    }
    const itemWidth = (containerWidth - (cols - 1) * spacing) / cols;
    return (React.createElement(Row, { wrap: true, spacing: spacing, onLayout: ev => setContainerWidth(ev.nativeEvent.layout.width), ...rowProps }, containerWidth !== -1
        ? React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                const props = { ...child.props };
                const itemStyle = {
                    width: itemWidth,
                };
                props.style = StyleSheet.flatten([props.style || {}, itemStyle]);
                return React.cloneElement(child, props);
            }
            return child;
        })
        : null));
};
export default Grid;
