import { getPathFromState } from '@react-navigation/native';
import linking from 'src/navigation/linking';
import { isWeb } from 'src/utils/platform';
function getOrigin() {
    if (isWeb) {
        return window.location.origin;
    }
    return 'marinesamraj://';
}
export function getPath(routeName, params) {
    return getPathFromState({
        routes: [
            {
                name: routeName,
                params,
            },
        ],
    }, linking.config);
}
export function getDeeplink(routeName, params) {
    const path = getPath(routeName, params);
    return `${getOrigin()}${path}`;
}
