import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { PlaylistItemState } from '../../types';
import useProcessor from './useProcessor';
import usePlaylist from '../usePlaylist';
export default function useProcessorWithStateUpdates() {
    const [, playlistActions] = usePlaylist();
    const processor = useProcessor();
    // Process the item
    const processItem = useCallbackWithCtx(async (ctx, item) => {
        if (item) {
            if (item.state === PlaylistItemState.IDLE) {
                ctx.playlistActions.updateItemState(item.id, PlaylistItemState.PROCESSING);
                try {
                    const result = await processor(item);
                    ctx.playlistActions.updateItem(item.id, {
                        playerInput: result,
                        state: PlaylistItemState.READY,
                    });
                }
                catch (error) {
                    console.error('Error:', error);
                    ctx.playlistActions.updateItemState(item.id, PlaylistItemState.ERROR);
                }
            }
        }
    }, { playlistActions });
    return processItem;
}
