import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../layout/Row';
import Typography from '../typography/Typography';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import FeatherIcon from '../icons/FeatherIcon';
import withPress from 'src/hoc/withPress';
const Chip = ({ children = '', selected, variant = 'choice', Icon, style, ...rowProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator, { variant }, [variant]);
    return (React.createElement(Row, { style: [styles.container, selected && styles.selectedContainer, style], xAxis: "center", yAxis: "center", spacing: theme.spacing.standard.xs, ...rowProps },
        Icon && (React.createElement(Icon, { size: theme.iconSize.sm, color: selected ? theme.palette.primary.dark : theme.palette.icon.primary })),
        children ? (React.createElement(Typography, { color: selected
                ? theme.palette.primary.dark
                : theme.palette.typography.primary }, children)) : null,
        variant === 'filter' && selected && (React.createElement(FeatherIcon, { name: "check", color: theme.palette.primary.dark, size: theme.iconSize.sm }))));
};
export const stylesGenerator = createThemedStyle(({ theme }, { variant }) => ({
    container: {
        paddingHorizontal: theme.spacing.standard.md,
        minHeight: PRESSABLE_SIZE - 8,
        borderRadius: PRESSABLE_SIZE / 2,
        backgroundColor: theme.palette.background.darker,
        ...(variant === 'non-pressable' && {
            backgroundColor: theme.palette.background.main,
            borderWidth: 1,
            borderColor: theme.palette.background.darker,
        }),
    },
    selectedContainer: {
        backgroundColor: theme.palette.primary.lightest,
    },
}));
export default withPress(Chip);
