import { useEffect, useRef } from 'react';
import { AppState } from 'react-native';
import useCallbackWithCtx from '../useCallbackUtils/useCallbackWithCtx';
export default function useAppState(onChange, ctx) {
    const appState = useRef(AppState.currentState);
    const onChangeWithCtx = useCallbackWithCtx(onChange, ctx);
    useEffect(() => {
        const subscription = AppState.addEventListener('change', (nextAppState) => {
            if (appState.current.match(/inactive|background/) &&
                nextAppState === 'active') {
                onChangeWithCtx('foreground');
            }
            else {
                onChangeWithCtx('background');
            }
            appState.current = nextAppState;
        });
        return subscription.remove;
    }, []);
}
