import React, { useEffect, useState } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Field from 'src/components/10-atoms/form/Field';
import TextInput from 'src/components/10-atoms/form/inputs/TextInput';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useI18n from 'src/modules/translations/hooks/useI18n';
const AccountDeleteDoubleConfirmation = ({ onCancel, onConfirm }) => {
    const { translate } = useI18n();
    const [input, setInput] = useState('');
    const [confirmDisabled, setConfirmDisabbled] = useState(false);
    useEffect(() => {
        if (input.toUpperCase() === 'DELETE') {
            setConfirmDisabbled(false);
        }
        else {
            setConfirmDisabbled(true);
        }
    }, [input]);
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Confirm Delete')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(Field, { label: translate('Please type <span translate="no">"DELETE"</span> to confirm that you want to delete your account') },
                React.createElement(TextInput, { value: input, onChange: newVal => setInput(newVal) }))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { variant: "text", onPress: onCancel }, translate('Cancel')),
            React.createElement(Button, { disabled: confirmDisabled, colorScheme: "negative", onPress: onConfirm }, translate('Confirm')))));
};
export default AccountDeleteDoubleConfirmation;
