import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import Button from 'src/components/10-atoms/button/Button';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from '../hooks/useI18n';
import Typography from 'src/components/10-atoms/typography/Typography';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import TranslatedIndicator from './TranslatedIndicator';
import TranslatedBadge from './TranslatedBadge';
import Row from 'src/components/10-atoms/layout/Row';
import Column from 'src/components/10-atoms/layout/Column';
const LangChangeSuccessDrawer = ({ onConfirm, }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Language Changed successfully')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(Typography, null, translate('Here are things you must pay attention:')),
            React.createElement(Column, null,
                React.createElement(ULItem, null, translate("Look for the below indicators, which tells us that the item's content is translated")),
                React.createElement(Row, { style: { marginLeft: 16 }, spacing: theme.spacing.standard.md },
                    React.createElement(TranslatedIndicator, null),
                    React.createElement(TranslatedBadge, null))),
            React.createElement(ULItem, null, translate('Under the hood we use Google Translate, so kindly forgive us if some of the translations are inappropriate')),
            React.createElement(ULItem, null, translate('Please note that the push notifications will also be translated to your preferred language'))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onConfirm }, translate('I understand')))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({}));
export default LangChangeSuccessDrawer;
