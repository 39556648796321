import { useCallback, useEffect, useState } from 'react';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { clamp, isWithinRange } from 'src/utils/math';
export default function useKeyboardNavigation({ data, onItemSelected, }) {
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const resetItemFocus = useCallback(() => {
        setFocusedIndex(-1);
    }, []);
    useEffect(() => {
        resetItemFocus();
    }, [data]);
    const handleKeyPress = useCallbackWithCtx((ctx, event) => {
        switch (event.nativeEvent.key) {
            case 'ArrowUp': {
                event.preventDefault();
                setFocusedIndex(clamp(ctx.focusedIndex - 1, 0, ctx.data.length - 1));
                break;
            }
            case 'ArrowDown': {
                event.preventDefault();
                setFocusedIndex(clamp(ctx.focusedIndex + 1, 0, ctx.data.length - 1));
                break;
            }
            case 'Enter': {
                if (isWithinRange(ctx.focusedIndex, 0, ctx.data.length - 1)) {
                    event.preventDefault();
                    ctx.onItemSelected(ctx.data[ctx.focusedIndex]);
                }
                break;
            }
        }
    }, { focusedIndex, data, onItemSelected });
    return [
        { focusedIndex },
        {
            resetItemFocus,
            handleKeyPress,
        },
    ];
}
