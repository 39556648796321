import React from 'react';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Row from '../layout/Row';
import LoaderBase from '../loaders/LoaderBase';
import Typography from '../typography/Typography';
const BreadCrumbsLoader = ({ visibleCount = 2, ...rowProps }) => {
    const [{ theme, styles }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { yAxis: "center", ItemSeparator: React.createElement(Typography, { variant: TypographyVariant.CAPTION }, "/"), bg: theme.palette.background.main, pv: theme.spacing.standard.xxs, spacing: theme.spacing.standard.xxs, ...rowProps }, new Array(visibleCount).fill(0).map((_, i) => (React.createElement(LoaderBase, { style: styles.loaderBase, key: i })))));
};
const stylesGenerator = createThemedStyle(({}) => ({
    loaderBase: { height: 16, width: 76 },
}));
export default BreadCrumbsLoader;
