import React from 'react';
function validateChildren(children) {
    return (React.Children.map(children, child => React.isValidElement(child) ? !!child.props.id : false) || []).every(Boolean);
}
const Tabs = ({ activeTab, children }) => {
    const isValid = validateChildren(children);
    if (!isValid) {
        throw new Error('Every child of Tabs component should have a `id` prop');
    }
    let matchingTab = null;
    React.Children.forEach(children, child => {
        if (React.isValidElement(child)) {
            if ((!activeTab && !matchingTab) || child.props.id === activeTab) {
                matchingTab = child;
            }
        }
    });
    return matchingTab || null;
};
export default React.memo(Tabs);
