import React from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import withPress from 'src/hoc/withPress';
import useI18n from 'src/modules/translations/hooks/useI18n';
const SelectedCommunity = ({ community, ...rowProps }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    return (React.createElement(Row, { xAxis: "space-between", yAxis: "center", ph: theme.spacing.page, pv: theme.spacing.standard.xxs, bg: theme.palette.background.dark, ...rowProps },
        React.createElement(Column, null,
            React.createElement(Typography, { variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary }, translate('Exam Community')),
            React.createElement(Typography, null, community.data.title)),
        React.createElement(FeatherIcon, { name: "edit-2", size: theme.iconSize.sm, color: theme.palette.primary.main })));
};
export default withPress(SelectedCommunity);
