import React from 'react';
import { withOpacity } from 'src/customizations/themes/10-parts/color-schemes/utils';
import useTheme from 'src/customizations/themes/hooks/useTheme';
/**
 * Adds default values to IconProps, to maintain standard
 * Icon representation throughout the Application
 */
export default function withDefaultIconProps(Component) {
    const WithDefaultIconProps = ({ disabled, color, size, ...rest }) => {
        const [{ theme }] = useTheme();
        color =
            color ||
                (disabled
                    ? withOpacity(theme.palette.icon.primary, 0.5)
                    : theme.palette.icon.primary);
        size = size || 24;
        return React.createElement(Component, { color: color, size: size, ...rest });
    };
    WithDefaultIconProps.displayName = `withDefaultIconProps(${Component.displayName || Component.name || 'Component'})`;
    return WithDefaultIconProps;
}
