import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import VerticalThreeDotIcon from '../icons/VerticalThreeDotIcon';
import Pressable from '../pressables/Pressable';
import MenuDrawer from './MenuDrawer';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
const MoreOptionsButton = ({ options, renderButton, }) => {
    const [{}] = useTheme(stylesGenerator);
    const showMenu = () => {
        bottomDrawerHelperActions.show({
            component: React.createElement(MenuDrawer, { sections: [{ title: 'Default', options }] }),
        });
    };
    return options.length === 0 ? null : (React.createElement(Pressable, { onPress: showMenu }, renderButton ? renderButton() : React.createElement(VerticalThreeDotIcon, null)));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default MoreOptionsButton;
