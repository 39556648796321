import { initializeAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported as isMessagingSupported, } from 'firebase/messaging';
import { firebaseConfig } from './config';
import once from 'lodash/once';
export let firebaseApp;
export let firebaseAnalytics;
export let firebaseMessaging;
async function initFirebase() {
    firebaseApp = initializeApp(firebaseConfig);
    firebaseAnalytics = initializeAnalytics(firebaseApp);
    if (await isMessagingSupported()) {
        firebaseMessaging = getMessaging(firebaseApp);
    }
}
export default once(initFirebase);
