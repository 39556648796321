import { uniqueId } from 'lodash';
import { createRef, useCallback, useState } from 'react';
export default function useHookList(initialListState = []) {
    const [state, setState] = useState(() => initialListState.map(item => ({
        id: uniqueId(),
        initialState: item,
        ref: createRef(),
    })));
    const addItem = useCallback((initialState) => {
        setState(prevState => {
            return [
                ...prevState,
                {
                    id: uniqueId(),
                    initialState,
                    ref: createRef(),
                },
            ];
        });
    }, []);
    const removeItem = useCallback((itemID) => {
        setState(prevState => {
            return prevState.filter(item => item.id !== itemID);
        });
    }, []);
    const actions = {
        addItem,
        removeItem,
    };
    return [state, actions];
}
