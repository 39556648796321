import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
import useCategoryColor from '../../../../topics/useCategoryColor';
const CATEGORY_LIST_QUERY = gql `
  query Community_getAllCategories($entityID: String!, $org: String!) {
    community_getAllCategories(entityID: $entityID, org: $org) {
      id
      title
      color
      prefix
      topics
    }
  }
`;
export function useCategoryListQuery(options) {
    const categoryColorActions = useCategoryColor();
    return useGQLQuery(CATEGORY_LIST_QUERY, {
        ...options,
        onCompleted(data) {
            data.community_getAllCategories.forEach(category => {
                if (category.color) {
                    categoryColorActions.setColor(category.prefix, category.color);
                }
            });
        },
    });
}
