import { useNavigation, useRoute } from '@react-navigation/native';
import useTabs from 'src/components/10-atoms/tabs/useTabs';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import { ProcessorType } from 'src/lib/playlist/types';
import useI18n from 'src/modules/translations/hooks/useI18n';
import isNot from 'src/utils/array/isNot';
import { useCreateAnswerMutation } from '../../graphql/answer/create';
import { useUpdateAnswerMutation } from '../../graphql/answer/update';
import AnswerUtils from '../../graphql/answer/utils';
import { NodeType } from '../../graphql/common/types/node';
import { useQuestionDetailsQuery } from '../../graphql/question/getQuestionDetails';
import { useQPPlaylist } from '../../graphql/question/getQuestionPlaylistForQP';
import { useTopicPlaylist } from '../../graphql/question/getQuestionPlaylistForTopic';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { htmlEditorHelperActions } from 'src/components/10-atoms/form/inputs/editor/html/HTMLEditor/useHTMLEditor';
import { promptHelperActions } from 'src/components/20-helper-views/prompt/helperActions';
import { playlistPlayerHelperViewActions } from 'src/lib/playlist/hooks/usePlaylistPlayer';
import { overlayHelperViewActions } from 'src/components/20-helper-views/overlay/useOverlay';
import EditQuestionOverlay from './containers/EditQuestionOverlay';
import React from 'react';
import useIsSuperAdmin from 'src/modules/authentication/hooks/useIsSuperAdmin';
export default function useData() {
    const route = useRoute();
    const { question, questionID } = route.params;
    const shouldFetchEF = question?.type
        ? question.type === NodeType.WRITTEN_QUESTION
        : true;
    const navigation = useNavigation();
    const { translate } = useI18n();
    const [questionDetailsQueryState, questionDetailsQueryActions] = useQuestionDetailsQuery({
        variables: {
            questionID,
            withEF: shouldFetchEF,
        },
    });
    const [tabsState, tabsActions] = useTabs();
    useEffectWithCtx(ctx => {
        if (questionDetailsQueryState.data) {
            const config = questionDetailsQueryState.data.ep_questionDetails.config;
            const shouldShowEF = questionDetailsQueryState.data.ep_questionDetails.type ===
                NodeType.WRITTEN_QUESTION
                ? (config?.WRITTEN_QUESTION?.hasExaminersFeedback ?? false)
                : false;
            ctx.tabsActions.setTabs([
                {
                    id: 'ANSWER',
                    label: translate('Answer'),
                },
                shouldShowEF && {
                    id: 'EXAMINERS_FEEDBACK',
                    label: translate("Examiner's Feedback"),
                },
            ].filter(isNot(false)));
            ctx.tabsActions.setActiveIndex(0);
        }
    }, { tabsActions }, [questionDetailsQueryState.data]);
    const [createAnswerMutationState, createAnswerMutationActions] = useCreateAnswerMutation();
    const handleCreateAnswer = useCallbackWithCtx(ctx => {
        htmlEditorHelperActions.show({
            title: translate('Write Answer'),
            html: '',
            placeholder: translate('Write your Answer here'),
            onSubmit(data) {
                if (!data.hasContent) {
                    return;
                }
                ctx.createAnswerMutationActions.create({
                    variables: {
                        questionID: ctx.questionID,
                        data: {
                            answer: data.html,
                        },
                    },
                });
            },
        });
    }, { questionID, createAnswerMutationActions });
    const [updateAnswerMutationState, updateAnswerMutationActions] = useUpdateAnswerMutation();
    const handleUpdateAnswer = useCallbackWithCtx(ctx => {
        if (!ctx.question) {
            return;
        }
        const currentAnswer = AnswerUtils.getCurrentAnswer(ctx.question.answer)?.answer ?? '';
        htmlEditorHelperActions.show({
            title: translate('Edit Answer'),
            placeholder: translate('Write your answer here'),
            html: currentAnswer,
            async onSubmit({ html: newAnswer, hasChanges }) {
                if (!hasChanges) {
                    snackbarHelperActions.show({ message: 'No changes to submit' });
                    return;
                }
                const reason = await promptHelperActions.show({
                    title: ctx.translate('Comments for the reviewer'),
                    placeholder: ctx.translate('Incorrect formula was used, hence fixed it'),
                });
                if (!reason) {
                    return;
                }
                ctx.updateAnswerMutationActions.submit({
                    variables: {
                        questionID: ctx.question.id,
                        data: {
                            answer: newAnswer,
                            comments: reason,
                        },
                    },
                });
            },
        });
    }, {
        translate,
        question: questionDetailsQueryState.data?.ep_questionDetails,
        updateAnswerMutationActions,
    });
    const playlistIndex = route.params?.navigationData
        ?.sequence ?? 0;
    const [qpPlaylistState, qpPlaylistActions] = useQPPlaylist(route.params?.navigationData?.qpID, playlistIndex);
    const [topicPlaylistState, topicPlaylistActions] = useTopicPlaylist(route.params?.navigationData?.ancestorID, route.params?.navigationData?.topic, playlistIndex);
    const handlePlaylistPress = useCallbackWithCtx(ctx => {
        const { navigatedFrom } = ctx.route
            .params;
        switch (navigatedFrom) {
            case 'QP_QUESTIONS': {
                qpPlaylistActions.play();
                return;
            }
            case 'TOPIC_QUESTIONS': {
                topicPlaylistActions.play();
                return;
            }
            case 'SEARCH_QUESTIONS':
            default: {
                playlistPlayerHelperViewActions.overrideQueue({
                    list: [
                        {
                            processorType: ProcessorType.EP_QUESTION_DETAILS,
                            meta: {
                                questionID: ctx.questionID,
                            },
                        },
                    ],
                    activeIndex: 0,
                });
            }
        }
    }, { route, navigation, questionID });
    const handleQuestionEditPress = useCallbackWithCtx(ctx => {
        if (ctx.questionDetailsQueryState.data) {
            const questionDetails = ctx.questionDetailsQueryState.data.ep_questionDetails;
            overlayHelperViewActions.show({
                component: (React.createElement(EditQuestionOverlay, { question: questionDetails, onClose: overlayHelperViewActions.hide, onComplete: overlayHelperViewActions.hide })),
            });
        }
    }, { questionDetailsQueryState });
    const isSuperAdmin = useIsSuperAdmin();
    const markAsDuplicateVisible = questionDetailsQueryState.data?.ep_questionDetails && isSuperAdmin;
    const state = {
        createAnswerMutation: createAnswerMutationState,
        updateAnswerMutation: updateAnswerMutationState,
        tabs: tabsState,
        questionDetailsQuery: questionDetailsQueryState,
        markAsDuplicateVisible,
        playlistLoading: qpPlaylistState.playlistQuery.loading ||
            topicPlaylistState.playlistQuery.loading,
    };
    const actions = {
        tabs: tabsActions,
        questionDetailsQuery: questionDetailsQueryActions,
        handleCreateAnswer,
        handleUpdateAnswer,
        createAnswerMutation: createAnswerMutationActions,
        handlePlaylistPress,
        handleQuestionEditPress,
    };
    return [state, actions];
}
