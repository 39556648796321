import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../layout/Row';
import Typography from '../typography/Typography';
const BreadCrumbs = ({ crumbs, visibleCount = 2, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const validCrumbs = crumbs.filter(Boolean);
    const pathCrumbs = validCrumbs
        .slice(-Math.min(visibleCount, validCrumbs.length), -1)
        .filter(Boolean);
    const lastCrumb = validCrumbs[validCrumbs.length - 1];
    const renderPath = () => {
        const pathComponents = [];
        for (let i = 0; i < pathCrumbs.length; ++i) {
            const crumb = pathCrumbs[i];
            pathComponents.push(React.createElement(Typography, { key: crumb, variant: TypographyVariant.CAPTION, fontWeight: TypographyFontWeight.MEDIUM, color: theme.palette.typography.secondary }, crumb.trim()));
            pathComponents.push(React.createElement(Typography, { key: `sep_${i}`, variant: TypographyVariant.CAPTION },
                ' ',
                "/",
                ' '));
        }
        return pathComponents;
    };
    return (React.createElement(Row, { pv: theme.spacing.standard.xxs, bg: theme.palette.background.main, ItemSeparator: React.createElement(Typography, { variant: TypographyVariant.CAPTION }, " / "), ...rowProps },
        React.createElement(Typography, null,
            renderPath(),
            lastCrumb && (React.createElement(Typography, { key: "heading", variant: TypographyVariant.CAPTION, fontWeight: TypographyFontWeight.MEDIUM, color: theme.palette.primary.dark }, lastCrumb.trim())))));
};
const stylesGenerator = createThemedStyle(() => ({}));
export default BreadCrumbs;
