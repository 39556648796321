export function duplicateRef(...refs) {
    return (r) => {
        refs.forEach(ref => {
            if (!ref)
                return;
            if (typeof ref === 'function') {
                ref(r);
            }
            else {
                ref.current = r;
            }
        });
    };
}
