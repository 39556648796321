import React from 'react';
import Author from 'src/components/10-atoms/author/Author';
import TextButton from 'src/components/10-atoms/button/TextButton';
import Row from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useData from './useData';
import useI18n from 'src/modules/translations/hooks/useI18n';
const BlockedUserContainer = ({ user }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    const [state, actions] = useData();
    const handleUnblockUser = () => {
        actions.unblockMutation.submit({
            variables: {
                userID: user.id,
            },
        });
    };
    return (React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.itemVerticalPadding, yAxis: "center", xAxis: "space-between", spacing: theme.spacing.itemSeparation },
        React.createElement(Author, { author: user, flex: true }),
        React.createElement(TextButton, { loading: state.unblockMutation.loading, onPress: handleUnblockUser }, translate('Unblock'))));
};
export default BlockedUserContainer;
