import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import TTS from 'src/lib/playlist/players/tts/api';
TTS.init();
export default function useTTSPlayer() {
    const play = useCallbackWithCtx((ctx, item, hooks) => {
        if (item.playerInput) {
            TTS.speak(item.playerInput.data, {
                hooks: {
                    onEnd: hooks.onEnd,
                    onSkip: hooks.onSkip,
                },
            });
        }
    }, {});
    const pause = useCallbackWithCtx(async () => {
        await TTS.pause();
    }, {});
    const resume = useCallbackWithCtx(async () => {
        await TTS.resume();
    }, {});
    const stop = useCallbackWithCtx(async () => {
        await TTS.stop();
    }, {});
    const actions = {
        play,
        pause,
        resume,
        stop,
    };
    return actions;
}
