import { MathFieldEditor, MathFieldRenderer, } from '../QuillEditor/formats/MathField';
function mathFieldMatcher(html, replacer) {
    return html.replace(/\${1,2}([^$]+)\${1,2}/g, replacer);
}
export function addMathFieldWrapper(html) {
    return mathFieldMatcher(html, (_, captured) => {
        return `<${MathFieldEditor.tagName}>${captured ?? ''}</${MathFieldEditor.tagName}>`;
    });
}
export function normalizeMathField(html) {
    return mathFieldMatcher(html, (_, captured) => {
        return `<div class="${MathFieldRenderer.className}">$$${captured}$$</div>`;
    });
}
