export const reviewPipelineStackLinking = {
    screens: {
        RP_Home: {
            path: 'review-pipeline/*',
        },
        RP_ReviewDetails: {
            path: 'review-pipeline/details/:reviewEntityID',
        },
    },
};
