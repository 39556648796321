import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import Button from 'src/components/10-atoms/button/Button';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import Typography from 'src/components/10-atoms/typography/Typography';
const DiscardChangesConfirmDrawer = ({ onCancel, onConfirm, }) => {
    const [{}] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Discard Changes ?')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(Typography, null, translate('Your changes will not be saved until you click on SAVE button.\nAre you sure you want to discard the changes ?'))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { variant: "text", onPress: onConfirm }, translate('YES')),
            React.createElement(Button, { onPress: onCancel }, translate('NO')))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default DiscardChangesConfirmDrawer;
