import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import Button from 'src/components/10-atoms/button/Button';
import { useNavigation } from '@react-navigation/native';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import pageAnalytics from '../analytics';
const STCCTA = ({}) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const navigation = useNavigation();
    const handleSTCPress = useCallbackWithCtx(ctx => {
        pageAnalytics.setWidget('STCCTA').setTarget('SupportUs_Btn').click();
        ctx.navigation.navigate('STC_Home', {});
    }, { navigation });
    return (React.createElement(Row, { yAxis: "center", xAxis: "space-between", pl: theme.spacing.page, pv: theme.spacing.standard.xxs, bg: theme.palette.background.highlight },
        React.createElement(Typography, { variant: TypographyVariant.CAPTION }, translate('Love MarineSamraj ?')),
        React.createElement(Button, { variant: "text", size: "xs", onPress: handleSTCPress }, translate('Support Us'))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default STCCTA;
