import React from 'react';
import { View } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
import Column from '../../10-atoms/layout/Column';
const Page = ({ style, children, ...rest }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { style: [styles.container, styleUtils.containerHeight, style], ...rest },
        React.createElement(View, { style: [styles.statusBarView] }),
        children));
};
const stylesGenerator = createThemedStyle(({ theme, dimensions: { safeAreaInsets } }) => ({
    container: {
        backgroundColor: theme.palette.background.main,
    },
    statusBarView: {
        zIndex: 10,
        height: safeAreaInsets.top,
        backgroundColor: theme.palette.background.main,
    },
}));
export default Page;
