export function addStringPaths(obj, basePath = '') {
    return Object.keys(obj).reduce((result, key) => {
        const value = obj[key];
        const path = `${basePath}.${key}`;
        if (typeof value === 'boolean') {
            result[key] = path;
        }
        else {
            result[key] = addStringPaths(value, path);
        }
        return result;
    }, {});
}
const obj = {
    foo: {
        bar: true,
    },
};
const result = addStringPaths(obj);
result.foo.bar;
