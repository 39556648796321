import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
const GuestSigninBtn = ({ ...btnProps }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    return (React.createElement(Button, { variant: "outline", textContainerStyle: styleUtils.flex, LeftIcon: () => React.createElement(React.Fragment, null), RightIcon: ({ disabled }) => (React.createElement(FeatherIcon, { name: "arrow-right", color: disabled
                ? theme.palette.pressable.disabledText
                : theme.palette.primary.dark })), ...btnProps }, translate('Continue as Guest')));
};
export default GuestSigninBtn;
