import { gql } from '@apollo/client';
import { addTypePolicies } from 'src/lib/graphql/cache';
import useGQLPaginatedQuery from 'src/lib/graphql/hooks/useGQLPaginatedQuery';
import paginatedListFieldPolicy from 'src/lib/graphql/utils/paginatedListFieldPolicy';
const GetCommunityListQuery = gql `
  query EP_CommunityList($pagination: PaginationOptions!) {
    ep_communityList(pagination: $pagination) {
      totalCount
      list {
        id
        data {
          title
        }
        sequence
      }
    }
  }
`;
export function useCommunityListQuery() {
    return useGQLPaginatedQuery(GetCommunityListQuery, 'ep_communityList', {
        variables: {
            pagination: {
                offset: 0,
            },
        },
    });
}
addTypePolicies({
    Query: {
        fields: {
            ep_communityList: paginatedListFieldPolicy(false),
        },
    },
});
