import { Image } from 'react-native';
export const fetchRemoteImageDimens = (imageURL) => {
    return new Promise((resolve, reject) => {
        Image.getSize(imageURL, (width, height) => {
            setTimeout(() => {
                resolve({ width, height });
            }, 10);
        }, reject);
    });
};
