import React, { useCallback } from 'react';
import SearchInput from 'src/components/10-atoms/form/inputs/SearchInput';
import Column from 'src/components/10-atoms/layout/Column';
import OverlayLayout from 'src/components/10-atoms/overlays/OverlayLayout';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useLayoutAnimation from 'src/hooks/animation/useLayoutAnimation';
import OnlySelectedTopicsSwitch from '../OnlySelectedTopicsSwitch';
import TopicsList from '../TopicList';
import useData from './useData';
const TopicPickerOverlay = ({ org, entityID, title = 'Select Topics', initialValue, maxSelectedTopics, onChange, onClose, }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const layoutAnimation = useLayoutAnimation();
    const [state, actions] = useData(org, entityID, initialValue);
    const handleSubmit = useCallback(() => {
        onChange(state.categoryList.lists.selected.map(item => item.id));
    }, [onChange, state.categoryList.lists.selected]);
    const handleTopicPress = (topicID) => {
        if (state.showOnlySelected) {
            layoutAnimation.setupLayoutAnimation();
        }
        const topic = state.categoryList.topicsStore[topicID];
        if (!topic.selected &&
            state.categoryList.lists.selected.length >= maxSelectedTopics) {
            snackbarHelperActions.show({
                variant: 'NEGATIVE',
                message: `You cannot select more than ${maxSelectedTopics} Topics`,
            });
            return;
        }
        actions.categoryList.toggleTopicSelection(topicID);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OverlayLayout.FormHeader, { title: title, onClose: onClose, onSubmit: handleSubmit }),
        React.createElement(Column, { flex: true },
            React.createElement(SearchInput, { style: styles.searchInput, autoFocus: true, autoCorrect: false, autoCapitalize: "none", spellCheck: false, value: state.categoryList.search.value, onChange: actions.categoryList.search.change, onClear: () => actions.categoryList.search.change('') }),
            React.createElement(OnlySelectedTopicsSwitch, { selectedCount: state.categoryList.lists.selected.length, style: styles.onlySelectedTopicsFilter, value: state.showOnlySelected, onChange: actions.toggleShowOnlySelected }),
            React.createElement(TopicsList, { listQueryState: state.categoryList.categoryListQuery, listQueryActions: actions.categoryList.categoryListQuery, onTopicPress: handleTopicPress, showOnlySelected: state.showOnlySelected, topicList: state.showOnlySelected
                    ? state.categoryList.lists.selected
                    : state.categoryList.lists.all }))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    chip: {
        paddingHorizontal: theme.spacing.standard.sm,
        paddingVertical: theme.spacing.standard.xxs,
        borderRadius: 16,
        borderWidth: 1,
        borderColor: theme.palette.border.main,
    },
    selectedChip: {
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.lightest,
    },
    searchInput: {
        marginHorizontal: theme.spacing.page,
        marginVertical: theme.spacing.standard.xs,
    },
    onlySelectedTopicsFilter: {
        paddingTop: 0,
        borderBottomColor: theme.palette.border.main,
        borderBottomWidth: 1,
    },
}));
export default TopicPickerOverlay;
