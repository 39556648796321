import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const UPDATE_FCM_TOKEN_MUTATION = gql `
  mutation Device_updateFCMToken($token: String!) {
    device_updateFCMToken(token: $token)
  }
`;
export function useUpdateTokenMutation(options) {
    return useGQLMutation(UPDATE_FCM_TOKEN_MUTATION, {
        showError: false,
        allowOnlyRegisteredUser: false,
        ...options,
    });
}
