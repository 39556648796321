import { MsgType } from '../types';
import { uniqueId } from 'lodash';
export default class ReqResChannel {
    initiator;
    sendMessage;
    onRequest;
    registry = new Map();
    options;
    constructor(initiator, sendMessage, onRequest, options) {
        this.initiator = initiator;
        this.sendMessage = sendMessage;
        this.onRequest = onRequest;
        this.options = {
            timeout: options?.timeout ?? 10 * 1000 /*10s*/,
        };
    }
    request(uri, payload) {
        return new Promise((resolve, reject) => {
            const req = {
                uri,
                payload,
            };
            const reqMsg = {
                id: uniqueId(),
                initiator: this.initiator,
                type: MsgType.REQ,
                payload: req,
            };
            this.registry.set(reqMsg.id, {
                resolve,
                reject,
            });
            this.sendMessage(reqMsg);
        });
    }
    handleMsg(msg) {
        if (!msg)
            return;
        if (msg.type === MsgType.REQ) {
            this.handleRequest(msg);
        }
        else {
            if (!msg.req)
                return;
            const reqMsgId = msg.req.id;
            const handler = this.registry.get(reqMsgId);
            if (handler) {
                if (msg.payload.status === 'fulfilled') {
                    handler.resolve(msg.payload.data);
                }
                else {
                    handler.reject(msg.payload.error);
                }
                this.registry.delete(reqMsgId);
            }
        }
    }
    async handleRequest(msg) {
        const respond = this.responder(msg);
        try {
            const res = await this.onRequest(msg.payload.uri, msg.payload.payload);
            respond(null, res);
        }
        catch (error) {
            respond(error);
        }
    }
    responder(reqMsg) {
        return (error, data) => {
            let resMsg = {
                id: uniqueId(),
                type: MsgType.RES,
                req: reqMsg,
                payload: {
                    status: 'fulfilled',
                    data,
                },
            };
            if (error) {
                resMsg.payload = {
                    status: 'rejected',
                    error,
                };
            }
            this.sendMessage(resMsg);
        };
    }
}
