import React, { useImperativeHandle } from 'react';
import Divider from 'src/components/10-atoms/Divider';
import Button from 'src/components/10-atoms/button/Button';
import DeleteIcon from 'src/components/10-atoms/icons/DeleteIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import QuestionForm from '../QuestionForm';
import useData from './useData';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
const QuestionListForm = React.forwardRef(({ subjectID, style, ...columnProps }, ref) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const [state, actions] = useData();
    useImperativeHandle(ref, () => ({
        form: {
            isValid: () => {
                return state.formList.reduce((result, formItem) => {
                    return ((formItem.ref.current?.actions.form.validateForm() ?? false) &&
                        result);
                }, true);
            },
            getData: () => {
                return state.formList.map((formItem, index) => ({
                    question: formItem.ref.current.state.questionEditorRef.current.getText(),
                    questionHTML: formItem.ref.current.state.form.fields.questionHTML.value,
                    marks: Number(formItem.ref.current.state.form.fields.marks.value),
                    topics: formItem.ref.current.state.form.fields.topics.value,
                    label: `${index + 1}`,
                    isSubQuestion: false,
                }));
            },
        },
    }));
    return (React.createElement(Column, { ItemSeparator: React.createElement(Divider, null), spacing: theme.spacing.standard.md, style: [{ zIndex: 1 }, style], ...columnProps },
        state.formList.map((item, index) => (React.createElement(Column, { style: { zIndex: 1 }, key: item.id, spacing: theme.spacing.standard.sm },
            React.createElement(QuestionForm, { style: { zIndex: 1 }, ref: item.ref, subjectID: subjectID, initialValue: item.initialState, label: `Q${index + 1}` }),
            React.createElement(Row, { xAxis: "flex-end" },
                React.createElement(Button, { noIconSpacing: true, LeftIcon: props => React.createElement(DeleteIcon, { ...props }), colorScheme: "negative", onPress: () => actions.removeQuestion(item.id), variant: "outline" }, "Delete"))))),
        React.createElement(Button, { LeftIcon: props => React.createElement(FeatherIcon, { name: "plus", ...props }), onPress: actions.addQuestion, variant: "outline" }, "Add Question")));
});
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QuestionListForm;
