import { useMemo, useRef } from 'react';
import { getFieldState } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import useFormState from 'src/components/10-atoms/form/hooks/useFormState';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { useQuestionTopicListQuery } from 'src/modules/exam-preparation/graphql/question-topics/getList';
import { useUpdateQuestionMutation } from 'src/modules/exam-preparation/graphql/question/updateQuestion';
import { searchList } from 'src/utils/search';
import { containsOnlyNumeric } from 'src/utils/validate';
export default function useData(question) {
    const [formState, formActions, formHelpers] = useFormState({
        fields: {
            questionHTML: getFieldState(question.data.questionHTML),
            topics: getFieldState(question.data.topics ?? []),
            topicsInput: getFieldState(''),
            marks: getFieldState(String(question.data.marks)),
        },
    }, {
        fieldValidations: {
            marks: ({ value }) => {
                const marks = Number(value);
                if (!marks ||
                    marks < 0 ||
                    marks > 50 ||
                    !containsOnlyNumeric(value)) {
                    return 'Please enter valid marks';
                }
                return true;
            },
            questionHTML: ({ value }) => {
                if (!value.length) {
                    return 'Please add the question';
                }
                return true;
            },
            topics: () => {
                return true;
            },
            topicsInput: () => true,
        },
    });
    const subject = question.ancestorsDetails.writtenSubject ??
        question.ancestorsDetails.oralsSubject;
    const [topicsListQueryState] = useQuestionTopicListQuery(subject.id);
    const suggestedTopics = useMemo(() => {
        const topicsList = topicsListQueryState.data?.ep_questionTopicList.list ?? [];
        const searchInput = formState.fields.topicsInput.value;
        return searchInput.length > 0 && topicsList.length > 0
            ? searchList(topicsList, ['title'], searchInput).map(topic => topic.title)
            : [];
    }, [topicsListQueryState.data, formState.fields.topicsInput.value]);
    const questionEditorRef = useRef(null);
    const [updateQuestionMutationState, updateQuestionMutationActions] = useUpdateQuestionMutation();
    const handleSubmit = useCallbackWithCtx(ctx => {
        if (!questionEditorRef.current)
            return;
        const formHasErrors = ctx.formHelpers.hasErrors();
        if (formHasErrors) {
            snackbarHelperActions.show({
                message: 'Fix the errors in the form',
                variant: 'NEGATIVE',
            });
            return;
        }
        const questionText = questionEditorRef.current.getText();
        updateQuestionMutationActions.submit({
            variables: {
                id: ctx.question.id,
                data: {
                    marks: Number(ctx.formState.fields.marks.value),
                    question: questionText,
                    questionHTML: ctx.formState.fields.questionHTML.value,
                    topics: ctx.formState.fields.topics.value,
                },
            },
        });
    }, { question, formState, formHelpers });
    const state = {
        form: formState,
        suggestedTopics,
        updateQuestionMutation: updateQuestionMutationState,
        questionEditorRef,
    };
    const actions = {
        form: formActions,
        handleSubmit,
    };
    return [state, actions];
}
