export const calculateSnackbarDuration = (() => {
    const TIME_PER_CHAR = 50;
    /**
     * Calculation:
     * On the internet, I found that the average reading
     * speed is 238wpm(words per minute). Added some
     * buffer and rounded it to 200wpm which turn out to be
     * Time per word: 60 / 200 => 0.3s / word => 300ms
     */
    const TIME_PER_WORD = 300;
    return (message, minDuration) => {
        const charDuration = message.length * TIME_PER_CHAR;
        const wordDuration = message.split(' ').length * TIME_PER_WORD;
        return Math.max(minDuration, charDuration, wordDuration);
    };
})();
export const getDuration = (() => {
    const durations = {
        SHORT: 2000,
        MEDIUM: 5000,
        LONG: 7000,
    };
    return (message, duration = 'SHORT') => calculateSnackbarDuration(message, durations[duration]);
})();
export const snackbarIdGenerator = (() => {
    let snackbarId = 1;
    return {
        getNew: () => snackbarId++,
        getCurrent: () => snackbarId,
    };
})();
