import { getJSONStorage } from 'src/lib/storage';
import StorageKeys from 'src/lib/storage/storageKeys';
const storage = getJSONStorage(StorageKeys.userDetails.scope);
const userStorage = {
    setUserDetails(data) {
        storage.set(StorageKeys.userDetails.key, data);
    },
    getUserDetails() {
        return storage.get(StorageKeys.userDetails.key);
    },
};
export default userStorage;
