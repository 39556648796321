import { useRoute } from '@react-navigation/native';
import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import QPNavigationBar from './QPNavigationbar';
import SearchNavigationBar from './SearchNavigationbar';
import TopicNavigationBar from './TopicNavigationbar';
const NavigationBar = () => {
    const [{}] = useTheme(stylesGenerator);
    const route = useRoute();
    const { navigatedFrom } = route.params;
    switch (navigatedFrom) {
        case 'QP_QUESTIONS':
            return React.createElement(QPNavigationBar, { btw: 1 });
        case 'TOPIC_QUESTIONS':
            return React.createElement(TopicNavigationBar, { btw: 1 });
        case 'SEARCH_QUESTIONS':
            return React.createElement(SearchNavigationBar, { btw: 1 });
        default:
            return null;
    }
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default NavigationBar;
