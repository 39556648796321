import LottieView from 'lottie-react-native';
import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import getGraphQLErrorMessage from 'src/lib/graphql/utils/getError';
import styleUtils from 'src/utils/styles';
import Button from '../button/Button';
import ColumnCenter from '../layout/ColumnCenter';
import Typography from '../typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
const GenericServerError = ({ error, message, onRetry, }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const errMsg = (error && getGraphQLErrorMessage(error, '')) ||
        message ||
        'Our Servers are facing issue. Please try again';
    return (React.createElement(ColumnCenter, { style: [styles.container, styleUtils.pagePadding], spacing: 8 },
        React.createElement(LottieView, { source: require('./lottie.json'), style: styleUtils.lottie, autoPlay: true, loop: true }),
        React.createElement(Typography, { style: styles.errorMsg, align: "center", variant: TypographyVariant.ERROR_MSG }, errMsg),
        React.createElement(Button, { variant: "text", onPress: () => onRetry() }, "RETRY")));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingVertical: 48,
    },
    errorMsg: {
        paddingHorizontal: theme.spacing.page,
    },
}));
export default GenericServerError;
