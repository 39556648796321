import { useEffect, useRef, useState } from 'react';
import { promiseQueue } from 'src/utils/promise';
import { fetchRemoteImageDimens } from './utils';
const fetchImageDimensQueue = promiseQueue(fetchRemoteImageDimens, 1);
const cache = {};
export default function useRemoteImageDimens(imageURL) {
    const mounted = useRef(true);
    const [state, setState] = useState(null);
    const fetchSizeFromRemoteImage = async () => {
        try {
            let imageDimens;
            if (cache[imageURL]) {
                imageDimens = cache[imageURL];
            }
            else {
                imageDimens = await fetchImageDimensQueue.push(imageURL);
            }
            if (!mounted.current) {
                return;
            }
            setState(imageDimens);
        }
        catch (error) { }
    };
    useEffect(() => {
        fetchSizeFromRemoteImage();
        return () => {
            mounted.current = false;
        };
    }, []);
    return [state];
}
