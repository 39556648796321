import React, { useState } from 'react';
import { useAnswerHistoryQuery } from '../../graphql/answer/history';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import WalkthroughDrawer from './components/WalkthroughDrawer';
import useFTUE from 'src/lib/ftue/hooks/useFTUE';
import FTUEKeys from 'src/lib/ftue/keys';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
export default function useData(questionID) {
    const [historyQueryState, historyQueryActions] = useAnswerHistoryQuery({
        variables: {
            questionID,
        },
    });
    const [showOnlyDiff, setShowOnlyDiff] = useState(true);
    const [isFTUE, ftueActions] = useFTUE(FTUEKeys.EP_WRITTEN_ANSWER_HISTORY_WALKTHROUGH);
    const showWalkthrough = useCallbackWithCtx((ctx, dismissable = false) => {
        bottomDrawerHelperActions.show({
            dismissable,
            component: (React.createElement(WalkthroughDrawer, { dismissable: dismissable, onHide: () => {
                    ftueActions.markComplete();
                    bottomDrawerHelperActions.hide();
                } })),
        });
    }, {});
    const state = {
        isFTUE,
        showOnlyDiff,
        historyQuery: historyQueryState,
    };
    const actions = {
        showWalkthrough,
        setShowOnlyDiff,
        historyQuery: historyQueryActions,
    };
    return [state, actions];
}
