import { getJSONStorage } from 'src/lib/storage';
import StorageKeys from 'src/lib/storage/storageKeys';
const storage = getJSONStorage(StorageKeys.userPreferences.scope);
const userPreferencesStorage = {
    setPrefs(preferences) {
        storage.set(StorageKeys.userPreferences.key, preferences);
    },
    getPrefs() {
        return storage.get(StorageKeys.userPreferences.key) ?? {};
    },
};
export default userPreferencesStorage;
