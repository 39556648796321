import usePersistedState from 'src/hooks/globalState/usePersistedState';
import { StorageScope } from 'src/lib/storage/types';
import { getFTUEKey } from '../utils';
export default function useFTUEStorage(key) {
    const [state = true, setState] = usePersistedState({
        scope: StorageScope.DEVICE,
        key: getFTUEKey(key),
    });
    return [state ?? true, setState];
}
