import { useMemo } from 'react';
import useFetchGQLListTill from 'src/lib/graphql/hooks/useFetchGQLListTill';
import { useEPQuestionSearchQuery, } from 'src/modules/exam-preparation/graphql/question/questionSearch';
export default function useSearchQuestionSiblings(query, till) {
    const [listQueryState, listQueryActions] = useEPQuestionSearchQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });
    const listQueryVariables = useMemo(() => ({
        query,
        withAncestorsDetails: true,
    }), [query]);
    const [siblingQueryState] = useFetchGQLListTill(listQueryState, listQueryActions, listQueryVariables, till);
    const state = siblingQueryState;
    const actions = {};
    return [state, actions];
}
