import React from 'react';
import { ActivityIndicator } from 'react-native';
import TextButton from 'src/components/10-atoms/button/TextButton';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const BlockedContentMessage = ({ message, unblockingInProgress, onUnblockPress, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(Row, { xAxis: "space-between", yAxis: "center", bg: theme.palette.background.dark, spacing: theme.spacing.itemSeparation, pv: theme.spacing.standard.xs, ...rowProps },
        React.createElement(Typography, { variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary }, message),
        unblockingInProgress ? (React.createElement(ActivityIndicator, null)) : (React.createElement(TextButton, { onPress: onUnblockPress }, translate('Unblock')))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default BlockedContentMessage;
