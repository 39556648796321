import { useEffect, useRef } from 'react';
import { useInAppNotificationUnreadCountQuery } from '../graphql/in-app/unread-count';
import useUnreadNotificationCount from './useUnreadNotificationCount';
import { NotificationStatus } from '../graphql/types';
import useIsRegisteredUser from 'src/modules/authentication/hooks/useIsRegisteredUser';
export default function useUnreadNotificationBgFetcher() {
    const isRegisteredUser = useIsRegisteredUser();
    const [unreadCountQueryState, unreadCountQueryActions] = useInAppNotificationUnreadCountQuery({
        pollInterval: 15 * 60 * 1000,
        lazy: true,
        skip: !isRegisteredUser,
        skipPollAttempt: () => !isRegisteredUser,
    });
    const [, setUnreadCount] = useUnreadNotificationCount();
    const hasStartedPolling = useRef(false);
    useEffect(() => {
        if (!hasStartedPolling.current && isRegisteredUser) {
            hasStartedPolling.current = true;
            unreadCountQueryActions.fetch();
        }
    }, [isRegisteredUser]);
    useEffect(() => {
        if (unreadCountQueryState.data?.notification_getInAppList) {
            setUnreadCount(unreadCountQueryState.data.notification_getInAppList.list.filter(item => item.status === NotificationStatus.UNREAD).length);
        }
    }, [unreadCountQueryState.data]);
}
