import React from 'react';
import TranslateIcon from 'src/components/10-atoms/icons/TranslateIcon';
import Row from 'src/components/10-atoms/layout/Row';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import styleUtils from 'src/utils/styles';
const TranslatedIndicator = ({ ...rowCenterProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(RowCenter, { ...rowCenterProps },
        React.createElement(Row, { style: styleUtils.dot, bg: theme.palette.background.translate }),
        React.createElement(TranslateIcon, { size: theme.iconSize.xxs })));
};
export default TranslatedIndicator;
