import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
const USER_GET_DETAILS_QUERY = gql `
  query User_getDetails {
    user_getDetails {
      id
      details {
        avatarURL
        firstName
        lastName
        userName
        email
      }
      reputation {
        score
        badgesInfo {
          summary {
            gold
            silver
            bronze
          }
        }
      }
    }
  }
`;
export function useUserDetailsQuery(options = {}) {
    return useGQLQuery(USER_GET_DETAILS_QUERY, options);
}
