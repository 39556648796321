import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../10-atoms/layout/Row';
const ActionsBar = ({ ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.standard.xs, yAxis: "center", xAxis: "space-between", bg: theme.palette.background.main, ...rowProps }));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default ActionsBar;
