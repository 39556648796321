import StorageKeys from 'src/lib/storage/storageKeys';
import { getDefaultDebugSettingsState } from './utils';
import { getJSONStorage } from 'src/lib/storage';
const storage = getJSONStorage(StorageKeys.debugSettings.scope);
const debugSettingsStorage = {
    getSettings() {
        return (storage.get(StorageKeys.debugSettings.key) ??
            getDefaultDebugSettingsState());
    },
    setSettings(settings) {
        storage.set(StorageKeys.debugSettings.key, settings);
    },
};
export default debugSettingsStorage;
