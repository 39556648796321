/**
 * Palette generator using:
 * http://mcg.mbitson.com
 */
const palette = {
    brand: {
        25: '#00a0e725',
        50: '#00a0e745',
        100: '#aae2f6',
        200: '#6fcff0',
        300: '#20bceb',
        400: '#00aeea',
        500: '#00a0e7',
        600: '#0092d9',
        700: '#0080c6',
        800: '#006fb3',
        900: '#005093',
    },
    navyBlue: '#478DF5',
    grey: {
        50: '#ffffff',
        75: '#f8f8f8',
        100: '#f5f5f5',
        200: '#eaeaea',
        300: '#d2d2d2',
        400: '#aeaeae',
        500: '#8d8d8d',
        600: '#656565',
        700: '#525252',
        800: '#343434',
        825: '#2a2a2a',
        850: '#222222',
        900: '#151515',
    },
    green: {
        50: '#e7f9e8',
        100: '#c5efc7',
        200: '#9fe5a3',
        300: '#72db7b',
        400: '#49d15b',
        500: '#00c839',
        600: '#00b82f',
        700: '#00a521',
        800: '#009411',
        900: '#007400',
    },
    red: {
        50: '#feebef',
        100: '#fcced4',
        200: '#ec9c9d',
        300: '#e17678',
        400: '#eb5757',
        500: '#f0483f',
        600: '#e13f3d',
        700: '#cf3637',
        800: '#c22f30',
        900: '#b32425',
    },
    yellow: {
        50: '#fefae8',
        100: '#fcf3c6',
        200: '#faeba1',
        300: '#f8e37b',
        400: '#f7dd5e',
        500: '#f5d742',
        600: '#f4d33c',
        700: '#f2cd33',
        800: '#f0c72b',
        900: '#eebe1d',
    },
    stc: '#FF4E4EEF',
};
export default palette;
