export function promiseQueue(workerFn, concurrencyLimit = 1) {
    const queue = [];
    let threadCount = 0;
    async function runWorker() {
        if (threadCount < concurrencyLimit) {
            threadCount++;
            const job = queue.shift();
            if (job) {
                runWorker();
                try {
                    const result = await workerFn(job.task);
                    job.resolve(result);
                }
                catch (error) {
                    job.reject(error);
                }
                threadCount--;
                runWorker();
            }
            else {
                threadCount--;
            }
        }
    }
    return {
        push(task) {
            return new Promise((resolve, reject) => {
                queue.push({
                    task,
                    resolve,
                    reject,
                });
                runWorker();
            });
        },
    };
}
export function createPromise() {
    const result = {};
    result.promise = new Promise((resolve, reject) => {
        result.resolve = resolve;
        result.reject = reject;
    });
    return result;
}
if (require.main === module) {
    console.time('promiseQueue');
    const queue = promiseQueue(async (timer) => {
        return new Promise(resolve => {
            setTimeout(resolve, timer, timer);
        });
    }, 3);
    queue.push(1000);
    queue.push(1000);
    queue.push(1000);
    queue.push(1000);
    queue.push(1000);
    setTimeout(async () => {
        const result = await queue.push(1200);
        console.log('result:', result);
        console.timeEnd('promiseQueue');
    }, 1000);
}
