import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import useLinkActions from './useLinkActions';
export default function useKeyboardShortcuts(quill) {
    const linkActions = useLinkActions(quill);
    // Register keyboard shortcuts
    useEffectWithCtx(ctx => {
        if (quill) {
            // Keyboard shortcut for Triggering Link
            quill.keyboard.addBinding({
                key: 'k',
                metaKey: true,
                handler() {
                    ctx.linkActions.triggerEdit();
                },
            });
        }
    }, { linkActions }, [quill]);
}
