import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import useTheme from 'src/customizations/themes/hooks/useTheme';
export const PrevBtn = ({ ...btnProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(Button, { variant: "text", ...btnProps }, ({ textStyle }) => (React.createElement(FeatherIcon, { name: "rewind", style: textStyle, size: theme.iconSize.md }))));
};
export const StopBtn = ({ ...btnProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(Button, { variant: "text", ...btnProps }, ({ textStyle }) => (React.createElement(FeatherIcon, { name: "square", style: textStyle, size: theme.iconSize.md }))));
};
export const PauseBtn = ({ ...btnProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(Button, { variant: "text", ...btnProps }, ({ textStyle }) => (React.createElement(FeatherIcon, { name: "pause", style: textStyle, size: theme.iconSize.md }))));
};
export const ResumeBtn = ({ ...btnProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(Button, { variant: "text", ...btnProps }, ({ textStyle }) => (React.createElement(FeatherIcon, { name: "play", style: textStyle, size: theme.iconSize.md }))));
};
export const NextBtn = ({ ...btnProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(Button, { variant: "text", ...btnProps }, ({ textStyle }) => (React.createElement(FeatherIcon, { name: "fast-forward", style: textStyle, size: theme.iconSize.md }))));
};
export const LinkBtn = ({ ...btnProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(Button, { variant: "text", ...btnProps }, ({ textStyle }) => (React.createElement(FeatherIcon, { name: "external-link", style: textStyle, size: theme.iconSize.md }))));
};
export const MoreBtn = ({ ...btnProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(Button, { variant: "text", ...btnProps }, ({ textStyle }) => (React.createElement(FeatherIcon, { name: "more-vertical", style: textStyle, size: theme.iconSize.md }))));
};
