import { useRef } from 'react';
import useAppState from '../useAppState';
import useUsageProfile from '../useUsageProfile';
import { UsageTrackingParams } from './constants';
export default function useTrackOpenedDuration() {
    const openTime = useRef(Date.now());
    const [, usageProfileModifiers] = useUsageProfile();
    useAppState((ctx, state) => {
        if (state === 'background') {
            const durationInMin = Math.round((Date.now() - openTime.current) / (1000 * 60));
            ctx.increment(UsageTrackingParams.durationInMin, durationInMin);
        }
        else {
            // Update App opentime when the App comes to Foreground
            openTime.current = Date.now();
        }
    }, { increment: usageProfileModifiers.increment });
}
