import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import { AnimatedRow } from 'src/components/10-atoms/layout/Row';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { isWeb } from 'src/utils/platform';
const Title = ({ ...rest }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(RowCenter, { style: styles.titleContainer },
        React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM, variant: TypographyVariant.PAGE_TITLE, ...rest })));
};
const Body = ({ style, ...rest }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return React.createElement(Column, { xAxis: "stretch", style: [styles.body, style], ...rest });
};
const Actions = ({ style, ...rest }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(AnimatedRow, { xAxis: "space-between", yAxis: "center", style: [styles.actions, style], ...rest }));
};
const BottomDrawerLayout = ({ style, ...columnProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return React.createElement(Column, { style: [styles.container, style], ...columnProps });
};
BottomDrawerLayout.Title = Title;
BottomDrawerLayout.Body = Body;
BottomDrawerLayout.Actions = Actions;
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        ...(isWeb && { flex: 1 }),
        backgroundColor: theme.palette.background.main,
    },
    titleContainer: {
        height: PRESSABLE_SIZE,
        borderBottomColor: theme.palette.divider.main,
        borderBottomWidth: 1,
    },
    body: {
        padding: theme.spacing.standard.sm,
        paddingBottom: theme.spacing.standard.sm,
    },
    actions: {
        paddingHorizontal: theme.spacing.page,
        alignSelf: 'stretch',
        backgroundColor: theme.palette.background.main,
        paddingVertical: theme.spacing.standard.xxs,
    },
}));
export default BottomDrawerLayout;
