import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { useState } from 'react';
import useSubscription from 'src/lib/events-bridge/hooks/useSubscription';
import EBAPIs from 'src/lib/events-bridge/topics';
import { ebGlobalChannel } from 'src/lib/events-bridge';
const DEFAULT_STATE = {
    visible: false,
    onClose: () => { },
};
export default function useDatePicker() {
    const [state, setState] = useState(DEFAULT_STATE);
    const pick = useCallbackWithCtx((ctx, data) => {
        setState({
            visible: true,
            ...data,
            onChange(newValue) {
                setState(DEFAULT_STATE);
                data.onChange?.(newValue);
            },
            onClose() {
                setState(DEFAULT_STATE);
                data.onClose?.();
            },
        });
    }, {});
    useSubscription(EBAPIs.helperViews.datePicker.pick, pick);
    return [state, { pick }];
}
export const datePickerHelperActions = {
    pick: (data) => {
        ebGlobalChannel.publish(EBAPIs.helperViews.datePicker.pick, data);
    },
};
