import { useCallback, useEffect } from 'react';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { getDeviceInfo } from 'src/lib/device';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { useGuestSigninAPI } from '../apis/signin';
export default function useGuestSignin() {
    const { translate } = useI18n();
    const [guestSigninAPIState, guestSigninAPIActions] = useGuestSigninAPI();
    useEffect(() => {
        if (guestSigninAPIState.error) {
            snackbarHelperActions.show({
                message: guestSigninAPIState.error.code === 'ERR_BAD_RESPONSE'
                    ? translate('Network connection failure. Please try later')
                    : guestSigninAPIState.error.message ||
                        translate('Something went wrong, please try again'),
                variant: 'NEGATIVE',
            });
        }
    }, [guestSigninAPIState.error]);
    const handleGuestLogin = useCallback(async () => {
        guestSigninAPIActions.submit(await getDeviceInfo());
    }, [guestSigninAPIActions]);
    const state = {
        guestSigninAPI: guestSigninAPIState,
    };
    const actions = {
        continueAsGuest: handleGuestLogin,
    };
    return [state, actions];
}
