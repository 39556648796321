import React from 'react';
import PlaylistController from './components/PlaylistController';
import usePlaylistPlayer from './hooks/usePlaylistPlayer';
import { HelperViewsZStack } from 'src/components/20-helper-views/constants';
const PlaylistControllerHelperView = ({}) => {
    const [playlistPlayerState, playlistPlayerActions, playlistPlayerHelpers] = usePlaylistPlayer();
    return (React.createElement(PlaylistController, { state: playlistPlayerState.player.state, mode: playlistPlayerState.mode, onModeChange: playlistPlayerActions.setMode, title: playlistPlayerState.player.item?.playerInput?.title, prevDisabled: !playlistPlayerHelpers.playlist.hasPrev(), onPrev: playlistPlayerActions.playlist.gotoPrev, nextDisabled: !playlistPlayerHelpers.playlist.hasNext(), onNext: playlistPlayerActions.playlist.gotoNext, onPause: playlistPlayerActions.player.pause, onResume: playlistPlayerActions.player.resume, onStop: playlistPlayerActions.player.stop, openDisabled: playlistPlayerHelpers.player.isOpenDisabled(), onOpen: playlistPlayerActions.player.openItem, style: {
            zIndex: HelperViewsZStack.PLAYLIST_CONTROLLER,
        } }));
};
export default PlaylistControllerHelperView;
