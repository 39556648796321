import { useMemo } from 'react';
import useFetchGQLListTill from 'src/lib/graphql/hooks/useFetchGQLListTill';
import { useQuestionListForTopicQuery, } from 'src/modules/exam-preparation/graphql/question/getQuestionsForTopic';
export default function useTopicQuestionSiblings(subjectID, topic, till) {
    const [listQueryState, listQueryActions] = useQuestionListForTopicQuery({
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
    });
    const listQueryVariables = useMemo(() => ({
        ancestorID: subjectID,
        topic,
    }), [subjectID, topic]);
    const [siblingQueryState] = useFetchGQLListTill(listQueryState, listQueryActions, listQueryVariables, till);
    const state = siblingQueryState;
    const actions = {};
    return [state, actions];
}
