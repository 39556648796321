import { useEffect, useState } from 'react';
import { Keyboard } from 'react-native';
const getInitialState = () => ({
    duration: 250,
    // easing: Easing.out(Easing.ease),
    height: 0,
});
export default function useKeyboardHeight() {
    const [keyboardAnimation, setKeyboardAnimation] = useState(getInitialState());
    useEffect(() => {
        const showSubscription = Keyboard.addListener('keyboardWillShow', ev => {
            setKeyboardAnimation({
                duration: ev.duration,
                // easing: ev.easing,
                height: ev.endCoordinates.height,
            });
        });
        const hideSubscription = Keyboard.addListener('keyboardWillHide', ev => {
            setKeyboardAnimation({
                duration: ev.duration,
                // easing: ev.easing,
                height: 0,
            });
        });
        return () => {
            showSubscription.remove();
            hideSubscription.remove();
        };
    }, []);
    return [keyboardAnimation];
}
