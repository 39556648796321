import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import { EP_QUESTION_DETAILS_QUERY } from '../question/getQuestionDetails';
import { handleAnswerMutationCompleted } from './create';
const UPDATE_ANSWER_MUTATION = gql `
  mutation EP_UpdateAnswer($questionID: String!, $data: EPAnswerInput!) {
    ep_updateAnswer(questionID: $questionID, data: $data) {
      sentForReview
    }
  }
`;
export function useUpdateAnswerMutation(options) {
    return useGQLMutation(UPDATE_ANSWER_MUTATION, {
        ...options,
        refetchQueries(result) {
            if (!result.data?.ep_updateAnswer.sentForReview) {
                return [EP_QUESTION_DETAILS_QUERY];
            }
            return [];
        },
        onCompleted(data) {
            handleAnswerMutationCompleted(data.ep_updateAnswer);
        },
    });
}
