import React from 'react';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Typography from '../../typography/Typography';
import InputLayout from './InputLayout';
import withPress from 'src/hoc/withPress';
const InputLayoutStatic = ({ placeholder, value, valueTextProps, ...rest }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(InputLayout, { focused: true, ...rest },
        React.createElement(Typography, { flex: true, numberOfLines: 1, ellipsizeMode: "tail", variant: value ? TypographyVariant.BODY : TypographyVariant.CAPTION, style: [!value && styles.placeholder], ...valueTextProps }, value ?? placeholder)));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    placeholder: {
        color: theme.palette.typography.placeholder,
    },
}));
export default withPress(InputLayoutStatic);
