import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../layout/Row';
const Badge = ({ style, ...rowProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { spacing: theme.spacing.standard.xxs, yAxis: "center", style: [styles.container, style], ...rowProps }));
};
const stylesGenerator = createThemedStyle(() => ({
    container: {
        borderRadius: 2,
        overflow: 'hidden',
        paddingHorizontal: 4,
        paddingVertical: 2,
    },
}));
export default Badge;
