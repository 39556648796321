export var DeviceType;
(function (DeviceType) {
    DeviceType["MOBILE"] = "mobile";
    DeviceType["TABLET"] = "tablet";
    DeviceType["DESKTOP"] = "desktop";
    DeviceType["TV"] = "TV";
    DeviceType["CONSOLE"] = "console";
    DeviceType["WEARABLE"] = "wearable";
    DeviceType["UNKNOWN"] = "unknown";
})(DeviceType || (DeviceType = {}));
