import React from 'react';
import Snackbar from './Snackbar';
import useSnackbar from './useSnackbar';
import { HelperViewsZStack } from '../constants';
const SnackbarHelperView = ({}) => {
    const [{ id: _, ...snackbarState }] = useSnackbar();
    return (React.createElement(Snackbar, { style: {
            zIndex: HelperViewsZStack.SNACKBAR,
        }, ...snackbarState }));
};
export default SnackbarHelperView;
