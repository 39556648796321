import { GoogleOAuthProvider } from '@react-oauth/google';
import React, { useEffect, useState } from 'react';
import config from 'react-native-ultimate-config';
import RootView from './WebRootView';
import setupBaseStyle from './web/baseStyle';
import initFirebase from './web/firebase';
import initSentry from './web/sentry';
import initSyncStorage from './web/syncStorage';
import RNBootSplash from 'react-native-bootsplash';
import useAppInit from './hooks/app-level/useAppInit';
import { isWebView } from './utils/platform';
import { WebRNModuleAPIs } from './components/15-containers/SuperChargedWebView/communication/web2RNCom/WebRNModuleAPIs';
initFirebase();
initSentry();
setupBaseStyle();
initSyncStorage();
const App = () => {
    const [appInitialized] = useAppInit();
    const [navigationReady, setNavigationReady] = useState(false);
    useEffect(() => {
        if (appInitialized && navigationReady) {
            if (isWebView) {
                WebRNModuleAPIs.WVLifeCycle.loaded();
            }
            else {
                RNBootSplash.hide({ fade: true });
            }
        }
    }, [appInitialized, navigationReady]);
    return (React.createElement(GoogleOAuthProvider, { clientId: config.GOOGLE_OAUTH_WEB_CLIENT_ID },
        React.createElement(RootView, { onNavigationReady: () => setNavigationReady(true) })));
};
export default App;
