import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import omitKeys from 'src/utils/object/omitKeys';
import useRequest from '../useRequest';
const getInitialState = (initialState) => Object.assign({
    loading: false,
    error: null,
    data: null,
}, initialState);
const getInitialListState = () => ({
    totalCount: 0,
    list: [],
});
export default function usePaginatedRequest(fetcher, events = {}, options = {}) {
    const [apiState, apiActions] = useRequest(fetcher, {
        onLoading: events.onLoading,
        onError: events.onError,
    }, {
        paginated: true,
        ...omitKeys(options, ['initialState']),
    });
    const [paginatedAPIState, setPaginatedAPIState] = useState(getInitialState(options.initialState));
    const pageNo = useRef(1);
    const hasMore = useRef(true);
    const forceFetch = useRef(false);
    const loading = useRef(false);
    const resetList = useCallback((clearList = false) => {
        pageNo.current = 1;
        hasMore.current = true;
        if (clearList) {
            setPaginatedAPIState(getInitialState());
        }
    }, []);
    const addToList = useCallback((response) => {
        setPaginatedAPIState(prevState => {
            if (!prevState.data || pageNo.current === 1) {
                prevState.data = getInitialListState();
            }
            // append the new page to the existing list
            prevState.data.list.push(...response.list);
            prevState.data.totalCount =
                response.totalCount ?? prevState.data.totalCount;
            if (response.totalCount > prevState.data.list.length) {
                hasMore.current = false;
            }
            events.onData &&
                events.onData({ data: prevState.data, loading: false, error: null });
            return {
                loading: false,
                error: null,
                data: prevState.data,
            };
        });
    }, []);
    useEffect(() => {
        if (apiState.loading) {
            setPaginatedAPIState(prevState => ({
                ...prevState,
                error: null,
                loading: true,
            }));
        }
        else {
            if (apiState.error) {
                setPaginatedAPIState(prevState => ({
                    ...prevState,
                    loading: false,
                    error: apiState.error,
                }));
            }
            else if (apiState.data) {
                addToList(apiState.data);
            }
        }
    }, [apiState]);
    useEffect(() => {
        if (!apiState.loading) {
            if (apiState.error || apiState.data) {
                loading.current = false;
            }
        }
    }, [apiState]);
    const actions = useMemo(() => ({
        refresh(...args) {
            // cancel prev requests
            apiActions.cancel();
            resetList();
            loading.current = true;
            if (forceFetch.current) {
                forceFetch.current = false;
                apiActions.forceFetch(pageNo.current, ...args);
            }
            else {
                apiActions.fetch(pageNo.current, ...args);
            }
        },
        forceRefresh(...args) {
            forceFetch.current = true;
            actions.refresh(...args);
        },
        clearAndRefresh(...args) {
            resetList(true);
            actions.refresh(...args);
        },
        getNextPage(...args) {
            if (loading.current || !hasMore.current) {
                return;
            }
            loading.current = true;
            pageNo.current += 1;
            apiActions.fetch(pageNo.current, ...args);
        },
    }), [apiActions, apiState.loading, resetList]);
    const helpers = useMemo(() => ({
        hasMore: () => (apiState.error ? false : hasMore.current),
    }), [apiState]);
    return [paginatedAPIState, actions, helpers];
}
