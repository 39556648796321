import 'mathlive';
import sanitizeHTML from 'sanitize-html';
export function sanitizeSupportedHTML(html) {
    return sanitizeHTML(html, {
        allowedTags: [
            'div',
            'b',
            'i',
            'p',
            'br',
            'img',
            'li',
            'ul',
            'ol',
            'table',
            'th',
            'thead',
            'tbody',
            'tr',
            'td',
        ],
        allowedAttributes: {
            img: sanitizeHTML.defaults.allowedAttributes.img,
        },
    });
}
export function stripHTMLTags(html) {
    return sanitizeHTML(html, {
        allowedTags: [],
        allowedAttributes: {},
    });
}
export function removeEmptyUnwantedNodes(node) {
    if (['DIV', 'P'].includes(node.tagName)) {
        for (let childNode of node.children) {
            removeEmptyUnwantedNodes(childNode);
        }
        if (node.innerHTML === '') {
            node.remove();
        }
    }
}
/**
 * Extract HTML Content from the given inputElem node
 */
function getResolvedNodes(nodeListRef) {
    const result = [];
    for (let i = 0; i < nodeListRef.length; ++i) {
        result.push(nodeListRef[i]);
    }
    return result;
}
function getMF(mathExprNode) {
    const [mf] = mathExprNode.getElementsByTagName('math-field');
    return mf;
}
function getMFValue(mathExprNode) {
    const mf = getMF(mathExprNode);
    const expr = mf.value;
    return expr;
}
/**
 * Returns the math field value as a spoken text
 * Source: https://cortexjs.io/mathlive/guides/speech/
 */
function getMFSpokenText(mathExprNode) {
    const mf = getMF(mathExprNode);
    const spokenText = mf.getValue('spoken-text');
    return spokenText;
}
function isMFEmpty(mathExprNode) {
    const expr = getMFValue(mathExprNode);
    return !expr.length;
}
function replaceMFWithLatex(mathExprNode) {
    const expr = getMFValue(mathExprNode);
    mathExprNode.replaceWith(expr.length ? `$$${expr}$$` : '');
}
function replaceMFWithSpokenText(mathExprNode) {
    const spokenText = getMFSpokenText(mathExprNode);
    mathExprNode.replaceWith(spokenText.length ? spokenText : '');
}
function mfIterator(inputElemRef, iteratee) {
    if (!inputElemRef.current) {
        return;
    }
    const mathExprContainers = getResolvedNodes(inputElemRef.current.getElementsByClassName('math-expr-container'));
    mathExprContainers.forEach(containerNode => {
        const [mathExprNode] = containerNode.getElementsByClassName('math-expr');
        iteratee({ containerNode, mathExprNode });
        containerNode.replaceWith(...containerNode.childNodes);
    });
    removeEmptyUnwantedNodes(inputElemRef.current);
    return inputElemRef.current.innerHTML;
}
export default function extractHTMLContent(inputElemRef) {
    if (!inputElemRef.current) {
        return '';
    }
    mfIterator(inputElemRef, ({ containerNode, mathExprNode }) => {
        // If the math-field value is empty, then
        // just delete the container node
        if (!mathExprNode || isMFEmpty(mathExprNode)) {
            containerNode.replaceWith('');
            return;
        }
        replaceMFWithLatex(mathExprNode);
    });
    removeEmptyUnwantedNodes(inputElemRef.current);
    return inputElemRef.current.innerHTML;
}
export function extractHTML2TTSContent(inputElemRef) {
    if (!inputElemRef.current) {
        return '';
    }
    // MathfieldElement.textToSpeechRules = 'mathlive';
    // MathfieldElement.textToSpeechRulesOptions = {
    //   domain: 'chromevox', // 'mathspeak' or 'chromevox'
    //   ruleset: 'mathspeak-brief', // 'mathspeak-default',
    //   // 'mathspeak-brief', 'mathspeak-sbrief', 'chromevox-short',
    //   // 'chromevox-default' or 'chromevox-alternative'
    // };
    mfIterator(inputElemRef, ({ containerNode, mathExprNode }) => {
        // If the math-field value is empty, then
        // just delete the container node
        if (isMFEmpty(mathExprNode)) {
            containerNode.replaceWith('');
            return;
        }
        replaceMFWithSpokenText(mathExprNode);
    });
    return inputElemRef.current.textContent ?? '';
}
