/**
 * Goals for this component:
 * - Should load the Translation dynamically when the selected language changes
 * - Should store/restore Translations from device storage
 * - Should periodically update the Translations from Server
 * - Should block the UI when Translations are loading for the First Time
 * - Should handle showing Language selection screen when a language isn't selected yet
 * - Should provide context
 */
import React, { useCallback, useMemo } from 'react';
import useEffectRange from 'src/hooks/useEffectUtils/useEffectRange';
import { graphqlClient } from 'src/lib/graphql';
import useUserPreferences from 'src/modules/preferences/storage/hook';
import { EnglishContext, translationsContext } from '.';
import SelectLanguage from '../container/SelectLanguage';
import useLangTransations from './useLangTranslations';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
const TranslationsProvider = ({ children }) => {
    const [userPrefs] = useUserPreferences();
    const changeLanguage = useCallback((force = false) => {
        bottomDrawerHelperActions.show({
            dismissable: !force,
            component: React.createElement(SelectLanguage, null),
        });
    }, []);
    useEffectRange([1], () => {
        graphqlClient.resetStore();
    }, [userPrefs.lang]);
    const [translationsState] = useLangTransations(userPrefs.lang);
    const value = useMemo(() => {
        if (!userPrefs.lang || !translationsState.langTranslations) {
            return null;
        }
        return {
            code: userPrefs.lang,
            label: userPrefs.lang,
            langTranslations: translationsState.langTranslations,
            changeLanguage,
        };
    }, [translationsState.langTranslations, userPrefs.lang]);
    return (React.createElement(translationsContext.Provider, { value: value ?? EnglishContext }, children));
};
export default TranslationsProvider;
