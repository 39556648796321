const round = (() => {
    const MaxValueLength = 3;
    return (num, options = {}) => {
        const { maxValueLength = MaxValueLength } = options;
        const roundedValue = Math.round(num * 100) / 100;
        const [integerValue] = String(roundedValue).split('.');
        const maxDecimalPoints = maxValueLength - integerValue.length;
        return maxDecimalPoints > 0
            ? Number(roundedValue.toFixed(maxDecimalPoints))
            : Number(integerValue);
    };
})();
var SplitFormat;
(function (SplitFormat) {
    SplitFormat[SplitFormat["INDIAN"] = 1] = "INDIAN";
    SplitFormat[SplitFormat["INTL"] = 2] = "INTL";
})(SplitFormat || (SplitFormat = {}));
const splitNumber = (() => {
    const splitters = {
        [SplitFormat.INDIAN](num) {
            let numStr = String(num);
            const parts = [];
            let i = 0;
            while (numStr) {
                const endIndex = i === 0 ? -3 : -2;
                const part = numStr.slice(endIndex);
                numStr = numStr.slice(0, endIndex);
                parts.push(part);
                i++;
            }
            return parts.reverse();
        },
        [SplitFormat.INTL](num) {
            let numStr = String(num);
            const parts = [];
            while (numStr) {
                const endIndex = -3;
                const part = numStr.slice(endIndex);
                numStr = numStr.slice(0, endIndex);
                parts.push(part);
            }
            return parts.reverse();
        },
    };
    return (num, options = {}) => {
        const { splitFormat = SplitFormat.INDIAN } = options;
        return splitters[splitFormat](num);
    };
})();
const addDelimiters = (num, options = {}) => {
    const { thousandSeparator = ',', decimalSeparator = '.', maxDecimalPlaces = 2, } = options;
    let numStr = String(num);
    let sign = '';
    if (numStr.startsWith('-')) {
        sign = '-';
        numStr = numStr.slice(1);
    }
    let [wholeNumber, decimal] = numStr.split('.');
    let result = `${sign}${splitNumber(wholeNumber, options).join(thousandSeparator)}`;
    if (decimal && decimal.length > 0) {
        result += `${decimalSeparator}${decimal.slice(0, maxDecimalPlaces)}`;
    }
    return result;
};
var AbbreviateFormat;
(function (AbbreviateFormat) {
    AbbreviateFormat[AbbreviateFormat["INDIAN"] = 1] = "INDIAN";
    AbbreviateFormat[AbbreviateFormat["INTL"] = 2] = "INTL";
})(AbbreviateFormat || (AbbreviateFormat = {}));
const abbreviate = (() => {
    // const MIN_LIMIT = 10000;
    const THOUSAND = 1000;
    const LAKH = THOUSAND * 100;
    const CRORE = LAKH * 100;
    const MILLION = THOUSAND * 1000;
    const BILLION = CRORE * 100;
    const Abbreviations = {
        THOUSAND: 'K',
        LAKH: 'L',
        CRORE: 'C',
        MILLION: 'M',
        BILLION: 'B',
        TRILLION: 'T',
    };
    return (num, options = {}) => {
        const { abbrFormat = AbbreviateFormat.INTL } = options;
        // if (num < MIN_LIMIT) return addDelimiters(round(num), options);
        if (abbrFormat === AbbreviateFormat.INDIAN) {
            if (num >= CRORE) {
                const crores = addDelimiters(round(num / CRORE, options), options);
                return `${crores}${Abbreviations.CRORE}`;
            }
            if (num >= LAKH) {
                const lakhs = addDelimiters(round(num / LAKH, options));
                return `${lakhs}${Abbreviations.LAKH}`;
            }
        }
        else {
            if (num >= BILLION) {
                const billions = addDelimiters(round(num / BILLION, options), options);
                return `${billions}${Abbreviations.BILLION}`;
            }
            if (num >= MILLION) {
                const millions = addDelimiters(round(num / MILLION, options));
                return `${millions}${Abbreviations.MILLION}`;
            }
        }
        if (num >= THOUSAND) {
            const thousands = addDelimiters(round(num / THOUSAND, options), options);
            return `${thousands}${Abbreviations.THOUSAND}`;
        }
        return String(round(num, options));
    };
})();
const random = (min, max) => {
    return min + Math.random() * (max - min);
};
const NumberUtils = {
    // ENUMS
    SplitFormat,
    AbbreviateFormat,
    // Methods
    round,
    addDelimiters,
    abbreviate,
    random,
};
export default NumberUtils;
