import React from 'react';
import RNZoomImageViewer from 'react-native-image-zoom-viewer';
import CloseIcon from 'src/components/10-atoms/icons/CloseIcon';
import ModalBase from 'src/components/10-atoms/modals/ModalBase';
import PressableArea from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const ImageViewer = ({ images, initialIndex, visible, onClose, }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(ModalBase, { animationIn: "zoomIn", animationOut: "zoomOut", propagateSwipe: false, isVisible: visible, onDismiss: onClose },
        React.createElement(RNZoomImageViewer, { index: initialIndex, imageUrls: images.map(image => ({ url: image.uri })), enableSwipeDown: true, onSwipeDown: onClose, swipeDownThreshold: 100, backgroundColor: theme.palette.background.dark, renderIndicator: () => React.createElement(React.Fragment, null) }),
        React.createElement(PressableArea, { style: [styles.close], xAxis: "flex-end" },
            React.createElement(CloseIcon, { onPress: onClose, color: "white" }))));
};
const stylesGenerator = createThemedStyle(({ dimensions: { safeAreaInsets } }) => ({
    close: {
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.3)',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 24,
        right: 12,
        top: safeAreaInsets.top + 12,
    },
}));
export default ImageViewer;
