import InAppReview from 'react-native-in-app-review';
import Analytics from 'src/lib/analytics';
import Crashlytics from 'src/lib/crashlytics';
import useUsageProfileThreshold from '../useUsageProfileThreshold';
import { InAppReviewTriggerCond } from '../useUsageProfileThreshold/conditions';
export default function useInAppReviewTrigger() {
    useUsageProfileThreshold(InAppReviewTriggerCond, () => {
        const isAvailable = InAppReview.isAvailable();
        const triggerAnalytics = Analytics.event
            .builder()
            .setPage('Common')
            .setWidget('InAppReview')
            .setTarget('Trigger');
        if (isAvailable) {
            InAppReview.RequestInAppReview()
                .then(hasFlowFinishedSuccessfully => {
                triggerAnalytics.success();
                console.log('Successfully Triggered InAppReview:', hasFlowFinishedSuccessfully);
            })
                .catch(error => {
                triggerAnalytics.failed();
                Crashlytics.error(error);
            });
        }
    });
}
