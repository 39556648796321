import { useEffect } from 'react';
import { getDeviceInfo } from '../device';
import Analytics from './analytics';
import { isWebView } from 'src/utils/platform';
export default function useInitAnalytics() {
    useEffect(() => {
        if (isWebView) {
            getDeviceInfo().then(deviceInfo => {
                Analytics.setDefaultEventProperties(deviceInfo);
            });
        }
    }, []);
}
