/**
 * The below section is to sync the store changes from Web 2 RN
 */
import { WebRNModuleAPIs } from 'src/components/15-containers/SuperChargedWebView/communication/web2RNCom/WebRNModuleAPIs';
import { getJSONStorage } from 'src/lib/storage';
import { StorageScope } from 'src/lib/storage/types';
import { isWebView } from 'src/utils/platform';
function copyValue(scope, key) {
    const storage = getJSONStorage(scope);
    const jsonValue = storage.get(key);
    if (!jsonValue) {
        WebRNModuleAPIs.Storage.delete(scope, key);
        return;
    }
    WebRNModuleAPIs.Storage.set(scope, key, jsonValue);
}
function syncStore(scope) {
    const storage = getJSONStorage(scope);
    storage.addOnValueChangedListener(changedKey => {
        copyValue(scope, changedKey);
    });
}
export default function initSyncStorage() {
    if (isWebView) {
        syncStore(StorageScope.DEVICE);
        syncStore(StorageScope.USER);
    }
}
