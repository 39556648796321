import { useEffect, useState } from 'react';
export default function useTimer(durationInSeconds) {
    const [state, setState] = useState({
        completed: durationInSeconds === 0,
        progressPercentage: durationInSeconds === 0 ? 100 : 0,
        progressInSeconds: 0,
    });
    useEffect(() => {
        const timer = setInterval(() => {
            setState(prevState => {
                prevState.progressInSeconds++;
                const completed = prevState.progressInSeconds >= durationInSeconds;
                if (completed) {
                    clearInterval(timer);
                }
                return {
                    completed,
                    progressInSeconds: prevState.progressInSeconds,
                    progressPercentage: Math.floor((prevState.progressInSeconds / durationInSeconds) * 100),
                };
            });
        }, 1000);
        return () => clearInterval(timer);
    }, []);
    return [state];
}
