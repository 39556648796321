import DefaultImage from 'quill/formats/image';
import { imageViewHelperActions } from 'src/components/20-helper-views/image-viewer/useImageViewer';
class Image extends DefaultImage {
    static create(value) {
        const node = DefaultImage.create(value);
        node.style.width = '100%';
        node.style.height = 'auto';
        node.style.borderRadius = '12px';
        node.addEventListener('click', () => {
            const imageUrl = node.getAttribute('src');
            if (imageUrl) {
                imageViewHelperActions.show({
                    images: [
                        {
                            uri: imageUrl,
                        },
                    ],
                });
            }
        });
        return node;
    }
}
export default Image;
