import { MMKV } from 'react-native-mmkv';
import { parseJSON } from 'src/utils/json';
export default class MMKVJSONStorage extends MMKV {
    set(key, value) {
        super.set(key, JSON.stringify(value));
    }
    get(key) {
        const value = super.getString(key);
        return typeof value === 'string' ? parseJSON(value) : value;
    }
}
