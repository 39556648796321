import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const USER_DELETE_ACCOUNT_MUTATION = gql `
  mutation User_deleteAccount {
    user_deleteAccount
  }
`;
export function useUserDeleteAccountMutation(options = {}) {
    return useGQLMutation(USER_DELETE_ACCOUNT_MUTATION, options);
}
