import get from 'lodash/get';
export default function sortBy(arr, path, type) {
    return arr.slice().sort((a, b) => {
        const sortOrder = type === 'desc' ? -1 : 1;
        if (get(a, path) > get(b, path)) {
            return sortOrder;
        }
        else if (get(a, path) < get(b, path)) {
            return -sortOrder;
        }
        return 0;
    });
}
