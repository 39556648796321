export const containsOnlyAlpha = (() => {
    const noSpaceRegex = /^\w*$/;
    const withSpaceRegex = /^[\w\s]*$/;
    return (str, includeSpaces = false) => {
        return (includeSpaces ? withSpaceRegex : noSpaceRegex).test(str.trim());
    };
})();
export const containsOnlyNumeric = (() => {
    const regex = /^\d*$/;
    return (str) => {
        return regex.test(str.trim());
    };
})();
