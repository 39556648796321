import React from 'react';
import Animated from 'react-native-reanimated';
import MoreOptionsButton from 'src/components/10-atoms/dropdown-menu/MoreOptionsButton';
import VerticalThreeDotIcon from 'src/components/10-atoms/icons/VerticalThreeDotIcon';
import Row from 'src/components/10-atoms/layout/Row';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import styleUtils from 'src/utils/styles';
import Navbar from './Navbar.template';
import PageNavbarIcon from './PageNavbarIcon.template';
import { getBackIcon } from './utils';
const Actions = ({ actions, maxVisibleActions = 2 }) => {
    const [{ theme }] = useTheme();
    const renderAction = (action) => {
        const Icon = action.Icon;
        return (React.createElement(PageNavbarIcon, { key: action.title, Icon: Icon, onPress: action.onPress, color: theme.palette.icon.primary }));
    };
    const validActions = actions
        ? actions.filter(Boolean)
        : [];
    const shouldRender = Array.isArray(validActions) && validActions.length > 0;
    const moreOptions = validActions.slice(maxVisibleActions - 1);
    const shouldRenderMoreOptions = moreOptions.length > 1;
    const iconOptions = validActions.slice(0, shouldRenderMoreOptions ? maxVisibleActions - 1 : maxVisibleActions);
    return !shouldRender ? null : (React.createElement(Row, { yAxis: "center", xAxis: "flex-end", pr: theme.spacing.standard.xs },
        iconOptions.map(renderAction),
        shouldRenderMoreOptions && (React.createElement(MoreOptionsButton, { renderButton: () => (React.createElement(RowCenter, { style: styleUtils.pressableArea },
                React.createElement(VerticalThreeDotIcon, null))), options: moreOptions }))));
};
const PageNavbar = React.forwardRef(({ title, actions, children, backType = 'none', onBackPress, maxVisibleActions = 1, ...rest }, ref) => {
    const [{ theme }] = useTheme();
    const renderBackIcon = () => {
        let Icon = getBackIcon(backType);
        if (Icon === null) {
            return null;
        }
        return React.createElement(PageNavbarIcon, { Icon: Icon, onPress: onBackPress });
    };
    return (React.createElement(Navbar, { ref: ref, ...rest },
        backType !== 'custom' && renderBackIcon(),
        React.createElement(Row, { flex: true, pl: backType === 'none' ? theme.spacing.page : 0 },
            title && (React.createElement(Typography, { variant: TypographyVariant.PAGE_TITLE, numberOfLines: 1, ellipsizeMode: "tail", flex: true }, title)),
            children),
        React.createElement(Actions, { actions: actions, maxVisibleActions: maxVisibleActions })));
});
export default PageNavbar;
export const AnimatedPageNavbar = Animated.createAnimatedComponent(PageNavbar);
