import once from 'lodash/once';
import { Platform } from 'react-native';
import UAParser from 'ua-parser-js';
import { v4 as uuidV4 } from 'uuid';
import { DeviceType } from './types';
import { isWebView } from 'src/utils/platform';
import { WebRNModuleAPIs } from 'src/components/15-containers/SuperChargedWebView/communication/web2RNCom/WebRNModuleAPIs';
const getDeviceType = (() => {
    const deviceTypeMap = {
        console: DeviceType.CONSOLE,
        mobile: DeviceType.MOBILE,
        tablet: DeviceType.TABLET,
        smarttv: DeviceType.TV,
        wearable: DeviceType.WEARABLE,
        embedded: DeviceType.UNKNOWN,
    };
    return (uaResult) => {
        const deviceType = uaResult.device.type;
        return deviceTypeMap[deviceType] || DeviceType.DESKTOP;
    };
})();
function getBrowserDeviceID() {
    let deviceID = window.localStorage.getItem('deviceID');
    if (!deviceID) {
        window.localStorage.setItem('deviceID', uuidV4());
        return getBrowserDeviceID();
    }
    return deviceID;
}
export async function getDeviceInfo() {
    if (isWebView) {
        return await WebRNModuleAPIs.DeviceInfo.getInfo();
    }
    const uaParser = new UAParser(window.navigator.userAgent);
    const uaResult = uaParser.getResult();
    const deviceID = getBrowserDeviceID();
    const osVersion = `OS:"${uaResult.os.name}_${uaResult.os.version}" BROWSER:"${uaResult.browser.name}_${uaResult.browser.version}"`;
    return {
        deviceID,
        appVersion: 'v.latest',
        osType: Platform.OS,
        osVersion,
        codePushVersion: null,
        deviceType: getDeviceType(uaResult),
    };
}
export const getCachedDeviceInfo = once(getDeviceInfo);
