import { useCallback } from 'react';
import useConfigStorage from './useConfigStorage';
import _get from 'lodash/get';
export default function useConfigService(basePath) {
    const [configState] = useConfigStorage();
    const getValue = useCallback((path, defaultValue) => {
        const absolutePath = [basePath, path].filter(Boolean).join('.');
        const resolvedValue = _get(configState, absolutePath, defaultValue);
        return typeof resolvedValue === typeof defaultValue
            ? resolvedValue
            : defaultValue;
    }, [configState]);
    return { getValue };
}
