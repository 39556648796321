import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import Pressable from 'src/components/10-atoms/pressables/Pressable';
import Typography from 'src/components/10-atoms/typography/Typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import InputOptionsLayout from '../../input-options/InputOptionsLayout';
export default function TagSuggestions({ visible, data, selectedTagIndex, onTagSelected, inputRef, ...inputOptionsLayoutProps }) {
    const [{ theme }] = useTheme();
    return (React.createElement(InputOptionsLayout, { visible: visible, ...inputOptionsLayoutProps, maxHeight: Math.min(data.length * PRESSABLE_SIZE, 3.5 * PRESSABLE_SIZE) },
        React.createElement(Column, { bg: theme.palette.background.darker }, data.map((tag, index) => {
            const selected = index === selectedTagIndex;
            return (React.createElement(Pressable, { onPress: () => {
                    onTagSelected(tag, index);
                } },
                React.createElement(Row, { style: { height: PRESSABLE_SIZE }, ph: theme.spacing.standard.md, yAxis: "center", key: tag, bg: selected
                        ? theme.palette.primary.dark
                        : theme.palette.background.darker },
                    React.createElement(Typography, { flex: true }, tag))));
        }))));
}
