import React from 'react';
import Label from 'src/components/10-atoms/form/Label';
import ReputationBadgeIcon from 'src/components/10-atoms/icons/ReputationBadgeIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { IUserReputationBadgeRank, } from 'src/modules/common/user/types';
import NumberUtils from 'src/utils/number';
import { getBadgeTitle } from '../../graphql/reputation/utils';
import useI18n from 'src/modules/translations/hooks/useI18n';
const getBadgeRank = (() => {
    const badgeRanks = {
        gold: IUserReputationBadgeRank.GOLD,
        silver: IUserReputationBadgeRank.SILVER,
        bronze: IUserReputationBadgeRank.BRONZE,
    };
    return (rank) => badgeRanks[rank];
})();
const ReputationSummary = ({ variant = 'summary', reputation, style, ...rowProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const rankOrder = [
        'gold',
        'silver',
        'bronze',
    ];
    return (React.createElement(Row, { xAxis: "space-between", yAxis: "center", style: [styles.container, style], ...rowProps },
        React.createElement(Row, { spacing: theme.spacing.standard.md, yAxis: "center" }, rankOrder.map(rank => (React.createElement(Column, { key: rank, xAxis: "center" },
            React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.xxxs },
                React.createElement(ReputationBadgeIcon, { type: getBadgeRank(rank) }),
                variant === 'details' && (React.createElement(Typography, { variant: TypographyVariant.CAPTION }, getBadgeTitle(getBadgeRank(rank))))),
            React.createElement(Typography, { variant: TypographyVariant.CAPTION }, NumberUtils.abbreviate(reputation.badgesInfo.summary[rank])))))),
        React.createElement(Column, { xAxis: "flex-end" },
            React.createElement(Label, null, translate('Score')),
            React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM, variant: TypographyVariant.CAPTION }, NumberUtils.abbreviate(reputation.score)))));
};
const stylesGenerator = createThemedStyle(({}) => ({
    container: {},
}));
export default ReputationSummary;
