import { onMessage } from 'firebase/messaging';
import { useEffect } from 'react';
import { firebaseMessaging } from 'src/web/firebase';
import handleMessageReceived from '../../handlers/handleMessageReceived.web';
import useFirebaseInit from 'src/web/firebase/useFirebaseInit';
export default function useForegroundMessageHandler() {
    const [isFbInitialized] = useFirebaseInit();
    useEffect(() => {
        if (isFbInitialized && firebaseMessaging) {
            return onMessage(firebaseMessaging, handleMessageReceived);
        }
    }, [isFbInitialized]);
}
