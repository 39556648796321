export var NotificationChannel;
(function (NotificationChannel) {
    NotificationChannel[NotificationChannel["IN_APP"] = 1] = "IN_APP";
    NotificationChannel[NotificationChannel["PUSH"] = 2] = "PUSH";
})(NotificationChannel || (NotificationChannel = {}));
export var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus[NotificationStatus["ALL"] = -1] = "ALL";
    NotificationStatus[NotificationStatus["UNREAD"] = 1] = "UNREAD";
    NotificationStatus[NotificationStatus["READ"] = 2] = "READ";
    NotificationStatus[NotificationStatus["DELETED"] = 3] = "DELETED";
})(NotificationStatus || (NotificationStatus = {}));
export var NotificationEntityType;
(function (NotificationEntityType) {
    NotificationEntityType[NotificationEntityType["COMMUNITY_QUESTION_CREATED"] = 1] = "COMMUNITY_QUESTION_CREATED";
    NotificationEntityType[NotificationEntityType["COMMUNITY_QUESTION_NEW_ANSWER"] = 2] = "COMMUNITY_QUESTION_NEW_ANSWER";
    NotificationEntityType[NotificationEntityType["COMMUNITY_QUESTION_NEW_REPLY"] = 3] = "COMMUNITY_QUESTION_NEW_REPLY";
    NotificationEntityType[NotificationEntityType["COMMUNITY_ANSWER_NEW_REPLY"] = 4] = "COMMUNITY_ANSWER_NEW_REPLY";
    NotificationEntityType[NotificationEntityType["COMMUNITY_REPUTATION_NEW_BADGE"] = 5] = "COMMUNITY_REPUTATION_NEW_BADGE";
    NotificationEntityType[NotificationEntityType["COMMUNITY_REPUTATION_ADD_SCORE"] = 6] = "COMMUNITY_REPUTATION_ADD_SCORE";
    NotificationEntityType[NotificationEntityType["SERVER_DRIVEN"] = 7] = "SERVER_DRIVEN";
})(NotificationEntityType || (NotificationEntityType = {}));
