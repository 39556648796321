import React from 'react';
import { Platform, Switch as RNSwitch, } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
const Switch = ({ value, onChange, ...switchProps }) => {
    const [{ theme }] = useTheme();
    return (React.createElement(RNSwitch, { trackColor: {
            false: theme.palette.background.dark,
            true: theme.palette.primary.lightest,
        }, thumbColor: Platform.select({
            ios: value ? theme.palette.primary.dark : theme.palette.background.main,
            android: value
                ? theme.palette.primary.dark
                : theme.palette.background.darker,
        }), value: value, onValueChange: onChange, ...switchProps }));
};
export default Switch;
