import Clipboard from '@react-native-clipboard/clipboard';
import React, { useEffect, useRef } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Divider from 'src/components/10-atoms/Divider';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import QueryHandler from 'src/components/10-atoms/QueryHandler';
import Tab from 'src/components/10-atoms/tabs/Tab';
import TabHeader from 'src/components/10-atoms/tabs/TabHeader';
import Tabs from 'src/components/10-atoms/tabs/Tabs';
import Typography from 'src/components/10-atoms/typography/Typography';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { ActivityIndicator } from 'react-native';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import FeatureIntroductionDrawer from 'src/components/templates/FeatureIntroduction';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useFTUE from 'src/lib/ftue/hooks/useFTUE';
import FTUEKeys from 'src/lib/ftue/keys';
import styleUtils from 'src/utils/styles';
import EPBreadCrumbs from '../../components/EPBreadCrumbs';
import { pageAnalytics } from './analytics';
import AnswerItem from './components/AnswerItem';
import AnswerNotFound from './components/AnswerNotFound';
import ExaminersFeedback from './components/examiners-feedback';
import Loader from './components/Loader';
import QuestionQueryHandler from './components/QuestionQueryHandler';
import MarkAsDuplicateDrawer from './containers/MarkAsDuplicateDrawer';
import NavigationBar from './containers/NavigationBar';
import useData from './useData';
import scrollTo from 'src/components/10-atoms/list-views/utils/scrollTo';
import { NodeType } from '../../graphql/common/types/node';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
const Question = ({ navigation, route }) => {
    const { examLevel, subject, question, qp, questionID } = route.params;
    const [{ styles, theme, deviceType }] = useTheme(stylesGenerator);
    const [state, actions] = useData();
    const { translate } = useI18n();
    const handleRetry = useCallbackWithCtx(ctx => {
        ctx.actions.questionDetailsQuery.fetch();
    }, { actions });
    const showAnswerUpdates = useCallbackWithCtx(ctx => {
        ctx.navigation.navigate('EP_AnswerHistory', {
            questionID: ctx.questionID,
        });
    }, { navigation, questionID });
    // Disabling this as this causes the HTMLRenderer(used within AnswerItem)
    // to rerender and that leads to unexpected behaviour (like scrolling back to the top of the page)
    // const [, usageProfileModifiers] = useUsageProfile();
    // useEffectWithCtx(
    //   ({increment}) => {
    //     if (state.questionDetailsQuery.data) {
    //       const timer = setTimeout(() => {
    //         // TODO: I know i should be using `epQuestionsViewed` instead
    //         // of `communityQuestionsViewed`, but using it for now as a
    //         // hack to trigger inAppReview because at this time of writing
    //         // this comment, usage of Community is close to zero and we
    //         // wanted to boost our ratings on App/Play stores.
    //         // In the future, the plan is the introduce OR logic and
    //         // use `epQuestionsViewed` in here and add it to the trigger
    //         // conditions
    //         increment(UsageTrackingParams.communityQuestionsViewed);
    //       }, 3000);
    //       return () => {
    //         clearTimeout(timer);
    //       };
    //     }
    //   },
    //   {increment: usageProfileModifiers.increment},
    //   [state.questionDetailsQuery.data],
    // )
    const handleDuplicatePress = useCallbackWithCtx(ctx => {
        if (!ctx.state.questionDetailsQuery.data) {
            return;
        }
        const duplicateQuestion = ctx.state.questionDetailsQuery.data.ep_questionDetails;
        bottomDrawerHelperActions.show({
            component: (React.createElement(MarkAsDuplicateDrawer, { duplicateQuestion: duplicateQuestion })),
        });
    }, { navigation, state });
    const ancestorsDetails = state.questionDetailsQuery.data?.ep_questionDetails?.ancestorsDetails;
    const subjectDetails = subject ??
        ancestorsDetails?.writtenSubject ??
        ancestorsDetails?.oralsSubject;
    const qpDetails = qp ?? (ancestorsDetails?.writtenQP || ancestorsDetails?.oralsQP);
    const questionDetails = state.questionDetailsQuery.data?.ep_questionDetails || question;
    const breadCrumbsLoading = !subject && state.questionDetailsQuery.loading;
    const [answerContributionFTUE, { markComplete: markAnswerContributionFTUEComplete },] = useFTUE(FTUEKeys.EP_ANSWER_CONTRIBUTION_INTRODUCTION);
    useEffect(() => {
        if (answerContributionFTUE) {
            bottomDrawerHelperActions.show({
                component: (React.createElement(FeatureIntroductionDrawer, { title: translate('Showcase your knowledge'), body: React.createElement(Column, null,
                        React.createElement(ULItem, null, "Write an Answer, if missing"),
                        React.createElement(ULItem, null, "Edit an Answer, if it can be improved"),
                        React.createElement(ULItem, null, "You'll be recognized for your Answers"),
                        React.createElement(ULItem, null, "Answers you write, will stay here forever")), dismissTitle: translate('Will do it 👍🏼'), onDismiss: () => {
                        markAnswerContributionFTUEComplete();
                        bottomDrawerHelperActions.hide();
                    } })),
            });
        }
    }, []);
    const scrollViewRef = useRef(null);
    useEffect(() => {
        if (questionID) {
            setTimeout(() => {
                scrollTo(scrollViewRef, {
                    animated: true,
                    y: 0,
                });
            }, 300);
        }
    }, [questionID]);
    return (React.createElement(Page, null,
        React.createElement(Column, { flex: true },
            React.createElement(CustomScrollView, { ref: scrollViewRef, forceCollapse: deviceType.isPhone, containerStyle: styleUtils.flex, renderCollapsibleHeader: () => (React.createElement(Column, { bbw: 1 },
                    React.createElement(PageNavbar, { backType: "back", title: translate('Question'), onBackPress: navigation.goBack, actions: [
                            state.markAsDuplicateVisible && {
                                title: 'Mark As Duplicate',
                                Icon: props => React.createElement(FeatherIcon, { name: "copy", ...props }),
                                onPress: handleDuplicatePress,
                            },
                            questionDetails?.type === NodeType.WRITTEN_QUESTION && {
                                title: 'Play',
                                Icon(props) {
                                    return state.playlistLoading ? (React.createElement(ActivityIndicator, { size: "small" })) : (React.createElement(FeatherIcon, { name: "play-circle", ...props }));
                                },
                                onPress() {
                                    pageAnalytics
                                        .setWidget('Navbar')
                                        .setTarget('TTS_Play')
                                        .click();
                                    actions.handlePlaylistPress();
                                },
                            },
                        ], maxVisibleActions: 3 }),
                    React.createElement(EPBreadCrumbs, { loading: breadCrumbsLoading, ancestorsDetails: ancestorsDetails, knownNodes: {
                            examLevel,
                            subject: subjectDetails,
                            qp: qpDetails,
                            question: questionDetails,
                        }, visibleCount: 3 }))), collapsibleHeaderHeight: NAVBAR_HEIGHT, renderCollapsibleFooter: () => React.createElement(NavigationBar, null), collapsibleFooterHeight: 62, contentContainerStyle: {
                    paddingBottom: NAVBAR_HEIGHT + 28 /* Breadcrumbs height */,
                }, onRefresh: handleRetry, refreshing: !!(state.questionDetailsQuery.loading &&
                    state.questionDetailsQuery.data), horizontal: false },
                __DEV__ && (React.createElement(Row, { xAxis: "space-between", yAxis: "center", style: styles.devHeader, spacing: 12 },
                    React.createElement(Typography, { fontWeight: TypographyFontWeight.BOLD, selectable: true, color: theme.palette.typography.dark },
                        "Question ID: ",
                        questionID),
                    React.createElement(Button, { variant: "text", noIconSpacing: true, textContainerStyle: styles.copyBtn, onPress: () => {
                            Clipboard.setString(questionID);
                            snackbarHelperActions.show({
                                message: 'Copied Question ID',
                                variant: 'NEUTRAL',
                                duration: 'SHORT',
                            });
                        } }, "Copy"))),
                React.createElement(QuestionQueryHandler, { question: question, queryState: state.questionDetailsQuery, onRetry: handleRetry, onEditPress: actions.handleQuestionEditPress }),
                React.createElement(Divider, { mb: theme.spacing.standard.xs }),
                React.createElement(QueryHandler, { queryState: state.questionDetailsQuery, LoaderComponent: Loader, ErrorComponent: React.createElement(GenericServerError, { error: state.questionDetailsQuery.error, onRetry: handleRetry }), DataComponent: React.createElement(Column, null,
                        state.tabs.tabs.length > 1 && (React.createElement(TabHeader, { ph: theme.spacing.page, activeIndex: state.tabs.activeIndex, tabs: state.tabs.tabs, onChange: (index) => {
                                pageAnalytics
                                    .setWidget('TabHeader')
                                    .setTarget(state.tabs.tabs[index].id)
                                    .click();
                                actions.tabs.setActiveIndex(index);
                            } })),
                        React.createElement(Tabs, { activeTab: state.tabs.tabs[state.tabs.activeIndex]?.id },
                            React.createElement(Tab, { id: "ANSWER" },
                                React.createElement(Column, { pv: theme.spacing.standard.sm }, questionDetails?.answer ? (React.createElement(AnswerItem, { question: questionDetails, updateAnswerLoading: state.updateAnswerMutation.loading, onEdit: actions.handleUpdateAnswer, onShowUpdates: showAnswerUpdates })) : (React.createElement(AnswerNotFound, { createAnswerLoading: state.createAnswerMutation.loading, onCreateAnswer: actions.handleCreateAnswer })))),
                            React.createElement(Tab, { id: "EXAMINERS_FEEDBACK" },
                                React.createElement(ExaminersFeedback, { question: questionDetails })))) })))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {},
    breadCrumbs: {
        paddingVertical: theme.spacing.standard.xxs,
    },
    devHeader: {
        paddingHorizontal: theme.spacing.page,
        backgroundColor: 'pink',
    },
    copyBtn: {
        padding: 0,
    },
}));
export default Question;
