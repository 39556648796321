import React, { useEffect } from 'react';
import { Extrapolation, interpolate, useAnimatedStyle, useSharedValue, withTiming, } from 'react-native-reanimated';
import AudioIcon from 'src/components/10-atoms/icons/AudioIcon';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Row, { AnimatedRow } from 'src/components/10-atoms/layout/Row';
import Pressable from 'src/components/10-atoms/pressables/Pressable';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
import { PlayerState } from '../../hooks/players/types';
import { PlaylistControllerMode } from '../../types';
import { LinkBtn, NextBtn, PauseBtn, PrevBtn, ResumeBtn, StopBtn, } from './buttons';
import { AnimatedColumn } from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import Marquee from 'src/components/10-atoms/Marquee';
import { analytics } from '../../analytics';
const getAnimationValue = (() => {
    const animVal = {
        [PlaylistControllerMode.HIDDEN]: -1,
        [PlaylistControllerMode.MINIMIZED]: 0,
        [PlaylistControllerMode.NORMAL]: 1,
        [PlaylistControllerMode.MAXIMIZED]: 1,
    };
    return (mode) => animVal[mode];
})();
const getControlsTotalWidth = (numOfControls) => {
    return 56 * numOfControls;
};
const controllerAnalytics = analytics.setWidget('playlist-controller');
const PlaylistController = ({ state, mode, title = 'No Title', onPause, onResume, onStop, nextDisabled, onNext, prevDisabled, onPrev, onModeChange, openDisabled, onOpen, style, ...rowProps }) => {
    const [{ theme, styles }] = useTheme(stylesGenerator);
    const animation = useSharedValue(getAnimationValue(mode));
    const toggleControllerStyle = useAnimatedStyle(() => ({
        transform: [
            {
                rotateZ: interpolate(animation.value, [0, 1], [0, 180], Extrapolation.CLAMP) + 'deg',
            },
        ],
    }), [animation]);
    const controlsContainerStyle = useAnimatedStyle(() => ({
        opacity: interpolate(animation.value, [0.5, 1], [0, 1], Extrapolation.CLAMP),
    }), [animation]);
    const stopVisible = state !== PlayerState.IDLE;
    let numOfControls = 3;
    if (stopVisible) {
        numOfControls++;
    }
    if (!openDisabled) {
        numOfControls++;
    }
    const controllerWidth = getControlsTotalWidth(numOfControls);
    const containerPosStyle = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: interpolate(animation.value, [-1, 0, 1], [
                    controllerWidth + SIZE + 15,
                    controllerWidth + theme.spacing.standard.md,
                    0,
                ], Extrapolation.CLAMP),
            },
        ],
    }), [animation, controllerWidth]);
    useEffect(() => {
        animation.value = withTiming(getAnimationValue(mode), { duration: 700 });
    }, [mode, animation]);
    const showAudioIcon = mode === PlaylistControllerMode.MINIMIZED && state === PlayerState.PLAYING;
    return (React.createElement(AnimatedRow, { bg: theme.palette.background.darker, xAxis: "flex-start", yAxis: "center", style: [styles.container, containerPosStyle, style], ...rowProps },
        React.createElement(Pressable, { onPress: () => {
                const newMode = mode === PlaylistControllerMode.MINIMIZED
                    ? PlaylistControllerMode.NORMAL
                    : PlaylistControllerMode.MINIMIZED;
                controllerAnalytics
                    .setTarget('Mode_' +
                    (newMode === PlaylistControllerMode.NORMAL
                        ? 'MAXIMIZE'
                        : 'MINIMIZE'))
                    .click();
                onModeChange(newMode);
            } }, showAudioIcon ? (React.createElement(Row, { xAxis: "center", yAxis: "center", style: [styles.modeController] },
            React.createElement(AudioIcon, { color: 'orange' }))) : (React.createElement(AnimatedRow, { xAxis: "center", yAxis: "center", style: [styles.modeController, toggleControllerStyle] },
            React.createElement(FeatherIcon, { name: 'chevron-left' })))),
        React.createElement(AnimatedColumn, { yAxis: "center", xAxis: "flex-start", style: controlsContainerStyle },
            React.createElement(Marquee, { style: { maxWidth: controllerWidth } },
                React.createElement(Typography, { variant: TypographyVariant.CAPTION }, title)),
            React.createElement(Row, { xAxis: "space-between" },
                React.createElement(PrevBtn, { disabled: prevDisabled, onPress: () => {
                        controllerAnalytics.setTarget('Previous').click();
                        onPrev();
                    } }),
                stopVisible && (React.createElement(StopBtn, { onPress: () => {
                        controllerAnalytics.setTarget('Stop').click();
                        onStop();
                    } })),
                state !== PlayerState.PAUSED ? (React.createElement(PauseBtn, { onPress: () => {
                        controllerAnalytics.setTarget('Pause').click();
                        onPause();
                    } })) : (React.createElement(ResumeBtn, { onPress: () => {
                        controllerAnalytics.setTarget('Resume').click();
                        onResume();
                    } })),
                React.createElement(NextBtn, { disabled: nextDisabled, onPress: () => {
                        controllerAnalytics.setTarget('Next').click();
                        onNext();
                    } }),
                !openDisabled && (React.createElement(LinkBtn, { disabled: openDisabled, onPress: () => {
                        controllerAnalytics.setTarget('Open_Playing').click();
                        onOpen();
                    } }))))));
};
/**
 * PRESSABLE_SIZE: For buttons
 * 12: Padding
 * 20: Title (Caption) line-height
 */
const SIZE = PRESSABLE_SIZE + 8 + 20;
const stylesGenerator = createThemedStyle(({ theme, dimensions: { safeAreaInsets } }) => ({
    container: {
        position: 'absolute',
        bottom: safeAreaInsets.bottom + PRESSABLE_SIZE + theme.spacing.standard.sm * 2,
        right: 0,
        paddingRight: theme.spacing.standard.xs,
        borderTopLeftRadius: SIZE,
        borderBottomLeftRadius: SIZE,
        height: SIZE,
        ...styleUtils.cardShadow,
    },
    modeController: {
        height: SIZE,
        width: SIZE,
        borderRadius: SIZE,
    },
}));
export default PlaylistController;
