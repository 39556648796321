import React from 'react';
import EmptyList from 'src/components/templates/EmptyList.template';
import useI18n from 'src/modules/translations/hooks/useI18n';
const QuestionListEmpty = ({ variant }) => {
    const { translate } = useI18n();
    const message = (() => {
        switch (variant) {
            case 'question-search':
                return translate('No matching questions found');
            default:
                return translate('There are no Questions at the moment.\nPlease check again later on.');
        }
    })();
    return (React.createElement(EmptyList, { message: message, image: {
            type: 'lottie',
            source: require('src/assets/lottie-files/notFound.json'),
        } }));
};
export default React.memo(QuestionListEmpty);
