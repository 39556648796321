import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import FitImage from 'src/components/10-atoms/image/FitImage';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import AppAssets from 'src/constants/appAssets';
import useI18n from 'src/modules/translations/hooks/useI18n';
const ReportAbuseSuccessDrawer = ({ onConfirm, }) => {
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Report Abuse')),
        React.createElement(BottomDrawerLayout.Body, { xAxis: "center" },
            React.createElement(Typography, null, translate('Submitted Successfully!')),
            React.createElement(FitImage, { source: { uri: AppAssets.abuseInspect } }),
            React.createElement(Typography, { align: "center" }, translate("Before we take an action, we'll notify the Author to fix the content and in case we don't receive any response, then we'll take necessary action."))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { onPress: onConfirm, flex: true }, translate('Okay')))));
};
export default ReportAbuseSuccessDrawer;
