import { gql } from '@apollo/client';
import { graphqlClient } from 'src/lib/graphql';
const UPDATE_DEVICE_INFO_MUTATION = gql `
  mutation UpdateUserDeviceInfo($deviceData: UserDeviceData!) {
    updateDeviceDetails(deviceData: $deviceData) {
      success
    }
  }
`;
export function updateDeviceInfo(deviceData) {
    return graphqlClient.mutate({
        mutation: UPDATE_DEVICE_INFO_MUTATION,
        variables: {
            deviceData,
        },
    });
}
