import { clamp } from 'src/utils/math';
import { ttsPreferencesStorage } from '../storage';
export default function addSSMLBaseWrapper(ssml, options) {
    const rate = clamp(options?.rate ?? ttsPreferencesStorage.getRate(), 0.5, 2);
    return `
    <speak>
      <prosody rate="${Math.floor(rate * 100)}%">
        ${ssml}
      </prosody>
    </speak>
  `;
}
