import React from 'react';
import useFTUE from 'src/lib/ftue/hooks/useFTUE';
import FTUEKeys from 'src/lib/ftue/keys';
import PlaylistIntroDrawer from '../components/PlaylistIntroDrawer';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
export default function usePlaylistIntro(options) {
    const [isFTUE, ftueActions] = useFTUE(FTUEKeys.PLAYLIST_INTRODUCTION);
    useEffectWithCtx(ctx => {
        if (isFTUE) {
            bottomDrawerHelperActions.show({
                component: (React.createElement(PlaylistIntroDrawer, { onCancel: () => {
                        bottomDrawerHelperActions.hide();
                        ftueActions.markComplete();
                        ctx.options.onCancel();
                    }, onGetStarted: () => {
                        bottomDrawerHelperActions.hide();
                        ftueActions.markComplete();
                        ctx.options.onGetStarted();
                    } })),
            });
        }
    }, { options }, []);
}
