import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import Switch from 'src/components/10-atoms/form/inputs/SwitchInput';
const OnlySelectedTopicsSwitch = ({ selectedCount, value, onChange, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { yAxis: "center", xAxis: "space-between", ph: theme.spacing.page, pv: theme.spacing.standard.xs, ...rowProps },
        React.createElement(Typography, null, `Show only selected Topics (${selectedCount})`),
        React.createElement(Switch, { value: value, onChange: onChange })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default OnlySelectedTopicsSwitch;
