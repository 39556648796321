import React from 'react';
import { Path } from 'react-native-svg';
import withPress from 'src/hoc/withPress';
import SVGIcon from './SVGIcon';
import withDefaultIconProps from './withDefaultIconProps';
const GoogleIcon = ({ color, ...rest }) => {
    return (React.createElement(SVGIcon, { ...rest },
        React.createElement(Path, { d: "M12 10.527h9.71c.107.598.16 1.17.16 1.714 0 1.938-.406 3.668-1.218 5.19A8.799 8.799 0 0 1 17.176 21c-1.504.857-3.23 1.286-5.176 1.286-1.402 0-2.737-.27-4.004-.81a10.285 10.285 0 0 1-3.282-2.19c-.92-.92-1.65-2.014-2.19-3.282A10.111 10.111 0 0 1 1.715 12c0-1.402.27-2.737.81-4.004a10.286 10.286 0 0 1 2.19-3.282c.92-.92 2.014-1.65 3.282-2.19 1.267-.54 2.602-.81 4.004-.81 2.679 0 4.978.898 6.897 2.692l-2.799 2.692C15 6.036 13.634 5.504 12 5.504c-1.152 0-2.217.29-3.194.871a6.415 6.415 0 0 0-2.324 2.364A6.437 6.437 0 0 0 5.625 12c0 1.179.286 2.266.857 3.261a6.415 6.415 0 0 0 2.324 2.364c.977.58 2.042.87 3.194.87.777 0 1.491-.107 2.143-.32.652-.215 1.187-.483 1.607-.805.42-.32.786-.687 1.098-1.098.313-.41.543-.799.69-1.165.147-.366.248-.714.301-1.044H12v-3.536Z", fill: color })));
};
export default withPress(withDefaultIconProps(GoogleIcon));
