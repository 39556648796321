import { getPathFromState } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { navigationRef } from 'src/navigation/NavigationWrapper';
import linking from 'src/navigation/linking';
/**
 * This hooks is meant to be cross platform, hence
 * DO NOT use window.location.href as it would not work for native apps.
 *
 * @returns URL of the currently viewing page
 */
export default function useCurrPagePath() {
    const [path, setPath] = useState('');
    useEffect(() => {
        navigationRef.addListener('state', (event) => {
            if (event.data.state) {
                setPath(getPathFromState(event.data.state, linking.config));
            }
        });
    }, []);
    return [path];
}
