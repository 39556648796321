import { useRef, useEffect } from 'react';
/**
 * This hooks helps to capture effects only in specified
 * effect count range
 */
export default function useEffectRange([startCount, endCount = Infinity], cb, deps) {
    const effectTriggerCount = useRef(0);
    useEffect(() => {
        if (effectTriggerCount.current >= startCount &&
            effectTriggerCount.current < endCount) {
            cb();
        }
        effectTriggerCount.current += 1;
    }, deps);
}
