import { renderNamedSlot } from 'src/utils/component';
function APIHandler({ requestState, LoaderComponent, renderData, renderError, }) {
    if (requestState.loading) {
        return renderNamedSlot(LoaderComponent);
    }
    else if (requestState.error) {
        return renderError(requestState);
    }
    else if (requestState.data) {
        return renderData(requestState);
    }
    return null;
}
export default APIHandler;
