import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Column from 'src/components/10-atoms/layout/Column';
import AvatarLoader from 'src/components/10-atoms/avatar/Loader';
import LoaderBase from 'src/components/10-atoms/loaders/LoaderBase';
const ProfileLoader = ({ ...columnProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { xAxis: "center", spacing: theme.spacing.standard.sm, pv: theme.spacing.standard.md, ...columnProps },
        React.createElement(AvatarLoader, { size: "jumbo" }),
        new Array(3).fill(0).map((_, i) => (React.createElement(LoaderBase, { key: i, style: styles.infoLoader })))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    infoLoader: {
        height: 32,
        marginHorizontal: theme.spacing.page,
        borderRadius: theme.borderRadius.standard.md,
        alignSelf: 'stretch',
    },
}));
export default ProfileLoader;
