import { useGetBlockedUserListQuery } from 'src/modules/ugc-policy/graphql/getBlockedUserList';
const listQueryVariables = {};
export default function useData() {
    const [listQueryState, listQueryActions] = useGetBlockedUserListQuery();
    const state = {
        listQuery: listQueryState,
        listQueryVariables,
    };
    const actions = {
        listQuery: listQueryActions,
    };
    return [state, actions];
}
