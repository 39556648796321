function isDeltaEmptyOrWhitespace(delta) {
    if (!delta.ops.length) {
        return true;
    }
    for (let i = 0; i < delta.ops.length; i++) {
        const insert = delta.ops[i].insert;
        if (typeof insert !== 'string' || !['', '\n'].includes(insert.trim())) {
            return false;
        }
    }
    return true;
}
export default function isEmpty(quill) {
    const contents = quill.getContents();
    return isDeltaEmptyOrWhitespace(contents);
}
