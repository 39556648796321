import React from 'react';
import { bottomDrawerHelperActions } from '../bottom-drawer/useBottomDrawer';
import PromptDrawer from './index';
export const promptHelperActions = {
    show(props) {
        return new Promise(resolve => {
            const handleSubmit = (value) => {
                // Resolve first before hiding the drawer
                // else, onClose will be invoked first
                resolve(value);
                bottomDrawerHelperActions.hide();
            };
            bottomDrawerHelperActions.show({
                component: React.createElement(PromptDrawer, { ...props, onSubmit: handleSubmit }),
                onClose() {
                    resolve(null);
                },
            });
        });
    },
    hide: bottomDrawerHelperActions.hide,
};
