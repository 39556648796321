import React from 'react';
import EmptyList from 'src/components/templates/EmptyList.template';
import useI18n from 'src/modules/translations/hooks/useI18n';
const QuestionPaperListEmpty = ({}) => {
    const { translate } = useI18n();
    return (React.createElement(EmptyList, { message: translate('There are no Question Papers for this Subject at the moment. Please check again later on.'), image: {
            type: 'lottie',
            source: require('src/assets/lottie-files/notFound.json'),
        } }));
};
export default React.memo(QuestionPaperListEmpty);
