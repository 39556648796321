import LottieView from 'lottie-react-native';
import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
const QuestionSearchLoader = ({}) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { xAxis: "center", spacing: 12, style: styles.container },
        React.createElement(LottieView, { source: require('src/assets/lottie-files/searching.json'), style: styleUtils.lottie, autoPlay: true, loop: true })));
};
const stylesGenerator = createThemedStyle(() => ({
    container: {
        marginTop: 56,
    },
}));
export default React.memo(QuestionSearchLoader);
