import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import ReputationBadgeIcon from 'src/components/10-atoms/icons/ReputationBadgeIcon';
import Typography from 'src/components/10-atoms/typography/Typography';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import NumberUtils from 'src/utils/number';
const ReputationBadgeLayout = ({ rank, title, description, count, style, ...columnProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { style: [styles.container, style], ...columnProps },
        React.createElement(Row, { yAxis: "center", xAxis: "space-between" },
            React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.xxs },
                React.createElement(ReputationBadgeIcon, { type: rank }),
                React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM }, title)),
            count > 1 && (React.createElement(RowCenter, { style: styles.countBadge },
                React.createElement(Typography, { variant: TypographyVariant.BADGE }, NumberUtils.abbreviate(count))))),
        React.createElement(Typography, { color: theme.palette.typography.secondary, variant: TypographyVariant.CAPTION }, description)));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingHorizontal: theme.spacing.page,
        paddingVertical: theme.spacing.itemVerticalPadding,
    },
    countBadge: {
        paddingHorizontal: theme.spacing.standard.xs,
        borderRadius: theme.borderRadius.standard.lg,
        backgroundColor: theme.palette.background.darker,
    },
}));
export default ReputationBadgeLayout;
