import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import Button from 'src/components/10-atoms/button/Button';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Typography from 'src/components/10-atoms/typography/Typography';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useI18n from 'src/modules/translations/hooks/useI18n';
const BlockContentDrawer = ({ onConfirm }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Hide Content')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(Typography, { fontWeight: TypographyFontWeight.BOLD, style: { marginBottom: theme.spacing.standard.xxs } }, translate('What happens if you hide content?')),
            React.createElement(ULItem, null, translate('Author will not know about this')),
            React.createElement(ULItem, null, translate("You'll not be able to see this content anymore")),
            React.createElement(ULItem, null, translate("You'll not be notified for changes in this content"))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onConfirm }, translate('Confirm')))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default BlockContentDrawer;
