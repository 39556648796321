import { useState } from 'react';
import getFieldActions from './useFieldState/actions';
const getDefaultMeta = () => ({
    disabled: false,
    hidden: false,
    touched: false,
    dirty: false,
    focused: false,
});
export default function useFormState(initialState, options = {}) {
    const { customFieldActions, fieldValidations } = options;
    const [state, setState] = useState({
        fields: initialState.fields,
        meta: initialState.meta ?? getDefaultMeta(),
        errors: initialState.errors ?? [],
    });
    const setFieldState = (field) => newFieldState => {
        setState(prevState => {
            const currFieldState = prevState.fields[field];
            const resolvedFieldState = typeof newFieldState === 'function'
                ? newFieldState(currFieldState)
                : newFieldState;
            const fieldValid = isFieldValid(field, resolvedFieldState, prevState);
            if (typeof fieldValid === 'string') {
                resolvedFieldState.errors = [{ message: fieldValid, type: 'error' }];
            }
            else {
                resolvedFieldState.errors = [];
            }
            return {
                ...prevState,
                meta: {
                    ...prevState.meta,
                    touched: true,
                    dirty: true,
                },
                fields: {
                    ...prevState.fields,
                    [field]: resolvedFieldState,
                },
            };
        });
    };
    const setFieldValue = (field, value) => {
        setFieldState(field)(prevState => {
            const newValue = typeof value === 'function'
                ? value(prevState.value)
                : value;
            return {
                ...prevState,
                value: newValue,
            };
        });
    };
    const isFieldValid = (field, fieldState = state.fields[field], formState = state) => {
        const fieldValidation = fieldValidations && fieldValidations[field];
        const fieldValid = fieldValidation
            ? fieldValidation(fieldState, formState)
            : true;
        return fieldValid;
    };
    const validateField = (field) => {
        const fieldValid = isFieldValid(field);
        if (typeof fieldValid === 'string') {
            actions.fields[field].setError({
                type: 'error',
                message: fieldValid,
            });
        }
        return fieldValid;
    };
    const validateForm = () => {
        let isValid = true;
        Object.keys(fieldValidations ?? {}).forEach((field) => {
            const fieldValid = validateField(field);
            if (typeof fieldValid === 'string') {
                isValid = false;
            }
        });
        return isValid;
    };
    const fieldsActions = Object.keys(state.fields).reduce((acc, field) => {
        const customActions = customFieldActions
            ? customFieldActions[field]
            : undefined;
        acc[field] = getFieldActions(setFieldState(field), {
            customFieldActions: customActions,
        });
        return acc;
    }, {});
    const actions = {
        setState,
        setFieldState,
        setFieldValue,
        fields: fieldsActions,
        validateForm,
        validateField,
        isFieldValid,
    };
    const helpers = {
        hasErrors() {
            return (state.errors.length > 0 ||
                Object.values(state.fields).some((fieldState) => fieldState.errors.length > 0));
        },
    };
    return [state, actions, helpers];
}
// function useTestFormState() {
//   const [formState, formActions] = useFormState(
//     {
//       fields: {
//         name: getFieldState(''),
//         age: getFieldState(21),
//       },
//     },
//     {
//       customFieldActions: {
//         name: {
//           setLastName(name: string) {
//             return name.split(' ')[1];
//           },
//         },
//       },
//     },
//   );
//   formState.fields.name.value;
//   formActions.fields.name.setValue('asdf');
//   formActions.fields.name.setLastName('asdf');
//   formActions.fields.age.setValue(21);
// }
/**
 * formActions.fields.title.setValue
 */
