import { NOTIFICATION_ANDROID_CHANNELS, NOTIFICATION_PRESS_ID, } from '../constants';
import AppAssets from 'src/constants/appAssets';
export function getNotificationDeeplink(notification) {
    return notification.data?.deeplink;
}
export const getNotificationID = (notification) => {
    const notificationID = notification?.data?.id;
    return typeof notificationID === 'string' ? notificationID : undefined;
};
const doesChannelExist = (channelId) => {
    if (!channelId) {
        return false;
    }
    return (NOTIFICATION_ANDROID_CHANNELS.findIndex(channel => channel.id === channelId) > -1);
};
export function convertFCMToNotifee(remoteMessage) {
    const { notification } = remoteMessage;
    if (notification) {
        const notifeeDisplay = {
            title: notification.title,
            body: notification.body,
            id: remoteMessage.messageId,
            android: {
                channelId: doesChannelExist(notification.android?.channelId)
                    ? notification.android?.channelId ?? 'default'
                    : 'default',
                pressAction: {
                    id: notification.android?.clickAction ?? NOTIFICATION_PRESS_ID.DEFAULT,
                },
            },
            ios: {},
        };
        if (remoteMessage.threadId) {
            notifeeDisplay.android.groupId = remoteMessage.threadId;
            notifeeDisplay.ios.threadId = remoteMessage.threadId;
        }
        if (notification.android?.smallIcon) {
            notifeeDisplay.android.smallIcon = notification.android.smallIcon;
        }
        if (notification.ios?.subtitle) {
            notifeeDisplay.subtitle = notification.ios.subtitle;
        }
        if (remoteMessage.data) {
            notifeeDisplay.data = remoteMessage.data;
        }
        if (remoteMessage.category) {
            notifeeDisplay.ios.categoryId = remoteMessage.category;
        }
        return notifeeDisplay;
    }
}
export function getWebNotificationData(payload) {
    if (payload.data?.notifee) {
        const notificationData = JSON.parse(payload.data.notifee);
        const title = notificationData.title;
        const options = {
            body: notificationData.body,
            icon: AppAssets.logo_512_512,
            data: payload,
        };
        return {
            title,
            options,
        };
    }
}
