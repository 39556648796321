import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
const ListLoader = ({}) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { xAxis: "stretch", spacing: 8, style: [styleUtils.pagePadding, styles.container] }, new Array(3).fill(0).map((_, i) => (React.createElement(Row, { key: i, style: styles.item })))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingTop: theme.spacing.page,
    },
    item: {
        backgroundColor: theme.palette.loader.placeholder,
        height: PRESSABLE_SIZE,
        borderRadius: theme.spacing.standard.md,
    },
}));
export default React.memo(ListLoader);
