import React from 'react';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import PressableArea from 'src/components/templates/PressableArea';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { renderNamedSlot } from 'src/utils/component';
const ToolbarItem = ({ icon, selected, disabled = false, ...rest }) => {
    const [{ theme, styles }] = useTheme(stylesGenerator);
    return (React.createElement(PressableArea, { disabled: disabled, ...rest },
        React.createElement(RowCenter, { style: [
                styles.iconContainer,
                disabled && styles.disabled,
                selected && { backgroundColor: theme.palette.primary.lightest },
            ] }, renderNamedSlot(icon, {
            color: disabled
                ? theme.palette.pressable.disabledText
                : selected
                    ? theme.palette.primary.dark
                    : theme.palette.typography.primary,
        }))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    iconContainer: {
        width: PRESSABLE_SIZE,
        height: PRESSABLE_SIZE,
        borderRadius: PRESSABLE_SIZE,
    },
    disabled: {
        backgroundColor: theme.palette.pressable.disabled,
    },
}));
export default ToolbarItem;
