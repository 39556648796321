import { DLPrefix } from './constants';
import { pick } from 'lodash';
export const addDLPrefix = (path) => `${DLPrefix}${path}`;
export const removeDLPrefix = (deeplink) => {
    if (deeplink.startsWith(DLPrefix)) {
        return deeplink.split(DLPrefix)[1];
    }
    return deeplink;
};
const stringifyNode = (node) => JSON.stringify(pick(node, ['id', 'data', 'type']));
function isNode(data) {
    return !!data?.id;
}
const stringify = (data) => isNode(data) ? stringifyNode(data) : JSON.stringify(data);
export const objectParamsConfig = (paramNames) => {
    return {
        stringify: paramNames.reduce((result, param) => {
            result[param] = stringify;
            return result;
        }, {}),
        parse: paramNames.reduce((result, param) => {
            result[param] = JSON.parse;
            return result;
        }, {}),
    };
};
