import { getJSONStorage } from 'src/lib/storage';
import StorageKeys from 'src/lib/storage/storageKeys';
const storage = getJSONStorage(StorageKeys.deviceInfo.scope);
const deviceInfoStorage = {
    setDeviceInfo(deviceInfo) {
        storage.set(StorageKeys.deviceInfo.key, deviceInfo);
    },
    getDeviceInfo() {
        return storage.get(StorageKeys.deviceInfo.key);
    },
};
export default deviceInfoStorage;
