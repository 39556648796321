import useFilePicker from 'src/components/10-atoms/file-picker/hooks/useFilePicker';
import { FileType } from 'src/components/10-atoms/file-picker/types';
import { getFieldState } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import useFormState from 'src/components/10-atoms/form/hooks/useFormState';
import useFileUploader from 'src/lib/file-storage/hooks/useFileUploader';
import appEvents from 'src/modules/10-app/events';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { isExternalLink } from 'src/utils/url';
import { containsOnlyAlpha } from 'src/utils/validate';
import { useUserDeleteAccountMutation } from '../../graphql/profile/delete';
import { useUserDetailsQuery } from '../../graphql/profile/getDetails';
import { useEffect } from 'react';
import useIsRegisteredUser from 'src/modules/authentication/hooks/useIsRegisteredUser';
import { useNavigation } from '@react-navigation/native';
export default function useData(user) {
    const { translate } = useI18n();
    const navigation = useNavigation();
    const [userDetailsQueryState, userDetailsQueryActions] = useUserDetailsQuery({
        lazy: true,
        onCompleted(data) {
            const userDetails = data.user_getDetails.details;
            formActions.fields.avatarURL.setValue(userDetails.avatarURL);
            formActions.fields.firstName.setValue(userDetails.firstName);
            formActions.fields.lastName.setValue(userDetails.lastName);
            formActions.fields.userName.setValue(userDetails.userName);
        },
    });
    const isRegistered = useIsRegisteredUser();
    useEffect(() => {
        if (!user) {
            if (isRegistered) {
                userDetailsQueryActions.fetch();
            }
            else {
                navigation.navigate('User_Home', {});
            }
        }
    }, [user]);
    const userDetails = (user || userDetailsQueryState.data?.user_getDetails)
        ?.details;
    const [formState, formActions] = useFormState({
        fields: {
            avatarURL: getFieldState(userDetails?.avatarURL || ''),
            firstName: getFieldState(userDetails?.firstName || ''),
            lastName: getFieldState(userDetails?.lastName || ''),
            userName: getFieldState(userDetails?.userName || ''),
        },
    }, {
        fieldValidations: {
            firstName(state) {
                if (state.value.trim().length === 0) {
                    return translate('Please enter a valid First Name');
                }
                if (!containsOnlyAlpha(state.value.trim(), true)) {
                    return translate('First Name can contain only Alphabets');
                }
                return true;
            },
            lastName(state) {
                if (state.value.trim().length === 0) {
                    return translate('Please enter a valid Last Name');
                }
                if (!containsOnlyAlpha(state.value.trim(), true)) {
                    return translate('Last Name can contain only Alphabets');
                }
                return true;
            },
        },
    });
    const filePicker = useFilePicker();
    const [fileUploaderState, fileUploadActions] = useFileUploader({
        events: {
            onComplete(data) {
                const publicURL = data.publicURL;
                formActions.fields.avatarURL.setValue(publicURL);
            },
        },
    });
    const [deleteMutationState, deleteMutationActions] = useUserDeleteAccountMutation({
        onCompleted() {
            appEvents.onLogout();
        },
    });
    const handleAvatarChange = async () => {
        const files = await filePicker.pick({
            allowedTypes: FileType.IMAGES,
            allowMultiple: false,
            // resizeImage: {
            //   maxHeight: 128,
            //   maxWidth: 128,
            // },
        });
        if (files) {
            const [pickedFile] = files;
            if (pickedFile) {
                if (isExternalLink(pickedFile.uri)) {
                    formActions.fields.avatarURL.setValue(pickedFile.uri);
                }
                else {
                    fileUploadActions.upload(pickedFile);
                }
            }
        }
    };
    const state = {
        form: formState,
        fileUploader: fileUploaderState,
        deleteMutation: deleteMutationState,
        userDetailsQuery: userDetailsQueryState,
    };
    const actions = {
        form: formActions,
        changeAvatar: handleAvatarChange,
        deleteMutation: deleteMutationActions,
    };
    return [state, actions];
}
