import React, { useState } from 'react';
import Animated, { useAnimatedScrollHandler, useDerivedValue, useSharedValue, } from 'react-native-reanimated';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useDiffClamp from 'src/hooks/animation/useDiffClamp';
import useShadowOnScroll from 'src/hooks/animation/useShadowOnScroll';
import Column from '../layout/Column';
const CollapsibleList = ({ renderCollapsibleHeader, collapsibleHeaderHeight = 10, renderCollapsibleFooter, collapsibleFooterHeight = 10, children, showCollapsibleShadow = true, forceCollapse = false, ...columnProps }) => {
    collapsibleHeaderHeight += 1;
    collapsibleFooterHeight += 1;
    const [{ styles }] = useTheme(stylesGenerator);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [footerHeight, setFooterHeight] = useState(0);
    const scroll = useSharedValue({
        y: 0,
        contentHeight: 0,
        layoutHeight: 0,
    });
    const [headerYPosStyle, headerYCollapse, headerYPos] = useDiffClamp(scroll, collapsibleHeaderHeight, -1);
    const [footerYPosStyle, footerYCollapse, footerYPos] = useDiffClamp(scroll, collapsibleFooterHeight, 1);
    const scrollY = useDerivedValue(() => scroll.value.y);
    const headerShadowStyle = useShadowOnScroll(scrollY, {
        shouldShowShadow() {
            'worklet';
            const isWithinRange = (value, min, max) => {
                return min <= value && value < max;
            };
            return forceCollapse
                ? !(isWithinRange(scrollY.value, 0, collapsibleHeaderHeight) &&
                    isWithinRange(headerYPos.value, 0, collapsibleHeaderHeight)) && headerYPos.value < scrollY.value
                : scrollY.value > 5;
        },
    });
    const footerShadowStyle = useShadowOnScroll(scrollY, {
        direction: 'top',
        shouldShowShadow() {
            'worklet';
            return footerYPos.value < collapsibleFooterHeight;
        },
    });
    const scrollHandler = useAnimatedScrollHandler({
        onScroll(ev) {
            scroll.value = {
                y: ev.contentOffset.y + (ev.contentInset?.top ?? 0),
                contentHeight: ev.contentSize.height,
                layoutHeight: ev.layoutMeasurement.height,
            };
        },
        onMomentumEnd() {
            headerYCollapse();
            footerYCollapse();
        },
    });
    return (React.createElement(Column, { ...columnProps },
        renderCollapsibleHeader && (React.createElement(Animated.View, { style: [
                styles.header,
                forceCollapse && headerYPosStyle,
                showCollapsibleShadow && headerShadowStyle,
            ], onLayout: ev => setHeaderHeight(ev.nativeEvent.layout.height) }, renderCollapsibleHeader(scrollY))),
        children({ scrollY, scrollHandler, headerHeight, footerHeight }),
        renderCollapsibleFooter && (React.createElement(Animated.View, { style: [
                styles.footer,
                forceCollapse && footerYPosStyle,
                showCollapsibleShadow && footerShadowStyle,
            ], onLayout: ev => setFooterHeight(ev.nativeEvent.layout.height) }, renderCollapsibleFooter(scrollY)))));
};
const stylesGenerator = createThemedStyle(() => ({
    header: {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        top: 0,
    },
    footer: {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        bottom: 0,
    },
}));
export default CollapsibleList;
