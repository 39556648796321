import { useCallback, useEffect } from 'react';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { getDeviceInfo } from 'src/lib/device';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { WebRNModuleAPIs } from 'src/components/15-containers/SuperChargedWebView/communication/web2RNCom/WebRNModuleAPIs';
import { isIosWV } from 'src/utils/platform';
import { useAppleSigninAPI } from '../apis/signin';
// Copied this in here because apple-auth library doesn't
// export these for web environment
var AppleError;
(function (AppleError) {
    /**
     * The authorization attempt failed for an unknown reason.
     */
    AppleError["UNKNOWN"] = "1000";
    /**
     * The user canceled the authorization attempt.
     */
    AppleError["CANCELED"] = "1001";
    /**
     * The authorization request received an invalid response.
     */
    AppleError["INVALID_RESPONSE"] = "1002";
    /**
     * The authorization request wasn't handled.
     */
    AppleError["NOT_HANDLED"] = "1003";
    /**
     * The authorization attempt failed.
     */
    AppleError["FAILED"] = "1004";
})(AppleError || (AppleError = {}));
export default function useAppleSignin(events = {}) {
    const { translate } = useI18n();
    const [appleSigninAPIState, appleSigninAPIActions] = useAppleSigninAPI(events);
    useEffect(() => {
        if (appleSigninAPIState.error) {
            snackbarHelperActions.show({
                message: appleSigninAPIState.error.code === 'ERR_BAD_RESPONSE'
                    ? translate('Network connection failure. Please try later')
                    : appleSigninAPIState.error.message ||
                        translate('Something went wrong, please try again'),
                variant: 'NEGATIVE',
            });
        }
    }, [appleSigninAPIState.error]);
    const handleAppleLogin = useCallback(async () => {
        try {
            if (!isIosWV) {
                snackbarHelperActions.show({
                    variant: 'NEGATIVE',
                    message: 'Apple Signin is allowed only on an iPhone. Please try other ways of Signing in',
                });
                return;
            }
            const appleAuthRes = await WebRNModuleAPIs.Login.appleSignin();
            appleSigninAPIActions.submit({
                idToken: appleAuthRes.identityToken,
                nonce: appleAuthRes.nonce,
            }, {
                firstName: appleAuthRes.fullName?.givenName || 'Mariner',
                lastName: appleAuthRes.fullName?.familyName || '',
                email: appleAuthRes.email,
            }, await getDeviceInfo());
        }
        catch (error) {
            switch (error.code) {
                case AppleError.UNKNOWN: // UNKNOWN
                case AppleError.CANCELED: // CANCELLED
                    // User cancelled the Auth flow
                    // Hence do nothing
                    break;
                default: {
                    console.log('error:', error);
                    snackbarHelperActions.show({
                        message: error?.message ||
                            translate('Something went wrong, please try again'),
                        variant: 'NEGATIVE',
                    });
                }
            }
        }
    }, [appleSigninAPIActions]);
    const state = {
        appleSigninAPI: appleSigninAPIState,
    };
    const actions = {
        signinWithApple: handleAppleLogin,
    };
    return [state, actions];
}
