import { objectParamsConfig } from 'src/navigation/linking/utils';
export const userStackLinking = {
    screens: {
        User_Reputation: {
            path: 'user/reputation',
        },
        User_EditProfile: {
            path: 'user/edit-profile',
            ...objectParamsConfig(['userDetails']),
        },
    },
};
export const userTabsLinking = {
    screens: {
        User_Home: {
            path: 'user/*',
        },
    },
};
