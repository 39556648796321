import React, { useEffect, useMemo, useState } from 'react';
import { useColorScheme } from 'react-native';
import ThemeContext from '.';
import getTheme from '../10-parts';
import config from 'react-native-ultimate-config';
const ThemeProvider = ({ theme, children, ...rest }) => {
    const colorScheme = useColorScheme();
    const [selectedTheme, setSelectedTheme] = useState(theme || config.DEFAULT_THEME);
    useEffect(() => {
        if (theme) {
            // When theme prop is given, then that
            // should take the highest priority over
            // colorScheme or any other influencers
            setSelectedTheme(theme);
        }
        else {
            // Automatically switch the Theme based on
            // Native color scheme only if theme is not provided
            setSelectedTheme(colorScheme || config.DEFAULT_THEME);
        }
    }, [colorScheme, theme]);
    const value = useMemo(() => ({
        theme: getTheme(selectedTheme),
        change: setSelectedTheme,
    }), [selectedTheme]);
    return (React.createElement(ThemeContext.Provider, { value: value, ...rest }, typeof children === 'function' ? children(value) : children));
};
export default ThemeProvider;
