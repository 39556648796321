import React, { useCallback, useRef } from 'react';
import { 
// StyleSheet,
View, } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useKeyboardHeight from 'src/hooks/keyboard/useKeyboardHeight';
import { renderNamedSlot } from 'src/utils/component';
import { extractStyles } from 'src/utils/props';
import styleUtils from 'src/utils/styles';
const stylePropMap = {
    pt: 'paddingTop',
    pb: 'paddingBottom',
    pr: 'paddingRight',
    pl: 'paddingLeft',
    pv: 'paddingVertical',
    ph: 'paddingHorizontal',
    bg: 'backgroundColor',
    mb: 'marginBottom',
    mt: 'marginTop',
    ml: 'marginLeft',
    mr: 'marginRight',
    mh: 'marginHorizontal',
    mv: 'marginVertical',
    alignSelf: 'alignSelf',
    btw: 'borderTopWidth',
    bbw: 'borderBottomWidth',
    brd: 'borderRadius',
    bwd: 'borderWidth',
    bcl: 'borderColor',
};
const applyItemSeparator = (childNode, options) => {
    const { ItemSeparator } = options;
    return ItemSeparator
        ? (() => {
            const childrenCount = React.Children.count(childNode);
            return React.Children.map(childNode, (child, index) => {
                if (React.isValidElement(child)) {
                    if (index < childrenCount - 1) {
                        return [child, renderNamedSlot(ItemSeparator)];
                    }
                }
                return child;
            });
        })()
        : childNode;
};
// const applySpacing = (
//   childNode: React.ReactNode,
//   options: Pick<
//     LayoutProps,
//     'spacing' | 'direction' | 'childStyleProp' | 'childrenStyle'
//   >,
// ): React.ReactNode => {
//   const {spacing, direction, childStyleProp = 'style', childrenStyle} = options;
//   const getStyleProp = (childProps: any) =>
//     childProps.styleProp ?? childStyleProp ?? 'style';
//   function addStyle(childProps: any, style: any) {
//     const styleProp = getStyleProp(childProps);
//     if (childProps[styleProp]) {
//       childProps[styleProp] = StyleSheet.flatten([
//         childProps[styleProp],
//         style,
//       ]);
//     } else {
//       childProps[styleProp] = style;
//     }
//   }
//   const resolveSpacing = (childProps: any): number | null =>
//     childProps.selfSpacing ?? spacing ?? null;
//   return typeof spacing === 'number'
//     ? React.Children.map(childNode, (child, index) => {
//         if (React.isValidElement(child)) {
//           if (index < React.Children.toArray(childNode).length - 1) {
//             const childProps = {...child.props};
//             const resolvedSpacing = resolveSpacing(childProps);
//             let spacingStyle: any =
//               typeof resolvedSpacing === 'number'
//                 ? StyleSheet.flatten({
//                     [getSpacingProp(direction)]: resolvedSpacing,
//                   } as any)
//                 : {};
//             addStyle(childProps, spacingStyle);
//             if (childrenStyle) {
//               addStyle(childProps, childrenStyle);
//             }
//             return React.cloneElement(child, childProps);
//           }
//           if (childrenStyle) {
//             const childProps = {...child.props};
//             addStyle(childProps, childrenStyle);
//             return React.cloneElement(child, childProps);
//           }
//         }
//         return child;
//       })
//     : childNode;
// };
const Layout = ({ style, direction, spacing, xAxis, yAxis, wrap, flex, children, ItemSeparator, 
// childStyleProp = 'style',
// childrenStyle,
avoidKeyboard = false, onLayout, sab = false, ...rest }, ref) => {
    const { pb, pv } = rest;
    const [{ styles }] = useTheme(stylesGenerator, { pb, pv }, [pb, pv]);
    const [{ height: keyboardHeight }] = useKeyboardHeight();
    const initialLayoutHeight = useRef(-1);
    const initialLayoutMeasured = useRef(false);
    const handleLayout = useCallback((ev) => {
        onLayout && onLayout(ev);
        if (!initialLayoutMeasured.current) {
            initialLayoutMeasured.current = true;
            initialLayoutHeight.current = ev.nativeEvent.layout.height;
        }
    }, [onLayout]);
    const shouldSubtractKeyboardHeight = avoidKeyboard && initialLayoutHeight.current > -1 && keyboardHeight !== 0;
    return (React.createElement(View, { ref: ref, style: [
            styles.common,
            styles[direction],
            getOrientationStyle({ direction, xAxis, yAxis }),
            wrap && styles.wrap,
            flex && !shouldSubtractKeyboardHeight && styleUtils.flex,
            style,
            extractStyles(stylePropMap, rest),
            sab && keyboardHeight === 0 && styles.safeAreaBottom,
            shouldSubtractKeyboardHeight && {
                flex: undefined,
                height: initialLayoutHeight.current - keyboardHeight,
            },
            spacing && { gap: spacing },
        ], onLayout: handleLayout, ...rest }, applyItemSeparator(children, { ItemSeparator })));
};
// type Direction = 'row' | 'column';
// const getSpacingProp = (direction: Direction) =>
//   direction === 'row' ? 'marginRight' : 'marginBottom';
const getOrientationStyle = ({ direction, xAxis, yAxis }) => {
    return {
        justifyContent: direction === 'row' ? xAxis : yAxis,
        alignItems: direction === 'row' ? yAxis : xAxis,
    };
};
const stylesGenerator = createThemedStyle(({ theme, dimensions }, { pb = 0, pv = 0 }) => ({
    common: {
        flexWrap: 'nowrap',
        borderColor: theme.palette.border.main,
    },
    row: {
        flexDirection: 'row',
    },
    column: {
        flexDirection: 'column',
    },
    wrap: {
        flexWrap: 'wrap',
    },
    safeAreaBottom: {
        paddingBottom: dimensions.safeAreaInsets.bottom + (pb || pv),
    },
}));
export default React.forwardRef(Layout);
