import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import Column from 'src/components/10-atoms/layout/Column';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import styleUtils from 'src/utils/styles';
const InputOptionsLayout = ({ visible, inputContainerRef, maxHeight = 3.5 * PRESSABLE_SIZE, children, style, ...columnProps }) => {
    const [{ theme }] = useTheme();
    const [yOffset, setYOffset] = useState(PRESSABLE_SIZE);
    useEffect(() => {
        inputContainerRef.current?.measure((_x, _y, _width, height) => {
            setYOffset(height + 4);
        });
    }, [visible]);
    return visible ? (React.createElement(Column, { brd: theme.borderRadius.standard.md, bwd: 1, style: [
            {
                maxHeight,
                position: 'absolute',
                top: yOffset,
                left: 0,
                right: 0,
            },
            styleUtils.ovfHidden,
            style,
        ], ...columnProps },
        React.createElement(ScrollView, { style: {
                backgroundColor: theme.palette.background.darker,
            }, contentContainerStyle: {
                maxHeight,
                backgroundColor: theme.palette.background.darker,
            } }, children))) : null;
};
export default InputOptionsLayout;
