import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../layout/Row';
import withPress from 'src/hoc/withPress';
import Column from '../layout/Column';
import Typography from '../typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import { renderNamedSlot } from 'src/utils/component';
const MenuItem = ({ Icon, title, description, style, ...rowProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { yAxis: "center", spacing: theme.spacing.standard.sm, style: [styles.container, style], ...rowProps },
        renderNamedSlot(Icon, {
            color: theme.palette.icon.dark,
            size: theme.iconSize.md,
        }),
        React.createElement(Column, null,
            React.createElement(Typography, null, title),
            description && (React.createElement(Typography, { color: theme.palette.typography.secondary, variant: TypographyVariant.CAPTION }, description)))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingVertical: theme.spacing.standard.xxs,
    },
}));
export default withPress(MenuItem);
