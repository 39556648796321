import murmur from 'murmurhash-js';
import { getJSONStorage } from 'src/lib/storage';
import StorageKeys from 'src/lib/storage/storageKeys';
const storageKey = StorageKeys.requestETag;
const storage = getJSONStorage(storageKey.scope);
const extractRequestID = (config) => {
    const uniqRequestParams = {
        url: config.url,
        params: config.params,
    };
    const hash = murmur.murmur3(JSON.stringify(uniqRequestParams), 718423);
    return hash;
};
const getKey = (config) => {
    const requestID = extractRequestID(config);
    return `${StorageKeys.requestETag.key}/${requestID}`;
};
const getSavedResponse = (config) => {
    return storage.get(getKey(config));
};
const saveResponse = (config, response) => {
    storage.set(getKey(config), {
        etag: response.headers.etag,
        response: response.data,
    });
};
const ETagUtils = {
    getETagForRequest(config) {
        if (config.bypassETag) {
            return;
        }
        const storedRequest = getSavedResponse(config);
        return storedRequest?.etag;
    },
    getSavedResponse,
    saveResponse,
};
export default ETagUtils;
