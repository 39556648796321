import React from 'react';
import { StyleSheet } from 'react-native';
import withPress from 'src/hoc/withPress';
import RowCenter from '../10-atoms/layout/RowCenter';
import { PRESSABLE_SIZE as ATOMS_PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
export const PRESSABLE_SIZE = ATOMS_PRESSABLE_SIZE;
const PressableArea = ({ style, ...rest }, ref) => {
    return React.createElement(RowCenter, { ref: ref, style: [styles.container, style], ...rest });
};
const styles = StyleSheet.create({
    container: {
        minWidth: PRESSABLE_SIZE,
        minHeight: PRESSABLE_SIZE,
    },
});
export default withPress(React.forwardRef(PressableArea));
