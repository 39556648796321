import React from 'react';
import { Path } from 'react-native-svg';
import withDefaultIconProps from './withDefaultIconProps';
import SVGIcon from './SVGIcon';
const TimerIcon = ({ color, ...rest }) => {
    return (React.createElement(SVGIcon, { ...rest },
        React.createElement(Path, { d: "M7 3a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1ZM8.56 9.084a6 6 0 0 0-2.19 2.84L12 14l-.02-6a6 6 0 0 0-3.42 1.084Z", fill: color }),
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M12 5a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm0 16.2a7.2 7.2 0 1 1 0-14.4 7.2 7.2 0 0 1 0 14.4Z", fill: color })));
};
export default withDefaultIconProps(TimerIcon);
