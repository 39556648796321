import React from 'react';
import { Path, G } from 'react-native-svg';
import SVGIcon from './SVGIcon';
import withDefaultIconProps from './withDefaultIconProps';
const AppleIcon = ({ color, ...rest }) => {
    return (React.createElement(SVGIcon, { ...rest, viewBox: "0 0 56 56" },
        React.createElement(G, { fill: "none", fillRule: "evenodd" },
            React.createElement(Path, { d: "M28.223 20.385c.832 0 1.875-.58 2.496-1.353.562-.7.972-1.68.972-2.658 0-.132-.011-.265-.035-.374-.926.036-2.039.64-2.707 1.45-.527.615-1.008 1.582-1.008 2.572 0 .145.024.29.036.338.058.013.152.025.246.025ZM25.293 35c1.137 0 1.64-.785 3.059-.785 1.441 0 1.757.76 3.023.76 1.242 0 2.074-1.183 2.86-2.342.878-1.33 1.242-2.634 1.265-2.694-.082-.024-2.46-1.027-2.46-3.841 0-2.44 1.874-3.54 1.98-3.624-1.243-1.836-3.13-1.884-3.645-1.884-1.395 0-2.531.87-3.246.87-.774 0-1.793-.822-3-.822-2.297 0-4.629 1.957-4.629 5.653 0 2.295.867 4.723 1.934 6.293.914 1.329 1.71 2.416 2.859 2.416Z", fill: color, fillRule: "nonzero" }))));
};
export default withDefaultIconProps(AppleIcon);
