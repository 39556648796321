export const notificationStackLinking = {
    screens: {},
};
export const notificationTabsLinking = {
    screens: {
        Notification_Home: {
            path: 'notification/*',
        },
    },
};
