import { useMemo } from 'react';
import useUserStorage from 'src/modules/authentication/storage/user/hook';
import { useGetBlockedUserListRawQuery } from '../graphql/getBlockedUserListRaw';
import { useUnblockContentMutation } from '../graphql/unblockContent';
import { useUnblockUserMutation } from '../graphql/unblockUser';
import useBlockContent from './useBlockContent';
import useBlockUser from './useBlockUser';
import useReportAbuse from './useReportAbuse';
export default function useUGCPolicy({ entity, entityAuthor, reportAbuseType, onBlockContentSuccess, onUnblockContentSuccess, contentBlockList = [], }) {
    // Report Abuse
    const [reportAbuseState, reportAbuseActions] = useReportAbuse();
    const handleReportAbuse = () => {
        reportAbuseActions.create({
            entity,
            type: reportAbuseType,
        });
    };
    // Block/Unblock Content
    const [userDetails] = useUserStorage();
    const isContentBlocked = userDetails
        ? contentBlockList.includes(userDetails.id)
        : false;
    const [blockContentState, blockContentActions] = useBlockContent({
        onCompleted: () => onBlockContentSuccess?.(),
    });
    const handleBlockContent = () => {
        blockContentActions.block(entity);
    };
    const [unblockContentState, unblockContentActions] = useUnblockContentMutation({
        onCompleted: () => onUnblockContentSuccess?.(),
    });
    const handleUnblockContent = () => {
        unblockContentActions.submit({
            variables: {
                entity,
            },
        });
    };
    // Block/Unblock User
    const [blockedUsersQueryState] = useGetBlockedUserListRawQuery();
    const blockedUsers = useMemo(() => blockedUsersQueryState.data?.ugcPolicy_getBlockedUserListRaw ?? [], [blockedUsersQueryState.data]);
    const isUserBlocked = entityAuthor
        ? blockedUsers.includes(entityAuthor)
        : false;
    const [blockUserState, blockUserActions] = useBlockUser();
    const handleBlockUser = () => {
        if (entityAuthor) {
            blockUserActions.block(entityAuthor);
        }
    };
    const [unblockUserState, unblockUserActions] = useUnblockUserMutation();
    const handleUnblockUser = () => {
        if (entityAuthor) {
            unblockUserActions.submit({
                variables: {
                    userID: entityAuthor,
                },
            });
        }
    };
    const state = {
        isContentBlocked,
        isUserBlocked,
        reportAbuse: reportAbuseState,
        unblockContent: unblockContentState,
        blockContent: blockContentState,
        blockUser: blockUserState,
        unblockUser: unblockUserState,
    };
    const actions = {
        reportAbuse: handleReportAbuse,
        blockContent: handleBlockContent,
        unblockContent: handleUnblockContent,
        blockUser: handleBlockUser,
        unblockUser: handleUnblockUser,
    };
    return [state, actions];
}
