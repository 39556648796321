import useRequest from 'src/hooks/request/useRequest';
const requestNewLangAPIConfig = (lang) => {
    return {
        url: `/translations/request-new/${lang}`,
        useMock: false,
        mockResponse: {
            status: '200',
            headers: {
                vary: 'Origin',
                'request-id': 'f5778a69-b0d7-4a9c-9f2a-18bdffca5505',
                etag: '4110771835',
                'content-type': 'application/json; charset=utf-8',
                'content-length': '204',
                connection: 'keep-alive',
            },
            data: {},
        },
    };
};
export function useRequestNewLangAPI() {
    return useRequest(requestNewLangAPIConfig);
}
