import React from 'react';
import { View } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Row from '../layout/Row';
const ULItemLayout = ({ children, ...rowProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { spacing: theme.spacing.standard.xs, ...rowProps },
        React.createElement(View, { style: styles.bullet }),
        children));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    bullet: {
        width: 4,
        height: 4,
        borderRadius: 2,
        backgroundColor: theme.palette.typography.primary,
        marginTop: 10,
        marginLeft: 4,
    },
}));
export default ULItemLayout;
