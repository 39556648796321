import { InMemoryCache } from '@apollo/client';
const dummyCache = new InMemoryCache();
export default function deduplicateItems(list) {
    const uniqIds = {};
    return list.filter(item => {
        const id = dummyCache.identify(item);
        if (id) {
            if (!uniqIds[id]) {
                uniqIds[id] = true;
                return true;
            }
            return false;
        }
        else {
            return true;
        }
    });
}
