import { useRef } from 'react';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { useEPCreateQPMutation } from 'src/modules/exam-preparation/graphql/qp/create';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
export default function useData(subjectID) {
    const metaDataFormRef = useRef(null);
    const questionListFormRef = useRef(null);
    const [createMutationState, createMutationActions] = useEPCreateQPMutation();
    const handleSubmit = useCallbackWithCtx(ctx => {
        if (metaDataFormRef.current?.form.isValid() &&
            questionListFormRef.current?.form.isValid()) {
            const metaData = metaDataFormRef.current.form.getData();
            const questionListData = questionListFormRef.current.form.getData();
            ctx.createMutationActions.submit({
                variables: {
                    subjectID: ctx.subjectID,
                    data: {
                        date: metaData.date.toISOString(),
                        qpImages: [],
                        questions: questionListData,
                    },
                },
            });
        }
        else {
            snackbarHelperActions.show({
                message: 'Please check the errors in the form',
                variant: 'NEGATIVE',
                duration: 'SHORT',
            });
        }
    }, { createMutationActions, subjectID });
    const state = {
        metaDataFormRef,
        questionListFormRef,
        createMutation: createMutationState,
    };
    const actions = {
        handleSubmit,
    };
    return [state, actions];
}
