import React, { useState } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import TextInput from 'src/components/10-atoms/form/inputs/TextInput';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useI18n from 'src/modules/translations/hooks/useI18n';
const PromptDrawer = ({ title, placeholder, onSubmit, submitLabel, }) => {
    const [{}] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [input, setInput] = useState('');
    const handleConfirm = useCallbackWithCtx(ctx => ctx.onSubmit(ctx.input), {
        onSubmit,
        input,
    });
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, title),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(TextInput, { autoFocus: true, multiline: true, minLines: 5, maxChars: 400, placeholder: placeholder, value: input, onChange: setInput })),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: handleConfirm }, submitLabel ?? translate('Submit')))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default PromptDrawer;
