import React from 'react';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import Typography from 'src/components/10-atoms/typography/Typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
const QuestionPaperItem = ({ data: qp, style, ...rowCenterProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(RowCenter, { brd: theme.borderRadius.standard.md, bg: theme.palette.background.dark, style: [styles.container, style], ...rowCenterProps },
        React.createElement(Typography, null, qp.data.label)));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        height: PRESSABLE_SIZE * 1.5,
        borderRadius: theme.borderRadius.standard.md,
        backgroundColor: theme.palette.background.dark,
    },
}));
export default withPress(QuestionPaperItem);
