import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import ColumnCenter from '../layout/ColumnCenter';
import { ActivityIndicator, StyleSheet, } from 'react-native';
const BackdropLoader = ({ size, style, ...columnProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(ColumnCenter, { style: [styles.container, style], ...columnProps },
        React.createElement(ActivityIndicator, { size: size, color: theme.palette.primary.main })));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        ...StyleSheet.absoluteFillObject,
        backgroundColor: theme.palette.backdrop,
        opacity: 0.6,
    },
}));
export default BackdropLoader;
