import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import GoogleIcon from 'src/components/10-atoms/icons/GoogleIcon';
import PressableArea from 'src/components/templates/PressableArea';
import withThemeProvider from 'src/customizations/themes/hoc/withThemeProvider';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
const GoogleSigninBtn = ({ ...btnProps }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    return (React.createElement(Button, { textContainerStyle: styleUtils.flex, LeftIcon: ({ disabled }) => (React.createElement(PressableArea, { style: {
                backgroundColor: disabled
                    ? theme.palette.pressable.disabled
                    : theme.palette.primary.dark,
            } },
            React.createElement(GoogleIcon, { color: disabled
                    ? theme.palette.pressable.disabledText
                    : theme.palette.primary.contrast }))), RightIcon: ({ disabled }) => (React.createElement(FeatherIcon, { name: "arrow-right", color: disabled
                ? theme.palette.pressable.disabledText
                : theme.palette.primary.contrast })), ...btnProps }, translate('Sign in with Google')));
};
export default withThemeProvider(GoogleSigninBtn, 'light');
