import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Column from '../layout/Column';
import stylesGenerator from './styles';
import withPress from 'src/hoc/withPress';
const ColumnCard = ({ style, ...columnProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return React.createElement(Column, { style: [styles.container, style], ...columnProps });
};
export default withPress(ColumnCard);
