import { useMemo } from 'react';
import { useCommunityListQuery } from 'src/modules/exam-preparation/graphql/community/getList';
export default function useData() {
    const [listQueryState, listQueryActions] = useCommunityListQuery();
    const listQueryVariables = useMemo(() => ({}), []);
    const state = {
        listQuery: listQueryState,
        listQueryVariables,
    };
    const actions = {
        listQuery: listQueryActions,
    };
    return [state, actions];
}
