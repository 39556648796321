import { useEffect } from 'react';
import { ebGlobalChannel } from '../channels';
export default function useSubscription(topic, callback) {
    useEffect(() => {
        const subscription = ebGlobalChannel.subscribe(topic, async (data, envelope) => {
            try {
                const result = await callback(data, envelope);
                envelope.reply?.(null, result);
            }
            catch (error) {
                envelope.reply?.(error);
            }
        });
        return () => subscription.unsubscribe();
    }, []);
}
