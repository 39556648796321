import React from 'react';
import Avatar from 'src/components/10-atoms/avatar/Avatar';
import Button from 'src/components/10-atoms/button/Button';
import Field from 'src/components/10-atoms/form/Field';
import Form from 'src/components/10-atoms/form/Form';
import TextInput from 'src/components/10-atoms/form/inputs/TextInput';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
import AccountDeleteConfirmation from '../../components/profile/Container/AccountDeleteConfirmation';
import { useUserProfileUpdateMutation } from '../../graphql/profile/update';
import useData from './useData';
import useOnBackPress from 'src/hooks/useOnBackPress';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import DiscardChangesConfirmDrawer from './DiscardChangesConfirmDrawer';
import { isWeb } from 'src/utils/platform';
import AccountDeleteDoubleConfirmation from '../../components/profile/Container/AccountDeleteDoubleConfirmation';
import ProfileLoader from '../../components/profile/Loader';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
const EditProfile = ({ navigation, route }) => {
    const { user } = route.params ?? {};
    const [{}] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData(user);
    const [updateMutationState, updateMutationActions] = useUserProfileUpdateMutation({
        onCompleted() {
            navigation.goBack();
        },
    });
    const handleBackPress = useCallbackWithCtx(ctx => {
        if (ctx.form.meta.dirty) {
            bottomDrawerHelperActions.show({
                component: (React.createElement(DiscardChangesConfirmDrawer, { onCancel: bottomDrawerHelperActions.hide, onConfirm: () => {
                        bottomDrawerHelperActions.hide();
                        navigation.goBack();
                    } })),
            });
            return true;
        }
        isWeb && navigation.goBack();
        return false;
    }, { form: state.form });
    useOnBackPress(handleBackPress);
    const handleSubmit = () => {
        if (!actions.form.validateForm()) {
            snackbarHelperActions.show({
                variant: 'NEGATIVE',
                duration: 'MEDIUM',
                message: translate('Please check the errors in the form'),
            });
        }
        else {
            updateMutationActions.update({
                variables: {
                    details: {
                        avatarURL: state.form.fields.avatarURL.value,
                        firstName: state.form.fields.firstName.value,
                        lastName: state.form.fields.lastName.value,
                        // userName: state.form.fields.userName.value,
                    },
                },
            });
        }
    };
    const handleDeleteAccountPress = () => {
        bottomDrawerHelperActions.show({
            component: (React.createElement(AccountDeleteConfirmation, { onCancel: bottomDrawerHelperActions.hide, 
                // onConfirm={() => {
                //   bottomDrawerHelperActions.hide();
                //   actions.deleteMutation.delete();
                // }}
                onConfirm: () => {
                    bottomDrawerHelperActions.show({
                        component: (React.createElement(AccountDeleteDoubleConfirmation, { onCancel: bottomDrawerHelperActions.hide, onConfirm: () => {
                                bottomDrawerHelperActions.hide();
                                actions.deleteMutation.delete();
                            } })),
                    });
                } })),
        });
    };
    return (React.createElement(Page, null,
        React.createElement(CustomScrollView, { renderCollapsibleHeader: () => (React.createElement(PageNavbar, { title: translate('Edit Profile'), backType: "back", onBackPress: handleBackPress, actions: [
                    {
                        Icon: props => React.createElement(FeatherIcon, { name: "trash-2", ...props }),
                        title: 'Delete',
                        onPress: handleDeleteAccountPress,
                    },
                ] })), collapsibleHeaderHeight: NAVBAR_HEIGHT }, state.userDetailsQuery.loading ? (React.createElement(ProfileLoader, null)) : (React.createElement(Form, null,
            React.createElement(Avatar, { style: styleUtils.alignSelfCenter, uri: state.form.fields.avatarURL.value, size: "jumbo", onChange: actions.changeAvatar, loading: state.fileUploader.loading }),
            React.createElement(Field, { label: translate('First Name'), error: state.form.fields.firstName.errors[0]?.message },
                React.createElement(TextInput, { value: state.form.fields.firstName.value, onChange: actions.form.fields.firstName.setValue })),
            React.createElement(Field, { label: translate('Last Name'), error: state.form.fields.lastName.errors[0]?.message },
                React.createElement(TextInput, { value: state.form.fields.lastName.value, onChange: actions.form.fields.lastName.setValue })),
            React.createElement(Button, { disabled: state.fileUploader.loading, loading: updateMutationState.loading, onPress: handleSubmit }, translate('SAVE')))))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default EditProfile;
