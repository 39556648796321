import { useUnblockUserMutation } from 'src/modules/ugc-policy/graphql/unblockUser';
export default function useData() {
    const [unblockMutationState, unblockMutationActions] = useUnblockUserMutation();
    const state = {
        unblockMutation: unblockMutationState,
    };
    const actions = {
        unblockMutation: unblockMutationActions,
    };
    return [state, actions];
}
