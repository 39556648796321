/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import { RootTab } from 'src/navigation/TabNavigation';
import withSuspense from 'src/navigation/withSuspense';
import useUserStorage from '../authentication/storage/user/hook';
import { isRegisteredUser } from '../authentication/storage/user/utils';
import useI18n from '../translations/hooks/useI18n';
import useUnreadNotificationCount from './hooks/useUnreadNotificationCount';
const Home = withSuspense(React.lazy(async () => {
    return import('./screens/Home');
}));
export const renderNotificationTabRoutes = () => {
    const [userDetails] = useUserStorage();
    const isRegistered = isRegisteredUser(userDetails);
    const [unreadCountState] = useUnreadNotificationCount();
    const { translate } = useI18n();
    return (React.createElement(RootTab.Group, null, isRegistered && (React.createElement(RootTab.Screen, { name: "Notification_Home", options: {
            tabBarLabel: translate('Notifications'),
            tabBarIcon: props => React.createElement(FeatherIcon, { name: "bell", ...props }),
            tabBarBadge: unreadCountState.count > 0 ? unreadCountState.count : undefined,
        }, component: Home }))));
};
