import React, { useMemo } from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import useI18n from 'src/modules/translations/hooks/useI18n';
export default function useUGCMenuOptions() {
    const { translate } = useI18n();
    return useMemo(() => ({
        REPORT_ABUSE: (onPress) => ({
            Icon: props => React.createElement(FeatherIcon, { name: "slash", ...props }),
            title: translate('Report Abuse'),
            description: translate("I'm concerned with this content"),
            onPress,
        }),
        BLOCK_CONTENT: (onPress) => ({
            Icon: props => React.createElement(FeatherIcon, { name: "eye-off", ...props }),
            title: translate('Hide Content'),
            description: translate("We'll not show this content"),
            onPress,
        }),
        BLOCK_AUTHOR: (onPress) => ({
            Icon: props => React.createElement(FeatherIcon, { name: "user-x", ...props }),
            title: translate('Block Author'),
            description: translate("You'll not see any content by this Author anymore"),
            onPress,
        }),
    }), []);
}
