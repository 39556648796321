export const isEligibleForCache = (config, paginated = false) => {
    if (!config.method || config.method.toUpperCase() === 'GET') {
        if (paginated) {
            // Cache only the first page in a paginated request
            return config.params?.pageNo === 1 ? !!config.cache : false;
        }
        return !!config.cache;
    }
    return false;
};
export const getCacheKey = (config) => ({
    url: config.url,
    params: config.params,
});
const MIN_5 = 60 * 5;
export const getCacheTTL = (config) => config.cache?.ttl ?? MIN_5;
