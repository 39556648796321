import { useState } from 'react';
import useKeyboardFocus from './useKeyboardFocus';
export default function useIsKeyboardFocused() {
    const [focused, setFocused] = useState(false);
    useKeyboardFocus(status => {
        if (status === 'didFocus') {
            setFocused(true);
        }
        else if (status === 'didBlur') {
            setFocused(false);
        }
    });
    return [focused];
}
