import useDimensions from './useDimensions';
export default function useDeviceType() {
    const [dimensions] = useDimensions();
    const smallestWidth = Math.min(dimensions.device.width, dimensions.device.height);
    const result = {
        isPhone: false,
        isTablet: false,
        isDesktop: false,
    };
    if (smallestWidth < 600) {
        result.isPhone = true;
    }
    else if (smallestWidth < 960) {
        result.isTablet = true;
    }
    else {
        result.isDesktop = true;
    }
    return result;
}
