import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Column from '../layout/Column';
const MenuSection = ({ style, ...colProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return React.createElement(Column, { style: [styles.container, style], ...colProps });
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        backgroundColor: theme.palette.background.main,
        borderRadius: theme.borderRadius.standard.md,
        marginHorizontal: theme.spacing.standard.sm,
    },
}));
export default MenuSection;
