import { useEffect, useState } from 'react';
import delay from 'src/utils/delay';
import { isWeb, isWebView } from 'src/utils/platform';
export const AppInitMinDelay = isWeb ? (isWebView ? 0 : 1000) : 500;
export default function useAppInit() {
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
        async function run() {
            await Promise.all([delay(AppInitMinDelay)]);
            setInitialized(true);
        }
        run();
    }, []);
    return [initialized];
}
