import { useState } from 'react';
import uploadFile from '../uploadFile';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
export default function useFileUploader(options) {
    const [state, setState] = useState({
        loading: false,
        error: null,
        data: null,
        progress: 0,
    });
    const updateState = (newState) => setState(prevState => ({
        ...prevState,
        ...newState,
    }));
    const actions = {
        async upload(file) {
            const response = await uploadFile(file, {
                onProgress(percentage) {
                    options?.events?.onProgress?.(percentage);
                    updateState({
                        loading: true,
                        progress: percentage,
                    });
                },
            });
            if (response) {
                if (response.data && response.statusCode < 300) {
                    updateState({
                        loading: false,
                        progress: 100,
                        error: null,
                        data: response.data,
                    });
                    options?.events?.onComplete?.(response.data);
                    return;
                }
                else {
                    const error = response.data?.message ?? 'Unknown Error';
                    updateState({
                        loading: false,
                        error,
                    });
                    options?.events?.onError?.(error);
                    if (options?.showError) {
                        snackbarHelperActions.show({
                            variant: 'NEGATIVE',
                            duration: 'MEDIUM',
                            message: error,
                        });
                    }
                }
            }
            else {
                const error = "Sorry could'nt upload the file. Please try again later";
                updateState({
                    loading: false,
                    error,
                });
                options?.events?.onError?.(error);
                if (options?.showError) {
                    snackbarHelperActions.show({
                        variant: 'NEGATIVE',
                        duration: 'MEDIUM',
                        message: error,
                    });
                }
            }
        },
    };
    return [state, actions];
}
