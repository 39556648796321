import React, { useCallback, useState } from 'react';
import { ActivityIndicator, Image, StyleSheet, } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import RowCenter from '../layout/RowCenter';
import useRemoteImageDimens from './useRemoteImageDimens';
const FitImage = ({ style, spacing = 0, source, imageStyle, ...rowCenterProps }) => {
    const [{ theme }] = useTheme();
    const [containerWidth, setContainerWidth] = useState(-1);
    const [imageDimens] = useRemoteImageDimens(source.uri);
    const handleLayout = useCallback((ev) => {
        const width = ev.nativeEvent.layout.width;
        setContainerWidth(width);
    }, []);
    const containerMeasured = containerWidth > -1;
    const imageMeasured = !!imageDimens;
    const showImage = containerMeasured && imageMeasured;
    const imageHeight = imageMeasured && showImage
        ? Math.round(containerWidth * (imageDimens.height / imageDimens.width))
        : 100;
    return !showImage ? (React.createElement(RowCenter, { onLayout: handleLayout, style: [style, styles.layout], ...rowCenterProps },
        React.createElement(ActivityIndicator, { color: theme.palette.primary.main, size: "small" }))) : (React.createElement(RowCenter, { style: [
            style,
            {
                width: containerWidth,
                height: imageHeight + spacing,
                maxWidth: containerWidth,
                maxHeight: imageHeight + spacing,
            },
        ], ...rowCenterProps },
        React.createElement(Image, { source: source, style: [
                imageStyle,
                {
                    maxWidth: containerWidth,
                    maxHeight: imageHeight,
                    width: containerWidth,
                    height: imageHeight,
                },
            ] })));
};
const styles = StyleSheet.create({
    layout: {
        width: '100%',
        height: 100,
    },
});
export default FitImage;
