import React, { useImperativeHandle } from 'react';
import Field from 'src/components/10-atoms/form/Field';
import { getFieldError } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import NumberInput from 'src/components/10-atoms/form/inputs/NumberInput';
import AutoCompleteTagsInput from 'src/components/10-atoms/form/inputs/TagsInput/AutoCompleteTagsInput';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import useData from './useData';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import HTMLEditorInput from 'src/components/10-atoms/form/inputs/editor/html/HTMLEditor/EditorInput';
const QuestionForm = React.forwardRef(({ subjectID, label, initialValue, onChange, ...rowProps }, ref) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const [state, actions, helpers] = useData(subjectID, initialValue);
    useImperativeHandle(ref, () => ({
        state,
        actions,
        helpers,
    }));
    useEffectWithCtx(ctx => {
        const newValue = {
            marks: Number(state.form.fields.marks.value),
            topics: state.form.fields.topics.value,
            questionHTML: state.form.fields.questionHTML.value,
            question: state.questionEditorRef.current?.getText() ?? '',
        };
        ctx.onChange?.(newValue);
    }, { onChange, state }, [state.form]);
    return (React.createElement(Row, { spacing: theme.spacing.standard.xxs, ...rowProps },
        React.createElement(Column, { xAxis: "flex-end", spacing: theme.spacing.standard.xs, pt: theme.spacing.standard.sm, style: [
                {
                    width: 24,
                },
            ] },
            React.createElement(Typography, { color: theme.palette.typography.secondary }, label)),
        React.createElement(Column, { flex: true, spacing: theme.spacing.standard.xs, style: { position: 'relative', zIndex: 1 } },
            React.createElement(Field, { error: getFieldError(state.form.fields.questionHTML.errors) },
                React.createElement(HTMLEditorInput, { ref: state.questionEditorRef, placeholder: "Enter the question", initialValue: state.form.fields.questionHTML.value, onChange: ({ html }) => {
                        actions.form.fields.questionHTML.setValue(html);
                    } })),
            React.createElement(Field, { style: { zIndex: 1 }, label: "Topics", error: getFieldError(state.form.fields.topics.errors) },
                React.createElement(AutoCompleteTagsInput, { input: state.form.fields.topicsInput.value, onInputChange: actions.form.fields.topicsInput.setValue, value: state.form.fields.topics.value, onChange: actions.form.fields.topics.setValue, suggestions: state.suggestedTopics })),
            React.createElement(Field, { label: "Marks", error: getFieldError(state.form.fields.marks.errors) },
                React.createElement(NumberInput, { keyboardType: "number-pad", value: state.form.fields.marks.value, onChange: actions.form.fields.marks.setValue })))));
});
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QuestionForm;
