import React, { useRef } from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import QuillEditor from './QuillEditor';
import { getDiffViewDelta } from './QuillEditor/utils/diff';
import { normalizeMathField } from './utils/mathField';
const HTMLDiffRenderer = ({ oldHtml: oldHtml, newHtml: newHtml, ...columnProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const editorRef = useRef(null);
    const handleEditorChange = useCallbackWithCtx((ctx, quill) => {
        if (quill) {
            const diffViewDelta = getDiffViewDelta(ctx.theme, quill, normalizeMathField(ctx.oldHTML ?? ''), normalizeMathField(ctx.newHTML ?? ''));
            editorRef.current?.setDelta(diffViewDelta);
        }
    }, { theme, oldHTML: oldHtml, newHTML: newHtml });
    return (React.createElement(QuillEditor, { ref: editorRef, readOnly: true, initialValue: '', onEditorChanged: handleEditorChange, ...columnProps }));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default HTMLDiffRenderer;
