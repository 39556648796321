import Inline from 'quill/blots/inline';
class HyperLink extends Inline {
    static blotName = 'hyperLink';
    static tagName = 'a';
    static create(value) {
        let node = super.create();
        node.setAttribute('href', value.link);
        node.setAttribute('target', '_blank');
        node.setAttribute('title', value.link);
        return node;
    }
    static value(domNode) {
        return {
            title: domNode.textContent ?? '',
            link: domNode.getAttribute('href') ?? '',
        };
    }
    static formats(domNode) {
        return this.value(domNode);
    }
    format(name, value) {
        if (name === HyperLink.blotName && value) {
            this.domNode.setAttribute('href', value);
        }
        else {
            super.format(name, value);
        }
    }
    formats() {
        let formats = super.formats();
        formats[HyperLink.blotName] = HyperLink.formats(this.domNode);
        return formats;
    }
}
export default HyperLink;
