import useDelay from 'src/hooks/useDelay';
import { renderNamedSlot } from 'src/utils/component';
function QueryHandler({ skip, queryState, LoaderComponent, DataComponent, ErrorComponent, }) {
    const delayCompleted = useDelay(50);
    if (!delayCompleted) {
        return null;
    }
    if (skip || queryState.data) {
        return renderNamedSlot(DataComponent);
    }
    else if (queryState.loading) {
        return renderNamedSlot(LoaderComponent);
    }
    else if (queryState.error) {
        return renderNamedSlot(ErrorComponent);
    }
    return null;
}
export default QueryHandler;
