import React from 'react';
import BreadCrumbsContainer from 'src/components/10-atoms/BreadCrumbs/Container';
import getBreadCrumbs from './getBreadCrumbs';
import useTheme from 'src/customizations/themes/hooks/useTheme';
const EPBreadCrumbs = ({ ancestorsDetails, knownNodes, visibleCount, ...breadCrumbsContainerProps }) => {
    const [{ theme }] = useTheme();
    const crumbs = getBreadCrumbs(ancestorsDetails, knownNodes, { visibleCount });
    return (React.createElement(BreadCrumbsContainer, { ph: theme.spacing.page, crumbs: crumbs, visibleCount: visibleCount, ...breadCrumbsContainerProps }));
};
export default EPBreadCrumbs;
