import { Dimensions } from 'react-native';
import borderRadius from '../10-parts/borderRadius';
import darkColorScheme from '../10-parts/color-schemes/dark';
import lightColorScheme from '../10-parts/color-schemes/light';
import spacing from '../10-parts/spacing';
import iconSize from './iconSize';
import { typography } from './typography';
const getTheme = (() => {
    const colorSchemes = {
        light: lightColorScheme,
        dark: darkColorScheme,
    };
    return (themeID) => ({
        themeID,
        palette: colorSchemes[themeID],
        typography: typography({ palette: colorSchemes[themeID] }),
        spacing,
        borderRadius,
        iconSize,
        viewPort: {
            width: Dimensions.get('window').width,
            height: Dimensions.get('window').height,
        },
    });
})();
export default getTheme;
