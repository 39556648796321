export var MsgType;
(function (MsgType) {
    MsgType[MsgType["REQ"] = 0] = "REQ";
    MsgType[MsgType["RES"] = 1] = "RES";
})(MsgType || (MsgType = {}));
export var MsgInitiator;
(function (MsgInitiator) {
    MsgInitiator[MsgInitiator["RN"] = 0] = "RN";
    MsgInitiator[MsgInitiator["WEB"] = 1] = "WEB";
})(MsgInitiator || (MsgInitiator = {}));
