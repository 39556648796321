export var NodeType;
(function (NodeType) {
    NodeType[NodeType["COMMUNITY"] = 0] = "COMMUNITY";
    NodeType[NodeType["EXAM_LEVEL"] = 1] = "EXAM_LEVEL";
    // Written Specific Node types
    NodeType[NodeType["WRITTEN_SUBJECT"] = 2] = "WRITTEN_SUBJECT";
    NodeType[NodeType["WRITTEN_QP"] = 3] = "WRITTEN_QP";
    NodeType[NodeType["WRITTEN_QUESTION"] = 4] = "WRITTEN_QUESTION";
    NodeType[NodeType["WRITTEN_ANSWER"] = 5] = "WRITTEN_ANSWER";
    // Please DO NOT use 6 & 7 as it was earlier used up for COMMENT & RELATION types
    NodeType[NodeType["WRITTEN_EF"] = 8] = "WRITTEN_EF";
    // COMMENT = 6,
    // // This type of Node can be used to store User <--> Node relationships
    // // For ex: User has read this Question, User has marked this Question as Favourite etc
    NodeType[NodeType["RELATION"] = 7] = "RELATION";
    // Oral Specific Node types
    NodeType[NodeType["ORALS_SUBJECT"] = 20] = "ORALS_SUBJECT";
    NodeType[NodeType["ORALS_QP"] = 21] = "ORALS_QP";
    NodeType[NodeType["ORALS_QUESTION"] = 22] = "ORALS_QUESTION";
    NodeType[NodeType["ORALS_ANSWER"] = 23] = "ORALS_ANSWER";
    NodeType[NodeType["COMMENT"] = 30] = "COMMENT";
    NodeType[NodeType["QUESTION_TOPIC"] = 31] = "QUESTION_TOPIC";
})(NodeType || (NodeType = {}));
