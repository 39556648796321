const spacing = {
    page: 16,
    input: 16,
    itemSeparation: 16,
    itemVerticalPadding: 12,
    label: 8,
    standard: {
        xxxs: 2,
        xxs: 4,
        xs: 8,
        sm: 12,
        md: 16,
        lg: 24,
        xl: 32,
        xxl: 40,
    },
    keyboardBottomOffset: 72,
};
export default spacing;
