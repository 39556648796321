import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
export default function useImagePicker(quill) {
    const pick = useCallbackWithCtx(async (ctx) => {
        const imageUploader = ctx.quill.getModule('imageUploader');
        imageUploader.selectLocalImage();
    }, { quill });
    const actions = {
        pick,
    };
    return actions;
}
