const lastCalledIDs = {};
export default function limitCalls(cb, options) {
    let lastCalled = null;
    return (...args) => {
        const lastCalledTime = options.id ? lastCalledIDs[options.id] : lastCalled;
        if (!lastCalledTime || Date.now() - lastCalledTime > options.duration) {
            if (options.id) {
                lastCalledIDs[options.id] = Date.now();
            }
            else {
                lastCalled = Date.now();
            }
            cb(...args);
        }
    };
}
