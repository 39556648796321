import { useEffect, useState } from 'react';
import initFirebase from './index';
export default function useFirebaseInit() {
    const [isInitialized, setIsInitialized] = useState(false);
    useEffect(() => {
        initFirebase().finally(() => {
            setIsInitialized(true);
        });
    }, []);
    return [isInitialized];
}
