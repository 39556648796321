import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
export const GET_BLOCKED_USER_LIST_RAW_QUERY = gql `
  query UGCPolicy_getBlockedUserListRaw {
    ugcPolicy_getBlockedUserListRaw
  }
`;
export function useGetBlockedUserListRawQuery(options) {
    return useGQLQuery(GET_BLOCKED_USER_LIST_RAW_QUERY, {
        fetchPolicy: 'cache-first',
        nextFetchPolicy: 'cache-first',
        ...options,
    });
}
