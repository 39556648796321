import React from 'react';
import { RootStack } from 'src/navigation/RootNavigation';
import BlockedUsers from './screens/BlockedUsers';
import EditProfile from './screens/EditProfile';
import Reputation from './screens/Reputation';
export const renderUserStackRoutes = () => {
    return (React.createElement(RootStack.Group, null,
        React.createElement(RootStack.Screen, { name: "User_Reputation", component: Reputation }),
        React.createElement(RootStack.Screen, { name: "User_EditProfile", component: EditProfile }),
        React.createElement(RootStack.Screen, { name: "User_BlockedUsers", component: BlockedUsers })));
};
