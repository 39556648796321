import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
export const EP_ANSWER_HISTORY_QUERY = gql `
  query EPAnswerHistory($questionID: ID!) {
    ep_questionDetails(questionID: $questionID) {
      id
      answer {
        id
        data {
          answerHistory {
            ctime
            answer
            comments
            author {
              id
              details {
                avatarURL
                firstName
                lastName
                userName
              }
            }
            review {
              reviews {
                actionedAt
                comments
                reviewer {
                  id
                  details {
                    avatarURL
                    firstName
                    lastName
                    userName
                  }
                }
              }
            }
            blockedBy
          }
        }
        config {
          WRITTEN_ANSWER {
            contribution {
              enableHistory
            }
          }
        }
        translationInfo {
          ...TranslationInfoFields
        }
      }
    }
  }
`;
export function useAnswerHistoryQuery(options) {
    return useGQLQuery(EP_ANSWER_HISTORY_QUERY, options);
}
