import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const REPORT_ABUSE_CREATE_MUTATION = gql `
  mutation UGCPolicy_reportAbuse($report: NewReportAbuseInput!) {
    ugcPolicy_reportAbuse(report: $report)
  }
`;
export function useReportAbuseMutation(options) {
    return useGQLMutation(REPORT_ABUSE_CREATE_MUTATION, options);
}
