import { Platform } from 'react-native';
export const isIOS = Platform.OS === 'ios';
export const isAndroid = Platform.OS === 'android';
export const isNative = isIOS || isAndroid;
export const isWeb = Platform.OS === 'web';
export const isWebView = isWeb && 'ReactNativeWebView' in window;
const Env = !isWebView
    ? { OS: Platform.OS }
    : JSON.parse(window.ReactNativeWebView?.injectedObjectJson() ?? '{}');
export const isIosWV = isWebView && Env.OS === 'ios';
export const isAndroidWV = isWebView && Env.OS === 'android';
