import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Column from '../layout/Column';
import LoaderBase from './LoaderBase';
const ListLoaderLayout = ({ count = 3, loaderItemHeight, ...columnProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { ph: theme.spacing.page, 
        // pv={theme.spacing.itemVerticalPadding}
        spacing: theme.spacing.standard.sm, ...columnProps }, new Array(count).fill(0).map((_, i) => (React.createElement(LoaderBase, { key: i, style: { height: loaderItemHeight } })))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({}));
export default ListLoaderLayout;
