/* tslint:disable no-console */
import { isWeb } from 'src/utils/platform';
const REQ_PREFIX = 'HTTP_REQ =>';
const RES_PREFIX = 'HTTP_RES =>';
const ERR_PREFIX = 'HTTP_ERR =>';
const getOffsetSpace = (prefixLen) => Array(prefixLen + 2 /** Extra space */)
    .fill('')
    .join(' ');
function addRequestLogger(instance, logMode) {
    const offsetSpace = getOffsetSpace(REQ_PREFIX.length);
    const reqParamLogger = {
        body(config) {
            config.data &&
                console.log(`${offsetSpace}BODY: ${JSON.stringify(config.data)}`);
        },
        headers(config) {
            config.headers &&
                console.log(`${offsetSpace}HEADERS: ${JSON.stringify(config.headers)}`);
        },
    };
    const reqLogger = (config, logParams = []) => {
        console.log(`${REQ_PREFIX} ${config.method?.toUpperCase()} ${config.url}`);
        config.params &&
            console.log(`${offsetSpace}QUERY: ${JSON.stringify(config.params)}`);
        logParams.forEach(param => reqParamLogger[param] && reqParamLogger[param](config));
    };
    instance.interceptors.request.use(config => {
        switch (logMode) {
            case 'none':
                break;
            case 'minimal':
                reqLogger(config);
                break;
            case 'verbose':
                reqLogger(config, ['headers', 'body']);
                break;
            default:
                reqLogger(config, ['body']);
        }
        return config;
    });
}
function getResponseLogger(logMode) {
    const offsetSpace = getOffsetSpace(RES_PREFIX.length);
    const resParamLogger = {
        body(response) {
            response.data && console.log(`${offsetSpace}DATA:`, response.data);
        },
        extendedBody(response) {
            response.data &&
                console.log(`${offsetSpace}DATA_V: ${JSON.stringify(response.data)}`);
        },
        headers(response) {
            response.headers &&
                console.log(`${offsetSpace}HEADERS: ${JSON.stringify(response.headers)}`);
        },
    };
    const resLogger = (response, logParams = []) => {
        console.log(`${RES_PREFIX} ${response.status} ${(response.config.method ?? 'get').toUpperCase()} ${response.config.url}`);
        response.config.params &&
            console.log(`${offsetSpace}QUERY: ${JSON.stringify(response.config.params)}`);
        logParams.forEach(param => resParamLogger[param](response));
    };
    return (response) => {
        const { config: request } = response;
        if (request && response.status < 400) {
            switch (logMode) {
                case 'none':
                    break;
                case 'minimal':
                    resLogger(response);
                    break;
                case 'verbose':
                    resLogger(response, ['headers', 'extendedBody']);
                    break;
                default:
                    resLogger(response, ['body']);
                    break;
            }
        }
        return response;
    };
}
function getErrorLogger() {
    const offsetSpace = getOffsetSpace(ERR_PREFIX.length);
    return (err) => {
        const { response } = err || {};
        if (!response) {
            console.log(`${ERR_PREFIX} ${err}`);
        }
        else {
            const { config: request } = response;
            if (request) {
                console.log(`${ERR_PREFIX} ${response.status}  ${request.method?.toUpperCase()} ${request.url}`);
                console.log(`${offsetSpace}HEADERS:`, response.headers);
                console.log(`${offsetSpace}DATA:`, JSON.stringify(response.data));
            }
        }
        return Promise.reject(err);
    };
}
function addResponseLogger(instance, logMode) {
    instance.interceptors.response.use(getResponseLogger(logMode), getErrorLogger());
}
export default function apiLogger(instance, logMode) {
    if (isWeb) {
        return;
    }
    addRequestLogger(instance, logMode);
    addResponseLogger(instance, logMode);
}
