import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { ProcessorType } from '../../types';
import useEPQuestionDetailsProcessor from './useEPQuestionDetailsProcessor';
export default function useProcessor() {
    const epQuestionDetailsProcessor = useEPQuestionDetailsProcessor();
    const processor = useCallbackWithCtx((ctx, item) => {
        const processors = {
            [ProcessorType.EP_QUESTION_DETAILS]: ctx.epQuestionDetailsProcessor,
        };
        return processors[item.processorType](item);
    }, { epQuestionDetailsProcessor });
    return processor;
}
