import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import EmptyList from 'src/components/templates/EmptyList.template';
import AppAssets from 'src/constants/appAssets';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const AnswerNotFound = ({ createAnswerLoading, onCreateAnswer, ...emptyListProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(EmptyList, { image: {
            type: 'image',
            source: {
                uri: AppAssets.writeAnswerIllustration,
            },
        }, message: "", ...emptyListProps },
        React.createElement(Column, { alignSelf: "stretch", spacing: theme.spacing.standard.sm },
            React.createElement(Typography, { variant: TypographyVariant.SUB_HEADING, color: theme.palette.typography.secondary, align: "center" }, translate('Write here once and \nit stays forever')),
            React.createElement(Button, { variant: "outline", LeftIcon: React.createElement(FeatherIcon, { name: "edit" }), loading: createAnswerLoading, onPress: onCreateAnswer }, translate('Write Answer')))));
};
const stylesGenerator = createThemedStyle(() => ({
    container: {},
    message: {
        textAlign: 'center',
    },
}));
export default AnswerNotFound;
