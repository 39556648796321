import { NetworkStatus, useLazyQuery, } from '@apollo/client';
import { useEffect, useRef } from 'react';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
export default function useGQLQuery(query, options = {}) {
    const { lazy = false, skip = false, ...queryOptions } = options;
    const [fetch, { loading, data, error, networkStatus, fetchMore }] = useLazyQuery(query, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        ...queryOptions,
    });
    const firstFetchCalled = useRef(false);
    useEffect(() => {
        if (!lazy && !skip) {
            firstFetchCalled.current = true;
            fetch();
        }
    }, [skip]);
    const state = {
        loading,
        data,
        error,
        fetchingMore: networkStatus === NetworkStatus.fetchMore,
        refetching: networkStatus === NetworkStatus.refetch,
    };
    const actions = {
        fetch: useCallbackWithCtx((ctx, variables) => {
            return ctx.fetch({ variables });
        }, { fetch }),
        fetchMore: useCallbackWithCtx((ctx, variables) => {
            return ctx.fetchMore({ variables });
        }, { fetchMore }),
    };
    return [state, actions];
}
