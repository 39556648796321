import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
import { ProcessorType } from 'src/lib/playlist/types';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { playlistPlayerHelperViewActions } from 'src/lib/playlist/hooks/usePlaylistPlayer';
const EP_QUESTION_PLAYLIST_FOR_QP_QUERY = gql `
  query EP_QuestionPlaylistForQP($questionPaperID: ID!) {
    ep_questionPlaylistForQP(questionPaperID: $questionPaperID) {
      totalCount
      list {
        id
      }
    }
  }
`;
export function useQuestionPlaylistForQPQuery(options) {
    return useGQLQuery(EP_QUESTION_PLAYLIST_FOR_QP_QUERY, {
        fetchPolicy: 'cache-first',
        lazy: true,
        ...options,
    });
}
export function useQPPlaylist(qpID, sequence = 0) {
    const [playlistQueryState, playlistQueryActions] = useQuestionPlaylistForQPQuery({
        onCompleted(data) {
            const list = data.ep_questionPlaylistForQP.list.map((item, index) => ({
                processorType: ProcessorType.EP_QUESTION_DETAILS,
                meta: {
                    questionID: item.id,
                    ...(qpID
                        ? {
                            navigatedFrom: 'QP_QUESTIONS',
                            navigationData: {
                                qpID,
                                sequence: index,
                            },
                        }
                        : {}),
                },
            }));
            playlistPlayerHelperViewActions.overrideQueue({
                list,
                activeIndex: sequence,
            });
        },
        onError(error) {
            snackbarHelperActions.show({
                message: `Failed to play: ${error.message ?? 'Unknown failure'}`,
                variant: 'NEGATIVE',
                duration: 'LONG',
            });
        },
    });
    const state = {
        playlistQuery: playlistQueryState,
    };
    const actions = {
        play() {
            playlistQueryActions.fetch({
                questionPaperID: qpID,
            });
        },
    };
    return [state, actions];
}
