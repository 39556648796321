import React from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Field from 'src/components/10-atoms/form/Field';
import Form from 'src/components/10-atoms/form/Form';
import { getFieldError, getFieldState, } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import useFormState from 'src/components/10-atoms/form/hooks/useFormState';
import TextInput from 'src/components/10-atoms/form/inputs/TextInput';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { isValidLink } from 'src/utils/url';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
const LinkDrawer = ({ mode = 'create', initialValue, onConfirm, onRemove, }) => {
    const [{}] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [formState, formActions, formHelpers] = useFormState({
        fields: {
            title: getFieldState(initialValue?.title ?? ''),
            link: getFieldState(initialValue?.link ?? ''),
        },
    }, {
        fieldValidations: {
            title({ value }) {
                if (!value) {
                    return 'Enter a valid title';
                }
                return true;
            },
            link({ value }) {
                if (!isValidLink(value)) {
                    return 'Enter a valid link';
                }
                return true;
            },
        },
    });
    const handleConfirm = useCallbackWithCtx(ctx => {
        if (ctx.formHelpers.hasErrors()) {
            snackbarHelperActions.show({
                variant: 'NEGATIVE',
                message: 'Please fix the errors in the form',
            });
            return;
        }
        ctx.onConfirm({
            title: ctx.formState.fields.title.value,
            link: ctx.formState.fields.link.value,
        });
    }, { formState, formHelpers, onConfirm });
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, mode === 'create' ? translate('Create Link') : translate('Edit Link')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(Form, null,
                React.createElement(Field, { label: translate('Title'), error: getFieldError(formState.fields.title.errors) },
                    React.createElement(TextInput, { autoFocus: !formState.fields.title.value, value: formState.fields.title.value, onChange: formActions.fields.title.setValue })),
                React.createElement(Field, { label: translate('Link'), error: getFieldError(formState.fields.link.errors) },
                    React.createElement(TextInput, { autoFocus: !!formState.fields.title.value, value: formState.fields.link.value, onChange: formActions.fields.link.setValue })))),
        React.createElement(BottomDrawerLayout.Actions, null, mode === 'create' ? (React.createElement(Button, { flex: true, onPress: handleConfirm }, translate('Confirm'))) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { variant: "text", onPress: onRemove }, translate('Remove Link')),
            React.createElement(Button, { onPress: handleConfirm }, translate('Apply')))))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default LinkDrawer;
