import { useRef } from 'react';
import { Keyboard } from 'react-native';
import useEffectWithCtx from '../useEffectUtils/useEffectWithCtx';
export default function useKeyboardFocus(callback) {
    const prevState = useRef();
    useEffectWithCtx(({ onFocusChange }) => {
        const handleChange = (status) => {
            return (ev) => {
                if (status !== prevState.current) {
                    onFocusChange(status, ev);
                    prevState.current = status;
                }
            };
        };
        const subscriptions = [
            Keyboard.addListener('keyboardWillShow', handleChange('willFocus')),
            Keyboard.addListener('keyboardDidShow', handleChange('didFocus')),
            Keyboard.addListener('keyboardWillHide', handleChange('willBlur')),
            Keyboard.addListener('keyboardDidHide', handleChange('didBlur')),
        ];
        return () => {
            subscriptions.forEach(subscription => subscription.remove());
        };
    }, { onFocusChange: callback }, []);
}
