import React from 'react';
import { RefreshControl, ScrollView } from 'react-native';
import { KeyboardAwareScrollView, } from 'react-native-keyboard-aware-scroll-view';
import Animated from 'react-native-reanimated';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useIsKeyboardFocused from 'src/hooks/keyboard/useIsKeyboardFocused';
import useDelay from 'src/hooks/useDelay';
import { isIOS } from 'src/utils/platform';
import styleUtils from 'src/utils/styles';
import CollapsibleList from './CollapsibleList';
const AnimatedScrollView = Animated.createAnimatedComponent(isIOS ? KeyboardAwareScrollView : ScrollView);
function CustomScrollView({ renderCollapsibleHeader, collapsibleHeaderHeight, renderCollapsibleFooter, collapsibleFooterHeight, showCollapsibleShadow, forceCollapse, contentContainerStyle, onMomentumScrollEnd = () => { }, onRefresh, refreshing, initialRenderDelay, style, containerStyle, ...scrollViewProps }, ref) {
    const [{ theme, dimensions: { safeAreaInsets }, },] = useTheme();
    const delayCompleted = useDelay(initialRenderDelay ?? 50);
    const shouldDelay = typeof initialRenderDelay === 'number';
    const [keyboardVisible] = useIsKeyboardFocused();
    const bottomOffset = keyboardVisible ? 0 : safeAreaInsets.bottom;
    return (React.createElement(CollapsibleList, { renderCollapsibleFooter: renderCollapsibleFooter, renderCollapsibleHeader: renderCollapsibleHeader, collapsibleHeaderHeight: collapsibleHeaderHeight, collapsibleFooterHeight: collapsibleFooterHeight, showCollapsibleShadow: showCollapsibleShadow, forceCollapse: forceCollapse, style: containerStyle }, ({ scrollHandler, headerHeight, footerHeight }) => {
        if (shouldDelay && !delayCompleted) {
            return null;
        }
        return (React.createElement(AnimatedScrollView, { ...(isIOS
                ? {
                    // Check this stackoverflow post:
                    // https://stackoverflow.com/a/75070076
                    innerRef(innerRef) {
                        if (ref) {
                            ref.current = innerRef;
                        }
                    },
                }
                : { ref }), style: [styleUtils.containerHeight, style], onScroll: scrollHandler, scrollEventThrottle: 16, onMomentumScrollEnd: onMomentumScrollEnd, contentContainerStyle: [
                contentContainerStyle,
                {
                    paddingTop: headerHeight +
                        ([].concat(contentContainerStyle)[0]
                            ?.paddingTop ?? 0),
                    paddingBottom: footerHeight +
                        bottomOffset +
                        ([].concat(contentContainerStyle)[0]
                            ?.paddingBottom ?? 0),
                },
            ], refreshControl: onRefresh ? (React.createElement(RefreshControl, { refreshing: refreshing, onRefresh: onRefresh, colors: [theme.palette.primary.main], tintColor: theme.palette.primary.main, progressBackgroundColor: theme.palette.background.dark, progressViewOffset: renderCollapsibleHeader ? headerHeight : 0 })) : undefined, enableResetScrollToCoords: false, showsVerticalScrollIndicator: false, keyboardDismissMode: "on-drag", keyboardShouldPersistTaps: "handled", scrollIndicatorInsets: { right: 1 }, bounces: !forceCollapse, ...scrollViewProps }));
    }));
}
export default React.forwardRef(CustomScrollView);
