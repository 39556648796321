import { Extrapolation, interpolate, useAnimatedReaction, useAnimatedStyle, useSharedValue, withTiming, } from 'react-native-reanimated';
export default function useDiffClamp(scroll, collapsibleHeight, direction) {
    const yPos = useSharedValue(0);
    useAnimatedReaction(() => scroll.value, (currentValue, prevValue) => {
        const diff = currentValue.y - (prevValue?.y ?? 0);
        const clamp = (value, min, max) => Math.min(max, Math.max(min, value));
        if (direction === -1) {
            yPos.value = clamp(Math.min(yPos.value + diff, scroll.value.y), 0, collapsibleHeight);
        }
        else {
            let value = yPos.value + diff;
            if (scroll.value.contentHeight -
                (scroll.value.y + scroll.value.layoutHeight) <
                collapsibleHeight) {
                value =
                    scroll.value.contentHeight -
                        (scroll.value.y + scroll.value.layoutHeight);
            }
            yPos.value = clamp(value, 0, collapsibleHeight);
        }
    }, [scroll]);
    const collapse = function () {
        'worklet';
        if (scroll.value.y > yPos.value) {
            const headerVal = yPos.value;
            const headerPointResult = Math.round([0, collapsibleHeight]
                .map(item => {
                'worklet';
                return item - headerVal;
            })
                .reduce((min, dist) => {
                'worklet';
                return Math.abs(dist) < Math.abs(min) ? dist : min;
            }, Infinity) + headerVal);
            yPos.value = withTiming(headerPointResult, {
                duration: 100,
            });
        }
    };
    const yPosStyle = useAnimatedStyle(() => ({
        transform: [
            {
                translateY: interpolate(yPos.value, [0, collapsibleHeight], [0, direction * collapsibleHeight], Extrapolation.CLAMP),
            },
        ],
    }), [collapsibleHeight, direction, yPos]);
    return [yPosStyle, collapse, yPos];
}
