import { ICaseEntityType } from '../graphql/types';
const gqlEntityTypeMap = {
    [ICaseEntityType.COMMUNITY_QUESTION]: 'CommunityQuestion',
    [ICaseEntityType.COMMUNITY_ANSWER]: 'CommunityAnswer',
    [ICaseEntityType.COMMUNITY_REPLY]: 'CommunityReply',
    [ICaseEntityType.EP_WRITTEN_ANSWER]: 'EPAnswerHistoryItem',
    [ICaseEntityType.EP_ORALS_ANSWER]: 'EPAnswerHistoryItem',
};
export default function getGraphQLEntityType(entityType) {
    return gqlEntityTypeMap[entityType];
}
