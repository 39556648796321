import { useEffect, useMemo } from 'react';
import useSearch from 'src/hooks/useSearch';
import { searchList } from 'src/utils/search';
import { useGetAvailableLangListAPI } from '../../../apis/getAvailableList';
export default function useData() {
    const [searchState, searchActions] = useSearch('throttle');
    const [listAPIState, listAPIActions, listAPIHelpers] = useGetAvailableLangListAPI();
    useEffect(() => {
        listAPIActions.clearAndRefresh();
    }, []);
    const filteredLanguages = useMemo(() => {
        if (!listAPIState.data) {
            return [];
        }
        if (!searchState.deferredVal) {
            return listAPIState.data.list;
        }
        return searchList(listAPIState.data.list, ['code', 'label'], searchState.deferredVal);
    }, [listAPIState.data, searchState.deferredVal]);
    const state = {
        search: searchState,
        languages: filteredLanguages,
        listAPI: listAPIState,
    };
    const actions = {
        search: searchActions,
        listAPI: listAPIActions,
    };
    const helpers = {
        listAPI: listAPIHelpers,
    };
    return [state, actions, helpers];
}
