import {AppRegistry} from 'react-native';
import app from './app.json';
import App from './src/App';
import appEvents from './src/modules/10-app/events';

await appEvents.onBoot();
AppRegistry.registerComponent(app.name, () => App);
AppRegistry.runApplication(app.name, {
  initialProps: {},
  rootTag: document.getElementById('root'),
});
