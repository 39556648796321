import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
export function getDefaultHitSlop({ height, width, }) {
    const hitSlop = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    };
    if (height < PRESSABLE_SIZE) {
        const diff = Math.ceil((PRESSABLE_SIZE - height) / 2);
        hitSlop.top = diff;
        hitSlop.bottom = diff;
    }
    if (width < PRESSABLE_SIZE) {
        const diff = (PRESSABLE_SIZE - width) / 2;
        hitSlop.left = diff;
        hitSlop.right = diff;
    }
    return hitSlop;
}
