import { StyleSheet } from 'react-native';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import { isWeb } from 'src/utils/platform';
const fontWeights = {
    [TypographyFontWeight.THIN]: {
        fontFamily: isWeb ? "'Montserrat', sans-serif" : 'Montserrat-ExtraLight',
        fontWeight: '200',
    },
    [TypographyFontWeight.LIGHT]: {
        fontFamily: isWeb ? "'Montserrat', sans-serif" : 'Montserrat-Light',
        fontWeight: '300',
    },
    [TypographyFontWeight.REGULAR]: {
        fontFamily: isWeb ? "'Montserrat', sans-serif" : 'Montserrat-Medium',
        fontWeight: '500',
    },
    [TypographyFontWeight.MEDIUM]: {
        fontFamily: isWeb ? "'Montserrat', sans-serif" : 'Montserrat-SemiBold',
        fontWeight: '600',
    },
    [TypographyFontWeight.BOLD]: {
        fontFamily: isWeb ? "'Montserrat', sans-serif" : 'Montserrat-SemiBold',
        fontWeight: '700',
    },
};
export const getFontWeightStyle = (() => {
    const styles = StyleSheet.create(fontWeights);
    return (fontWeight = TypographyFontWeight.REGULAR) => styles[fontWeight] || styles[TypographyFontWeight.REGULAR];
})();
export const getLineHeightForFontSize = (fontSize) => {
    return Math.ceil(1.5 * fontSize);
};
