import getRequestHandler from '../common/getRequestHandler';
import ReqResChannel from '../core/ReqResChannel';
import { MsgInitiator } from '../types';
import WebModuleAPIs from './modules';
const requestHandler = getRequestHandler(WebModuleAPIs);
export const web2RNCom = new ReqResChannel(MsgInitiator.WEB, msg => {
    window?.ReactNativeWebView?.postMessage?.(JSON.stringify(msg));
}, requestHandler);
function handleMessageEvent(event) {
    if (typeof event.data === 'string') {
        web2RNCom.handleMsg(JSON.parse(event.data));
    }
}
window.addEventListener('message', handleMessageEvent, false);
document.addEventListener('message', handleMessageEvent, false);
