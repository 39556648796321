import React from 'react';
import { Image, Pressable, View } from 'react-native';
import palette from 'src/customizations/themes/10-parts/color-schemes/palette';
import { withOpacity } from 'src/customizations/themes/10-parts/color-schemes/utils';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { isWeb } from 'src/utils/platform';
import BackdropLoader from '../loaders/BackdropLoader';
import Typography from '../typography/Typography';
import { getStyleForSize } from './utils';
const defaultAvatar = require('src/assets/images/defaultAvatar.png');
const Avatar = ({ size, uri, onChange, loading, style, ...imageProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(View, { style: [styles.container, getStyleForSize(size), style] },
        React.createElement(Image, { source: uri ? { uri } : isWeb ? defaultAvatar.default : defaultAvatar, resizeMode: "cover", style: [getStyleForSize(size), styles.avatar], ...imageProps }),
        !!onChange && (React.createElement(Pressable, { style: styles.changeBtn, onPress: onChange },
            React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM, color: palette.grey['900'] }, translate('CHANGE')))),
        loading && React.createElement(BackdropLoader, null)));
};
export const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        overflow: 'hidden',
    },
    avatar: {
        borderWidth: 1,
        borderColor: theme.palette.border.darker,
        backgroundColor: palette.grey['50'],
    },
    changeBtn: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        paddingVertical: theme.spacing.standard.xxs,
        backgroundColor: withOpacity(palette.grey['50'], 0.55),
    },
}));
export default Avatar;
