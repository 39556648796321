import React, { useState } from 'react';
import { Platform, StyleSheet, TextInput } from 'react-native';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { getFontWeightStyle } from '../../typography/utils';
import { isWeb } from 'src/utils/platform';
const TextInputBase = React.forwardRef(({ multiline, minLines = 3, style, ...textInputProps }, ref) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const [inputHeight, setInputHeight] = useState(20);
    return (React.createElement(TextInput, { ref: ref, placeholderTextColor: theme.palette.typography.placeholder, style: [
            styles.input,
            multiline && styles.multilineInput,
            multiline && { height: isWeb ? minLines * 20 : inputHeight },
            isWeb && { outline: 'none' },
            style,
        ], selectionColor: theme.palette.form.input.border.focused, ...(!isWeb && {
            onContentSizeChange: ev => setInputHeight(Math.max(minLines * 20, ev.nativeEvent.contentSize.height) +
                Platform.select({
                    ios: theme.spacing.standard.sm + theme.spacing.standard.md,
                    android: 12,
                    default: 12,
                })),
        }), multiline: multiline, ...textInputProps }));
});
const stylesGenerator = createThemedStyle(({ theme }) => ({
    input: {
        flex: 1,
        minHeight: PRESSABLE_SIZE,
        color: theme.palette.typography.primary,
        ...theme.typography[TypographyVariant.BODY].style,
        ...getFontWeightStyle(theme.typography[TypographyVariant.BODY].fontWeight),
        lineHeight: 16,
        ...Platform.select({
            ios: {
                paddingVertical: theme.spacing.input,
            },
        }),
    },
    multilineInput: StyleSheet.flatten([
        {
            lineHeight: 20,
            verticalAlign: 'top',
        },
        Platform.select({
            ios: {
                paddingTop: theme.spacing.standard.sm,
                paddingBottom: theme.spacing.standard.md,
            },
        }),
    ]),
}));
export default TextInputBase;
