import React from 'react';
import Quill from 'quill';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import HyperLink from '../../../QuillEditor/formats/HyperLink';
import LinkDrawer from '../../components/LinkDrawer';
import PasteLink from '../../../QuillEditor/modules/PasteLink';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
function getHyperLinkAt(quill, range) {
    if (!quill) {
        return;
    }
    const [blot, offset] = quill.getLeaf(range.index);
    if (blot && blot.parent instanceof HyperLink) {
        const startIndex = range.index - offset;
        const length = blot.length();
        const value = HyperLink.value(blot.domNode.parentElement);
        return {
            blot,
            value,
            range: {
                index: startIndex,
                length,
            },
        };
    }
}
function getInitialValue(quill) {
    let title = '';
    let link = '';
    const range = quill.getSelection(true);
    const hyperLink = getHyperLinkAt(quill, range);
    if (hyperLink) {
        quill.setSelection(hyperLink.range);
        title = hyperLink.value.title ?? '';
        link = hyperLink.value.link ?? '';
    }
    else {
        title = quill.getText(range);
    }
    return {
        range,
        initialValue: {
            title,
            link,
        },
    };
}
export default function useLinkActions(quill) {
    const triggerEdit = useCallbackWithCtx(ctx => {
        if (!ctx.quill) {
            return;
        }
        const { range, initialValue } = getInitialValue(ctx.quill);
        bottomDrawerHelperActions.show({
            component: (React.createElement(LinkDrawer, { mode: initialValue.link ? 'edit' : 'create', initialValue: initialValue, onConfirm: value => {
                    bottomDrawerHelperActions.hide();
                    if (ctx.quill) {
                        PasteLink.paste(ctx.quill, value);
                    }
                }, onRemove: () => {
                    bottomDrawerHelperActions.hide();
                    ctx.quill && removeLinkAt(range);
                } })),
        });
    }, { quill });
    const removeLinkAt = useCallbackWithCtx((ctx, range) => {
        if (ctx.quill) {
            const hyperLink = getHyperLinkAt(ctx.quill, range);
            if (hyperLink) {
                ctx.quill.formatText(hyperLink.range, 'hyperLink', false, Quill.sources.USER);
                ctx.quill.setSelection({
                    index: hyperLink.range.index + hyperLink.range.length,
                    length: 0,
                });
                ctx.quill.focus();
            }
        }
    }, { quill });
    return {
        triggerEdit,
    };
}
