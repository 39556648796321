export var IUserRole;
(function (IUserRole) {
    IUserRole[IUserRole["ADMIN"] = 1] = "ADMIN";
    IUserRole[IUserRole["USER"] = 2] = "USER";
    IUserRole[IUserRole["GUEST"] = 3] = "GUEST";
    IUserRole[IUserRole["DUMMY"] = 4] = "DUMMY";
    IUserRole[IUserRole["SUPER_ADMIN"] = 5] = "SUPER_ADMIN";
    IUserRole[IUserRole["COMMUNITY_ADMIN"] = 6] = "COMMUNITY_ADMIN";
    IUserRole[IUserRole["BOT"] = 7] = "BOT";
})(IUserRole || (IUserRole = {}));
export var IUserReputationBadgeRank;
(function (IUserReputationBadgeRank) {
    IUserReputationBadgeRank[IUserReputationBadgeRank["BRONZE"] = 1] = "BRONZE";
    IUserReputationBadgeRank[IUserReputationBadgeRank["SILVER"] = 2] = "SILVER";
    IUserReputationBadgeRank[IUserReputationBadgeRank["GOLD"] = 3] = "GOLD";
})(IUserReputationBadgeRank || (IUserReputationBadgeRank = {}));
export var IUserReputationBadgeType;
(function (IUserReputationBadgeType) {
    IUserReputationBadgeType[IUserReputationBadgeType["ONE_TIME"] = 1] = "ONE_TIME";
    IUserReputationBadgeType[IUserReputationBadgeType["REPETITIVE"] = 2] = "REPETITIVE";
})(IUserReputationBadgeType || (IUserReputationBadgeType = {}));
