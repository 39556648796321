import useDisplayLang from './useDisplayLang';
import useTranslation from './useTranslation';
export default function useI18n() {
    const translate = useTranslation();
    const displayLang = useDisplayLang();
    return {
        translate,
        displayLang,
    };
}
