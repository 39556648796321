import Quill from 'quill';
import { useState } from 'react';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
export default function useFocusHandler(quill, { onFocus, onBlur, }) {
    const [focused, setFocused] = useState(false);
    useEffectWithCtx(ctx => {
        if (quill) {
            /// Implementation ref:
            // https://github.com/slab/quill/blob/5d752e3fa8155a9b1ea1ddd99a9ae2d9a04f0ade/packages/quill/src/modules/toolbar.ts#L64C5-L67C8
            let prevFocused = false;
            const handleChange = () => {
                const currFocused = !!quill.getSelection();
                if (currFocused !== prevFocused) {
                    setFocused(currFocused);
                    if (currFocused) {
                        ctx.onFocus?.();
                    }
                    else {
                        ctx.onBlur?.();
                    }
                    prevFocused = currFocused;
                }
            };
            quill.on(Quill.events.SELECTION_CHANGE, handleChange);
            return () => {
                quill.on(Quill.events.SELECTION_CHANGE, handleChange);
            };
        }
    }, { onFocus, onBlur }, [quill]);
    return [focused];
}
