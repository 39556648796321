import { Linking } from 'react-native';
import { openComposer } from 'react-native-email-link';
import config from 'react-native-ultimate-config';
import { convertSelfSchemeToWebScheme, getQueryString, isSelfDomain, isSelfScheme, } from './utils';
import { isWeb } from 'src/utils/platform';
import Crashlytics from 'src/lib/crashlytics';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { getStateFromPath } from '@react-navigation/native';
import linking from 'src/navigation/linking';
const DeepLink = {
    async open(deeplink, { errorMsg, navigation, }) {
        if (isWeb) {
            // if the deeplink is: marinesamraj://review-pipeline, then
            // convert it to https link and open it
            if (isSelfScheme(deeplink)) {
                const webURL = convertSelfSchemeToWebScheme(deeplink);
                // Check if the WebURL can be opened directly, so that
                // we don't have to wait for the entire web-page load
                // in webView
                if (navigation) {
                    const parsedUrl = new URL(webURL);
                    const path = parsedUrl.pathname + parsedUrl.search;
                    const state = getStateFromPath(path, linking.config);
                    const route = state?.routes[state.index ?? -1];
                    if (route) {
                        navigation.navigate(route.name, route.params);
                    }
                    else {
                        location.href = webURL;
                    }
                }
                else {
                    location.href = webURL;
                }
                // if the deeplink is: https://marinesamraj.com/review-pipeline, then
                // open such links within the same tab
            }
            else if (isSelfDomain(deeplink)) {
                window.location.href = deeplink;
            }
        }
        else {
            try {
                await Linking.openURL(deeplink);
            }
            catch (error) {
                Crashlytics.error(error, 'NotificationDLOpenFailed');
                snackbarHelperActions.show({
                    message: errorMsg,
                    variant: 'NEGATIVE',
                    duration: 'LONG',
                });
            }
        }
    },
    openWhatsapp(initialText) {
        const deeplink = `whatsapp://send?${getQueryString({
            text: initialText,
            phone: config.WA_PHONE_NUMBER,
        })}`;
        return Linking.openURL(deeplink);
    },
    async openComposeMail(options = {}) {
        openComposer({
            to: options.to ?? config.CONTRIBUTION_MAIL_ID,
            body: options.body,
            subject: options.subject,
        });
    },
    async openTalkToUs(regarding) {
        try {
            await DeepLink.openWhatsapp(regarding);
        }
        catch (error) {
            DeepLink.openComposeMail({
                subject: regarding ?? 'I have a Query/Suggestion about Marine Samraj',
            });
        }
    },
};
export default DeepLink;
