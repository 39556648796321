import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import Button from 'src/components/10-atoms/button/Button';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Typography from 'src/components/10-atoms/typography/Typography';
import ULItem from 'src/components/10-atoms/typography/ULItem';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useI18n from 'src/modules/translations/hooks/useI18n';
const BlockUserDrawer = ({ onConfirm }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Block User')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(Typography, { fontWeight: TypographyFontWeight.BOLD, style: { marginBottom: theme.spacing.standard.xxs } }, translate('What happens if you block a User?')),
            React.createElement(ULItem, null, translate('User will not know about this')),
            React.createElement(ULItem, null, translate("You'll not be able to see any content from this User anymore")),
            React.createElement(ULItem, null, translate("You'll not be notified for any content by this User anymore"))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onConfirm }, translate('Confirm')))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({}));
export default BlockUserDrawer;
