import { Platform } from 'react-native';
import { useAnimatedStyle, useSharedValue, useAnimatedReaction, } from 'react-native-reanimated';
import useTheme from 'src/customizations/themes/hooks/useTheme';
export default function useShadowOnScroll(scrollY, options = {}) {
    const { threshold = 5, shouldShowShadow, direction = 'bottom' } = options;
    const [{ theme }] = useTheme();
    const shadowRadius = useSharedValue(0);
    const shadowOffsetHeight = useSharedValue(0);
    const shadowOffsetWidth = useSharedValue(0);
    const borderWidth = useSharedValue(0);
    useAnimatedReaction(() => scrollY.value, value => {
        if (value > threshold) {
            shadowRadius.value = 1.5;
            shadowOffsetHeight.value = 1;
            shadowOffsetWidth.value = 1;
            borderWidth.value = 1;
        }
        else {
            shadowRadius.value = 0;
            shadowOffsetHeight.value = 0;
            shadowOffsetWidth.value = 0;
            borderWidth.value = 0;
        }
    });
    const shadowStyle = useAnimatedStyle(() => {
        const showShadow = shouldShowShadow
            ? shouldShowShadow()
            : scrollY.value > threshold;
        switch (Platform.OS) {
            case 'ios':
                return {
                    shadowColor: '#000000',
                    shadowOpacity: 0.3,
                    shadowRadius: showShadow ? 1.5 : 0,
                    shadowOffset: {
                        height: showShadow ? 1 : 0,
                        width: showShadow ? 1 : 0,
                    },
                };
            default:
                return direction === 'bottom'
                    ? {
                        borderBottomColor: theme.palette.divider.main,
                        borderBottomWidth: showShadow ? 1 : 0,
                    }
                    : {
                        borderTopColor: theme.palette.divider.main,
                        borderTopWidth: showShadow ? 1 : 0,
                    };
        }
    }, [
        direction,
        scrollY,
        shouldShowShadow,
        theme.palette.divider.main,
        threshold,
    ]);
    return shadowStyle;
}
