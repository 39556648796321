import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { useTranslationsContext } from '../../context';
import { resolveTranslationKey, fprintTranslations } from './utils';
export default function useTranslation() {
    const context = useTranslationsContext();
    const translate = useCallbackWithCtx((ctx, key, replacers = {}) => {
        if (!ctx.context) {
            if (__DEV__) {
                throw new Error('You must wrap using TranslationContext.Provider before calling useTranslation()');
            }
        }
        const translatedStr = resolveTranslationKey(ctx.context, key);
        return fprintTranslations(translatedStr, replacers, ctx.context.code);
    }, { context });
    return translate;
}
