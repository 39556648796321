import { useMemo, useRef } from 'react';
import { getFieldState } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import useFormState from 'src/components/10-atoms/form/hooks/useFormState';
import { useQuestionTopicListQuery } from 'src/modules/exam-preparation/graphql/question-topics/getList';
import { isWithinRange } from 'src/utils/math';
import { searchList } from 'src/utils/search';
import { containsOnlyNumeric } from 'src/utils/validate';
import { stripHTMLTags } from 'src/utils/html';
export default function useData(subjectID, initialValue) {
    const [formState, formActions, formHelpers] = useFormState({
        fields: {
            questionHTML: getFieldState(initialValue?.questionHTML ?? ''),
            topics: getFieldState(initialValue?.topics ?? []),
            topicsInput: getFieldState(''),
            marks: getFieldState(String(initialValue?.marks ?? 20)),
        },
    }, {
        fieldValidations: {
            marks: ({ value }) => {
                const marks = Number(value);
                if (!containsOnlyNumeric(value) ||
                    isNaN(marks) ||
                    !isWithinRange(marks, 1, 50)) {
                    return 'Please enter valid marks';
                }
                return true;
            },
            questionHTML: ({ value }) => {
                if (!stripHTMLTags(value).trim().length) {
                    return 'Please add the question';
                }
                return true;
            },
            topics: () => {
                return true;
            },
            topicsInput: () => true,
        },
    });
    const [topicsListQueryState] = useQuestionTopicListQuery(subjectID);
    const suggestedTopics = useMemo(() => {
        const topicsList = topicsListQueryState.data?.ep_questionTopicList.list ?? [];
        const searchInput = formState.fields.topicsInput.value;
        return searchInput.length > 0 && topicsList.length > 0
            ? searchList(topicsList, ['title'], searchInput).map(topic => topic.title)
            : [];
    }, [topicsListQueryState.data, formState.fields.topicsInput.value]);
    const questionEditorRef = useRef(null);
    const state = {
        form: formState,
        questionEditorRef,
        suggestedTopics,
    };
    const actions = {
        form: formActions,
    };
    const helpers = {
        form: formHelpers,
    };
    return [state, actions, helpers];
}
