import { useState } from 'react';
import useDeferredState from '../useDeferredState';
export default function useSearch(deferredFn = 'debounce', interval = 700, initialVal = '') {
    const [value, setValue] = useState(initialVal);
    const [deferredVal, setDeferredVal] = useDeferredState(deferredFn, value, interval);
    const state = {
        value,
        deferredVal,
    };
    const actions = {
        change(val) {
            setValue(val);
            // if (!val || !val.trim()) {
            //   setFinalVal('');
            // } else {
            setDeferredVal(val);
            // }
        },
    };
    return [state, actions];
}
