import React, { useImperativeHandle } from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Column from 'src/components/10-atoms/layout/Column';
import Field from 'src/components/10-atoms/form/Field';
import BreadCrumbsContainer from 'src/components/10-atoms/BreadCrumbs/Container';
import getBreadCrumbs from 'src/modules/exam-preparation/components/EPBreadCrumbs/getBreadCrumbs';
import useData from './useData';
import DateInput from 'src/components/10-atoms/form/inputs/DateInput';
const TWENTY_YEARS = 20 * 365 * 24 * 60 * 60 * 1000;
const MetaDataForm = React.forwardRef(({ subjectID, ...columnProps }, ref) => {
    const [{}] = useTheme(stylesGenerator);
    const [state, actions, helpers] = useData(subjectID);
    useImperativeHandle(ref, () => ({
        form: {
            isValid: () => {
                return actions.form.validateForm();
            },
            getData: () => {
                return {
                    date: state.form.fields.date.value,
                };
            },
        },
    }));
    return (React.createElement(Column, { ...columnProps },
        React.createElement(Field, { label: "Subject" },
            React.createElement(BreadCrumbsContainer, { loading: state.subjectDetailsQuery.loading, crumbs: getBreadCrumbs({}, {
                    ...state.subjectDetailsQuery.data?.ep_subjectDetails
                        .ancestorsDetails,
                    subject: state.subjectDetailsQuery.data?.ep_subjectDetails,
                }), visibleCount: 10 })),
        React.createElement(Field, { label: "Date" },
            React.createElement(DateInput, { placeholder: "Examination date", maxValue: new Date(), minValue: new Date(Date.now() - TWENTY_YEARS), value: state.form.fields.date.value, onChange: newValue => {
                    actions.form.fields.date.setValue(newValue);
                } }))));
});
const stylesGenerator = createThemedStyle(({}) => ({}));
export default MetaDataForm;
