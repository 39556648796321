import usePaginatedRequest from 'src/hooks/request/usePaginatedRequest';
const getAvailableLangListAPIConfig = () => {
    return {
        url: '/translations/available-list',
        useMock: false,
        mockResponse: {
            status: '200',
            headers: {
                vary: 'Origin',
                'request-id': 'f5778a69-b0d7-4a9c-9f2a-18bdffca5505',
                etag: '4110771835',
                'content-type': 'application/json; charset=utf-8',
                'content-length': '204',
                connection: 'keep-alive',
            },
            data: {
                totalCount: 2,
                list: [
                    {
                        code: 'en',
                        label: 'English',
                        fallbackLangs: [],
                        translations: {},
                        dateLocale: 'enUS',
                    },
                    {
                        code: 'kn',
                        label: 'Kannada',
                        fallbackLangs: [],
                        translations: {},
                        dateLocale: 'kn',
                    },
                ],
            },
        },
    };
};
export function useGetAvailableLangListAPI() {
    return usePaginatedRequest(getAvailableLangListAPIConfig);
}
