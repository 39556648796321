import React from 'react';
import { ScrollView } from 'react-native';
import Button from 'src/components/10-atoms/button/Button';
import Field from 'src/components/10-atoms/form/Field';
import { getFieldError } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import NumberInput from 'src/components/10-atoms/form/inputs/NumberInput';
import AutoCompleteTagsInput from 'src/components/10-atoms/form/inputs/TagsInput/AutoCompleteTagsInput';
import Column from 'src/components/10-atoms/layout/Column';
import OverlayLayout from 'src/components/10-atoms/overlays/OverlayLayout';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import useData from './useData';
import HTMLEditorInput from 'src/components/10-atoms/form/inputs/editor/html/HTMLEditor/EditorInput';
const EditQuestionOverlay = ({ question, onClose, onComplete, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const [state, actions] = useData(question);
    useEffectWithCtx(ctx => {
        if (state.updateQuestionMutation.data) {
            snackbarHelperActions.show({
                message: 'Question updated successfully',
                variant: 'POSITIVE',
                duration: 'SHORT',
            });
            ctx.onComplete();
        }
    }, { onComplete }, [state.updateQuestionMutation.data]);
    return (React.createElement(Column, { flex: true },
        React.createElement(OverlayLayout.FormHeader, { title: "Edit Question", onClose: onClose }),
        React.createElement(ScrollView, null,
            React.createElement(Column, { ph: theme.spacing.page, pv: theme.spacing.standard.sm, spacing: theme.spacing.standard.xs, style: { position: 'relative', zIndex: 1 } },
                React.createElement(Field, { label: "Question", error: getFieldError(state.form.fields.questionHTML.errors) },
                    React.createElement(HTMLEditorInput, { ref: state.questionEditorRef, placeholder: "Enter the question", initialValue: state.form.fields.questionHTML.value, onChange: ({ html }) => {
                            actions.form.fields.questionHTML.setValue(html);
                        } })),
                React.createElement(Field, { style: { zIndex: 1 }, label: "Topics", error: getFieldError(state.form.fields.topics.errors) },
                    React.createElement(AutoCompleteTagsInput, { input: state.form.fields.topicsInput.value, onInputChange: actions.form.fields.topicsInput.setValue, value: state.form.fields.topics.value, onChange: actions.form.fields.topics.setValue, suggestions: state.suggestedTopics })),
                React.createElement(Field, { label: "Marks", error: getFieldError(state.form.fields.marks.errors) },
                    React.createElement(NumberInput, { keyboardType: "number-pad", value: state.form.fields.marks.value, onChange: actions.form.fields.marks.setValue }))),
            React.createElement(BottomDrawerLayout.Actions, { pv: theme.spacing.standard.sm },
                React.createElement(Button, { loading: state.updateQuestionMutation.loading, onPress: actions.handleSubmit, flex: true }, "Confirm")),
            React.createElement(Column, { style: { height: 5 * PRESSABLE_SIZE } }))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default EditQuestionOverlay;
