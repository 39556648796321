import Quill from 'quill';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { MathFieldEditor } from '../../../QuillEditor/formats/MathField';
export default function useMathFieldActions(quill) {
    const insert = useCallbackWithCtx(ctx => {
        if (ctx.quill) {
            const range = ctx.quill.getSelection(true);
            ctx.quill.deleteText(range.index, range.length, Quill.sources.USER);
            ctx.quill.insertText(range.index, '\n', Quill.sources.USER);
            ctx.quill.insertEmbed(range.index + 1, MathFieldEditor.blotName, { expr: '' }, Quill.sources.USER);
            ctx.quill.setSelection(range.index + 2, Quill.sources.USER);
        }
    }, { quill });
    return {
        insert,
    };
}
