import React, { useRef, useState } from 'react';
import { Animated } from 'react-native';
import InputLayout from './InputLayout';
import TextInputBase from './TextInputBase';
import { AnimatedTypography } from '../../typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { Easing } from 'react-native-reanimated';
import Column from '../../layout/Column';
const TextInput = React.forwardRef(({ LeftIcon, RightIcon, leftIconColor, rightIconColor, disabled, onChange, onFocus, onBlur, style, containerStyle, maxChars, value, ...rest }, ref) => {
    const [{ theme }] = useTheme();
    const [focused, setFocused] = useState(false);
    const handleFocus = useCallbackWithCtx(() => {
        setFocused(true);
        onFocus && onFocus();
    }, {});
    const handleBlur = useCallbackWithCtx(() => {
        setFocused(false);
        onBlur && onBlur();
    }, {});
    const animatedValue = useRef(new Animated.Value(0)).current;
    const maxCharExceededColorAnimation = animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: [
            theme.palette.typography.secondary,
            theme.palette.negative.main,
        ],
    });
    const maxCharExceededSizeAnimation = animatedValue.interpolate({
        inputRange: [0, 1],
        outputRange: [
            theme.typography[TypographyVariant.CAPTION].style.fontSize,
            theme.typography[TypographyVariant.BODY].style.fontSize,
        ],
    });
    const animationRef = useRef(Animated.loop(Animated.sequence([
        Animated.timing(animatedValue, {
            toValue: 1,
            duration: 300,
            easing: Easing.inOut(Easing.ease),
            useNativeDriver: false,
        }),
        Animated.timing(animatedValue, {
            toValue: 0,
            duration: 300,
            easing: Easing.inOut(Easing.ease),
            useNativeDriver: false,
        }),
    ]), {
        resetBeforeIteration: true,
        iterations: 2,
    }));
    const animateMaxCharLimit = useCallbackWithCtx(() => {
        animationRef.current?.reset();
        animationRef.current?.start();
    }, {});
    const handleChange = useCallbackWithCtx((ctx, newValue) => {
        if (typeof ctx.maxChars === 'number' &&
            newValue.length > ctx.maxChars) {
            animateMaxCharLimit();
        }
        else {
            animationRef.current?.stop();
            animationRef.current?.reset();
            ctx.animatedValue.setValue(0);
            onChange && onChange(newValue);
        }
    }, { value, maxChars, animatedValue });
    return (React.createElement(Column, { spacing: theme.spacing.standard.xxs, style: containerStyle },
        React.createElement(InputLayout, { style: style, LeftIcon: LeftIcon, leftIconColor: leftIconColor, RightIcon: RightIcon, rightIconColor: rightIconColor, disabled: disabled, focused: focused },
            React.createElement(TextInputBase, { ref: ref, onFocus: handleFocus, onBlur: handleBlur, onChangeText: handleChange, readOnly: disabled, value: value, ...rest })),
        typeof maxChars === 'number' && (React.createElement(AnimatedTypography, { style: {
                alignSelf: 'flex-end',
                color: maxCharExceededColorAnimation,
                fontSize: maxCharExceededSizeAnimation,
            }, variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary }, `${(value ?? '').length} / ${maxChars}`))));
});
export default TextInput;
