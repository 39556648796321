import React from 'react';
import HiddenView from '.';
import useHiddenView from './useHiddenView';
import { HelperViewsZStack } from '../constants';
const HiddenViewHelperView = ({}) => {
    const [hiddenViewState] = useHiddenView();
    return (React.createElement(HiddenView, { style: {
            zIndex: HelperViewsZStack.HIDDEN_VIEW,
        } }, hiddenViewState.component));
};
export default HiddenViewHelperView;
