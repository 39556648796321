import getAxiosInstance from './getAxiosInstance';
import addHeaders from './interceptors/addHeaders';
import apiLogger from './interceptors/apiLogger';
import handleInvalidToken from './interceptors/handleToken';
const axios = getAxiosInstance();
// Add interceptors
apiLogger(axios, 'minimal');
// Add interceptors
addHeaders(axios);
// Handles invalid token by logging in again
handleInvalidToken(axios);
export default function APIClient(options) {
    return axios(options);
}
