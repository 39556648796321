import React from 'react';
import EmptyList from 'src/components/templates/EmptyList.template';
import AppAssets from 'src/constants/appAssets';
import useI18n from 'src/modules/translations/hooks/useI18n';
const BlockedUsersListEmpty = ({}) => {
    const { translate } = useI18n();
    return (React.createElement(EmptyList, { message: translate("All Users that you've blocked will appear here"), image: {
            source: {
                uri: AppAssets.blockedUsersEmptyList,
            },
            type: 'image',
        } }));
};
export default BlockedUsersListEmpty;
