import deduplicateItems from './deduplicate';
import { getComputedTotalCount, isSelfComputeListLength } from './selfCompute';
const paginatedListFieldPolicy = (keyArgs) => ({
    keyArgs,
    merge(existing, incoming, { args }) {
        const { pagination: { offset = 0 }, } = args || { pagination: { offset: 0 } };
        // Slicing is necessary because the existing data is
        // immutable, and frozen in development.
        let mergedList = (existing ? existing.list : []).slice(0, offset);
        mergedList.push(...incoming.list);
        mergedList = deduplicateItems(mergedList);
        return {
            totalCount: (existing?.totalCount ?? 0) < 0
                ? getComputedTotalCount(incoming)
                : isSelfComputeListLength(incoming)
                    ? getComputedTotalCount(incoming)
                    : incoming.totalCount,
            list: mergedList,
        };
    },
});
export default paginatedListFieldPolicy;
