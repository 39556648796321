import React from 'react';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import QueryHandler from 'src/components/10-atoms/QueryHandler';
import Column from 'src/components/10-atoms/layout/Column';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import ListLoaderLayout from 'src/components/10-atoms/loaders/ListLoaderLayout';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { useQuestionTopicListQuery } from 'src/modules/exam-preparation/graphql/question-topics/getList';
import TopicItem from '../../../../components/topic-list/TopicItem';
import styleUtils from 'src/utils/styles';
const TopicList = ({ subjectID, onTopicPress }) => {
    const [{ theme, dimensions }] = useTheme(stylesGenerator);
    const [queryState, queryActions] = useQuestionTopicListQuery(subjectID);
    return (React.createElement(CustomScrollView, { containerStyle: styleUtils.flex, contentContainerStyle: {
            paddingBottom: NAVBAR_HEIGHT + PRESSABLE_SIZE + dimensions.safeAreaInsets.bottom,
        }, refreshing: queryState.data && queryState.loading, onRefresh: queryActions.fetch },
        React.createElement(Column, { pv: theme.spacing.standard.sm },
            React.createElement(QueryHandler, { queryState: queryState, ErrorComponent: React.createElement(GenericServerError, { onRetry: queryActions.fetch }), LoaderComponent: React.createElement(ListLoaderLayout, { loaderItemHeight: PRESSABLE_SIZE, count: 5 }), DataComponent: React.createElement(Column, { ph: theme.spacing.page, spacing: theme.spacing.standard.sm }, queryState.data?.ep_questionTopicList.list.map(item => (React.createElement(TopicItem, { key: item.id, data: item, onPress: () => onTopicPress(item) })))) }))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    label: {
        marginLeft: theme.spacing.page,
        marginBottom: theme.spacing.label,
    },
    divider: {
        marginHorizontal: theme.spacing.page,
    },
}));
export default TopicList;
