export var ToolbarAction;
(function (ToolbarAction) {
    ToolbarAction[ToolbarAction["BOLD"] = 1] = "BOLD";
    ToolbarAction[ToolbarAction["ITALIC"] = 2] = "ITALIC";
    ToolbarAction[ToolbarAction["UNORDERED_LIST"] = 3] = "UNORDERED_LIST";
    ToolbarAction[ToolbarAction["ORDERED_LIST"] = 4] = "ORDERED_LIST";
    ToolbarAction[ToolbarAction["IMAGE"] = 5] = "IMAGE";
    ToolbarAction[ToolbarAction["FORMULA"] = 6] = "FORMULA";
    ToolbarAction[ToolbarAction["HYPERLINK"] = 7] = "HYPERLINK";
    ToolbarAction[ToolbarAction["STRIKE_THROUGH"] = 8] = "STRIKE_THROUGH";
    ToolbarAction[ToolbarAction["TABLE"] = 9] = "TABLE";
})(ToolbarAction || (ToolbarAction = {}));
export var ToolbarActionState;
(function (ToolbarActionState) {
    ToolbarActionState[ToolbarActionState["INACTIVE"] = 0] = "INACTIVE";
    ToolbarActionState[ToolbarActionState["ACTIVE"] = 1] = "ACTIVE";
    ToolbarActionState[ToolbarActionState["DISABLED"] = 2] = "DISABLED";
})(ToolbarActionState || (ToolbarActionState = {}));
