import { useNavigation, useRoute } from '@react-navigation/native';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { pageAnalytics } from '../../../analytics';
import useQPQuestionSiblings from './useQPQuestionSiblings';
export default function useData() {
    const route = useRoute();
    const { navigationData } = route.params;
    const { qpID, sequence } = navigationData;
    const currQuestionIndex = sequence;
    const [siblingsQueryState] = useQPQuestionSiblings(qpID, currQuestionIndex +
        2 /* 2 because 1 is for the next sibling and other 1 is to convert index to 1's based */);
    const navigation = useNavigation();
    const prevQuestion = siblingsQueryState.data?.list[currQuestionIndex - 1];
    const nextQuestion = siblingsQueryState.data?.list[currQuestionIndex + 1];
    const handlePrevPress = useCallbackWithCtx(ctx => {
        pageAnalytics.setWidget('QPNavigationBar').setTarget('Prev').click();
        if (ctx.prevQuestion) {
            ctx.navigation.setParams({
                questionID: ctx.prevQuestion.id,
                question: undefined,
                navigatedFrom: 'QP_QUESTIONS',
                navigationData: {
                    qpID: ctx.qpID,
                    sequence: ctx.sequence - 1,
                },
            });
        }
    }, { siblingsQueryState, prevQuestion, navigation, qpID, sequence });
    const handleNextPress = useCallbackWithCtx(ctx => {
        pageAnalytics.setWidget('QPNavigationBar').setTarget('Next').click();
        if (ctx.nextQuestion) {
            ctx.navigation.setParams({
                questionID: ctx.nextQuestion.id,
                question: undefined,
                navigatedFrom: 'QP_QUESTIONS',
                navigationData: {
                    qpID: ctx.qpID,
                    sequence: ctx.sequence + 1,
                },
            });
        }
    }, { siblingsQueryState, navigation, nextQuestion, qpID, sequence });
    const state = {
        prevQuestion,
        nextQuestion,
        siblingQuery: siblingsQueryState,
    };
    const actions = {
        handlePrevPress,
        handleNextPress,
    };
    return [state, actions];
}
