import { useState } from 'react';
export default function useTabs(initialState) {
    const [state, setState] = useState(initialState ?? {
        tabs: [],
        activeIndex: -1,
    });
    const actions = {
        setActiveIndex(activeIndex) {
            setState(prevState => ({
                ...prevState,
                activeIndex,
            }));
        },
        setTabs(tabs) {
            setState(prevState => ({
                ...prevState,
                tabs,
            }));
        },
        setTabCount(tabID, count) {
            setState(prevState => {
                const matchedTab = prevState.tabs.find(tab => tab.id === tabID);
                if (!matchedTab) {
                    return prevState;
                }
                matchedTab.count = count;
                return { ...prevState };
            });
        },
    };
    return [state, actions];
}
