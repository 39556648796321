const borderRadius = {
    button: 4,
    standard: {
        xs: 2,
        sm: 4,
        md: 8,
        lg: 12,
        xl: 16,
        xxl: 20,
        xxxl: 32,
    },
};
export default borderRadius;
