import { getFieldState } from 'src/components/10-atoms/form/hooks/useFieldState/utils';
import useFormState from 'src/components/10-atoms/form/hooks/useFormState';
import { useSubjectDetailsQuery } from 'src/modules/exam-preparation/graphql/subject/getDetails';
export default function useData(subjectID) {
    const [subjectDetailsQueryState] = useSubjectDetailsQuery(subjectID);
    const [formState, formActions, formHelpers] = useFormState({
        fields: {
            date: getFieldState(new Date()),
        },
    }, {
        fieldValidations: {
            date: ({ value }) => {
                if (!value) {
                    return 'Please enter a valid examination date';
                }
                return true;
            },
        },
    });
    const state = {
        form: formState,
        subjectDetailsQuery: subjectDetailsQueryState,
    };
    const actions = {
        form: formActions,
    };
    const helpers = {
        form: formHelpers,
    };
    return [state, actions, helpers];
}
