import useAppState from 'src/hooks/app-level/useAppState';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import useIsLoggedIn from 'src/modules/authentication/hooks/useIsLoggedIn';
import limitCalls from 'src/utils/function/limitCalls';
import { useConfigAPI } from '../api';
import useConfigStorage from './useConfigStorage';
import useEffectRange from 'src/hooks/useEffectUtils/useEffectRange';
import { isWeb } from 'src/utils/platform';
export default function useConfigUpdater() {
    const [, setConfigState] = useConfigStorage();
    const [configAPIState, configAPIActions] = useConfigAPI();
    const isLoggedIn = useIsLoggedIn();
    useEffectRange([1], () => {
        if (isLoggedIn) {
            configAPIActions.fetch();
        }
    }, [isLoggedIn]);
    useAppState((ctx, state) => {
        if (!isWeb) {
            if (ctx.isLoggedIn && state === 'foreground') {
                // Fetch Config only after some delay because
                // the initial network bandwidth should be used for
                // critical render path APIs instead of Config APIs
                limitCalls(configAPIActions.fetch, {
                    duration: __DEV__ ? 5000 : 5 * 60 * 1000,
                    id: 'config_updater',
                })();
            }
        }
    }, { isLoggedIn });
    useEffectWithCtx(ctx => {
        if (configAPIState.data) {
            ctx.setConfigState(configAPIState.data);
        }
    }, { setConfigState }, [configAPIState.data]);
}
