import React from 'react';
import SearchInput from 'src/components/10-atoms/form/inputs/SearchInput';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import RowCenter from 'src/components/10-atoms/layout/RowCenter';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
const PageSearchBar = ({ onBackPress, containerStyle, ...searchInputProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(RowCenter, { bbw: 1, style: styles.container },
        React.createElement(SearchInput, { autoFocus: true, containerStyle: [styleUtils.flex, containerStyle], LeftIcon: props => (React.createElement(FeatherIcon, { name: "chevron-left", ...props, onPress: onBackPress })), ...searchInputProps })));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingHorizontal: theme.spacing.standard.sm,
        paddingVertical: theme.spacing.standard.xs,
        borderBottomWidth: 1,
    },
}));
export default PageSearchBar;
