import React from 'react';
import { Path } from 'react-native-svg';
import SVGIcon from './SVGIcon';
import withDefaultIconProps from './withDefaultIconProps';
const InfoIcon = ({ color, ...rest }) => {
    return (React.createElement(SVGIcon, { ...rest },
        React.createElement(Path, { fillRule: "evenodd", clipRule: "evenodd", d: "M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm0-2a8 8 0 100-16 8 8 0 000 16z", fill: color }),
        React.createElement(Path, { d: "M11 11h2v6h-2v-6zM11 7h2v2h-2V7z", fill: color })));
};
export default withDefaultIconProps(InfoIcon);
