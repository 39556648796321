import Quill from 'quill';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
export default function useChangeHandler(quill, { onChange }) {
    useEffectWithCtx(ctx => {
        if (quill) {
            const handleChange = () => {
                if (ctx.onChange) {
                    ctx.onChange({
                        html: quill.getSemanticHTML(),
                        text: quill.getText(),
                    });
                }
            };
            quill.on(Quill.events.EDITOR_CHANGE, handleChange);
            return () => {
                quill.on(Quill.events.EDITOR_CHANGE, handleChange);
            };
        }
    }, { onChange }, [quill]);
}
