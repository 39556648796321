import React, { useEffect } from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Column from './components/10-atoms/layout/Column';
import HelperViews from './components/20-helper-views/HelperViews';
import withThemeProvider from './customizations/themes/hoc/withThemeProvider';
import withSafeAreaProvider from './hoc/withSafeAreaProvider';
import useUsageTrackers from './hooks/app-level/trackers/useUsageTrackers';
import { withGraphQLClientProvider } from './lib/graphql';
import useNotifications from './lib/notifications/hooks/useNotifications';
import tokenStorage from './modules/authentication/storage/token/storage';
import userStorage from './modules/authentication/storage/user/storage';
import TranslationsProvider from './modules/translations/context/Provider';
import RootNavigation from './navigation/RootNavigation';
import useConfigUpdater from './service/config-service/hooks/useConfigUpdater';
import styleUtils from './utils/styles';
import useInitAnalytics from './lib/analytics/init';
import MUISetup from './web/MUISetup';
const RootView = ({ onNavigationReady }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    useEffect(() => {
        if (__DEV__) {
            const token = tokenStorage.getToken();
            if (token) {
                console.log('User Token:', token);
                console.log('User Roles:', tokenStorage.getRoles());
            }
            const userDetails = userStorage.getUserDetails();
            if (userDetails) {
                console.log('User Details:', userDetails);
            }
        }
    }, []);
    useInitAnalytics();
    useConfigUpdater();
    useUsageTrackers();
    useNotifications();
    return (React.createElement(MUISetup, null,
        React.createElement(GestureHandlerRootView, { style: [
                styleUtils.flex,
                { backgroundColor: theme.palette.background.main },
            ] },
            React.createElement(TranslationsProvider, null,
                React.createElement(Column, { style: [styleUtils.containerHeight, styleUtils.ovfHidden] },
                    React.createElement(RootNavigation, { onReady: onNavigationReady }),
                    React.createElement(HelperViews, null))))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default withThemeProvider(withGraphQLClientProvider(withSafeAreaProvider(RootView)));
