import { useMemo } from 'react';
import useConfigService from 'src/service/config-service/hooks/useConfigService';
import createLookup from 'src/utils/object/createLookup';
import { searchList } from 'src/utils/search';
export default function useFilteredList(topicsStore, searchTerm) {
    const config = useConfigService();
    const filteredTopicsLookup = useMemo(() => {
        const filteredTopics = searchList(Object.values(topicsStore), ['topic'], searchTerm, config.getValue('community.topics.synonyms', {}));
        return createLookup(filteredTopics, 'id');
    }, [topicsStore, searchTerm]);
    const getTopicDetails = (topicID) => topicsStore[topicID];
    const lists = useMemo(() => ({
        all: Object.keys(filteredTopicsLookup).map(getTopicDetails),
        selected: Object.values(topicsStore).filter(topic => topic.selected),
    }), [filteredTopicsLookup, topicsStore]);
    const state = {
        lists,
    };
    return [state];
}
