// import palette from './palette';
// import {ColorShade} from './types';
export const withOpacity = (color, opacity) => {
    const opacityInHex = Math.floor(255 * opacity).toString(16);
    return `${color}${opacityInHex}`;
};
// const shadeOrder = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];
// export const getDarker = (
//   color: keyof typeof palette,
//   shade: ColorShade,
//   darkness = 1,
// ) => {
//   if (palette[color] && typeof palette[color] === 'object') {
//     const shadeIndex = shadeOrder.indexOf(shade);
//     const expectedShadeIndex = shadeIndex + darkness;
//     if (
//       shadeOrder[expectedShadeIndex] &&
//       (palette[color] as any)[expectedShadeIndex]
//     ) {
//       return (palette[color] as any)[expectedShadeIndex];
//     }
//     return (palette[color] as any)[shade];
//   }
//   return palette[color];
// };
// export const getDarkest = (
//   color: keyof typeof palette,
//   // this variable is named as lightness as opposed to darkness
//   // because this represent how light the color should be
//   // from the darkest end
//   lightness = 0,
// ) => {
//   if (palette[color] && typeof palette[color] === 'object') {
//     const expectedShadeIndex = shadeOrder.length - 1 - lightness;
//     return (
//       (palette[color] as any)[expectedShadeIndex] ||
//       (palette[color] as any)[shadeOrder.length - 1]
//     );
//   }
//   return palette[color];
// };
// export const getLightest = (
//   color: keyof typeof palette,
//   // this variable is named as lightness as opposed to darkness
//   // because this represent how light the color should be
//   // from the darkest end
//   darkness = 0,
// ) => {
//   if (palette[color] && typeof palette[color] === 'object') {
//     const expectedShadeIndex = darkness;
//     return (
//       (palette[color] as any)[expectedShadeIndex] || (palette[color] as any)[0]
//     );
//   }
//   return palette[color];
// };
// export const getPrimary = (color: keyof typeof palette) => {
//   if (palette[color] && typeof palette[color] === 'object') {
//     return (
//       (palette[color] as any)[500] ||
//       (palette[color] as any)[shadeOrder.length - 1]
//     );
//   }
//   return palette[color];
// };
