import React from 'react';
import Divider from 'src/components/10-atoms/Divider';
import Column from 'src/components/10-atoms/layout/Column';
import Grid from 'src/components/10-atoms/layout/Grid';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import QuestionPaperItem from './QuestionPaperItem';
const QuestionPaperGroupItem = ({ data, onQPPress, ...columnProps }) => {
    const [{ theme, deviceType }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { spacing: theme.spacing.standard.sm, ph: theme.spacing.page, ...columnProps },
        React.createElement(Typography, { variant: TypographyVariant.BODY_TITLE }, data.label),
        React.createElement(Divider, null),
        React.createElement(Grid, { cols: deviceType.isPhone ? 2 : 3 }, data.questionPapers.map(qp => (React.createElement(QuestionPaperItem, { key: qp.id, data: qp, onPress: () => onQPPress(qp) }))))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    pageMargin: {
        marginHorizontal: theme.spacing.page,
    },
}));
export default QuestionPaperGroupItem;
