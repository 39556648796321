import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import Button from 'src/components/10-atoms/button/Button';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import FitImage from 'src/components/10-atoms/image/FitImage';
import AppAssets from 'src/constants/appAssets';
const AnswerUpdateSuccessDrawer = ({ onConfirm, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Body, { xAxis: "center", spacing: theme.spacing.standard.sm },
            React.createElement(Typography, { variant: TypographyVariant.PAGE_TITLE }, translate('Answer Updated Successfully 🎉')),
            React.createElement(FitImage, { source: { uri: AppAssets.communityAddAnswerSuccess } }),
            React.createElement(Typography, { align: "center" }, translate('We salute your contributions on behalf of Marine Community'))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onConfirm }, translate('Ok')))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default AnswerUpdateSuccessDrawer;
