import { getToken } from 'firebase/messaging';
import { firebaseMessaging } from 'src/web/firebase';
import initFirebase from 'src/web/firebase';
export default function registerTokenListener(onTokenUpdate) {
    initFirebase().then(() => {
        if (firebaseMessaging) {
            getToken(firebaseMessaging, {
                vapidKey: 'BNkP4zume4Rt20wGPZlFEznQA7X7gA2Zk5M6xDaJVHm0HqYG1PqtyQelJT8HFc2ZSFXb9VzYSZfu--kzXX11w70',
            }).then(currentToken => {
                if (currentToken) {
                    onTokenUpdate(currentToken);
                }
            });
        }
    });
}
