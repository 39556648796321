import React from 'react';
import { RootStack } from 'src/navigation/RootNavigation';
import AnswerHistory from './screens/AnswerHistory';
import Home from './screens/Home';
import QP from './screens/QP';
import Question from './screens/Question';
import QuestionSearch from './screens/QuestionSearch';
import Subject from './screens/Subject';
import TopicQuestions from './screens/TopicQuestions';
export const renderExamPreparationStackRoutes = () => {
    return (React.createElement(RootStack.Group, null,
        React.createElement(RootStack.Screen, { name: "EP_Home", component: Home }),
        React.createElement(RootStack.Screen, { name: "EP_Subject", component: Subject }),
        React.createElement(RootStack.Screen, { name: "EP_TopicQuestions", component: TopicQuestions }),
        React.createElement(RootStack.Screen, { name: "EP_QP", component: QP }),
        React.createElement(RootStack.Screen, { name: "EP_Question", component: Question }),
        React.createElement(RootStack.Screen, { name: "EP_AnswerHistory", component: AnswerHistory }),
        React.createElement(RootStack.Screen, { name: "EP_QuestionSearch", component: QuestionSearch })));
};
