import React from 'react';
import { View } from 'react-native';
import Divider from 'src/components/10-atoms/Divider';
import Column from 'src/components/10-atoms/layout/Column';
import Grid from 'src/components/10-atoms/layout/Grid';
import TextLoader from 'src/components/10-atoms/loaders/TextLoader';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const QuestionPaperListLoading = ({ ...columnProps }) => {
    const [{ styles, theme, deviceType }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { spacing: theme.spacing.standard.sm, ...columnProps },
        React.createElement(TextLoader, { style: styles.yearLoader }),
        React.createElement(Divider, null),
        React.createElement(Grid, { cols: deviceType.isPhone ? 2 : 3 }, new Array(4).fill(0).map((_, i) => (React.createElement(View, { style: styles.item, key: i }))))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    yearLoader: {
        width: 72,
    },
    item: {
        height: PRESSABLE_SIZE * 1.5,
        borderRadius: theme.borderRadius.standard.md,
        backgroundColor: theme.palette.loader.placeholder,
    },
}));
export default QuestionPaperListLoading;
