import { useState } from 'react';
import { ebGlobalChannel } from 'src/lib/events-bridge';
import useSubscription from 'src/lib/events-bridge/hooks/useSubscription';
import EBAPIs from 'src/lib/events-bridge/topics';
export default function useHiddenView() {
    const [state, setState] = useState({
        component: null,
    });
    useSubscription(EBAPIs.helperViews.hiddenView.render, (component) => {
        setState({
            component,
        });
    });
    useSubscription(EBAPIs.helperViews.hiddenView.clear, () => {
        setState({
            component: null,
        });
    });
    return [state];
}
export const hiddenViewHelperActions = {
    render(component) {
        ebGlobalChannel.publish(EBAPIs.helperViews.hiddenView.render, component);
    },
    clear() {
        ebGlobalChannel.publish(EBAPIs.helperViews.hiddenView.clear);
    },
};
