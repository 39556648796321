import { useMemo } from 'react';
import { useExamLevelListQuery, } from 'src/modules/exam-preparation/graphql/exam-level/getList';
export default function useData(community) {
    const [listQueryState, listQueryActions] = useExamLevelListQuery(community.id);
    const listQueryVariables = useMemo(() => ({ communityID: community.id }), [community]);
    const state = {
        listQuery: listQueryState,
        listQueryVariables,
    };
    const actions = {
        listQuery: listQueryActions,
    };
    return [state, actions];
}
