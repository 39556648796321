import { getJSONStorage } from 'src/lib/storage';
import StorageKeys from 'src/lib/storage/storageKeys';
import { IUserRole } from '../../apis/signin/types';
import { decodeJWT } from './utils';
const storage = getJSONStorage(StorageKeys.userAuthToken.scope);
const tokenStorage = {
    setToken(token) {
        storage.set(StorageKeys.userAuthToken.key, token);
    },
    getToken() {
        return storage.get(StorageKeys.userAuthToken.key);
    },
    getRoles() {
        const token = this.getToken();
        const payload = decodeJWT(token);
        return payload?.roles ?? [IUserRole.USER];
    },
    getUserID() {
        const token = this.getToken();
        const payload = decodeJWT(token);
        return payload?.sub;
    },
};
export default tokenStorage;
