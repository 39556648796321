import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import withPress from 'src/hoc/withPress';
import Typography from '../typography/Typography';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import { ActivityIndicator } from 'react-native';
import { getButtonTextVariant } from './utils';
import Row from '../layout/Row';
import { renderNamedSlot } from 'src/utils/component';
const TextButton = withPress(({ size = 'md', disabled, loading, children, style, LeftIcon, RightIcon, ...typographyProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return loading ? (React.createElement(ActivityIndicator, null)) : (React.createElement(Row, { spacing: theme.spacing.standard.xxs, yAxis: "center" },
        renderNamedSlot(LeftIcon, {
            size: theme.iconSize.sm,
        }),
        React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM, variant: getButtonTextVariant(size), style: [styles.btnText, disabled && styles.disabledBtnText, style], ...typographyProps }, children),
        renderNamedSlot(RightIcon, {
            size: theme.iconSize.sm,
        })));
});
const stylesGenerator = createThemedStyle(({ theme }) => ({
    btnText: {
        textAlign: 'center',
        color: theme.palette.primary.dark,
    },
    disabledBtnText: {
        color: theme.palette.pressable.disabledText,
    },
}));
export default TextButton;
