import React from 'react';
import DiffFinder from './DiffFinder';
import { hiddenViewHelperActions } from 'src/components/20-helper-views/hidden-view/useHiddenView';
export async function findDiff(oldHTML, newHTML) {
    return new Promise(resolve => {
        hiddenViewHelperActions.render(React.createElement(DiffFinder, { oldHTML: oldHTML, newHTML: newHTML, onDiff: result => {
                resolve(result);
                hiddenViewHelperActions.clear();
            } }));
    });
}
