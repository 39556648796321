import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
const EXAM_LEVEL_DETAILS_QUERY = gql `
  query EP_ExamLevelDetails($examLevelID: ID!) {
    ep_examLevelDetails(examLevelID: $examLevelID) {
      id
      data {
        title
      }
      config {
        EXAM_LEVEL {
          chatRoom {
            link
            iconURL
            text
          }
          written {
            enable
            hasSubjects
            enableMockExams
          }
          orals {
            enable
            hasSubjects
            enableMockExams
          }
        }
      }
    }
  }
`;
export function useExamLevelDetailsQuery(options) {
    return useGQLQuery(EXAM_LEVEL_DETAILS_QUERY, options);
}
