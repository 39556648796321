import React from 'react';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import Button from 'src/components/10-atoms/button/Button';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from '../hooks/useI18n';
import Typography from 'src/components/10-atoms/typography/Typography';
const RequestNewLangSuccessDrawer = ({ lang, onDismiss, }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Submitted your request successfully!')),
        React.createElement(BottomDrawerLayout.Body, null,
            React.createElement(Typography, null, translate('We have noted your request to support %langName language. If we receive a couple more requests, then we will be happy to support the language', { langName: lang.name }))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, onPress: onDismiss }, translate('Okay')))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({}));
export default RequestNewLangSuccessDrawer;
