import { useEffect, useState } from 'react';
import useEffectIf from './useEffectUtils/useEffectIf';
import useId from './useId';
const visibilityQueue = [];
function addToQueue(item) {
    const existingIndex = visibilityQueue.findIndex(i => i.id === item.id);
    if (existingIndex > -1) {
        visibilityQueue.splice(existingIndex, 1);
    }
    visibilityQueue.push(item);
    if (visibilityQueue.length === 1) {
        item.setState(true);
    }
}
function removeFromQueue(id) {
    const [firstItem] = visibilityQueue;
    if (firstItem && firstItem.id === id) {
        firstItem.setState(false);
    }
    const existingIndex = visibilityQueue.findIndex(item => item.id === id);
    if (existingIndex > -1) {
        visibilityQueue.splice(existingIndex, 1);
        if (visibilityQueue.length > 0) {
            const [nextItem] = visibilityQueue;
            if (nextItem) {
                nextItem.setState(true);
            }
        }
    }
}
export default function useQueuedVisibility(visible) {
    const id = useId();
    const [state, setState] = useState(false);
    useEffectIf(visible, () => addToQueue({ id, setState }));
    useEffectIf(!visible, () => setState(false));
    useEffect(() => () => removeFromQueue(id), []);
    const actions = {
        handleHideCompleted: () => removeFromQueue(id),
    };
    return [state, actions];
}
