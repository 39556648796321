import { Delta } from 'quill/core';
var DiffStatus;
(function (DiffStatus) {
    DiffStatus[DiffStatus["INSERTED"] = 1] = "INSERTED";
    DiffStatus[DiffStatus["DELETED"] = 2] = "DELETED";
    DiffStatus[DiffStatus["RETAINED"] = 3] = "RETAINED";
})(DiffStatus || (DiffStatus = {}));
function attributeInsNDel(diffDelta) {
    const diffViewDelta = new Delta();
    for (let i = 0; i < diffDelta.ops.length; i++) {
        let op = diffDelta.ops[i];
        // if the change was an insertion
        if (op.insert) {
            diffViewDelta.insert(op.insert, {
                ...op.attributes,
                diffStatus: DiffStatus.INSERTED,
            });
        }
        else if (op.delete) {
            diffViewDelta.retain(op.delete, {
                ...op.attributes,
                diffStatus: DiffStatus.DELETED,
            });
        }
        else if (op.retain) {
            diffViewDelta.retain(op.retain, {
                ...op.attributes,
                diffStatus: DiffStatus.RETAINED,
            });
        }
    }
    return diffViewDelta;
}
function updateDiffStyles(theme, delta) {
    for (let i = 0; i < delta.ops.length; ++i) {
        const op = delta.ops[i];
        if (op.attributes?.diffStatus) {
            if (op.insert && typeof op.insert !== 'string') {
                op.attributes.padding = `${theme.spacing.standard.md}px`;
                op.attributes.display = 'block';
            }
            if (op.attributes.diffStatus === DiffStatus.INSERTED) {
                op.attributes.background = theme.palette.background.positive;
            }
            else if (op.attributes.diffStatus === DiffStatus.DELETED) {
                op.attributes.background = theme.palette.background.negative;
                op.attributes.strike = true;
            }
        }
    }
    return delta;
}
export function getDiffViewDelta(theme, quill, oldHTML, newHTML) {
    const oldDelta = quill.clipboard.convert({ html: oldHTML });
    const newDelta = quill.clipboard.convert({ html: newHTML });
    const diffDelta = oldDelta.diff(newDelta);
    return updateDiffStyles(theme, oldDelta.compose(attributeInsNDel(diffDelta)));
}
export function getDiffDelta(quill, oldHTML, newHTML) {
    const oldDelta = quill.clipboard.convert({ html: oldHTML });
    const newDelta = quill.clipboard.convert({ html: newHTML });
    return oldDelta.diff(newDelta);
}
export function doesDeltaContainInsOrDel(delta) {
    return delta.ops.some(op => {
        return op.insert || op.delete;
    });
}
