import murmur from 'murmurhash-js';
const seed = 19345;
const getKey = (apiConfig) => murmur.murmur3(JSON.stringify(apiConfig), seed);
const requests = {};
const requestInProgress = {
    add(apiConfig, apiPromise) {
        requests[getKey(apiConfig)] = apiPromise;
    },
    remove(apiConfig) {
        delete requests[getKey(apiConfig)];
    },
    get(apiConfig) {
        return requests[getKey(apiConfig)];
    },
    exists(apiConfig) {
        return getKey(apiConfig) in apiConfig;
    },
};
export default requestInProgress;
