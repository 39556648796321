import React from 'react';
import { Linking } from 'react-native';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import RemoteIcon from 'src/components/10-atoms/icons/RemoteIcon';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const ChatWithUs = ({ data, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const handlePress = () => {
        Linking.openURL(data.link);
    };
    return (React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.standard.xs, ...rowProps },
        React.createElement(Button, { flex: true, variant: "outline", LeftIcon: data.iconURL ? (React.createElement(RemoteIcon, { uri: data.iconURL })) : (React.createElement(FeatherIcon, { name: "message-square", color: theme.palette.primary.main })), onPress: handlePress },
            React.createElement(Typography, null, data.text))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default ChatWithUs;
