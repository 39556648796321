import React, { useCallback } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import EmptyList from 'src/components/templates/EmptyList.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import DeepLink from 'src/modules/10-app/deep-links';
import useI18n from 'src/modules/translations/hooks/useI18n';
const EFNotFound = ({ question }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const handleEmailExaminersFeedback = useCallback(async () => {
        DeepLink.openTalkToUs(`Writing Examiners Feedback for Question - ${question.id}\n`);
    }, []);
    return (React.createElement(EmptyList, { image: {
            type: 'lottie',
            source: require('src/assets/lottie-files/notFound.json'),
        }, message: "" },
        React.createElement(Column, null,
            React.createElement(Typography, { style: styles.message }, translate("We don't have it yet.\nOthers don't have to see this\nif YOU")),
            React.createElement(Button, { variant: "text", onPress: handleEmailExaminersFeedback }, translate("Have Examiner's Feedback?")))));
};
const stylesGenerator = createThemedStyle(() => ({
    container: {},
    message: {
        textAlign: 'center',
    },
}));
export default EFNotFound;
