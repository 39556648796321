export default function getModuleInterfaceAPIs(moduleAPIs, channel) {
    return Object.keys(moduleAPIs).reduce((result, moduleName) => {
        const module = moduleAPIs[moduleName];
        result[moduleName] = Object.keys(module).reduce((apis, apiName) => {
            apis[apiName] = (...args) => channel.request(`${moduleName}.${apiName}`, {
                args,
            });
            return apis;
        }, {});
        return result;
    }, {});
}
