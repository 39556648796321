import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import RowCenter from './layout/RowCenter';
import Typography from './typography/Typography';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
const NumberBadge = ({ count, style, ...rowCenterProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(RowCenter, { style: [styles.badge, style], ...rowCenterProps },
        React.createElement(Typography, { variant: TypographyVariant.CAPTION, fontWeight: TypographyFontWeight.MEDIUM }, translate(count))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    badge: {
        height: 24,
        minWidth: 24,
        borderRadius: 12,
        backgroundColor: theme.palette.background.darker,
        paddingHorizontal: theme.spacing.standard.xs,
    },
}));
export default NumberBadge;
