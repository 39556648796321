import { useMutation } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import useLayoutAnimation from 'src/hooks/animation/useLayoutAnimation';
import getGraphQLErrorMessage from '../../utils/getError';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import authHelperViewActions from 'src/modules/authentication/helperViewActions';
export default function useGQLMutation(query, options = {}) {
    const { 
    // By default show negative snackbars when a mutation
    // request fails
    showError = true, 
    // By default allow only registered user to perform a mutation
    // request
    allowOnlyRegisteredUser = true, 
    // Run Layout animation on Mutation Success
    // Especially useful, when adding/removing items from the list
    animateOnSuccess = false, ...mutationOptions } = options;
    const layoutAnimation = useLayoutAnimation();
    const [submit, { loading, data, error, reset }] = useMutation(query, {
        ...mutationOptions,
        onCompleted(response) {
            if (animateOnSuccess) {
                layoutAnimation.setupLayoutAnimation();
            }
            mutationOptions.onCompleted && mutationOptions.onCompleted(response);
        },
    });
    useEffect(() => {
        if (showError) {
            if (!loading && error) {
                const errorMsg = getGraphQLErrorMessage(error);
                snackbarHelperActions.show({
                    variant: 'NEGATIVE',
                    duration: 'MEDIUM',
                    message: errorMsg,
                });
            }
        }
    }, [error, loading]);
    const handleSubmit = useCallback(async (...args) => {
        if (allowOnlyRegisteredUser) {
            try {
                await authHelperViewActions.getRegisteredUser();
            }
            catch (err) {
                if (err.name === 'USER_REGISTRATION_CANCELLED') {
                    console.log('User cancelled registration process');
                }
                else {
                    console.log('error:', err);
                }
                return;
            }
        }
        try {
            await submit(...args);
        }
        catch (err) {
            console.log('Apollo request failed:', err);
        }
    }, [submit]);
    const state = {
        loading,
        data,
        error,
    };
    const actions = {
        reset,
        delete: handleSubmit,
        update: handleSubmit,
        submit: handleSubmit,
        create: handleSubmit,
    };
    return [state, actions];
}
