import React from 'react';
import ReportAbuseReasonDrawer from '../components/ReportAbuseReasonDrawer';
import ReportAbuseSuccessDrawer from '../components/ReportAbuseSuccessDrawer';
import { useReportAbuseMutation } from '../graphql/reportAbuse';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
export default function useReportAbuse() {
    const [, createMutationActions] = useReportAbuseMutation({
        onCompleted() {
            bottomDrawerHelperActions.show({
                component: (React.createElement(ReportAbuseSuccessDrawer, { onConfirm: bottomDrawerHelperActions.hide })),
            });
        },
    });
    const create = (report) => {
        bottomDrawerHelperActions.show({
            component: (React.createElement(ReportAbuseReasonDrawer, { onConfirm: reason => {
                    bottomDrawerHelperActions.hide();
                    createMutationActions.submit({
                        variables: {
                            report: {
                                ...report,
                                reason,
                            },
                        },
                    });
                } })),
        });
    };
    const state = {};
    const actions = {
        create,
    };
    return [state, actions];
}
