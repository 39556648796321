import React from 'react';
import { datePickerHelperActions } from 'src/components/20-helper-views/date-picker/useDatePicker';
import useDateUtils from 'src/utils/date/useDateUtils';
import FeatherIcon from '../../../icons/FeatherIcon';
import InputLayoutStatic from '../inputLayoutStatic';
const DateInput = ({ minValue, maxValue, value, onChange, ...inputLayoutStaticProps }) => {
    const dateUtils = useDateUtils();
    return (React.createElement(InputLayoutStatic, { focused: false, LeftIcon: props => React.createElement(FeatherIcon, { name: "calendar", ...props }), value: value && dateUtils.formatDate(value, 'READER_FRIENDLY'), onPress: () => {
            datePickerHelperActions.pick({
                value,
                minValue,
                maxValue,
                onChange(newValue) {
                    onChange?.(newValue);
                },
            });
        }, ...inputLayoutStaticProps }));
};
export default DateInput;
