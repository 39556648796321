import { useEffect, useState } from 'react';
export default function useMFFocused(quill) {
    // Capture MathField focus & blur events
    const [mfFocused, setMFFocused] = useState(false);
    useEffect(() => {
        if (quill) {
            const handleMFFocus = (event) => {
                event.stopPropagation();
                setMFFocused(true);
            };
            const handleMFBlur = (event) => {
                event.stopPropagation();
                setMFFocused(false);
            };
            // These  are CustomEvents emitted from MathField blot on focusin & focusout of math field element
            quill.root.addEventListener('mf-focus', handleMFFocus);
            quill.root.addEventListener('mf-blur', handleMFBlur);
            return () => {
                quill.root.removeEventListener('mf-focus', handleMFFocus);
                quill.root.removeEventListener('mf-blur', handleMFBlur);
            };
        }
    }, [quill]);
    return [mfFocused];
}
