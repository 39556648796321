import LottieView from 'lottie-react-native';
import React from 'react';
import { Dimensions } from 'react-native';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import FitImage from '../10-atoms/image/FitImage';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
const defaultImage = {
    type: 'lottie',
    source: require('src/assets/lottie-files/notFound.json'),
};
const EmptyList = ({ image = defaultImage, message, children, style, ...columnProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { ...columnProps, xAxis: "center", spacing: 36, style: [styles.container, style] },
        React.createElement(Column, { xAxis: "center", spacing: 12, style: styles.content },
            image.type === 'image' ? (React.createElement(FitImage, { source: image.source })) : (React.createElement(LottieView, { source: image.source, style: styles.image, autoPlay: true, loop: true })),
            React.createElement(Typography, { variant: TypographyVariant.EMPTY_MSG, style: styles.text }, message),
            children)));
};
const ContentHeight = Math.min(Dimensions.get('window').height - 48 * 2, 300);
const ContentWidth = Math.min(Dimensions.get('window').width - 48 * 2, 300);
const stylesGenerator = createThemedStyle(() => ({
    container: {
        marginTop: 56,
    },
    content: {
        width: ContentWidth,
    },
    image: {
        height: ContentHeight,
        width: ContentWidth,
    },
    text: {
        textAlign: 'center',
    },
}));
export default EmptyList;
