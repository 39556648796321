import { logEvent, setUserId, setUserProperties, setDefaultEventParameters, } from 'firebase/analytics';
import { firebaseAnalytics } from 'src/web/firebase';
const AnalyticsAdaptor = {
    login(userID, properties) {
        setUserId(firebaseAnalytics, userID);
        setUserProperties(firebaseAnalytics, properties);
    },
    logout() {
        this.sendEvent({
            page: 'App',
            target: 'User',
            action: 'logout',
        });
    },
    setDefaultEventProperties(properties) {
        setDefaultEventParameters(properties);
    },
    sendEvent(event, properties) {
        logEvent(firebaseAnalytics, event.action || 'UNKNOWN', {
            ...properties,
            page: event.page,
            widget: event.widget,
            target: event.target,
        });
    },
    sendScreenView(screenName) {
        logEvent(firebaseAnalytics, 'web_screen_view', {
            screen_name: screenName,
        });
    },
};
export default AnalyticsAdaptor;
