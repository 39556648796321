import React from 'react';
import { StyleSheet } from 'react-native';
import Column from 'src/components/10-atoms/layout/Column';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
const HiddenView = ({ children, style, ...colProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return !children ? null : (React.createElement(Column, { style: [StyleSheet.absoluteFill, styles.container, style], ...colProps },
        React.createElement(CustomScrollView, null, children)));
};
const stylesGenerator = createThemedStyle(({ dimensions }) => ({
    container: {
        transform: [
            {
                translateY: dimensions.viewPort.height * 3,
            },
        ],
        width: dimensions.viewPort.width,
        height: dimensions.viewPort.height * 0.5,
    },
}));
export default HiddenView;
