import { useCallback, useEffect, useState } from 'react';
import { debounce, throttle } from 'src/utils/timing';
export default function useDeferredState(deferredFn, value, interval = 300) {
    const [state, setState] = useState(value);
    const updateState = useCallback(deferredFn === 'debounce'
        ? debounce(setState, interval)
        : throttle(setState, interval), []);
    useEffect(() => {
        updateState(value);
    }, [value]);
    return [state, updateState];
}
