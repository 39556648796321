import { getJSONStorage } from '../storage';
import StorageKeys from '../storage/storageKeys';
import { getFTUEKey, isFTUEKey } from './utils';
const storage = getJSONStorage(StorageKeys.ftuePrefix.scope);
const FTUEStorage = {
    UNSAFE_clearAll() {
        storage
            .getAllKeys()
            .filter(isFTUEKey)
            .forEach(ftueKey => {
            storage.delete(ftueKey);
        });
    },
    markComplete(key) {
        storage.set(getFTUEKey(key), true);
    },
    isComplete(key) {
        return storage.get(getFTUEKey(key)) ?? false;
    },
};
export default FTUEStorage;
