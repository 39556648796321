import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import { refetchCommunityQuestionList } from 'src/modules/community/graphql/question/list/queries';
import { GET_BLOCKED_USER_LIST_RAW_QUERY } from './getBlockedUserListRaw';
const BLOCK_USER_MUTATION = gql `
  mutation UGCPolicy_blockUser($userID: String!) {
    ugcPolicy_blockUser(blockedUserID: $userID)
  }
`;
export function useBlockUserMutation(options) {
    return useGQLMutation(BLOCK_USER_MUTATION, {
        animateOnSuccess: true,
        ...options,
        update(cache, {}, { variables }) {
            if (variables?.userID) {
                cache.updateQuery({ query: GET_BLOCKED_USER_LIST_RAW_QUERY }, data => ({
                    ugcPolicy_getBlockedUserListRaw: (data?.ugcPolicy_getBlockedUserListRaw ?? []).concat(variables.userID),
                }));
            }
        },
        refetchQueries: [refetchCommunityQuestionList],
    });
}
