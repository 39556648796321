import { getMonth } from 'date-fns';
import { useCallback } from 'react';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { formatDate, getDayOfWeek, getReadableDate, parseDate } from '.';
export default function useDateUtils() {
    const { displayLang } = useI18n();
    const _formatDate = useCallback((...args) => {
        return formatDate(...args, displayLang.dateLocale);
    }, [displayLang?.dateLocale]);
    const _getReadableDate = useCallback((...args) => {
        return getReadableDate(...args, displayLang.dateLocale);
    }, [displayLang?.dateLocale]);
    return {
        getMonth,
        getDayOfWeek,
        formatDate: _formatDate,
        parseDate,
        getReadableDate: _getReadableDate,
    };
}
