import { MMKV } from 'react-native-mmkv';
import MMKVJSONStorage from './jsonStorage';
import { StorageScope } from './types';
const deviceStorageOptions = {
    id: 'device',
};
const sessionStorageOptions = {
    id: 'session',
};
// Native Storagte
const deviceNativeStorage = new MMKV(deviceStorageOptions);
const sessionNativeStorage = new MMKV(sessionStorageOptions);
export const getNativeStorage = (scope) => scope === StorageScope.DEVICE ? deviceNativeStorage : sessionNativeStorage;
export const UNSAFE_clearAllStorages = () => {
    deviceNativeStorage.clearAll();
    sessionNativeStorage.clearAll();
};
// JSON Storage
const deviceJSONStorage = new MMKVJSONStorage(deviceStorageOptions);
const sessionJSONStorage = new MMKVJSONStorage(sessionStorageOptions);
export const getJSONStorage = (scope) => scope === StorageScope.DEVICE ? deviceJSONStorage : sessionJSONStorage;
