import { getWebNotificationData } from './utils';
import DeepLink from 'src/modules/10-app/deep-links';
import { getNotificationDeeplink } from './utils';
export default async function handleMessageReceived(payload) {
    const notificationData = getWebNotificationData(payload);
    if (notificationData) {
        const notification = new Notification(notificationData.title, notificationData.options);
        notification.addEventListener('click', (event) => {
            event.target.close();
            const dl = getNotificationDeeplink(payload);
            if (dl) {
                DeepLink.open(dl, {
                    errorMsg: 'Sorry, unable to open the notification link',
                });
            }
        });
    }
}
