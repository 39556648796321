export var ProcessorType;
(function (ProcessorType) {
    ProcessorType[ProcessorType["EP_QUESTION_DETAILS"] = 1] = "EP_QUESTION_DETAILS";
})(ProcessorType || (ProcessorType = {}));
export var PlaylistItemState;
(function (PlaylistItemState) {
    PlaylistItemState[PlaylistItemState["IDLE"] = 1] = "IDLE";
    PlaylistItemState[PlaylistItemState["PROCESSING"] = 2] = "PROCESSING";
    PlaylistItemState[PlaylistItemState["READY"] = 3] = "READY";
    PlaylistItemState[PlaylistItemState["PLAYING"] = 4] = "PLAYING";
    PlaylistItemState[PlaylistItemState["PAUSED"] = 5] = "PAUSED";
    PlaylistItemState[PlaylistItemState["SKIPPED"] = 6] = "SKIPPED";
    PlaylistItemState[PlaylistItemState["ENDED"] = 7] = "ENDED";
    PlaylistItemState[PlaylistItemState["ERROR"] = 8] = "ERROR";
})(PlaylistItemState || (PlaylistItemState = {}));
export var PlayerType;
(function (PlayerType) {
    PlayerType[PlayerType["TTS"] = 1] = "TTS";
})(PlayerType || (PlayerType = {}));
export var TTSStatus;
(function (TTSStatus) {
    TTSStatus[TTSStatus["INITIALIZING"] = 1] = "INITIALIZING";
    TTSStatus[TTSStatus["IDLE"] = 2] = "IDLE";
    TTSStatus[TTSStatus["SPEAKING"] = 3] = "SPEAKING";
    TTSStatus[TTSStatus["PAUSED"] = 4] = "PAUSED";
})(TTSStatus || (TTSStatus = {}));
export var PlaylistControllerMode;
(function (PlaylistControllerMode) {
    PlaylistControllerMode[PlaylistControllerMode["HIDDEN"] = 1] = "HIDDEN";
    PlaylistControllerMode[PlaylistControllerMode["MINIMIZED"] = 2] = "MINIMIZED";
    PlaylistControllerMode[PlaylistControllerMode["NORMAL"] = 3] = "NORMAL";
    PlaylistControllerMode[PlaylistControllerMode["MAXIMIZED"] = 4] = "MAXIMIZED";
})(PlaylistControllerMode || (PlaylistControllerMode = {}));
