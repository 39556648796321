import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import TextInput from './TextInput';
import CloseIcon from '../../icons/CloseIcon';
import SearchIcon from '../../icons/SearchIcon';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
const SearchInput = ({ onClear, value, style, ...textInputProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(TextInput, { style: [styles.container, style], ...textInputProps, value: value, enterKeyHint: "search", returnKeyLabel: "Search", RightIcon: props => (value?.length ?? 0) > 0 ? (React.createElement(CloseIcon, { ...props, onPress: onClear })) : (React.createElement(SearchIcon, { ...props })) }));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        borderRadius: PRESSABLE_SIZE / 2,
        paddingHorizontal: theme.spacing.page,
    },
}));
export default SearchInput;
