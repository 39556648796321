/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import { RootTab } from 'src/navigation/TabNavigation';
import withSuspense from 'src/navigation/withSuspense';
import useI18n from '../translations/hooks/useI18n';
const Home = withSuspense(React.lazy(() => import('./screens/Home')));
export const renderExamPreparationTabRoutes = () => {
    const { translate } = useI18n();
    return (React.createElement(RootTab.Group, null,
        React.createElement(RootTab.Screen, { name: "EP_Home", options: {
                tabBarLabel: translate('Exams'),
                tabBarIcon: props => React.createElement(FeatherIcon, { name: "edit-3", ...props }),
            }, component: Home })));
};
