import { useNavigation, useRoute } from '@react-navigation/native';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { pageAnalytics } from '../../../analytics';
import useTopicQuestionSiblings from './useTopicQuestionSiblings';
export default function useData() {
    const navigation = useNavigation();
    const route = useRoute();
    const { navigationData } = route.params;
    const { ancestorID, topic, sequence } = navigationData;
    const [siblingsQueryState] = useTopicQuestionSiblings(ancestorID, topic, sequence + 2);
    const currQuestionIndex = sequence;
    const prevQuestion = siblingsQueryState.data?.list[currQuestionIndex - 1];
    const nextQuestion = siblingsQueryState.data?.list[currQuestionIndex + 1];
    const handlePrevPress = useCallbackWithCtx(ctx => {
        pageAnalytics.setWidget('TopicNavigationBar').setTarget('Prev').click();
        if (ctx.prevQuestion) {
            ctx.navigation.setParams({
                questionID: ctx.prevQuestion.id,
                question: undefined,
                navigatedFrom: 'TOPIC_QUESTIONS',
                navigationData: {
                    ancestorID: ctx.ancestorID,
                    topic: ctx.topic,
                    sequence: ctx.sequence - 1,
                },
            });
        }
    }, { siblingsQueryState, prevQuestion, navigation, sequence, topic, ancestorID });
    const handleNextPress = useCallbackWithCtx(ctx => {
        pageAnalytics.setWidget('TopicNavigationBar').setTarget('Next').click();
        if (ctx.nextQuestion) {
            ctx.navigation.setParams({
                questionID: ctx.nextQuestion.id,
                question: undefined,
                navigatedFrom: 'TOPIC_QUESTIONS',
                navigationData: {
                    ancestorID: ctx.ancestorID,
                    topic: ctx.topic,
                    sequence: ctx.sequence + 1,
                },
            });
        }
    }, { siblingsQueryState, navigation, nextQuestion, topic, sequence, ancestorID });
    const state = {
        prevQuestion,
        nextQuestion,
        siblingQuery: siblingsQueryState,
    };
    const actions = {
        handlePrevPress,
        handleNextPress,
    };
    return [state, actions];
}
