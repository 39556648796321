import { useUserReputationQuery } from '../../graphql/profile/getReputation';
export default function useData() {
    const [reputationQueryState, reputationQueryActions] = useUserReputationQuery();
    const state = {
        reputationQuery: reputationQueryState,
    };
    const actions = {
        reputationQuery: reputationQueryActions,
    };
    return [state, actions];
}
