import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
import Row from '../../10-atoms/layout/Row';
export const NAVBAR_HEIGHT = 48;
const Navbar = React.forwardRef(({ style, ...rest }, ref) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { ref: ref, yAxis: "center", xAxis: "space-between", spacing: 12, style: [styles.container, styleUtils.containerWidth, style], ...rest }));
});
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        height: NAVBAR_HEIGHT,
        backgroundColor: theme.palette.background.main,
    },
}));
export default Navbar;
