import last from 'lodash/last';
import useHookList from 'src/hooks/useHookList';
const getInitialItemState = () => ({
    questionHTML: '',
    question: '',
    marks: 20,
    topics: [],
});
const INITIAL_LIST_STATE = [getInitialItemState()];
export default function useData() {
    const [formListState, formListActions] = useHookList(INITIAL_LIST_STATE);
    const state = {
        formList: formListState,
    };
    const actions = {
        addQuestion: () => {
            const newQuestion = getInitialItemState();
            formListActions.addItem({
                ...newQuestion,
                marks: Number(last(formListState)?.ref.current?.state.form.fields.marks.value ??
                    newQuestion.marks),
            });
        },
        removeQuestion: formListActions.removeItem,
    };
    return [state, actions];
}
