import parseURL from 'src/utils/parseURL';
export function getQueryString(options) {
    return Object.entries(options)
        .reduce((result, [key, value]) => {
        if (value !== undefined) {
            result.push(`${key}=${encodeURIComponent(value)}`);
        }
        return result;
    }, [])
        .join('&');
}
export function removeQueryParams(deeplink) {
    return deeplink.split('?')[0];
}
export function isSelfDomain(url) {
    const parsedUrl = parseURL(url);
    return [
        'localhost',
        'marinesamraj.com',
        'www.marinesamraj.com',
        'preprod.marinesamraj.com',
    ].includes(parsedUrl.hostname);
}
export function isSelfScheme(url) {
    return url.startsWith('marinesamraj://');
}
export function convertSelfSchemeToWebScheme(url) {
    return url.replace(/^marinesamraj:\//, window.location.origin);
}
