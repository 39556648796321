import Quill from 'quill';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { ToolbarAction, ToolbarActionState } from '../../types';
import { toggleActiveState } from '../../utils';
import useActionsState from './useActionsState';
import useImagePicker from './useImagePicker';
import useKeyboardShortcuts from './useKeyboardShortcuts';
import useLinkActions from './useLinkActions';
import useMathFieldActions from './useMathFieldActions';
import useMFFocused from './useMFFocused';
export default function useToolbar(quill) {
    useKeyboardShortcuts(quill);
    const [actionsState, setActionsState] = useActionsState(quill);
    const [mfFocused] = useMFFocused(quill);
    const mathFieldActions = useMathFieldActions(quill);
    const linkActions = useLinkActions(quill);
    const imagePickerActions = useImagePicker(quill);
    const handleActionPress = useCallbackWithCtx(async (ctx, action) => {
        switch (action) {
            case ToolbarAction.BOLD: {
                const newState = toggleActiveState(ctx.actionsState.bold);
                ctx.quill?.format('bold', newState, Quill.sources.USER);
                setActionsState(prevState => ({
                    ...prevState,
                    bold: newState,
                }));
                break;
            }
            case ToolbarAction.ITALIC: {
                const newState = toggleActiveState(ctx.actionsState.italic);
                ctx.quill?.format('italic', newState, Quill.sources.USER);
                setActionsState(prevState => ({
                    ...prevState,
                    italic: newState,
                }));
                break;
            }
            case ToolbarAction.UNORDERED_LIST: {
                const newState = toggleActiveState(ctx.actionsState.unorderedList);
                if (newState === ToolbarActionState.ACTIVE) {
                    ctx.quill?.format('list', 'bullet', Quill.sources.USER);
                }
                else {
                    ctx.quill?.format('list', false, Quill.sources.USER);
                }
                setActionsState(prevState => ({
                    ...prevState,
                    unorderedList: newState,
                    orderedList: ToolbarActionState.INACTIVE,
                }));
                break;
            }
            case ToolbarAction.ORDERED_LIST: {
                const newState = toggleActiveState(ctx.actionsState.orderedList);
                if (newState === ToolbarActionState.ACTIVE) {
                    ctx.quill?.format('list', 'ordered', Quill.sources.USER);
                }
                else {
                    ctx.quill?.format('list', false, Quill.sources.USER);
                }
                setActionsState(prevState => {
                    return {
                        ...prevState,
                        orderedList: newState,
                        unorderedList: ToolbarActionState.INACTIVE,
                    };
                });
                break;
            }
            case ToolbarAction.IMAGE: {
                ctx.imagePickerActions.pick();
                break;
            }
            case ToolbarAction.HYPERLINK: {
                ctx.linkActions.triggerEdit();
                break;
            }
            case ToolbarAction.FORMULA: {
                ctx.mathFieldActions.insert();
                break;
            }
            case ToolbarAction.TABLE: {
                const betterTable = ctx.quill?.getModule('better-table');
                betterTable?.insertTable(3, 3);
                break;
            }
        }
    }, { actionsState, quill, linkActions, mathFieldActions, imagePickerActions });
    return [
        { actionsState, mfFocused },
        {
            handleActionPress,
        },
    ];
}
