import React from 'react';
import TimerButton from 'src/components/10-atoms/button/TimerButton';
import ULItemLayout from 'src/components/10-atoms/content/ULItemLayout';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const WalkthroughDrawer = ({ dismissable, onHide, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, translate('Feature walkthrough')),
        React.createElement(BottomDrawerLayout.Body, { spacing: theme.spacing.standard.md },
            React.createElement(ULItemLayout, null,
                React.createElement(Column, { flex: true },
                    React.createElement(Row, { bg: theme.palette.background.negative, style: { height: 24 } }),
                    React.createElement(Typography, null, translate('This indicates that the line was Removed')))),
            React.createElement(ULItemLayout, null,
                React.createElement(Column, { flex: true },
                    React.createElement(Row, { bg: theme.palette.background.positive, style: { height: 24 } }),
                    React.createElement(Typography, null, translate('This indicates that the line was Added')))),
            React.createElement(ULItemLayout, null,
                React.createElement(Column, { flex: true },
                    React.createElement(Row, { bg: theme.palette.background.negative, style: { height: 24 } }),
                    React.createElement(Row, { bg: theme.palette.background.positive, style: { height: 24 } }),
                    React.createElement(Typography, null, translate('This indicates that the line was Modified')))),
            React.createElement(ULItemLayout, null,
                React.createElement(Typography, { flex: true }, translate('By default, we display only the difference between what was existing and what was added/removed. However you can use the Switch to view the entire Answer')))),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(TimerButton, { flex: true, enableAfter: dismissable ? 0 : 5, onPress: onHide }, translate('Ok')))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default WalkthroughDrawer;
