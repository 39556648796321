import React from 'react';
import { FlatList, RefreshControl } from 'react-native';
import Animated from 'react-native-reanimated';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useDelay from 'src/hooks/useDelay';
import CollapsibleList from './CollapsibleList';
const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);
function CustomFlatList({ 
// Collapsible list props
renderCollapsibleHeader, collapsibleHeaderHeight, renderCollapsibleFooter, collapsibleFooterHeight, showCollapsibleShadow, forceCollapse, 
// Flatlist props
onMomentumScrollEnd = () => { }, onRefresh, refreshing, contentContainerStyle, containerStyle, initialRenderDelay, ...flatListProps }) {
    const [{ theme, dimensions: { safeAreaInsets }, },] = useTheme();
    const delayCompleted = useDelay(initialRenderDelay ?? 50);
    const shouldDelay = typeof initialRenderDelay === 'number';
    return (React.createElement(CollapsibleList, { style: containerStyle, renderCollapsibleHeader: renderCollapsibleHeader, collapsibleHeaderHeight: collapsibleHeaderHeight, renderCollapsibleFooter: renderCollapsibleFooter, collapsibleFooterHeight: collapsibleFooterHeight, showCollapsibleShadow: showCollapsibleShadow, forceCollapse: forceCollapse }, ({ scrollHandler, headerHeight, footerHeight }) => {
        if (shouldDelay && !delayCompleted) {
            return null;
        }
        // Wait until header height is determined
        if (renderCollapsibleHeader && headerHeight === 0) {
            return null;
        }
        // Wait until footer height is determined
        if (renderCollapsibleFooter && footerHeight === 0) {
            return null;
        }
        return (React.createElement(AnimatedFlatList, { onScroll: scrollHandler, scrollEventThrottle: 16, contentContainerStyle: [
                contentContainerStyle,
                {
                    paddingTop: headerHeight +
                        ([].concat(contentContainerStyle)[0]
                            ?.paddingTop ?? 0),
                    paddingBottom: footerHeight +
                        safeAreaInsets.bottom +
                        ([].concat(contentContainerStyle)[0]
                            ?.paddingBottom ?? 0),
                },
            ], onMomentumScrollEnd: onMomentumScrollEnd, refreshControl: onRefresh ? (React.createElement(RefreshControl, { refreshing: refreshing, onRefresh: onRefresh, colors: [theme.palette.primary.main], tintColor: theme.palette.primary.main, progressViewOffset: renderCollapsibleHeader ? headerHeight : 0 })) : undefined, keyboardDismissMode: "on-drag", keyboardShouldPersistTaps: "handled", scrollIndicatorInsets: { right: 1 }, ...flatListProps }));
    }));
}
export default CustomFlatList;
