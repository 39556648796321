import { useCallback, useState } from 'react';
import { ebGlobalChannel } from 'src/lib/events-bridge';
import useSubscription from 'src/lib/events-bridge/hooks/useSubscription';
import EBAPIs from 'src/lib/events-bridge/topics';
const getInitialState = () => ({
    visible: false,
    images: [],
    initialIndex: 0,
});
export default function useImageViewer() {
    const [state, setState] = useState(getInitialState());
    const show = useCallback((imageViewerData) => {
        setState({
            ...imageViewerData,
            visible: true,
            initialIndex: 0,
        });
    }, []);
    const hide = useCallback(() => {
        setState(getInitialState());
    }, []);
    useSubscription(EBAPIs.helperViews.imageViewer.show, show);
    useSubscription(EBAPIs.helperViews.imageViewer.hide, hide);
    const actions = {
        show,
        hide,
    };
    return [state, actions];
}
export const imageViewHelperActions = {
    show(data) {
        ebGlobalChannel.publish(EBAPIs.helperViews.imageViewer.show, data);
    },
    hide() {
        ebGlobalChannel.publish(EBAPIs.helperViews.imageViewer.hide);
    },
};
