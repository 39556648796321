import { format } from 'date-fns';
import { getQuestionLabel } from '../../containers/QuestionList/utils';
import { NodeType } from '../../graphql/common/types/node';
export default function getBreadCrumbs(ancestorsDetails, knownNodes, options = {}) {
    const communityTitle = knownNodes?.community?.data.title ??
        ancestorsDetails?.community?.data.title;
    const examLevelTitle = knownNodes?.examLevel?.data.title ??
        ancestorsDetails?.examLevel?.data.title;
    const subject = knownNodes?.subject ??
        ancestorsDetails?.writtenSubject ??
        ancestorsDetails?.oralsSubject;
    const subjectTitle = subject?.data.title;
    const topic = knownNodes?.topic;
    const qp = ancestorsDetails?.writtenQP ?? ancestorsDetails?.oralsQP ?? knownNodes?.qp;
    const qpTitle = [
        qp?.type === NodeType.ORALS_QP
            ? format(new Date(qp.data.date), 'MMM yyyy')
            : undefined,
        qp?.data?.label,
    ];
    const question = knownNodes?.question;
    const path = [
        communityTitle,
        examLevelTitle,
        subjectTitle,
        topic,
        qpTitle,
        question && getQuestionLabel(question),
    ]
        .flat()
        .filter(part => !!part && part !== '__DEFAULT__');
    return options.visibleCount ? path.slice(-options.visibleCount) : path;
}
