import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useI18n from '../hooks/useI18n';
import TranslatedIndicator from './TranslatedIndicator';
import Badge from 'src/components/10-atoms/badge/Badge';
const TranslatedBadge = ({ style, ...badgeProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(Badge, { style: [styles.container, style], ...badgeProps, bg: theme.palette.background.main },
        React.createElement(TranslatedIndicator, null),
        React.createElement(Typography, { color: theme.palette.typography.primary, fontWeight: TypographyFontWeight.MEDIUM, variant: TypographyVariant.CAPTION }, translate('Translated'))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        borderWidth: 1,
        borderColor: theme.palette.border.dark,
    },
}));
export default TranslatedBadge;
