import React, { useCallback } from 'react';
import { overlayHelperViewActions } from 'src/components/20-helper-views/overlay/useOverlay';
import { ebGlobalChannel } from 'src/lib/events-bridge';
import useSubscription from 'src/lib/events-bridge/hooks/useSubscription';
import EBAPIs from 'src/lib/events-bridge/topics';
import TopicPickerOverlay from '.';
export default function useTopicPicker() {
    const show = useCallback(({ onChange, ...rest }) => {
        overlayHelperViewActions.show({
            component: (React.createElement(TopicPickerOverlay, { ...rest, onClose: overlayHelperViewActions.hide, onChange: newTopics => {
                    overlayHelperViewActions.hide();
                    onChange(newTopics);
                } })),
        });
    }, []);
    useSubscription(EBAPIs.helperViews.topicPicker.show, show);
}
export const topicPickerHelperViewActions = {
    show(data) {
        ebGlobalChannel.publish(EBAPIs.helperViews.topicPicker.show, data);
    },
};
