import { gql } from '@apollo/client';
import { addTypePolicies } from 'src/lib/graphql/cache';
import useGQLPaginatedQuery from 'src/lib/graphql/hooks/useGQLPaginatedQuery';
import paginatedListFieldPolicy from 'src/lib/graphql/utils/paginatedListFieldPolicy';
import { EP_QUESTION_DATA_FIELDS } from './getQuestionDetails';
const QUESTION_LIST_FOR_TOPIC_QUERY = gql `
  query EP_QuestionListForTopic(
    $topic: String!
    $ancestorID: ID!
    $pagination: PaginationOptions!
  ) {
    ep_questionListForTopic(
      topic: $topic
      ancestorID: $ancestorID
      pagination: $pagination
    ) {
      totalCount
      list {
        id
        type
        data {
          ...EPQuestionDataFields
        }
        translationInfo {
          sourceLang
          targetLang
        }
        questionPaper {
          data {
            date
            label
          }
        }
        duplicates {
          questionPaper {
            data {
              date
              label
            }
          }
        }
      }
    }
  }

  ${EP_QUESTION_DATA_FIELDS}
`;
export function useQuestionListForTopicQuery(options) {
    return useGQLPaginatedQuery(QUESTION_LIST_FOR_TOPIC_QUERY, 'ep_questionListForTopic', options);
}
addTypePolicies({
    Query: {
        fields: {
            ep_questionListForTopic: paginatedListFieldPolicy([
                'topic',
                'ancestorID',
            ]),
        },
    },
});
