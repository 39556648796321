import { getJSONStorage } from 'src/lib/storage';
import StorageKeys from 'src/lib/storage/storageKeys';
import { DEFAULT_STATE } from './constants';
const storage = getJSONStorage(StorageKeys.ttsPreferences.scope);
export const ttsPreferencesStorage = {
    set(state) {
        storage.set(StorageKeys.ttsPreferences.key, state);
    },
    get() {
        return storage.get(StorageKeys.ttsPreferences.key) || DEFAULT_STATE;
    },
    setProp(prop, value) {
        const state = ttsPreferencesStorage.get();
        ttsPreferencesStorage.set({
            ...state,
            [prop]: value,
        });
    },
    getProp(prop) {
        const state = ttsPreferencesStorage.get();
        return state[prop];
    },
    setVoiceId(voiceId) {
        ttsPreferencesStorage.setProp('voiceId', voiceId);
    },
    getVoiceId() {
        return ttsPreferencesStorage.getProp('voiceId');
    },
    setRate(rate) {
        ttsPreferencesStorage.setProp('rate', rate);
    },
    getRate() {
        return ttsPreferencesStorage.getProp('rate');
    },
};
