import React, { useCallback } from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import { ISelectionType } from '../SelectInput/types';
import SelectInputItem from './SelectInputItem';
const SelectInputGroup = ({ type = ISelectionType.SINGLE, value, options, onChange, ...columnProps }) => {
    const isItemSelected = useCallback((option) => {
        if (type === ISelectionType.SINGLE) {
            return option.id === value?.id;
        }
        return !!(value || []).find(item => item.id === option.id);
    }, [type, value]);
    const handleChange = useCallback((option) => {
        if (type === ISelectionType.SINGLE) {
            onChange(option);
        }
        else {
            const isSelected = isItemSelected(option);
            if (isSelected) {
                // Remove from Selected List
                onChange((value || []).filter(item => item.id !== option.id));
            }
            else {
                // Add this to the selected items list
                onChange([
                    ...(value || []),
                    option,
                ]);
            }
        }
    }, [isItemSelected, onChange, type, value]);
    return (React.createElement(Column, { ...columnProps }, options.map(option => {
        const { id, ...rest } = option;
        return (React.createElement(SelectInputItem, { ...rest, key: id, type: type, isSelected: isItemSelected(option), onPress: () => handleChange(option) }));
    })));
};
export default SelectInputGroup;
