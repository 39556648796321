import React from 'react';
import { Path } from 'react-native-svg';
import withDefaultIconProps from './withDefaultIconProps';
import SVGIcon from './SVGIcon';
const EditLinkIcon = ({ color, ...rest }) => {
    return (React.createElement(SVGIcon, { ...rest },
        React.createElement(Path, { fill: color, d: "M3.883 4.883a3.636 3.636 0 0 1 2.572-1.065h2.727a.91.91 0 1 0 0-1.818H6.455a5.455 5.455 0 1 0 0 10.91h2.727a.91.91 0 1 0 0-1.82H6.455a3.637 3.637 0 0 1-2.572-6.207Z" }),
        React.createElement(Path, { fill: color, d: "M15.545 8.364H8.273a.91.91 0 1 1 0-1.819h7.272a.91.91 0 1 1 0 1.819ZM20.884 8.364h1.858a5.46 5.46 0 0 0-1.521-4.766A5.454 5.454 0 0 0 17.364 2h-2.728a.91.91 0 0 0 0 1.818h2.728a3.636 3.636 0 0 1 3.52 4.546Z" }),
        React.createElement(Path, { fill: color, fillRule: "evenodd", d: "M21.262 9.273a2.465 2.465 0 0 0-1.743.722l-7.427 7.427a.91.91 0 0 0-.235.404l-.825 3.026a.91.91 0 0 0 1.116 1.116l3.026-.825a.91.91 0 0 0 .404-.235l7.427-7.427a2.466 2.466 0 0 0-1.743-4.208Zm-.248 1.867a.646.646 0 0 1 .706 1.055l-7.257 7.257-1.258.343.343-1.258 7.257-7.257a.646.646 0 0 1 .21-.14Z", clipRule: "evenodd" })));
};
export default withDefaultIconProps(EditLinkIcon);
