import markdownItMathPlugin from 'markdown-it-math';
import React from 'react';
import Markdown, { MarkdownIt, renderRules, } from 'react-native-markdown-display';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Column from '../../layout/Column';
import RowCenter from '../../layout/RowCenter';
import Typography from '../../typography/Typography';
import { getFontWeightStyle, getLineHeightForFontSize, } from '../../typography/utils';
import ImageRenderer from './ImageRenderer';
const rules = {
    ...renderRules,
    text(node, _children, _parent, styles, inheritedStyles) {
        const { fontWeight, ...restStyles } = inheritedStyles ?? {};
        return (React.createElement(Typography, { fontWeight: fontWeight === 'bold'
                ? TypographyFontWeight.MEDIUM
                : TypographyFontWeight.REGULAR, style: [
                styles.text,
                restStyles,
                inheritedStyles?.fontSize || styles?.fontSize
                    ? {
                        lineHeight: getLineHeightForFontSize(inheritedStyles?.fontSize || styles?.fontSize),
                    }
                    : {},
            ] }, node.content));
    },
    strong: (node, children) => (React.createElement(Typography, { key: node.key, fontWeight: TypographyFontWeight.MEDIUM }, children)),
    image(node, _children, _parent, styles) {
        return (React.createElement(RowCenter, null,
            React.createElement(ImageRenderer, { key: node.key, node: node, styles: styles })));
    },
};
const markdownItInstance = MarkdownIt({ typographer: true, linkify: true }).use(markdownItMathPlugin);
const MDRenderer = ({ children, ...columnProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { ...columnProps },
        React.createElement(Markdown, { style: styles, rules: rules, markdownit: markdownItInstance }, children)));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    text: {
        color: theme.palette.typography.primary,
        ...getFontWeightStyle(TypographyFontWeight.REGULAR),
        letterSpacing: 0.5,
    },
    paragraph: {
        marginTop: 0,
        marginBottom: 0,
        lineHeight: 24,
        letterSpacing: 0.5,
    },
    listUnorderedItemText: {
        lineHeight: 24,
        letterSpacing: 0.5,
    },
    list_item: {
        marginBottom: 8,
        lineHeight: 24,
        color: theme.palette.typography.primary,
    },
    bullet: {
        color: theme.palette.typography.primary,
    },
}));
export default MDRenderer;
