import React from 'react';
import { Linking } from 'react-native';
import Typography from 'src/components/10-atoms/typography/Typography';
import AppAssets from 'src/constants/appAssets';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
const SigninTnC = ({ ...typographyProps }) => {
    const [{ theme }] = useTheme();
    const { translate } = useI18n();
    return (React.createElement(Typography, { ...typographyProps },
        translate('By signing in, you agree to '),
        React.createElement(Typography, { style: { color: theme.palette.typography.link }, onPress: () => {
                Linking.openURL(AppAssets.termsOfService);
            } }, translate('Terms of Service')),
        ' and ',
        React.createElement(Typography, { style: { color: theme.palette.typography.link }, onPress: () => {
                Linking.openURL(AppAssets.privacyPolicy);
            } }, translate('Privacy Policy'))));
};
export default SigninTnC;
