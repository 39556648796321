import { useCallback } from 'react';
import StorageKeys from 'src/lib/storage/storageKeys';
import usePersistedState from 'src/hooks/globalState/usePersistedState';
import { getCachedDeviceInfo } from 'src/lib/device';
import useUserStorage from 'src/modules/authentication/storage/user/hook';
var Version;
(function (Version) {
    Version["CURRENT"] = "current";
})(Version || (Version = {}));
const getCurrentVersionStr = async () => {
    const deviceInfo = await getCachedDeviceInfo();
    return [deviceInfo.appVersion, deviceInfo.codePushVersion]
        .filter(Boolean)
        .join('_');
};
const resolveVersion = async (version) => {
    switch (version) {
        case Version.CURRENT:
            return await getCurrentVersionStr();
        default:
            return version;
    }
};
export default function useUsageProfile() {
    const [state, setState] = usePersistedState(StorageKeys.usageProfile, {});
    const [userDetails] = useUserStorage();
    const getUserId = useCallback(() => {
        if (!userDetails) {
            return 'unknown';
        }
        return userDetails.id;
    }, [userDetails]);
    const getUserSandbox = useCallback(() => {
        return state[getUserId()] ?? {};
    }, [getUserId, state]);
    const getVersionSandbox = useCallback(async (userSandbox, version = Version.CURRENT) => {
        const versionStr = await resolveVersion(version);
        return userSandbox[versionStr] ?? {};
    }, []);
    const getUserVersionSandbox = useCallback(async (version = Version.CURRENT) => {
        const userSandbox = getUserSandbox();
        const versionSandbox = await getVersionSandbox(userSandbox, version);
        return versionSandbox;
    }, [getUserSandbox, getVersionSandbox]);
    const getValue = useCallback(async (param, version = Version.CURRENT) => {
        const sandbox = await getUserVersionSandbox(version);
        return sandbox[param];
    }, [getUserVersionSandbox]);
    const getAllUsedVersions = useCallback(() => {
        return Object.keys(state[getUserId()] ?? {});
    }, [state, getUserId]);
    const updateUserSandbox = useCallback((userSandbox) => {
        const userID = getUserId();
        state[userID] = { ...userSandbox };
        setState({ ...state });
    }, [state, getUserId]);
    const updateVersionSandbox = useCallback(async (versionSandbox) => {
        const versionStr = await getCurrentVersionStr();
        const userSandbox = getUserSandbox();
        userSandbox[versionStr] = { ...versionSandbox };
        updateUserSandbox(userSandbox);
    }, [getUserSandbox, updateUserSandbox]);
    const updateValue = useCallback(async (param, value) => {
        const versionSandbox = await getUserVersionSandbox();
        versionSandbox[param] = value;
        await updateVersionSandbox(versionSandbox);
    }, [getUserVersionSandbox, updateVersionSandbox]);
    const accessors = {
        getValue: useCallback(async (param, version) => {
            if (version) {
                return await getValue(param, version);
            }
            const usedVersions = getAllUsedVersions();
            return await usedVersions.reduce(async (aggr, v) => {
                return (await aggr) + (await getValue(param, v));
            }, Promise.resolve(0));
        }, [getValue, getAllUsedVersions]),
    };
    const modifiers = {
        increment: useCallback(async (param, incrVal = 1) => {
            const currValue = await getValue(param);
            await updateValue(param, (typeof currValue === 'number' ? currValue : 0) + incrVal);
        }, [getValue, updateValue]),
        decrement: useCallback(async (param, decrVal = 1) => {
            const currValue = await getValue(param);
            await updateValue(param, (typeof currValue === 'number' ? currValue : 0) - decrVal);
        }, [getValue, updateValue]),
        setValue: useCallback(async (param, value) => {
            await updateValue(param, value);
        }, [updateValue]),
    };
    return [accessors, modifiers];
}
