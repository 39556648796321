import { useNavigation, useRoute } from '@react-navigation/native';
import { useMemo } from 'react';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { isWeb } from 'src/utils/platform';
import { useTopicPlaylist } from '../../graphql/question/getQuestionPlaylistForTopic';
import { useQuestionListForTopicQuery, } from '../../graphql/question/getQuestionsForTopic';
import { useSubjectDetailsQuery } from '../../graphql/subject/getDetails';
import { pageAnalytics } from './analytics';
export default function useData() {
    const route = useRoute();
    const { examLevel, subject, ancestorID, topic } = route.params;
    // Since Topic can have spaces & '&' characters
    const decodedTopic = decodeURIComponent(topic);
    const navigation = useNavigation();
    const [listQueryState, listQueryActions] = useQuestionListForTopicQuery();
    const listQueryVariables = useMemo(() => ({
        topic,
        ancestorID,
    }), [topic, ancestorID]);
    const [subjectDetailsQueryState] = useSubjectDetailsQuery(ancestorID, {
        skip: !!subject,
    });
    const gotoQuestionDetails = useCallbackWithCtx((ctx, question, index) => {
        pageAnalytics.setWidget('QuestionList').setTarget('Question').click();
        navigation.navigate('EP_Question', {
            ...(!isWeb && {
                examLevel: ctx.examLevel,
                subject: ctx.subject,
                question,
            }),
            questionID: question.id,
            navigatedFrom: 'TOPIC_QUESTIONS',
            navigationData: {
                ancestorID: ctx.ancestorID,
                topic: ctx.topic,
                sequence: index,
            },
        });
    }, { subject, examLevel, topic: decodedTopic, ancestorID });
    const [playlistState, playlistActions] = useTopicPlaylist(ancestorID, topic);
    const state = {
        topic: decodedTopic,
        playlist: playlistState,
        listQuery: listQueryState,
        listQueryVariables,
        subjectDetailsQuery: subjectDetailsQueryState,
        subject: subject ?? subjectDetailsQueryState.data?.ep_subjectDetails,
        examLevel: examLevel ??
            subjectDetailsQueryState.data?.ep_subjectDetails.ancestorsDetails
                .examLevel,
    };
    const actions = {
        listQuery: listQueryActions,
        playlist: playlistActions,
        gotoQuestionDetails,
    };
    return [state, actions];
}
