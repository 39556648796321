import { useNavigation } from '@react-navigation/native';
import React from 'react';
import Column from 'src/components/10-atoms/layout/Column';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import { NodeType } from 'src/modules/exam-preparation/graphql/common/types/node';
import SubjectListAPIHandler from './ListAPIHandler';
import useData from './useData';
import styleUtils from 'src/utils/styles';
import { isWeb } from 'src/utils/platform';
const SubjectTab = ({ examLevel, type, ...customScrollViewProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const navigation = useNavigation();
    const [state, actions] = useData(examLevel, type);
    const hasSubjects = type === NodeType.WRITTEN_SUBJECT
        ? examLevel?.config?.EXAM_LEVEL?.written?.hasSubjects ?? true
        : examLevel?.config?.EXAM_LEVEL?.orals?.hasSubjects ?? true;
    // const enableMockExams =
    //   type === NodeType.WRITTEN_SUBJECT
    //     ? examLevel?.config?.EXAM_LEVEL?.written?.enableMockExams ?? false
    //     : examLevel?.config?.EXAM_LEVEL?.orals?.enableMockExams ?? false;
    useEffectWithCtx(ctx => {
        ctx.queryFetch();
    }, { queryFetch: actions.query.fetch }, [hasSubjects]);
    const handleSubjectPress = useCallbackWithCtx((ctx, subject) => {
        ctx.navigation.navigate('EP_Subject', {
            ...(!isWeb && {
                examLevel: ctx.examLevel,
                subject,
            }),
            subjectID: subject.id,
        });
    }, { examLevel, navigation });
    return (React.createElement(CustomScrollView, { containerStyle: styleUtils.flex, refreshing: state.query.refetching, onRefresh: actions.query.fetch, ...customScrollViewProps },
        React.createElement(Column, { ph: theme.spacing.page, pv: theme.spacing.standard.sm, spacing: theme.spacing.standard.sm },
            React.createElement(SubjectListAPIHandler, { hasSubjects: hasSubjects, queryState: state.query, onRetry: actions.query.fetch, onSubjectPress: handleSubjectPress }))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default SubjectTab;
