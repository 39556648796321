import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import GQLPaginatedList from 'src/lib/graphql/components/GQLPaginatedList';
import Column from 'src/components/10-atoms/layout/Column';
import QuestionPaperGroupItem from '../../components/qp-list/QuestionPaperGroupItem';
import QuestionPaperListEmpty from '../../components/qp-list/QuestionPaperListEmpty';
import QuestionPaperListLoading from '../../components/qp-list/QuestionPaperListLoading';
import useData from './useData';
const QPList = ({ subjectID, subjectType, onQPPress, groupBy, ...paginatedListProps }) => {
    const [{ theme, styles }] = useTheme(stylesGenerator);
    const [state, actions] = useData(subjectType, subjectID, groupBy);
    return (React.createElement(GQLPaginatedList, { LoaderComponent: React.createElement(QuestionPaperListLoading, { pt: theme.spacing.standard.md, ph: theme.spacing.page }), ListEmptyComponent: QuestionPaperListEmpty, variables: state.listQueryVariables, queryState: state.listQuery, queryActions: actions.listQuery, ItemSeparatorComponent: () => React.createElement(Column, { style: styles.separator }), renderItem: ({ item }) => (React.createElement(QuestionPaperGroupItem, { data: item, onQPPress: onQPPress })), ...paginatedListProps }));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    separator: {
        height: theme.spacing.standard.lg,
    },
}));
export default QPList;
