import { useNavigation, useRoute } from '@react-navigation/native';
import { useMemo } from 'react';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { isWeb } from 'src/utils/platform';
import { useQPDetailsQuery } from '../../graphql/qp/getDetails';
import { useQPPlaylist } from '../../graphql/question/getQuestionPlaylistForQP';
import { useQuestionListForQPQuery } from '../../graphql/question/getQuestionsForQP';
import { pageAnalytics } from './analytics';
export default function useData() {
    const navigation = useNavigation();
    const route = useRoute();
    const { examLevel, subject, qp, qpID } = route.params;
    const [listQueryState, listQueryActions] = useQuestionListForQPQuery();
    const listQueryVar = useMemo(() => ({ questionPaperID: qpID }), [qpID]);
    const gotoQuestionDetails = useCallbackWithCtx((ctx, question, index) => {
        pageAnalytics.setWidget('QuestionList').setTarget('Question').click();
        ctx.navigation.navigate('EP_Question', {
            ...(!isWeb && {
                examLevel: ctx.examLevel,
                subject: ctx.subject,
                question,
                qp: ctx.qp,
            }),
            questionID: question.id,
            navigatedFrom: 'QP_QUESTIONS',
            navigationData: {
                qpID: ctx.qpID,
                sequence: index,
            },
        });
    }, { navigation, subject, examLevel, qp, qpID });
    const [playlistState, playlistActions] = useQPPlaylist(qpID);
    const [qpDetailsQueryState, qpDetailsQueryActions] = useQPDetailsQuery(qpID, {
        lazy: true,
    });
    const state = {
        listQuery: listQueryState,
        listQueryVar,
        qpDetailsQuery: qpDetailsQueryState,
        playlist: playlistState,
    };
    const actions = {
        listQuery: listQueryActions,
        qpDetailsQuery: qpDetailsQueryActions,
        playlist: playlistActions,
        gotoQuestionDetails,
    };
    return [state, actions];
}
