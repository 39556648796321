import React from 'react';
import { ScrollView } from 'react-native';
import Button from 'src/components/10-atoms/button/Button';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import OverlayLayout from 'src/components/10-atoms/overlays/OverlayLayout';
import Typography from 'src/components/10-atoms/typography/Typography';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import MetaDataForm from './MetaDataForm';
import QuestionListForm from './QuestionListForm';
import useData from './useData';
const QPFormOverlay = ({ subjectID, variant, onClose, onComplete, }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const [state, actions] = useData(subjectID);
    useEffectWithCtx(ctx => {
        if (state.createMutation.data) {
            if (!state.createMutation.data.ep_createQP.sentForReview) {
                snackbarHelperActions.show({
                    message: 'Question Paper uploaded successfully',
                    variant: 'POSITIVE',
                    duration: 'SHORT',
                });
            }
            else {
                // TODO: Show sent for review Banner
            }
            ctx.onComplete();
        }
    }, { onComplete }, [state.createMutation.data]);
    return (React.createElement(Column, { flex: true },
        React.createElement(OverlayLayout.FormHeader, { title: variant === 'new' ? 'Add Question Paper' : 'Edit Question Paper', onClose: onClose }),
        React.createElement(Column, { flex: true },
            React.createElement(ScrollView, null,
                React.createElement(Column, { spacing: theme.spacing.standard.md },
                    React.createElement(MetaDataForm, { ref: state.metaDataFormRef, subjectID: subjectID, brd: theme.borderRadius.standard.xl, bwd: 1, spacing: theme.spacing.standard.md, ph: theme.spacing.page, pv: theme.spacing.standard.sm, mh: theme.spacing.standard.md, mv: theme.spacing.standard.sm }),
                    React.createElement(Column, { spacing: theme.spacing.standard.md, ph: theme.spacing.page },
                        React.createElement(Typography, { variant: TypographyVariant.BODY_TITLE, color: theme.palette.typography.label }, "Questions"),
                        React.createElement(QuestionListForm, { subjectID: subjectID, ref: state.questionListFormRef }))),
                React.createElement(Column, { style: { height: 400 } }))),
        React.createElement(BottomDrawerLayout.Actions, { btw: 1, spacing: theme.spacing.standard.md, pv: theme.spacing.standard.sm },
            React.createElement(Button, { disabled: state.createMutation.loading, flex: true, variant: "outline", onPress: onClose }, "Cancel"),
            React.createElement(Button, { loading: state.createMutation.loading, flex: true, LeftIcon: props => React.createElement(FeatherIcon, { name: "save", ...props }), onPress: actions.handleSubmit }, "Submit"))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QPFormOverlay;
