import { useMemo } from 'react';
import { useEPQuestionSearchQuery, } from '../../graphql/question/questionSearch';
import useSearch from 'src/hooks/useSearch';
import { useNavigation, useRoute } from '@react-navigation/native';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
export default function useData() {
    const navigation = useNavigation();
    const route = useRoute();
    const { query: initialQuery = '' } = route.params ?? {};
    const [searchState, searchActions] = useSearch('debounce', 500, initialQuery);
    useEffectWithCtx(ctx => {
        ctx.navigation.setParams({
            query: searchState.deferredVal,
        });
    }, { navigation }, [searchState.deferredVal]);
    const [listQueryState, listQueryActions] = useEPQuestionSearchQuery();
    const listQueryVars = useMemo(() => {
        const query = searchState.deferredVal.trim();
        return {
            query,
            withAncestorsDetails: true,
        };
    }, [searchState.deferredVal]);
    const state = {
        search: searchState,
        listQuery: listQueryState,
        listQueryVars,
    };
    const actions = {
        search: searchActions,
        listQuery: listQueryActions,
    };
    return [state, actions];
}
