import { NavigationContainer, createNavigationContainerRef, } from '@react-navigation/native';
import React, { useRef } from 'react';
import Analytics from 'src/lib/analytics';
// import {PreferredInitialRoutes} from './config';
import linking from './linking';
export const navigationRef = createNavigationContainerRef();
const NavigationWrapper = ({ onReady, ...navigationContainerProps }) => {
    const routeNameRef = useRef('');
    // const [, setInitialRouteName] = useInitialRouteName();
    return (React.createElement(NavigationContainer, { ref: navigationRef, linking: linking, onReady: () => {
            routeNameRef.current = navigationRef.getCurrentRoute()?.name;
            onReady && onReady();
        }, onStateChange: () => {
            const previousRouteName = routeNameRef.current;
            const currentRouteName = navigationRef.getCurrentRoute()?.name;
            if (previousRouteName !== currentRouteName) {
                // if (PreferredInitialRoutes.includes(currentRouteName as any)) {
                //   setInitialRouteName(currentRouteName as any);
                // }
                Analytics.event
                    .builder()
                    .setPage(currentRouteName ?? 'INIT')
                    .visited();
            }
            // Save the current route name for later comparison
            routeNameRef.current = currentRouteName;
        }, ...navigationContainerProps }));
};
export default NavigationWrapper;
