import React from 'react';
import InputLayout from 'src/components/10-atoms/form/inputs/InputLayout';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useI18n from 'src/modules/translations/hooks/useI18n';
import SelectExamLevelDrawer from './SelectExamLevelDrawer';
import SelectCommunityDrawer from './SelectCommunityDrawer';
import { bottomDrawerHelperActions } from 'src/components/20-helper-views/bottom-drawer/useBottomDrawer';
const SelectExamLevel = ({ value, onChange, style, ...inputLayoutProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const handleChange = useCallbackWithCtx((ctx, newValue) => {
        bottomDrawerHelperActions.hide();
        ctx.onChange(newValue);
    }, { onChange });
    const showSelectCommunityDrawer = useCallbackWithCtx(ctx => {
        bottomDrawerHelperActions.show({
            component: (React.createElement(SelectCommunityDrawer, { value: ctx.value?.community, onChange: community => {
                    showSelectExamLevelDrawer({ community });
                } })),
        });
    }, { value, onChange });
    const showSelectExamLevelDrawer = useCallbackWithCtx((ctx, currValue) => {
        bottomDrawerHelperActions.show({
            component: (React.createElement(SelectExamLevelDrawer, { value: currValue, onChange: handleChange, onCommunityChange: showSelectCommunityDrawer })),
        });
    }, { value, onChange });
    const initiateSelectionFlow = useCallbackWithCtx(ctx => {
        if (ctx.value?.community) {
            showSelectExamLevelDrawer(ctx.value);
        }
        else {
            showSelectCommunityDrawer();
        }
    }, { value, onChange });
    // useEffect(() => {
    //   if (!value?.examLevel) {
    //     initiateSelectionFlow();
    //   }
    // }, []);
    const communityName = value?.community.data.title;
    const examLevelName = value?.examLevel?.data.title;
    return (React.createElement(InputLayout, { bg: theme.palette.background.dark, style: [styles.container, style], focused: false, onPress: initiateSelectionFlow, RightIcon: React.createElement(FeatherIcon, { name: "chevron-down", size: theme.iconSize.sm, color: theme.palette.primary.main }), rightIconColor: theme.palette.primary.main, ...inputLayoutProps },
        React.createElement(Column, { flex: true }, !communityName ? (React.createElement(Typography, { variant: TypographyVariant.CAPTION, color: theme.palette.typography.placeholder }, translate('-- Select your Exam Community --'))) : (React.createElement(React.Fragment, null,
            React.createElement(Typography, { color: theme.palette.typography.secondary, variant: TypographyVariant.CAPTION }, communityName),
            examLevelName ? (React.createElement(Typography, null, examLevelName)) : (React.createElement(Typography, { variant: TypographyVariant.CAPTION, color: theme.palette.typography.placeholder }, translate('-- Select your Exam Level --'))))))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        marginHorizontal: theme.spacing.page,
        marginVertical: theme.spacing.standard.sm,
        paddingVertical: theme.spacing.standard.xxs,
    },
}));
export default SelectExamLevel;
