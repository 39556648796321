import React from 'react';
import Divider from 'src/components/10-atoms/Divider';
import GQLPaginatedList from 'src/lib/graphql/components/GQLPaginatedList';
import styleUtils from 'src/utils/styles';
import QuestionItem from './QuestionItem';
import QuestionListEmpty from './QuestionListEmpty';
import QuestionListLoader from './QuestionListLoader';
import QuestionSearchLoader from './QuestionSearchLoader';
function QuestionList({ onQuestionPress, variant, ...paginatedListProps }) {
    return (React.createElement(GQLPaginatedList, { LoaderComponent: variant === 'question-search' ? (React.createElement(QuestionSearchLoader, null)) : (React.createElement(QuestionListLoader, { style: styleUtils.spaceBtwHeadNBody })), NextLoaderComponent: QuestionListLoader, ListEmptyComponent: React.createElement(QuestionListEmpty, { variant: variant }), renderItem: ({ item, index }) => (React.createElement(QuestionItem, { variant: variant, data: item, onPress: () => onQuestionPress(item, index) })), ItemSeparatorComponent: Divider, containerStyle: styleUtils.flex, ...paginatedListProps }));
}
export default QuestionList;
