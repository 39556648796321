import { useEffect } from 'react';
import StorageKeys from 'src/lib/storage/storageKeys';
import useAppState from 'src/hooks/app-level/useAppState';
import usePersistedState from 'src/hooks/globalState/usePersistedState';
import useEffectRange from 'src/hooks/useEffectUtils/useEffectRange';
import useIsLoggedIn from 'src/modules/authentication/hooks/useIsLoggedIn';
import limitCalls from 'src/utils/function/limitCalls';
import { useGetLangTranslationsAPI } from '../apis/getLangTranslations';
import useTranslationConfig from '../hooks/useTranslationConfig';
import { isWeb } from 'src/utils/platform';
export default function useLangTransations(code) {
    const config = useTranslationConfig();
    const isTranslationsEnabled = config.getValue('enabled', false);
    const [langTranslations, setLangTranslations] = usePersistedState(StorageKeys.languageTranslations);
    const [langTranslationsAPIState, langTransationsAPIActions] = useGetLangTranslationsAPI();
    useEffect(() => {
        if (code) {
            if (!langTranslations) {
                langTransationsAPIActions.fetch(code, true);
            }
        }
    }, [langTranslations, code]);
    useEffectRange([1], () => {
        if (code) {
            langTransationsAPIActions.fetch(code, true);
        }
    }, [code]);
    const isLoggedIn = useIsLoggedIn();
    useAppState((ctx, state) => {
        if (!isWeb) {
            if (ctx.isLoggedIn &&
                ctx.isTranslationsEnabled &&
                state === 'foreground' &&
                ctx.code) {
                // Fetch Config only after some delay because
                // the initial network bandwidth should be used for
                // critical render path APIs instead of Config APIs
                limitCalls(() => langTransationsAPIActions.fetch(ctx.code), {
                    duration: __DEV__ ? 1000 : 6 * 60 * 60 * 1000,
                    id: 'translations_updater',
                })();
            }
        }
    }, { isLoggedIn, isTranslationsEnabled, code });
    useEffect(() => {
        if (langTranslationsAPIState.data) {
            setLangTranslations(langTranslationsAPIState.data);
        }
    }, [langTranslationsAPIState.data]);
    const state = {
        langTranslationsAPI: langTranslationsAPIState,
        langTranslations,
    };
    return [state];
}
