import { Dimensions, Platform, StyleSheet } from 'react-native';
import { PRESSABLE_SIZE } from 'src/components/10-atoms/pressables/constants';
import spacing from 'src/customizations/themes/10-parts/spacing';
const { height, width } = Dimensions.get('window');
const styleUtils = StyleSheet.create({
    lottie: {
        height: 200,
        width: 200,
        maxHeight: 200,
        maxWidth: 200,
    },
    containerHeight: {
        height: '100%',
    },
    containerWidth: {
        width: '100%',
    },
    deviceHeight: {
        height,
    },
    deviceWidth: {
        width,
    },
    pagePadding: {
        paddingHorizontal: spacing.page,
    },
    spaceBtwHeadNBody: {
        paddingTop: 12,
    },
    flex: {
        flex: 1,
    },
    pressableArea: {
        minWidth: PRESSABLE_SIZE,
        minHeight: PRESSABLE_SIZE,
    },
    alignSelfCenter: {
        alignSelf: 'center',
    },
    dot: {
        height: 4,
        width: 4,
        borderRadius: 4,
    },
    ovfHidden: {
        overflow: 'hidden',
    },
    cardShadow: Platform.select({
        ios: {
            shadowColor: '#000000',
            shadowOpacity: 0.3,
            shadowRadius: 1.5,
            shadowOffset: {
                height: 1,
                width: 1,
            },
        },
        android: {
            elevation: 5,
        },
        web: {
            boxShadow: 'rgba(20, 20, 20, 0.7) 0px 2px 5px',
        },
    }),
});
export default styleUtils;
