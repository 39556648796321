import React from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
export const getBackIcon = (() => {
    const backIcons = {
        back: props => React.createElement(FeatherIcon, { name: "chevron-left", ...props }),
        menu: null, // DO NOT menu icon because we're now using Bottom Tabbed Navigation instead of Drawer Navigation
        custom: null,
        none: null,
    };
    return (backType) => backType ? backIcons[backType] ?? null : null;
})();
