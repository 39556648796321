import isNumber from 'lodash/isNumber';
export function encodeVariables(str) {
    return str.replace(/(\%\w+)/g, '<span translate="no">$1</span>');
}
function resolveTranslation(context, key) {
    return context?.langTranslations?.translations[encodeVariables(key)] ?? key;
}
function isPluralTranslationKey(translationKey) {
    return typeof translationKey !== 'string';
}
function resolvePluralTranslation(context, key) {
    const isPlural = key.count > 1;
    return isPlural
        ? resolveTranslation(context, key.plural)
        : resolveTranslation(context, key.singular);
}
function isNumberKey(key) {
    return isNumber(key);
}
function resolveNumberKey(key) {
    // TODO: Translate the number to the corresponding language
    // using Intl.format
    // Also ensure to format the number if it's a string (ex: 12,234)
    return String(key);
}
export function fprintTranslations(translatedStr, replacers, locale) {
    return Object.entries(replacers).reduce((result, [key, value]) => {
        return result.replace(new RegExp(`%${key}`, 'g'), typeof value === 'number' ? value.toLocaleString(locale) : value);
    }, translatedStr);
}
export function resolveTranslationKey(context, key) {
    return isNumberKey(key)
        ? resolveNumberKey(key)
        : isPluralTranslationKey(key)
            ? resolvePluralTranslation(context, key)
            : resolveTranslation(context, key);
}
