import { useMemo } from 'react';
import useSearch from 'src/hooks/useSearch';
import { searchList } from 'src/utils/search';
import allLanguages from './allLanguages.json';
export default function useData() {
    const [searchState, searchActions] = useSearch('throttle');
    const filteredLanguages = useMemo(() => {
        if (!searchState.deferredVal) {
            return allLanguages;
        }
        return searchList(allLanguages, ['name', 'native'], searchState.deferredVal);
    }, [searchState.deferredVal]);
    const state = {
        search: searchState,
        languages: filteredLanguages,
    };
    const actions = {
        search: searchActions,
    };
    return [state, actions];
}
