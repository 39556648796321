import React, { useRef } from 'react';
import { addMathFieldWrapper } from 'src/components/10-atoms/form/inputs/editor/html/HTMLEditor/utils/mathField';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import { extractHTML2TTSContent } from 'src/utils/html';
const HTMLTTSProcessor = ({ html, onCompleted }) => {
    const ttsContainerRef = useRef(null);
    useEffectWithCtx(ctx => {
        if (ttsContainerRef.current) {
            ttsContainerRef.current.innerHTML = addMathFieldWrapper(html);
            const result = extractHTML2TTSContent(ttsContainerRef);
            ctx.onCompleted(result ?? '');
        }
    }, { onCompleted }, []);
    return React.createElement("div", { ref: ttsContainerRef });
};
export default HTMLTTSProcessor;
