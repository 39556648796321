import React from 'react';
import Label from 'src/components/10-atoms/form/Label';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
const Comments = ({ comments, ...columnProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(Column, { ph: theme.spacing.standard.sm, pv: theme.spacing.standard.xs, bg: theme.palette.background.dark, brd: theme.borderRadius.standard.md, ...columnProps },
        React.createElement(Label, null, translate('Comments')),
        React.createElement(Typography, { variant: TypographyVariant.CAPTION }, comments)));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default Comments;
