import React from 'react';
import SearchInput from 'src/components/10-atoms/form/inputs/SearchInput';
import SelectInputItem from 'src/components/10-atoms/form/inputs/select-input-group/SelectInputItem';
import CustomFlatList from 'src/components/10-atoms/list-views/CustomFlatList';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
import useData from './useData';
const RequestNewLanguageDrawer = ({ onItemPress, }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData();
    return (React.createElement(BottomDrawerLayout, { yAxis: "flex-start", style: styleUtils.containerHeight },
        React.createElement(BottomDrawerLayout.Title, null, translate('Request for New Language')),
        React.createElement(SearchInput, { style: styles.searchBar, autoFocus: true, autoCorrect: false, autoCapitalize: "none", spellCheck: false, value: state.search.value, onChange: actions.search.change, onClear: () => actions.search.change('') }),
        React.createElement(CustomFlatList, { data: state.languages, renderItem: ({ item }) => (React.createElement(SelectInputItem, { isSelected: false, title: item.name, description: item.nativeName, onPress: () => {
                    onItemPress(item);
                } })) })));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    searchBar: {
        marginHorizontal: theme.spacing.standard.sm,
        marginVertical: theme.spacing.standard.sm,
    },
}));
export default RequestNewLanguageDrawer;
