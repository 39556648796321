import React, { useContext, useRef } from 'react';
import Animated, { useAnimatedStyle, withTiming } from 'react-native-reanimated';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Button from '../button/Button';
import Column from '../layout/Column';
import Row from '../layout/Row';
import Typography from '../typography/Typography';
import Label from './Label';
const AnimatedTypography = Animated.createAnimatedComponent(Typography);
const HelperText = ({ message }) => {
    const [{ theme }] = useTheme();
    const textAnimatedStyle = useAnimatedStyle(() => {
        return {
            opacity: withTiming(message ? 1 : 0),
        };
    }, [message]);
    return (React.createElement(AnimatedTypography, { variant: TypographyVariant.BADGE, color: theme.palette.negative.main, style: textAnimatedStyle }, message));
};
const FieldEventsCbContext = React.createContext({
    onFocus() { },
});
export function useFieldEventsCbContext() {
    return useContext(FieldEventsCbContext);
}
const Field = ({ label, action, children, description, error, style, ...rest }) => {
    const fieldRef = useRef(null);
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { ref: fieldRef, collapsable: false, style: [styles.container, style], ...rest },
        React.createElement(Row, { xAxis: "space-between", style: action && { paddingBottom: theme.spacing.standard.xs } },
            !!label && React.createElement(Label, { style: styles.label }, label),
            action && (React.createElement(Button, { variant: "text", size: "sm", onPress: action.onPress }, action.title))),
        !!description && (React.createElement(Typography, { variant: TypographyVariant.CAPTION, color: theme.palette.typography.secondary }, description)),
        children,
        !!error && React.createElement(HelperText, { variant: "error", message: error })));
};
const stylesGenerator = createThemedStyle(() => ({
    container: {},
    label: {
        marginBottom: 4,
    },
}));
export default Field;
