import { UsageTrackingParams } from '../trackers/constants';
import { UsageProfileThresholdCondID } from './constants';
export const InAppReviewTriggerCond = {
    id: UsageProfileThresholdCondID.inAppReview,
    thresholds: {
        [UsageTrackingParams.openedDaysCount]: 5,
        [UsageTrackingParams.durationInMin]: 1 * 60, // 1 hours
        [UsageTrackingParams.communityQuestionsViewed]: 10,
    },
    frequency: 90, // 3 months
};
