import React, { useEffect, useState } from 'react';
import { ScrollView } from 'react-native';
import { Easing, useAnimatedStyle, useSharedValue, withDelay, withRepeat, withSequence, withTiming, } from 'react-native-reanimated';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import styleUtils from 'src/utils/styles';
import Row, { AnimatedRow } from './layout/Row';
const Marquee = ({ children, style, ...rowProps }) => {
    const [{}] = useTheme(stylesGenerator);
    const [parentWidth, setParentWidth] = useState(-1);
    const [childrenWidth, setChildrenWidth] = useState(-1);
    const xPos = useSharedValue(0);
    useEffect(() => {
        if (parentWidth > -1 && childrenWidth > -1) {
            if (parentWidth < childrenWidth) {
                xPos.value = withRepeat(withDelay(500, withSequence(withTiming(-childrenWidth, {
                    duration: childrenWidth * 25,
                    easing: Easing.linear,
                }), withTiming(0, {
                    duration: 300,
                    easing: Easing.out(Easing.ease),
                }))), -1);
            }
        }
    }, [parentWidth, childrenWidth]);
    const childrenStyle = useAnimatedStyle(() => ({
        transform: [
            {
                translateX: xPos.value,
            },
        ],
    }), [xPos]);
    return (React.createElement(Row, { onLayout: ev => setParentWidth(ev.nativeEvent.layout.width), style: [styleUtils.ovfHidden, style], ...rowProps },
        React.createElement(ScrollView, { horizontal: true },
            React.createElement(AnimatedRow, { onLayout: ev => setChildrenWidth(ev.nativeEvent.layout.width), style: [childrenStyle] }, children))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default Marquee;
