import React, { useCallback, useMemo } from 'react';
import Button from 'src/components/10-atoms/button/Button';
import Column from 'src/components/10-atoms/layout/Column';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import DeepLink from 'src/modules/10-app/deep-links';
import useI18n from 'src/modules/translations/hooks/useI18n';
import Row from 'src/components/10-atoms/layout/Row';
import TranslatedBadge from 'src/modules/translations/components/TranslatedBadge';
import isTranslatedDoc from 'src/modules/translations/utils/isTranslatedDoc';
import sortBy from 'src/utils/array/sortBy';
import styleUtils from 'src/utils/styles';
import EFNotFound from './EFNotFound';
import ExaminersFeedbackTimelineItem from './EFTimelineItem';
const ExaminersFeedback = ({ question, style, ...columnProps }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const feedbacks = useMemo(() => {
        const allFeedbacks = [
            ...question.duplicates,
            {
                ...question,
            },
        ];
        return sortBy(allFeedbacks, 'questionPaper.data.date', 'desc').map((item, i) => ({
            isCurrent: question.questionPaper?.data.date === item.questionPaper?.data.date,
            questionPaper: item.questionPaper,
            examinersFeedback: item.examinersFeedback,
            isLast: i === allFeedbacks.length - 1,
        }));
    }, [question.duplicates]);
    const handleHaveSuggestionsPress = useCallback(() => {
        DeepLink.openTalkToUs(`Writing Examiners Feedback for Question - ${question.id}\n`);
    }, []);
    const isTranslated = isTranslatedDoc(feedbacks[0]?.examinersFeedback?.translationInfo);
    return (React.createElement(Column, { style: [styles.container, style], ...columnProps }, feedbacks.length > 0 ? (React.createElement(Column, { spacing: 32, style: styleUtils.pagePadding },
        isTranslated && (React.createElement(Row, null,
            React.createElement(TranslatedBadge, null))),
        React.createElement(Column, { mt: theme.spacing.standard.md }, feedbacks.map((feedback, i) => (React.createElement(ExaminersFeedbackTimelineItem, { key: i, ...feedback })))),
        React.createElement(Button, { variant: "outline", onPress: handleHaveSuggestionsPress }, translate('Have Suggestions?')))) : (React.createElement(EFNotFound, { question: question }))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingTop: theme.spacing.standard.sm,
    },
}));
export default ExaminersFeedback;
