import { useEffect, useState } from 'react';
import useCategoryList from '../hooks/useCategoryList';
export default function useData(org, entityID, initialValue) {
    const [categoryListState, categoryListActions] = useCategoryList(org, entityID, initialValue);
    const [showOnlySelected, setShowOnlySelected] = useState(false);
    useEffect(() => {
        setShowOnlySelected(false);
    }, [categoryListState.search.value]);
    const state = {
        categoryList: categoryListState,
        showOnlySelected,
    };
    const actions = {
        categoryList: categoryListActions,
        toggleShowOnlySelected: () => setShowOnlySelected(prevState => !prevState),
    };
    return [state, actions];
}
