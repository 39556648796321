import appEvents from 'src/modules/10-app/events';
export default function handleInvalidToken(instance) {
    instance.interceptors.response.use(response => {
        return response;
    }, err => {
        if (err.response) {
            const { response } = err;
            if (+response.status === 401) {
                appEvents.onLogout();
                return;
            }
        }
        return err;
    });
}
