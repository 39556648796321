import React from 'react';
import { Animated, Text } from 'react-native';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { extractStyles } from 'src/utils/props';
import { getFontWeightStyle } from './utils';
const stylePropMap = {
    align: 'textAlign',
    color: 'color',
    lineHeight: 'lineHeight',
};
const Typography = React.forwardRef(({ fontWeight, flex, variant = TypographyVariant.BODY, style, color, ...rest }, ref) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    color =
        color ||
            theme.typography[variant].style?.color ||
            theme.palette.typography.primary;
    fontWeight =
        fontWeight ||
            theme.typography[variant].fontWeight ||
            TypographyFontWeight.REGULAR;
    return (React.createElement(Text, { ref: ref, style: [
            styles.default,
            theme.typography[variant].style,
            flex && styles.flex,
            extractStyles(stylePropMap, {
                ...rest,
                color,
            }),
            style,
            fontWeight && getFontWeightStyle(fontWeight),
        ], ...rest }));
});
export const stylesGenerator = createThemedStyle(({ theme }) => ({
    default: {
        letterSpacing: 0.5,
        ...theme.typography[TypographyVariant.BODY].style,
        ...getFontWeightStyle(theme.typography[TypographyVariant.BODY].fontWeight),
    },
    flex: {
        flex: 1,
    },
}));
export const AnimatedTypography = Animated.createAnimatedComponent(Typography);
export default Typography;
