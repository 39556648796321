import palette from './palette';
import { withOpacity } from './utils';
const white = palette.grey['50'];
const lightColorScheme = {
    statusBar: {
        content: 'dark-content',
    },
    primary: {
        lightest: palette.brand['25'],
        lighter: palette.brand['100'],
        light: palette.brand['400'],
        main: palette.brand['500'],
        dark: palette.brand['600'],
        darker: palette.brand['700'],
        contrast: white,
    },
    notification: {
        main: 'rgba(255,0,0,0.7)',
    },
    background: {
        main: white,
        subtle: palette.grey['75'],
        dark: palette.grey['100'],
        darker: palette.grey['200'],
        darkest: palette.grey['900'],
        message: withOpacity(palette.yellow['500'], 0.7),
        highlight: withOpacity(palette.yellow['500'], 0.5),
        unread: palette.brand['25'],
        translate: palette.brand['300'],
        positive: palette.green['100'],
        negative: palette.red['100'],
        notificationBadge: palette.red['200'],
    },
    backdrop: 'rgba(0,0,0,0.65)',
    divider: {
        main: palette.grey['200'],
        dark: palette.grey['300'],
    },
    positive: {
        main: palette.green['400'],
        contrast: white,
    },
    negative: {
        main: palette.red['400'],
        contrast: white,
    },
    neutral: {
        main: palette.grey['800'],
        contrast: white,
    },
    typography: {
        primary: palette.grey['900'],
        secondary: palette.grey['700'],
        link: palette.navyBlue,
        placeholder: palette.grey['400'],
        label: palette.grey['500'],
        dark: palette.grey['850'],
    },
    icon: {
        primary: palette.grey['500'],
        secondary: palette.grey['300'],
        dark: palette.grey['850'],
    },
    pressable: {
        active: 'rgba(0,0,0,0.1)',
        disabled: palette.grey['300'],
        disabledText: palette.grey['500'],
    },
    loader: {
        placeholder: 'rgba(0,0,0,0.15)',
    },
    form: {
        inputBorder: palette.grey['700'],
        input: {
            background: {
                focused: palette.grey['50'],
                blurred: palette.grey['100'],
                disabled: palette.grey['300'],
            },
            border: {
                focused: palette.brand['300'],
                blurred: palette.grey['200'],
            },
        },
    },
    border: {
        main: palette.grey['200'],
        dark: palette.grey['300'],
        darker: palette.grey['500'],
        darkest: palette.grey['700'],
    },
    stc: palette.stc,
};
export default lightColorScheme;
