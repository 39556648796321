import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import tokenStorage from 'src/modules/authentication/storage/token/storage';
import userStorage from 'src/modules/authentication/storage/user/storage';
const USER_PROFILE_UPDATE_MUTATION = gql `
  mutation User_updateDetails($details: AuthorDetailsInput!) {
    user_updateDetails(details: $details)
  }
`;
export function useUserProfileUpdateMutation(options = {}) {
    return useGQLMutation(USER_PROFILE_UPDATE_MUTATION, {
        ...options,
        update(cache, _, { variables }) {
            if (variables) {
                const userID = tokenStorage.getUserID();
                if (userID) {
                    const { details } = variables;
                    cache.modify({
                        fields: {
                            details(existing) {
                                return {
                                    ...existing,
                                    ...details,
                                };
                            },
                        },
                        id: cache.identify({
                            __typename: 'Author',
                            id: userID,
                        }),
                    });
                    const userDetails = userStorage.getUserDetails();
                    if (userDetails) {
                        userStorage.setUserDetails({
                            ...userDetails,
                            avatarURL: details.avatarURL,
                            firstName: details.firstName,
                            lastName: details.lastName,
                        });
                    }
                }
            }
        },
    });
}
