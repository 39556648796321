import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import Row from 'src/components/10-atoms/layout/Row';
import Typography from 'src/components/10-atoms/typography/Typography';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import get from 'lodash/get';
const ImproveAnswer = ({ answer, onEdit, ...rowProps }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const showEdit = get(answer.config, 'WRITTEN_ANSWER.contribution.enableEdit', true);
    return showEdit ? (React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.standard.sm, bg: theme.palette.background.dark, xAxis: "space-between", spacing: theme.spacing.standard.md, ...rowProps },
        React.createElement(Typography, { flex: true }, translate('Want to improve the Answer ?')),
        React.createElement(FeatherIcon, { name: "edit", color: theme.palette.primary.main, onPress: onEdit }))) : null;
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default ImproveAnswer;
