import React, { useCallback } from 'react';
import { overlayHelperViewActions } from 'src/components/20-helper-views/overlay/useOverlay';
import { ebGlobalChannel } from 'src/lib/events-bridge';
import useSubscription from 'src/lib/events-bridge/hooks/useSubscription';
import EBAPIs from 'src/lib/events-bridge/topics';
import HTMLEditorOverlay from './Overlay';
export default function useHTMLEditor() {
    const show = useCallback(({ onSubmit, onClose, ...rest }) => {
        overlayHelperViewActions.show({
            component: (React.createElement(HTMLEditorOverlay, { ...rest, onClose: () => {
                    overlayHelperViewActions.hide();
                    onClose && onClose();
                }, onSubmit: newValue => {
                    overlayHelperViewActions.hide();
                    onSubmit(newValue);
                } })),
        });
    }, []);
    useSubscription(EBAPIs.helperViews.htmlEditor.show, show);
}
export const htmlEditorHelperActions = {
    show(data) {
        ebGlobalChannel.publish(EBAPIs.helperViews.htmlEditor.show, data);
    },
};
