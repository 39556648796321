import tokenStorage from 'src/modules/authentication/storage/token/storage';
import { uploadURL } from './constants';
const uploadFile = async (file, options = {}) => {
    const formData = new FormData();
    formData.append('file', file.file);
    const token = tokenStorage.getToken();
    try {
        options.onProgress && options.onProgress(0);
        const response = await fetch(uploadURL, {
            method: 'POST',
            headers: {
                authorization: `Bearer ${token}`,
            },
            body: formData,
        });
        const body = await response.json();
        options.onProgress && options.onProgress(100);
        return {
            statusCode: response.status,
            data: body.data,
        };
    }
    catch (error) {
        console.log('error:', error);
        return null;
    }
};
export default uploadFile;
