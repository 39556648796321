import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import { Vibrate } from 'src/lib/vibrate';
import Button from '../button/Button';
import Column from '../layout/Column';
const Form = ({ style, children, onSubmit, ...rest }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return (React.createElement(Column, { spacing: 24, style: [styles.container, style], ...rest },
        children,
        onSubmit && (React.createElement(Button, { onPress: () => {
                Vibrate.formSubmitted();
                onSubmit();
            } }, "Submit"))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingHorizontal: theme.spacing.page,
        paddingVertical: theme.spacing.itemVerticalPadding,
    },
}));
export default Form;
