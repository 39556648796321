import { useMemo } from 'react';
import selectBasedOnThemeID from '../utils/selectBasedOnThemeID';
import useDimensions from './useDimensions';
import useThemeContext from './useThemeContext';
import useDeviceType from './useDeviceType';
export default function useTheme(styleGenerator, props = {}, deps = []) {
    const themeContext = useThemeContext();
    const [dimensions] = useDimensions();
    const deviceType = useDeviceType();
    const data = useMemo(() => ({
        styles: (styleGenerator
            ? styleGenerator({
                theme: themeContext.theme,
                dimensions,
                deviceType,
                select: selectBasedOnThemeID(themeContext.theme.themeID),
            }, props)
            : undefined),
        theme: themeContext.theme,
        dimensions,
        deviceType,
        select: selectBasedOnThemeID(themeContext.theme.themeID),
    }), [themeContext.theme, dimensions, styleGenerator, ...deps]);
    const actions = useMemo(() => ({
        changeTheme: themeContext.change,
    }), [themeContext.change]);
    return [data, actions];
}
