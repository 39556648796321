import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useI18n from 'src/modules/translations/hooks/useI18n';
import Button from '../10-atoms/button/Button';
import BottomDrawerLayout from '../20-helper-views/bottom-drawer/BottomDrawerLayout';
const FeatureIntroductionDrawer = ({ title, body, dismissTitle, onDismiss, }) => {
    const [{}] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    return (React.createElement(BottomDrawerLayout, null,
        React.createElement(BottomDrawerLayout.Title, null, title),
        React.createElement(BottomDrawerLayout.Body, null, body),
        React.createElement(BottomDrawerLayout.Actions, null,
            React.createElement(Button, { flex: true, variant: "contained", onPress: onDismiss }, dismissTitle ?? translate('Close')))));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default FeatureIntroductionDrawer;
