import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useData from './useData';
import CustomScrollView from 'src/components/10-atoms/list-views/CustomScrollView';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import QueryHandler from 'src/components/10-atoms/QueryHandler';
import GenericServerError from 'src/components/10-atoms/GenericServerError';
import Column from 'src/components/10-atoms/layout/Column';
import ReputationSummary from '../../components/reputation/Summary';
import ReputationBadge from '../../components/reputation-badge/View';
import Divider from 'src/components/10-atoms/Divider';
import ReputationBadgeLoader from '../../components/reputation-badge/Loader';
import EmptyList from 'src/components/templates/EmptyList.template';
import styleUtils from 'src/utils/styles';
import AppAssets from 'src/constants/appAssets';
import useI18n from 'src/modules/translations/hooks/useI18n';
const Reputation = ({ navigation }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData();
    return (React.createElement(Page, null,
        React.createElement(CustomScrollView, { style: styleUtils.containerHeight, renderCollapsibleHeader: () => (React.createElement(PageNavbar, { title: translate('Reputation'), backType: "back", onBackPress: navigation.goBack })), collapsibleHeaderHeight: NAVBAR_HEIGHT },
            React.createElement(QueryHandler, { LoaderComponent: React.createElement(ReputationBadgeLoader, null), ErrorComponent: () => (React.createElement(GenericServerError, { error: state.reputationQuery.error, onRetry: actions.reputationQuery.fetch })), DataComponent: () => {
                    const isEmpty = state.reputationQuery.data.user_getDetails.reputation.badgesInfo
                        .badges.length === 0;
                    return (React.createElement(Column, null,
                        React.createElement(ReputationSummary, { variant: "details", style: styles.summary, reputation: state.reputationQuery.data.user_getDetails.reputation }),
                        isEmpty ? (React.createElement(EmptyList, { image: {
                                type: 'image',
                                source: {
                                    uri: AppAssets.reputationEmptyBadges,
                                },
                            }, message: translate("You'll find all your Badges in here.\nStart Contributing Now!") })) : (React.createElement(Column, { ItemSeparator: React.createElement(Divider, null) }, state.reputationQuery.data.user_getDetails.reputation.badgesInfo.badges.map(badge => (React.createElement(ReputationBadge, { key: badge.id, badge: badge })))))));
                }, queryState: state.reputationQuery }))));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    summary: {
        backgroundColor: theme.palette.background.dark,
        paddingHorizontal: theme.spacing.page,
        paddingVertical: theme.spacing.itemVerticalPadding,
    },
}));
export default Reputation;
