import React from 'react';
import Overlay from 'src/components/10-atoms/overlays/Overlay';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useOverlay from './useOverlay';
import { HelperViewsZStack } from '../constants';
const OverlayHelperView = () => {
    const [{}] = useTheme(stylesGenerator);
    const [overlayState] = useOverlay();
    const { component, ...otherProps } = overlayState;
    return (React.createElement(Overlay, { style: {
            zIndex: HelperViewsZStack.OVERLAY,
        }, ...otherProps }, component));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default OverlayHelperView;
