import { useMemo } from 'react';
import { useQPListQuery } from 'src/modules/exam-preparation/graphql/qp/getList';
import { groupQPList } from 'src/modules/exam-preparation/screens/Subject/container/QPList/utils';
import { NodeType } from 'src/modules/exam-preparation/graphql/common/types/node';
import sortBy from 'src/utils/array/sortBy';
export default function useData(subjectType, subjectID, groupBy) {
    const [listQueryState, listQueryActions] = useQPListQuery(subjectID);
    const listQueryVariables = useMemo(() => ({ subjectID }), [subjectID]);
    const queryState = useMemo(() => ({
        ...listQueryState,
        data: listQueryState.data
            ? {
                ...listQueryState.data,
                list: groupQPList(listQueryState.data.list, groupBy).map(group => ({
                    ...group,
                    questionPapers: subjectType === NodeType.WRITTEN_SUBJECT
                        ? sortBy(group.questionPapers, 'data.date', 'asc')
                        : sortBy(group.questionPapers, 'data.label', 'asc'),
                })),
            }
            : listQueryState.data,
    }), [listQueryState, groupBy]);
    const state = {
        listQuery: queryState,
        listQueryVariables,
    };
    const actions = {
        listQuery: listQueryActions,
    };
    return [state, actions];
}
