import React from 'react';
import { RootStack } from 'src/navigation/RootNavigation';
import withSuspense from 'src/navigation/withSuspense';
const Home = withSuspense(React.lazy(() => import('./screens/Home')));
const Details = withSuspense(React.lazy(() => import('./screens/ReviewDetails')));
export const renderReviewPipelineStackRoutes = () => {
    return (React.createElement(RootStack.Group, null,
        React.createElement(RootStack.Screen, { name: "RP_Home", component: Home }),
        React.createElement(RootStack.Screen, { name: "RP_ReviewDetails", component: Details })));
};
