import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import { ebGlobalChannel } from 'src/lib/events-bridge';
import useSubscription from 'src/lib/events-bridge/hooks/useSubscription';
import EBAPIs from 'src/lib/events-bridge/topics';
import useDocumentPicker from './useDocumentPicker';
const useFilePicker = () => {
    const documentPicker = useDocumentPicker();
    const pick = useCallbackWithCtx(async (ctx, options) => {
        return ctx.documentPicker.pick(options);
    }, { documentPicker });
    useSubscription(EBAPIs.helperViews.filePicker.pick, (options) => {
        return pick(options);
    });
    return { pick };
};
export const filePickerHelperViewActions = {
    pick(options) {
        return ebGlobalChannel.request(EBAPIs.helperViews.filePicker.pick, options);
    },
};
export default useFilePicker;
