import React from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import Column from 'src/components/10-atoms/layout/Column';
import Row from 'src/components/10-atoms/layout/Row';
import { PRESSABLE_SIZE } from 'src/components/templates/PressableArea';
import { withOpacity } from 'src/customizations/themes/10-parts/color-schemes/utils';
import { TypographyFontWeight, TypographyVariant, } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import withPress from 'src/hoc/withPress';
import styleUtils from 'src/utils/styles';
import Typography from '../../../typography/Typography';
import { ISelectionType } from '../SelectInput/types';
const SelectInputItem = ({ type = ISelectionType.SINGLE, isSelected, title, description, style, ...rest }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    const renderTitle = () => {
        if (typeof title === 'string') {
            return React.createElement(Typography, { style: [{ lineHeight: 24 }] }, title);
        }
        return title();
    };
    const renderIcon = () => {
        if (type === ISelectionType.SINGLE) {
            return null;
        }
        return !isSelected ? (React.createElement(FeatherIcon, { name: "square" })) : (React.createElement(FeatherIcon, { name: "check-square" }));
    };
    const renderDescription = () => {
        if (typeof description === 'string') {
            return (React.createElement(Typography, { variant: TypographyVariant.CAPTION, fontWeight: TypographyFontWeight.LIGHT }, description));
        }
        return description();
    };
    return (React.createElement(Row, { xAxis: "space-between", yAxis: "center", spacing: 24, style: [styles.container, isSelected && styles.containerSelected, style], ...rest },
        React.createElement(Column, { yAxis: "center", style: styleUtils.flex },
            renderTitle(),
            !!description && renderDescription()),
        renderIcon()));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        paddingVertical: theme.spacing.itemVerticalPadding,
        paddingHorizontal: theme.spacing.page,
        minHeight: PRESSABLE_SIZE,
    },
    containerSelected: {
        backgroundColor: withOpacity(theme.palette.primary.main, 0.3),
    },
}));
export default withPress(SelectInputItem);
