import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
export const RP_PENDING_COUNT_QUERY = gql `
  query RPPendingCount($types: [Int!]!) {
    rp_pendingCount(types: $types)
  }
`;
export function useRPPendingCountQuery(options) {
    return useGQLQuery(RP_PENDING_COUNT_QUERY, options);
}
