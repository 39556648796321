import React, { useRef } from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useEffectRange from 'src/hooks/useEffectUtils/useEffectRange';
import QuillEditor from './QuillEditor';
const HTMLRenderer = ({ html, ...columnProps }) => {
    const [{}] = useTheme(stylesGenerator);
    const editorRef = useRef(null);
    useEffectRange([1], () => {
        editorRef.current?.setHTML(html);
    }, [html]);
    return (React.createElement(QuillEditor, { ref: editorRef, readOnly: true, initialValue: html, ...columnProps }));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default HTMLRenderer;
