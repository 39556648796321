export const isExternalLink = (() => {
    const regex = /^https?:\/\//;
    return (link) => link.match(regex);
})();
export const isValidLink = (link) => {
    try {
        // eslint-disable-next-line no-new
        new URL(link);
        return true;
    }
    catch (_) {
        return false;
    }
};
export const stripQueryParamsFromUrl = (url) => {
    return url.split('?')[0];
};
export const getFileNameFromUrl = (url) => {
    return url.split('/').pop() ?? '';
};
