import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
const EP_UPDATE_QUESTION_MUTATION = gql `
  mutation EP_updateQuestion($data: EPEditQuestionInput!, $id: ID!) {
    ep_updateQuestion(data: $data, id: $id) {
      sentForReview
    }
  }
`;
export function useUpdateQuestionMutation(options) {
    return useGQLMutation(EP_UPDATE_QUESTION_MUTATION, {
        ...options,
        update: (cache, _, { variables }) => {
            if (variables?.id) {
                cache.modify({
                    id: cache.identify({
                        id: variables.id,
                        __typename: 'EPQuestion',
                    }),
                    fields: {
                        data(existing) {
                            return {
                                ...existing,
                                ...variables.data,
                            };
                        },
                    },
                });
            }
        },
    });
}
