import React from 'react';
import { TypographyFontWeight } from 'src/customizations/themes/10-parts/typography';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import useI18n from 'src/modules/translations/hooks/useI18n';
import Row from '../layout/Row';
import RowCenter from '../layout/RowCenter';
import Typography from '../typography/Typography';
import Avatar, { stylesGenerator } from './Avatar';
import { getStyleForSize } from './utils';
function getStyleForIndex(index) {
    return {
        zIndex: 100 - index,
        transform: [
            {
                translateX: -(index * 4),
            },
        ],
    };
}
const AvatarStack = ({ uris, maxVisible }) => {
    const [{ theme, styles }] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const showMore = uris.length > maxVisible;
    const moreCount = uris.length - maxVisible + 1; /* The +1 is for showing +N */
    const visibleAvatarCount = showMore ? maxVisible - 1 : uris.length;
    const visibleAvatarUris = uris.slice(0, visibleAvatarCount);
    return (React.createElement(Row, null,
        visibleAvatarUris.map((uri, i) => (React.createElement(Avatar, { key: uri, size: "sm", uri: uri, style: getStyleForIndex(i) }))),
        showMore && (React.createElement(RowCenter, { bg: theme.palette.background.dark, ph: theme.spacing.standard.xs, style: [
                getStyleForSize('sm'),
                getStyleForIndex(maxVisible),
                styles.avatar,
            ] },
            React.createElement(Typography, { fontWeight: TypographyFontWeight.MEDIUM }, `+${translate(moreCount)}`)))));
};
export default AvatarStack;
