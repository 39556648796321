import { gql } from '@apollo/client';
import useGQLQuery from 'src/lib/graphql/hooks/useGQLQuery';
const GetDetailsQuery = gql `
  query EP_CommunityDetails($communityID: ID!) {
    ep_communityDetails(communityID: $communityID) {
      id
      data {
        title
      }
      sequence
    }
  }
`;
export function useCommunityDetailsQuery(options) {
    return useGQLQuery(GetDetailsQuery, options);
}
