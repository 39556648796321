import { gql } from '@apollo/client';
export const COMMUNITY_QUESTION_LIST_QUERY = gql `
  query Community_questionList(
    $pagination: PaginationOptions!
    $searchFilter: CommunityQuestionSearchFilter!
  ) {
    community_questionList(
      pagination: $pagination
      searchFilter: $searchFilter
    ) {
      totalCount
      list {
        id
        ctime
        data {
          title
          shortDescription
          tags
          votes
          IMO
        }
        translationInfo {
          sourceLang
          targetLang
        }
        hasAcceptedAnswer
        author {
          id
          details {
            firstName
            lastName
            userName
            avatarURL
          }
        }
        repliesCount
        answersCount
      }
    }
  }
`;
export const refetchCommunityQuestionList = {
    query: COMMUNITY_QUESTION_LIST_QUERY,
    variables: {
        searchFilter: {
            onlyUnanswered: false,
            tags: [],
            searchTerm: '',
            exclude: [],
        },
        pagination: {
            offset: 0,
        },
    },
};
