import useRequest from 'src/hooks/request/useRequest';
import { handleSigninData } from './utils';
/****************
 * GOOGLE SIGNIN
 ***************/
const getGoogleSigninAPIConfig = (idToken, deviceDetails) => ({
    method: 'post',
    url: '/auth/google/signin',
    data: {
        idToken,
        device: deviceDetails,
    },
});
export function useGoogleSigninAPI(events = {}) {
    const [apiState, apiActions] = useRequest(getGoogleSigninAPIConfig, {
        onData(state) {
            handleSigninData(state);
            events.onComplete && events.onComplete();
        },
    });
    return [apiState, apiActions];
}
/********************
 * GOOGLE SIGNIN WEB
 *******************/
const getGoogleSigninWebAPIConfig = (accessToken, deviceDetails) => ({
    method: 'post',
    url: '/auth/google/signin-web',
    data: {
        accessToken,
        device: deviceDetails,
    },
});
export function useGoogleSigninWebAPI(events = {}) {
    const [apiState, apiActions] = useRequest(getGoogleSigninWebAPIConfig, {
        onData(state) {
            handleSigninData(state);
            events.onComplete && events.onComplete();
        },
    });
    return [apiState, apiActions];
}
const getAppleSigninAPIConfig = (auth, user, device) => ({
    method: 'post',
    url: '/auth/apple/signin',
    data: {
        auth,
        user,
        device,
    },
});
export function useAppleSigninAPI(events = {}) {
    const [apiState, apiActions] = useRequest(getAppleSigninAPIConfig, {
        onData(state) {
            handleSigninData(state);
            events.onComplete && events.onComplete();
        },
    });
    return [apiState, apiActions];
}
const getGuestSigninAPIConfig = (device) => ({
    method: 'post',
    url: '/auth/guest/signin',
    data: {
        device,
    },
});
export function useGuestSigninAPI() {
    const [apiState, apiActions] = useRequest(getGuestSigninAPIConfig, {
        onData: handleSigninData,
    });
    return [apiState, apiActions];
}
