import tokenStorage from 'src/modules/authentication/storage/token/storage';
import userPreferencesStorage from 'src/modules/preferences/storage';
export default function addHeaders(instance) {
    instance.interceptors.request.use(request => {
        const token = tokenStorage.getToken();
        const lang = userPreferencesStorage.getPrefs().lang;
        if (token) {
            request.headers.set('Authorization', `Bearer ${token}`);
        }
        if (lang) {
            request.headers.set('accept-language', lang);
        }
        return request;
    });
}
