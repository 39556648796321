import { gql } from '@apollo/client';
import { addTypePolicies } from 'src/lib/graphql/cache';
import useGQLPaginatedQuery from 'src/lib/graphql/hooks/useGQLPaginatedQuery';
import paginatedListFieldPolicy from 'src/lib/graphql/utils/paginatedListFieldPolicy';
const EXAM_LEVEL_LIST_QUERY = gql `
  query EP_ExamLevelList($communityID: ID!, $pagination: PaginationOptions!) {
    ep_examLevelList(communityID: $communityID, pagination: $pagination) {
      totalCount
      list {
        id
        data {
          title
        }
        config {
          EXAM_LEVEL {
            chatRoom {
              link
              iconURL
              text
            }
            written {
              enable
              hasSubjects
              enableMockExams
            }
            orals {
              enable
              hasSubjects
              enableMockExams
            }
          }
        }
      }
    }
  }
`;
export function useExamLevelListQuery(communityID) {
    return useGQLPaginatedQuery(EXAM_LEVEL_LIST_QUERY, 'ep_examLevelList', {
        variables: {
            communityID,
            pagination: {
                offset: 0,
            },
        },
    });
}
addTypePolicies({
    Query: {
        fields: {
            ep_examLevelList: paginatedListFieldPolicy([
                'communityID',
            ]),
        },
    },
});
