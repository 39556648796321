export function throttle(cb, interval = 200) {
    let currArgs;
    let timer;
    return (...args) => {
        currArgs = args;
        if (!timer) {
            timer = setTimeout(() => {
                timer && clearTimeout(timer);
                timer = null;
                cb(...currArgs);
            }, interval);
        }
    };
}
export function debounce(cb, delay = 200) {
    let timer;
    return (...args) => {
        timer && clearTimeout(timer);
        timer = setTimeout(cb, delay, ...args);
    };
}
