import { getPath } from 'src/navigation/getDeeplink';
export function shouldAutoNavigate(prevItem, currItem, currPageURL) {
    // Initially prevItem wouldn't exist, hence it should auto navigate
    if (!prevItem) {
        return true;
    }
    // If the previous item is current being viewed until the next item pops up
    // then we are free to auto navigate to the next item
    if (isSamePath(prevItem, currPageURL)) {
        return true;
    }
    if (currItem.firstItemInPlaylist) {
        return true;
    }
    return false;
}
export function isSamePath(item, currPageURL) {
    if (!item?.playerInput) {
        return false;
    }
    const itemLink = getPath(item.playerInput.pageDetails.routeName, item.playerInput.pageDetails.routeParams);
    return itemLink === currPageURL;
}
