import React, { useCallback } from 'react';
import withPress from 'src/hoc/withPress';
import FitImage from '../../image/FitImage';
import { imageViewHelperActions } from 'src/components/20-helper-views/image-viewer/useImageViewer';
const ImageView = withPress(({ ...fitImageProps }) => {
    return React.createElement(FitImage, { ...fitImageProps });
});
const ImageRenderer = ({ node, styles }) => {
    const { src, alt } = node.attributes;
    const imageProps = {
        style: [styles._VIEW_SAFE_image, { backgroundColor: 'white' }],
        source: {
            uri: src,
        },
    };
    if (alt) {
        imageProps.accessible = true;
        imageProps.accessibilityLabel = alt;
    }
    const handlePress = useCallback(() => {
        imageViewHelperActions.show({
            images: [
                {
                    uri: src,
                },
            ],
        });
    }, [node]);
    return (React.createElement(ImageView, { key: node.key, imageStyle: { backgroundColor: 'white' }, 
        // eslint-disable-next-line react-native/no-inline-styles
        pressableStyle: { width: '100%' }, ...imageProps, onPress: handlePress }));
};
export default ImageRenderer;
