/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { RootTab } from 'src/navigation/TabNavigation';
import withSuspense from 'src/navigation/withSuspense';
import useI18n from '../translations/hooks/useI18n';
import STCIcon from 'src/components/10-atoms/icons/STCIcon';
const Home = withSuspense(React.lazy(() => import('./screens/Home')));
export const renderSTCTabRoutes = () => {
    const { translate } = useI18n();
    return (React.createElement(RootTab.Group, null,
        React.createElement(RootTab.Screen, { name: "STC_Home", options: {
                tabBarLabel: translate('Support Us'),
                tabBarIcon: STCIcon,
            }, component: Home })));
};
