import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from '../layout/Row';
import stylesGenerator from './styles';
import withPress from 'src/hoc/withPress';
const RowCard = ({ style, ...rowProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return React.createElement(Row, { style: [styles.container, style], ...rowProps });
};
export default withPress(RowCard);
