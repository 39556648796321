import React from 'react';
import Page from 'src/components/templates/page/Page.template';
import PageSearchBar from 'src/components/templates/page/PageSearchBar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import Analytics from 'src/lib/analytics';
import useI18n from 'src/modules/translations/hooks/useI18n';
import { isWeb } from 'src/utils/platform';
import QuestionList from '../../containers/QuestionList';
import useData from './useData';
const pageAnalytics = Analytics.event.builder().setPage('EP_QuestionSearch');
const QuestionSearch = ({ navigation }) => {
    const [{}] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData();
    const handleQuestionPress = useCallbackWithCtx((ctx, question, index) => {
        pageAnalytics
            .setWidget('QuestionSearchList')
            .setTarget('Question')
            .click();
        ctx.navigation.navigate('EP_Question', {
            ...(!isWeb && {
                question,
            }),
            questionID: question.id,
            navigatedFrom: 'SEARCH_QUESTIONS',
            navigationData: {
                query: ctx.state.search.deferredVal,
                sequence: index,
            },
        });
    }, { navigation, state });
    return (React.createElement(Page, null,
        React.createElement(PageSearchBar, { value: state.search.value, onChange: actions.search.change, onClear: () => actions.search.change(''), placeholder: translate('Search for a question...'), onBackPress: navigation.goBack }),
        React.createElement(QuestionList, { variant: "question-search", queryState: state.listQuery, queryActions: actions.listQuery, variables: state.listQueryVars, onQuestionPress: handleQuestionPress })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default QuestionSearch;
