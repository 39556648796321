import crashlytics from '@react-native-firebase/crashlytics';
const Crashlytics = {
    login(userID, properties) {
        crashlytics().setUserId(userID);
        crashlytics().setAttributes(properties);
    },
    log(message) {
        crashlytics().log(message);
    },
    error(error, jsErrorName) {
        if (__DEV__) {
            console.error('[Crashlytics] Error:', error);
        }
        else {
            crashlytics().recordError(error, jsErrorName);
        }
    },
};
export default Crashlytics;
