import { PRESSABLE_SIZE } from '../pressables/constants';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
export const getMainColor = (theme, colorScheme = 'normal', variant) => {
    const isTextBtn = variant === 'text';
    const mainColorMap = {
        normal: isTextBtn ? theme.palette.primary.main : theme.palette.primary.dark,
        negative: theme.palette.negative.main,
        positive: theme.palette.positive.main,
    };
    return mainColorMap[colorScheme];
};
export const getContrastColor = (theme, colorScheme = 'normal') => {
    const contrastColorMap = {
        normal: theme.palette.primary.contrast,
        negative: theme.palette.negative.contrast,
        positive: theme.palette.positive.contrast,
    };
    return contrastColorMap[colorScheme];
};
export const getButtonHeight = (() => {
    const heightMap = {
        xs: 24,
        sm: 32,
        md: PRESSABLE_SIZE,
    };
    return (size = 'md') => heightMap[size];
})();
export const getButtonTextVariant = (() => {
    const variantMap = {
        xs: TypographyVariant.CAPTION,
        sm: TypographyVariant.CAPTION,
        md: TypographyVariant.BODY,
    };
    return (size = 'md') => variantMap[size];
})();
