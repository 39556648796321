import { WebFileTypeMapping, } from '../types';
import { handleImagePickerError } from '../utils';
const useDocumentPicker = () => {
    const createInputElement = (options) => {
        const [bodyElem] = document.getElementsByTagName('body');
        const inputElem = document.createElement('input');
        inputElem.setAttribute('type', 'file');
        inputElem.setAttribute('multiple', options.allowMultiple ? 'true' : 'false');
        inputElem.setAttribute('accept', WebFileTypeMapping[options.allowedTypes]);
        inputElem.setAttribute('hidden', 'true');
        inputElem.style.visibility = 'hidden';
        bodyElem.appendChild(inputElem);
        return inputElem;
    };
    const triggerFilePicker = (inputElem) => {
        return new Promise((resolve, reject) => {
            inputElem.addEventListener('cancel', () => {
                console.log('Cancelled.');
                const error = new Error('Document Picker closed');
                error.name = 'DOCUMENT_PICKER_CANCELLED';
                reject(error);
            });
            inputElem.addEventListener('change', () => {
                if ((inputElem.files?.length ?? 0) >= 1) {
                    // console.log('File selected: ', input.files[0]);
                    resolve(inputElem.files);
                }
                else {
                    const error = new Error('No file selected');
                    error.name = 'DOCUMENT_PICKER_NO_FILE_SELECTED';
                    reject(error);
                }
            });
            inputElem.click();
        });
    };
    return {
        async pick(options) {
            const inputElem = createInputElement(options);
            try {
                const response = await triggerFilePicker(inputElem);
                console.log('FilePicker:', response);
                const result = [];
                for (const file of response) {
                    result.push({
                        uri: '__UNAVAILABLE__',
                        file,
                        filename: file.name,
                        type: file.type,
                        size: file.size,
                    });
                }
                return result;
            }
            catch (error) {
                if (![
                    'DOCUMENT_PICKER_CANCELLED',
                    'DOCUMENT_PICKER_NO_FILE_SELECTED',
                ].includes(error.name)) {
                    handleImagePickerError(error);
                }
            }
            finally {
                inputElem.remove();
            }
            return null;
        },
    };
};
export default useDocumentPicker;
