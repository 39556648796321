import { useRef, useState } from 'react';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import useCallbackWithCtx from 'src/hooks/useCallbackUtils/useCallbackWithCtx';
import useEffectWithCtx from 'src/hooks/useEffectUtils/useEffectWithCtx';
import useSubscription from 'src/lib/events-bridge/hooks/useSubscription';
import { PlaylistControllerMode, PlaylistItemState, } from '../types';
import { PlayerState } from './players/types';
import usePlayer from './players/usePlayer';
import usePlaylist from './usePlaylist';
import useProcessorWithStateUpdates from './processors/useProcessorWithStateUpdates';
import { analytics } from '../analytics';
import { ebGlobalChannel } from 'src/lib/events-bridge';
import EBAPIs from 'src/lib/events-bridge/topics';
export default function usePlaylistPlayer() {
    const [playlistState, playlistActions, playlistHelpers] = usePlaylist();
    const [playerState, playerActions, playerHelpers] = usePlayer();
    const playingItem = playlistHelpers.getActiveItem();
    const [mode, setMode] = useState(PlaylistControllerMode.HIDDEN);
    // Automatically show the player, when the player is hidden while
    // playing an item
    useEffectWithCtx(ctx => {
        if ([PlayerState.PAUSED, PlayerState.PLAYING].includes(playerState.state)) {
            if (ctx.mode === PlaylistControllerMode.HIDDEN) {
                ctx.setMode(PlaylistControllerMode.NORMAL);
            }
        }
    }, { mode, setMode }, [playerState.state]);
    const prevPlayingItem = useRef();
    useEffectWithCtx(ctx => {
        // This effect would be triggered even when the `playingItem` state is updated
        // Hence we must filter out such events and look out only for the item changed event
        if (playingItem && playingItem.id !== prevPlayingItem.current?.id) {
            if (![PlaylistItemState.IDLE, PlaylistItemState.PROCESSING].includes(playingItem.state)) {
                prevPlayingItem.current = playingItem;
                ctx.playlistActions.updateItemState(playingItem.id, PlaylistItemState.PLAYING);
                playingItem.hooks?.onPlay?.();
                ctx.playerActions.play(playingItem, {
                    onEnd() {
                        ctx.playlistActions.updateItemState(playingItem.id, PlaylistItemState.ENDED);
                        playingItem.hooks?.onEnd?.();
                    },
                    onSkip() {
                        ctx.playlistActions.updateItemState(playingItem.id, PlaylistItemState.SKIPPED);
                        playingItem.hooks?.onEnd?.();
                    },
                });
            }
        }
    }, { playerActions, playlistActions }, [playingItem]);
    // Auto-play the next question, when the
    // current question ends.
    useEffectWithCtx(ctx => {
        if (playingItem) {
            if (playingItem.state === PlaylistItemState.ENDED) {
                if (ctx.playlistHelpers.hasNext()) {
                    analytics
                        .setWidget('PlaylistPlayer')
                        .setTarget('AutoPlayNext')
                        .plain();
                    ctx.playlistActions.gotoNext();
                }
                else {
                    analytics
                        .setWidget('PlaylistPlayer')
                        .setTarget('EndOfPlaylist')
                        .plain();
                    stop();
                }
            }
            else if (playingItem.state === PlaylistItemState.ERROR) {
                snackbarHelperActions.show({
                    message: 'Failed to speak the next item',
                    variant: 'NEGATIVE',
                });
                stop();
            }
        }
    }, { playlistActions, playlistHelpers, playingItem }, [playingItem?.state]);
    const processItem = useProcessorWithStateUpdates();
    // Process the item
    useEffectWithCtx(ctx => {
        if (playingItem) {
            ctx.processItem(playingItem);
        }
    }, { processItem, playlistHelpers }, [playingItem]);
    // Pre-process the next item
    // Process & cache the next item, so that when the player automatically
    // navigates the next item, there will be NO delays (waiting time).
    useEffectWithCtx(ctx => {
        if (playingItem?.state === PlaylistItemState.PLAYING) {
            const nextItem = ctx.playlistHelpers.getNext();
            ctx.processItem(nextItem);
        }
    }, { processItem, playlistHelpers }, [playingItem?.state]);
    useSubscription(EBAPIs.helperViews.playlist.addToQueue, playlistActions.addToQueue);
    useSubscription(EBAPIs.helperViews.playlist.overrideQueue, (data) => {
        if (playlistState.list.length > 0) {
            analytics
                .setWidget('PlaylistPlayer')
                .setTarget('OverrideQueue')
                .plain();
        }
        else {
            analytics.setWidget('PlaylistPlayer').setTarget('NewQueue').plain();
        }
        playlistActions.overrideQueue(data);
    });
    const stop = useCallbackWithCtx(async (ctx) => {
        ctx.playlistActions.clearQueue();
        await ctx.playerActions.stop();
        ctx.setMode(PlaylistControllerMode.HIDDEN);
    }, { playerActions, playlistActions, setMode });
    useSubscription(EBAPIs.helperViews.playlistPlayer.stop, stop);
    const state = {
        mode,
        playlistItem: playingItem,
        player: playerState,
        playlist: playlistState,
    };
    const actions = {
        setMode,
        player: {
            ...playerActions,
            stop,
        },
        playlist: playlistActions,
    };
    const helpers = {
        player: playerHelpers,
        playlist: playlistHelpers,
    };
    return [state, actions, helpers];
}
export const playlistPlayerHelperViewActions = {
    addToQueue(data) {
        ebGlobalChannel.publish(EBAPIs.helperViews.playlist.addToQueue, data);
    },
    overrideQueue(data) {
        ebGlobalChannel.publish(EBAPIs.helperViews.playlist.overrideQueue, data);
    },
    stop() {
        ebGlobalChannel.publish(EBAPIs.helperViews.playlistPlayer.stop, {});
    },
};
