import { useCallback, useState } from 'react';
import { ebGlobalChannel } from 'src/lib/events-bridge';
import useSubscription from 'src/lib/events-bridge/hooks/useSubscription';
import EBAPIs from 'src/lib/events-bridge/topics';
const getInitialState = () => ({
    visible: false,
    component: null,
    onClose() { },
});
export default function useOverlay() {
    const [state, setState] = useState(getInitialState());
    const hide = useCallback(() => {
        setState(prevState => ({
            ...getInitialState(),
            component: prevState.component,
        }));
    }, []);
    const show = useCallback((data) => {
        setState({
            visible: true,
            onClose: hide,
            component: data.component,
        });
    }, [hide]);
    useSubscription(EBAPIs.helperViews.overlay.show, show);
    useSubscription(EBAPIs.helperViews.overlay.hide, hide);
    return [state];
}
export const overlayHelperViewActions = {
    show(data) {
        ebGlobalChannel.publish(EBAPIs.helperViews.overlay.show, data);
    },
    hide() {
        ebGlobalChannel.publish(EBAPIs.helperViews.overlay.hide);
    },
};
