import React from 'react';
import { StyleSheet } from 'react-native';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Row from './layout/Row';
const Divider = ({ variant, orientation = 'horizontal', color, style, ...viewProps }) => {
    const [{ styles }] = useTheme(stylesGenerator, { variant, color, orientation }, [
        variant,
        color,
        orientation,
    ]);
    return React.createElement(Row, { style: [styles.container, style], ...viewProps });
};
const stylesGenerator = createThemedStyle(({ theme, select }, { variant = 'item-divider', color, orientation }) => {
    const orientationStyle = {};
    if (orientation === 'horizontal') {
        orientationStyle.height = 1;
    }
    else {
        orientationStyle.width = 1;
    }
    return {
        container: StyleSheet.flatten([
            orientationStyle,
            {
                alignSelf: 'stretch',
                backgroundColor: color ??
                    (variant === 'item-divider'
                        ? select({
                            dark: theme.palette.divider.main,
                            light: theme.palette.divider.main,
                            // dark: theme.palette.divider.main,
                            // light: theme.palette.divider.dark,
                        })
                        : theme.palette.divider.dark),
            },
        ]),
    };
});
export default React.memo(Divider);
