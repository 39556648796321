import React from 'react';
import { hiddenViewHelperActions } from 'src/components/20-helper-views/hidden-view/useHiddenView';
import HTMLTTSProcessor from './HTMLTTSProcessor';
export default function processHTML(html) {
    return new Promise(resolve => {
        hiddenViewHelperActions.render(React.createElement(HTMLTTSProcessor, { html: html, onCompleted: text => {
                resolve(text);
                hiddenViewHelperActions.clear();
            } }));
    });
}
