import React from 'react';
import { ISelectionType } from 'src/components/10-atoms/form/inputs/SelectInput/types';
import SelectInputItem from 'src/components/10-atoms/form/inputs/select-input-group/SelectInputItem';
import BottomDrawerLayout from 'src/components/20-helper-views/bottom-drawer/BottomDrawerLayout';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import GQLPaginatedList from 'src/lib/graphql/components/GQLPaginatedList';
import useI18n from 'src/modules/translations/hooks/useI18n';
import styleUtils from 'src/utils/styles';
import ListEmpty from './ListEmpty';
import ListLoader from './ListLoader';
import SelectedCommunity from './SelectedCommunity';
import useData from './useData';
const SelectExamLevelDrawer = ({ value, onChange, onCommunityChange, }) => {
    const [{}] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData(value.community);
    return (React.createElement(BottomDrawerLayout, { yAxis: "flex-start", style: styleUtils.containerHeight },
        React.createElement(BottomDrawerLayout.Title, null, translate('Select Exam Level')),
        React.createElement(GQLPaginatedList, { LoaderComponent: ListLoader, ListEmptyComponent: ListEmpty, ListHeaderComponent: value.community && (React.createElement(SelectedCommunity, { community: value.community, onPress: onCommunityChange })), queryState: state.listQuery, queryActions: actions.listQuery, variables: state.listQueryVariables, renderItem: ({ item: examLevel }) => (React.createElement(SelectInputItem, { type: ISelectionType.SINGLE, isSelected: examLevel.id === value?.examLevel?.id, title: examLevel.data.title, onPress: () => onChange({
                    community: value.community,
                    examLevel: examLevel,
                }) })), containerStyle: { flex: 1 }, style: { flex: 1 } })));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default SelectExamLevelDrawer;
