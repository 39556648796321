import { useRequestNewLangAPI } from '../../apis/requestNewLang';
export default function useData() {
    const [requestNewLangAPIState, requestNewLangAPIActions] = useRequestNewLangAPI();
    const state = {
        requestNewLangAPI: requestNewLangAPIState,
    };
    const actions = {
        requestNewLangAPI: requestNewLangAPIActions,
    };
    return [state, actions];
}
