import React, { useState } from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import HTMLEditor from './Editor';
import styleUtils from 'src/utils/styles';
import useFormStyles from 'src/components/10-atoms/form/styles';
const HTMLEditorInput = React.forwardRef(({ onFocus, onBlur, style, ...htmlEditorProps }, ref) => {
    const [focused, setFocused] = useState(false);
    const [{}] = useTheme(stylesGenerator);
    const formStyles = useFormStyles({
        focused,
    });
    return (React.createElement(HTMLEditor, { ref: ref, style: [formStyles.inputContainer, styleUtils.ovfHidden, style], ph: 0, gutterBottom: false, onFocus: () => setFocused(true), onBlur: () => setFocused(false), ...htmlEditorProps }));
});
const stylesGenerator = createThemedStyle(({}) => ({}));
export default HTMLEditorInput;
