import React from 'react';
import ListLoaderLayout from 'src/components/10-atoms/loaders/ListLoaderLayout';
import { NAVBAR_HEIGHT } from 'src/components/templates/page/Navbar.template';
import Page from 'src/components/templates/page/Page.template';
import PageNavbar from 'src/components/templates/page/PageNavbar.template';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import GQLPaginatedList from 'src/lib/graphql/components/GQLPaginatedList';
import BlockedUserContainer from '../../components/blocked-user/container';
import BlockedUsersListEmpty from '../../components/blocked-user/list/Empty';
import useData from './useData';
import useI18n from 'src/modules/translations/hooks/useI18n';
const BlockedUsers = ({ navigation }) => {
    const [{}] = useTheme(stylesGenerator);
    const { translate } = useI18n();
    const [state, actions] = useData();
    const renderItem = ({ item }) => {
        return React.createElement(BlockedUserContainer, { user: item });
    };
    return (React.createElement(Page, null,
        React.createElement(GQLPaginatedList, { renderCollapsibleHeader: () => (React.createElement(PageNavbar, { title: translate('Blocked Users'), backType: "back", onBackPress: navigation.goBack })), collapsibleHeaderHeight: NAVBAR_HEIGHT, ListEmptyComponent: BlockedUsersListEmpty, LoaderComponent: () => (React.createElement(ListLoaderLayout, { loaderItemHeight: 72, count: 3 })), queryState: state.listQuery, queryActions: actions.listQuery, variables: state.listQueryVariables, renderItem: renderItem })));
};
const stylesGenerator = createThemedStyle(({ theme }) => ({}));
export default BlockedUsers;
