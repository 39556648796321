import { BlockEmbed } from 'quill/blots/block';
export default class LoadingImage extends BlockEmbed {
    static create(src) {
        const node = super.create(src);
        node.style.width = '100%';
        node.style.minHeight = '200px';
        node.style.position = 'relative';
        node.style.borderRadius = '12px';
        node.innerHTML = `<div class="image-loader-container">
        <div class="image-loader" />
      </div>`;
        if (typeof src === 'string') {
            const image = document.createElement('img');
            image.setAttribute('src', src);
            image.style.width = '100%';
            image.style.height = 'auto';
            image.style.borderRadius = '12px';
            node.appendChild(image);
        }
        return node;
    }
    static value(domNode) {
        const { src, custom } = domNode.dataset;
        return { src, custom };
    }
    static blotName = 'imageUploading';
    static className = 'image-uploading';
    static tagName = 'div';
}
