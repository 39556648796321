// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import merge from 'lodash/merge';
import palette from 'src/customizations/themes/10-parts/color-schemes/palette';
const commonThemeSettings = {
    typography: {
        fontFamily: "'Montserrat', sans-serif",
    },
    palette: {
        primary: {
            main: palette.brand['500'],
        },
    },
};
const lightTheme = createTheme(merge(commonThemeSettings, {
    palette: {
        mode: 'light',
    },
}));
const darkTheme = createTheme(merge(commonThemeSettings, {
    palette: {
        mode: 'dark',
    },
}));
const MUISetup = ({ children }) => {
    const [{ theme }] = useTheme(stylesGenerator);
    return (React.createElement(ThemeProvider, { theme: theme.themeID === 'light' ? lightTheme : darkTheme },
        React.createElement(CssBaseline, null),
        React.createElement(LocalizationProvider, { dateAdapter: AdapterDateFns }, children)));
};
const stylesGenerator = createThemedStyle(({}) => ({}));
export default MUISetup;
