import Quill from 'quill';
import LoadingImage from './LoadingImage';
import { snackbarHelperActions } from 'src/components/20-helper-views/snackbar/useSnackbar';
import { filePickerHelperViewActions } from 'src/components/10-atoms/file-picker/hooks/useFilePicker';
import { FileType } from 'src/components/10-atoms/file-picker/types';
class ImageUploader {
    quill;
    options;
    constructor(quill, options) {
        this.quill = quill;
        this.options = options;
        if (typeof this.options.upload !== 'function') {
            console.warn('[Missing config] upload function that returns a promise is required');
        }
        this.handleDrop = this.handleDrop.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
        // `capture: true` will ensure that the image isn't pasted as base64 by core of Quill
        // else it will cause duplicate images in the editor
        this.quill.root.addEventListener('drop', this.handleDrop, {
            capture: true,
        });
        this.quill.root.addEventListener('paste', this.handlePaste, {
            capture: true,
        });
    }
    async selectLocalImage() {
        this.quill.focus();
        const pickedFiles = await filePickerHelperViewActions.pick({
            allowedTypes: FileType.IMAGES,
            allowMultiple: false,
        });
        const file = pickedFiles?.[0];
        file && this.readAndUploadFile(file.file);
    }
    handleDrop(evt) {
        if (evt.dataTransfer &&
            evt.dataTransfer.files &&
            evt.dataTransfer.files.length) {
            evt.stopPropagation();
            evt.preventDefault();
            if (document.caretRangeFromPoint) {
                const selection = document.getSelection();
                const range = document.caretRangeFromPoint(evt.clientX, evt.clientY);
                if (selection && range) {
                    selection.setBaseAndExtent(range.startContainer, range.startOffset, range.startContainer, range.startOffset);
                }
            }
            else {
                const selection = document.getSelection();
                const range = document.caretPositionFromPoint(evt.clientX, evt.clientY);
                if (selection && range) {
                    selection.setBaseAndExtent(range.offsetNode, range.offset, range.offsetNode, range.offset);
                }
            }
            let file = evt.dataTransfer.files[0];
            setTimeout(() => {
                this.readAndUploadFile(file);
            }, 0);
        }
    }
    handlePaste(evt) {
        let clipboard = evt.clipboardData || window.clipboardData;
        // IE 11 is .files other browsers are .items
        if (clipboard && (clipboard.items || clipboard.files)) {
            let items = clipboard.items || clipboard.files;
            const IMAGE_MIME_REGEX = /^image\/(jpe?g|gif|png|svg|webp)$/i;
            for (let i = 0; i < items.length; i++) {
                if (IMAGE_MIME_REGEX.test(items[i].type)) {
                    let file = items[i].getAsFile ? items[i].getAsFile() : items[i];
                    if (file) {
                        evt.preventDefault();
                        evt.stopPropagation();
                        setTimeout(() => {
                            this.readAndUploadFile(file);
                        }, 0);
                        // Allow pasting only image at a time
                        break;
                    }
                }
            }
        }
    }
    readAndUploadFile(file) {
        if (!file) {
            return;
        }
        const fileReader = new FileReader();
        this.quill.insertText(this.quill.getSelection(true).index, '\n');
        const range = this.quill.getSelection(true);
        fileReader.addEventListener('load', () => {
            const base64ImageSrc = fileReader.result;
            const placeholderBlot = this.insertPlaceholderImage(range, base64ImageSrc);
            this.options.upload(file).then(imageUrl => {
                this.removePlaceholderImage(placeholderBlot);
                this.insertUploadedImage(range, imageUrl);
            }, error => {
                this.removePlaceholderImage(placeholderBlot);
                console.warn(error);
                snackbarHelperActions.show({
                    variant: 'NEGATIVE',
                    message: 'Failed to upload the image',
                });
            });
        }, false);
        fileReader.readAsDataURL(file);
    }
    insertPlaceholderImage(range, url) {
        const index = range?.index ?? this.quill.getLength();
        this.quill.insertEmbed(index, LoadingImage.blotName, `${url}`, Quill.sources.USER);
        const [placeholderBlot] = this.quill.getLine(index);
        return placeholderBlot;
    }
    insertUploadedImage(range, url) {
        const index = range?.index ?? this.quill.getLength();
        // Insert the server saved image
        this.quill.insertEmbed(index, 'image', `${url}`, Quill.sources.USER);
        this.quill.setSelection(index + 2, Quill.sources.USER);
    }
    removePlaceholderImage(placeholderBlot) {
        const index = this.quill.getIndex(placeholderBlot);
        this.quill.deleteText(index, 1, Quill.sources.USER);
    }
}
export default ImageUploader;
