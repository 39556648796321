import React from 'react';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import Row from 'src/components/10-atoms/layout/Row';
import Column from 'src/components/10-atoms/layout/Column';
import Typography from 'src/components/10-atoms/typography/Typography';
import { TypographyVariant } from 'src/customizations/themes/10-parts/typography';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import withPress from 'src/hoc/withPress';
const TopicItem = ({ selected, category, topic }) => {
    const [{ styles, theme }] = useTheme(stylesGenerator);
    return (React.createElement(Row, { ph: theme.spacing.page, pv: theme.spacing.standard.xs, bg: selected
            ? theme.palette.primary.lightest
            : theme.palette.background.main, yAxis: "center", spacing: theme.spacing.standard.md },
        React.createElement(Row, { bg: category.color, style: styles.bullet }),
        React.createElement(Column, { flex: true },
            React.createElement(Typography, { color: theme.palette.typography.secondary, variant: TypographyVariant.CAPTION }, category.title),
            React.createElement(Typography, { numberOfLines: 1 }, topic)),
        selected && React.createElement(FeatherIcon, { name: "check" })));
};
const stylesGenerator = createThemedStyle(({}) => ({
    bullet: {
        height: 10,
        width: 10,
        borderRadius: 10,
    },
}));
export default withPress(TopicItem);
