import React from 'react';
import useTheme from 'src/customizations/themes/hooks/useTheme';
import createThemedStyle from 'src/customizations/themes/utils/createThemedStyles';
import Row from '../layout/Row';
const LoaderBase = ({ style, ...rowProps }) => {
    const [{ styles }] = useTheme(stylesGenerator);
    return React.createElement(Row, { style: [styles.container, style], ...rowProps });
};
const stylesGenerator = createThemedStyle(({ theme }) => ({
    container: {
        borderRadius: theme.borderRadius.standard.lg,
        backgroundColor: theme.palette.loader.placeholder,
    },
}));
export default LoaderBase;
