import Quill from 'quill';
import { isValidLink } from 'src/utils/url';
import HyperLink from '../formats/HyperLink';
import delay from 'src/utils/delay';
class PasteLink {
    quill;
    constructor(quill) {
        this.quill = quill;
        // this.quill.root.addEventListener('paste', this.handlePaste.bind(this), {
        //   capture: true,
        // });
    }
    static async paste(quill, value) {
        await delay(700);
        const range = quill.getSelection(true);
        quill.deleteText(range, Quill.sources.USER);
        quill.insertText(range.index, value.title, Quill.sources.USER);
        quill.setSelection({
            index: range.index,
            length: value.title.length,
        });
        quill.format(HyperLink.blotName, value, Quill.sources.USER);
        quill.setSelection(range.index + value.title.length);
    }
    handlePaste(event) {
        let clipboard = event.clipboardData || window.clipboardData;
        if (clipboard && !clipboard.files.length) {
            const pastedText = clipboard.getData('text');
            if (isValidLink(pastedText)) {
                event.preventDefault();
                event.stopPropagation();
                const range = this.quill.getSelection(true);
                const text = this.quill.getText(range);
                PasteLink.paste(this.quill, {
                    title: text || pastedText,
                    link: pastedText,
                });
            }
        }
    }
}
export default PasteLink;
