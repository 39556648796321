import { gql } from '@apollo/client';
import useGQLMutation from 'src/lib/graphql/hooks/useGQLMutation';
import { EP_QUESTION_DETAILS_QUERY } from './getQuestionDetails';
const MARK_QUESTION_AS_DUPLICATE_MUTATION = gql `
  mutation EP_MarkQuestionAsDuplicate(
    $duplicateQuestionId: ID!
    $questionId: ID!
  ) {
    ep_markQuestionAsDuplicate(
      duplicateQuestionID: $duplicateQuestionId
      questionID: $questionId
    )
  }
`;
export function useEPMarkQuestionAsDuplicateMutation(options) {
    return useGQLMutation(MARK_QUESTION_AS_DUPLICATE_MUTATION, {
        ...options,
        refetchQueries: [EP_QUESTION_DETAILS_QUERY],
    });
}
