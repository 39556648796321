/**
 * This hook can be used to trigger a callback when the given
 * threshold conditions are met.
 * Once the callback is triggered, a snapshot of the matched
 * conditions and timestamp are stored, which is later used
 * to compare thresholds against the matched timestamp and stored
 * thresholds.
 * For example:
 * Let's assume condition:
 * ```JSON
 * {
 *   communityQuestionsViewed: 5
 * }
 * ```
 * This condition will be triggered once a User views 5 Community Questions.
 * For the next trigger, the User will have to view 5 more Questions and the timestamp
 * should have crossed atleast the given `frequency` duration. Once this condition
 * is met again, then the stored values are updated with the matched values(Actual UsageProfile values)
 * as opposed to `threshold + cond.threshold` because if the UsageProfile value has gone pretty
 * high before the `frequency` duration has met, then we might end up triggering callback
 * very shortly once again causing a bad user experience.
 *
 * @example
 * - trigger inAppReview(once in 3 months)
 */
import StorageKeys from 'src/lib/storage/storageKeys';
import { convertDaysToMS } from 'src/utils/date';
import usePersistedState from '../../globalState/usePersistedState';
import useEffectWithCtx from '../../useEffectUtils/useEffectWithCtx';
import useUsageProfile from '../useUsageProfile';
export default function useUsageProfileThreshold(cond, callback) {
    const [condState, setCondState] = usePersistedState(StorageKeys.usageProfileThresholdCondState, {});
    const [usageProfileAccessors] = useUsageProfile();
    const getPrevThreshold = (param) => {
        if (!condState[cond.id]) {
            return 0;
        }
        return condState[cond.id].prevThresholds[param] ?? 0;
    };
    const getCurrentThreshold = (param) => {
        return cond.thresholds[param] + getPrevThreshold(param);
    };
    const validateCond = async () => {
        const matches = await Promise.all(Object.keys(cond.thresholds).map(async (param) => {
            const threshold = getCurrentThreshold(param);
            const usageValue = (await usageProfileAccessors.getValue(param)) ?? 0;
            // console.log('[UsageProfileThreshold] ValidationCond:', {
            //   threshold,
            //   usageValue,
            //   param,
            // });
            return usageValue >= threshold;
        }));
        return matches.every(Boolean);
    };
    const getUpdatedThresholds = async () => {
        if (!condState[cond.id]) {
            return cond.thresholds;
        }
        return await Object.keys(cond.thresholds).reduce(async (aggr, param) => {
            const updatedAggr = await aggr;
            updatedAggr[param] = await usageProfileAccessors.getValue(param);
            return updatedAggr;
        }, Promise.resolve({}));
    };
    const markCondComplete = async () => {
        condState[cond.id] = {
            lastCalled: Date.now(),
            prevThresholds: await getUpdatedThresholds(),
        };
        setCondState({ ...condState });
    };
    const shouldValidateCond = () => {
        // If callback was never called before
        // then call it
        if (!condState[cond.id]) {
            return true;
        }
        // If frequency wasn't provided, then
        // this isn't a repeat cond, hence ignore
        if (typeof cond.frequency !== 'number') {
            return false;
        }
        return (Date.now() >=
            condState[cond.id].lastCalled + convertDaysToMS(cond.frequency));
    };
    useEffectWithCtx(ctx => {
        const shouldValidate = ctx.shouldValidateCond();
        if (shouldValidate) {
            (async () => {
                const isValid = await ctx.validateCond();
                if (isValid) {
                    ctx.markCondComplete();
                    ctx.callback();
                }
            })();
        }
    }, { condState, validateCond, markCondComplete, callback, shouldValidateCond }, 
    // We've added the ref of `usageProfileAccessors.getValue` in deps
    // because there is no direct way to access usageProfile and this
    // acts as a hack as the ref changes when usageProfile(state) changes
    // within useUsageProfile hook
    [usageProfileAccessors.getValue]);
}
