/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import FeatherIcon from 'src/components/10-atoms/icons/FeatherIcon';
import { RootTab } from 'src/navigation/TabNavigation';
import withSuspense from 'src/navigation/withSuspense';
import useI18n from '../translations/hooks/useI18n';
const Home = withSuspense(React.lazy(() => import('./screens/DebugSettings')));
export const renderDebugTabRoutes = () => {
    const { translate } = useI18n();
    return (React.createElement(RootTab.Group, null,
        React.createElement(RootTab.Screen, { name: "Debug_Settings", options: {
                tabBarLabel: translate('Debug'),
                tabBarIcon: ({ color, size }) => (React.createElement(FeatherIcon, { name: "settings", color: color, size: size })),
            }, component: Home })));
};
